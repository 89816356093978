import React, { useState } from "react";
import {
  uidproject,
  comma,
  moneyDates,
  removeComma,
  getContract,
  loadImage,
  offerok,
  alertreturn,
  getTime,
  TextAreas,
} from "../../service/project";
import { useEffect } from "react";
import Downloadfile from "../../service/components/Downfile";
import Swal from "sweetalert2";
import { modaltap } from "../../service/tabarray";
import Out_Main_contract from "../Outsourcings/Out_Main_contract";
// import Exestimate from '../../service/components/Exestimate';
import Out_Contract_signature from "../Outsourcings/Out_Contract_signature";
import { exuserinfos } from "../../service/dataarray";
import Pdfcontain from "../../service/components/Pdfcontain";
import Toptabs from "../../service/components/Toptabs";
import Basic_contract_My from "../Outsourcings/Basic_contract_My";
import Estimatecell from "../../service/components/Estimatecell";


function Projectpgs({
  pg,
  path,
  setPgs,
  setupPgs,
  admin,
  my,
  fine,
  contract,
  userinfo,
  setContract,
  setProjectData,
  setNum,
  bigLabels,
  setBigl,
  exdata,
  offerlist,
  setprojecthistory
}) {
  const [nums, setNums] = useState(0);
  const [contractPage, setContractPage] = useState(0)
  const [imageSrc, setImageSrc] = useState(1);

  useEffect(() => {

    if (path && bigLabels && !admin) {
      uidproject(path).then(function (response) {
        const saveListWithConvertedMinicate = response.data.map((item) => {
          if (item.minicate) {
            item.minicate = JSON.parse(item.minicate);
            item.conditions = JSON.parse(item.conditions);
          }
          return item;
        });
        if (path && !admin && !fine) {
          const pgValue = saveListWithConvertedMinicate[0]

          setPgs(pgValue);

          const adminstatus = saveListWithConvertedMinicate[0].adminstatus;
          function findLabelKeyByAdminstatus(adminstatus) {
            for (const key in bigLabels) {
              if (bigLabels.hasOwnProperty(key)) {
                const array = bigLabels[key];
                for (const item of array) {
                  if (item.label === adminstatus) {
                    return key;
                  }
                }
              }
            }
            return null;
          }
          const key = findLabelKeyByAdminstatus(adminstatus);
          setBigl(key);
          setNum([
            key,
            key === "전체"
              ? 0
              : key === "프로젝트등록"
                ? 1
                : key === "견적진행"
                  ? 2
                  : key === "계약진행"
                    ? 3
                    : key === "프로젝트 진행"
                      ? 4
                      : 5,
          ]);
          setupPgs(saveListWithConvertedMinicate[0]);
        } else {
          setProjectData(saveListWithConvertedMinicate[0]);
        }
      });
    }
    if (!fine) {
      getContract(path).then(function (response) {
        if (response.data) {
          const pgValue = response.data[0]

          setContract(pgValue);
          if (!admin && pgValue && !pgValue.b2) {
            setNums(0)
          } else if (!admin && pgValue && pgValue.b2 && !pgValue.c1) {
            setNums(1)
          } else if (!admin && pgValue && pgValue.c1 && !pgValue.d2) {
            setNums(3)
          } else if (!admin && pgValue && pgValue.d2 && !pgValue.d4) {
            setNums(4)
          } else if (!admin && pgValue && pgValue.d4 && !pgValue.d5) {
            setNums(5)
          } else if (!admin && pgValue && pgValue.d5f) {
            setNums(6)
          } else {
            setNums(0)
          }
        }
      });
    }
  }, []);
  useEffect(() => {
    if (nums !== 3 && nums !== 0) {
      if (nums === 1 && contract && contract.estimate) {
        loadImage(
          contract,
          setImageSrc,
          nums === 1 ? "estimate" : "specification"
        );
      } else if (nums === 2 && contract && contract.specification) {
        loadImage(
          contract,
          setImageSrc,
          nums === 1 ? "estimate" : "specification"
        );
      } else if (nums === 4 && contract && contract.develop1) {
        loadImage(contract, setImageSrc, "develop1");
      } else if (nums === 5 && contract && contract.develop2) {
        loadImage(contract, setImageSrc, "develop2");
      } else if (nums === 6 && contract && contract.develop3) {
        loadImage(contract, setImageSrc, "develop3");
      } else {
        alertreturn(
          "notready",
          nums === 1
            ? "견적서"
            : nums === 2
              ? "사양서"
              : nums === 3
                ? "계약서"
                : nums === 4
                  ? "1차개발보고서"
                  : nums === 5
                    ? "2차개발보고서"
                    : "최종개발보고서"
        );
        loadImage(
          exdata,
          setImageSrc,
          nums === 1
            ? "estimate"
            : nums === 2
              ? "specification"
              : nums === 3
                ? "contract"
                : nums === 4
                  ? "develop1"
                  : nums === 5
                    ? "develop2"
                    : "develop3"
        );
      }
    } else if (
      (nums === 3 && !contract) ||
      (nums === 3 && contract && !contract.c2f)
    ) {
      alertreturn("notready", "계약서");
    }
  }, [nums]);
  const moneyDate = (num, tab) => {
    Swal.fire({
      title: "입금 날짜를 입력해주세요",
      html: '<input  type="date" id="swal-input1" class="projectAdd_inputbox">        <input placeholder="금액을 입력해주세요"  type="number" id="swal-input2" class="projectAdd_inputbox mt10">',
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "입력",
      showLoaderOnConfirm: true,
      cancelButtonText: `아니요`,
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
        ];
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        const inputDate = result.value;

        moneyDates(contract.uid, tab, inputDate[0], inputDate[1])
          .then((response) => {

            Swal.fire({
              title: `입금날짜가 수정되었습니다`,
            });
            setPgs((prevPg) => ({
              ...prevPg,
              adminstatus: tab,
            }));
            if (num === 1) {
              setContract((prevPg) => ({
                ...prevPg,
                beforemoney: inputDate[1],
                d1f: inputDate[0],
              }));
            } else if (num === 2) {
              setContract((prevPg) => ({
                ...prevPg,
                middlemoney: inputDate[1],
                d3f: inputDate[0],
              }));
            } else if (num === 3) {
              setContract((prevPg) => ({
                ...prevPg,
                aftermoney: inputDate[1],
                d6f: inputDate[0],
              }));
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            Swal.fire("입금 날짜를 업데이트하는 동안 오류가 발생했습니다.");
          });
      } else {
        console.log("User canceled the input.");
      }
    });
  };
  const buttonRender = (label, value) => {
    const money =
      label === "선금"
        ? contract.beforemoney
        : label === "중도금"
          ? contract.middlemoney
          : contract.aftermoney;
    const dates =
      label === "선금"
        ? contract.d1f
        : label === "중도금"
          ? contract.d3f
          : contract.d6f;
    const labels =
      label === "선금"
        ? !contract.d1f
        : label === "중도금"
          ? !contract.d3f
          : !contract.d6f;

    return (
      <>
        {pg.adminstatus === `${label} 납부` && labels ? (
          <>
            <span>{label} 납부중</span>
            <button
              className="navibtn_hover ps ml10"
              onClick={() => moneyDate(value, pg.adminstatus)}
            >
              입력
            </button>
          </>
        ) : money ? (
          <>
            <span className="mr5">{label} 완료</span>
            <div className="">
              <div className="f12 ">입금날짜 : {dates}</div>
              <div className="f12">입금액 : {comma(money)}원</div>
            </div>
          </>
        ) : (
          <span>대기중</span>
        )}
      </>
    );
  };
  const offerOks = (num) => {

    let fieldToUpdate =
      num === 1 ? "b3f" :
        num === 2 ? "c1f" :
          num === 4 ? "d2f" :
            num === 5 ? "d4f" :
              num === 6 ? "d5f" :
                (() => {
                  // 다른 값에 대한 처리 추가 (필요에 따라)
                  console.error("Unexpected value for num");
                  return "";
                })();

    const path = num == 1 ? "estimate" : num == 4 ? "develop1" : num == 5 ? "develop2" : "develop3";
    Swal.fire({
      title: "승인 하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: `아니요`,
      confirmButtonText: "네",
    }).then((result) => {
      if (result.isConfirmed) {
        const lengthPoint = offerlist.length
        offerok(contract.uid, getTime(), path, pg.adminstatus, lengthPoint, userId).then(function (response) {
          if (response.data) {



            if (num === 6) {

              setPgs(prevData => ({
                ...prevData,
                ['adminstatus']: '잔금 납부'
              }));
            } else if (num === 1 && offerlist.length === 1) {
              setContract(prevData => ({
                ...prevData,
                ['b2f']: getTime(),
                ['b3']: getTime(),
                ['b3f']: getTime()
              }));
              setprojecthistory(prevData => ({
                ...prevData,
                ['b2f']: getTime(),
                ['b3']: getTime(),
                ['b3f']: getTime()
              }));
              setPgs(prevData => ({
                ...prevData,
                ['adminstatus']: '견적 승인',
                ['b2f']: getTime(),
                ['b3']: getTime(),
                ['b3f']: getTime()
              }));
            } else if (num === 1 && offerlist.length !== 1) {
              setContract(prevData => ({
                ...prevData,
                [fieldToUpdate]: getTime()
              }));
              setPgs(prevData => ({
                ...prevData,
                ['adminstatus']: '견적 승인',
                [fieldToUpdate]: getTime()
              }));
              setprojecthistory(prevData => ({
                ...prevData,
                [fieldToUpdate]: getTime()
              }));
            } else {
              setContract(prevData => ({
                ...prevData,
                [fieldToUpdate]: getTime()
              }));
            }
            Swal.fire({
              title: "승인되었습니다",
              icon: "success",
            });

          }
        });
      }
    });
  };
  const countNum = (value) => {

    if (value === 1) {
      if (contractPage === 5) {
        Swal.fire({
          title: '마지막페이지 입니다',
          icon: 'warning'
        })
      } else {
        setContractPage(contractPage + 1)

      }
    } else {
      if (contractPage === 0) {
        Swal.fire({
          title: '처음페이지 입니다',
          icon: 'warning'
        })
      } else {
        setContractPage(contractPage - 1)

      }
    }
  }
  const lastOffer = offerlist && offerlist[offerlist.length - 1] || null
  return (
    <>
      {pg ? (
        <div
          className={
            fine
              ? "add_right_wrap full"
              : " wide pgdetail ModalMypageInfo-1 mt0 rd10 "
          }
        >
          {my ? (
            <nav>
              <ul className="w100 flex">
                {modaltap.map((item) => (
                  <li
                    key={item.value}
                    className={item.value === nums ? "selected p10-1" : "p10-1"}
                    onClick={() => setNums(item.value)}
                  >
                    {`${item.label}`}
                  </li>
                ))}
              </ul>
            </nav>
          ) : (
            ""
          )}
          {nums === 0 ? (
            <>
              <div className="pgbox_top flex j a mb20 ">
                <h1 className="pgT mb0 w100 mt10">
                  <span className="mr10 gbox_hover">
                    {fine ? "프로젝트 등록" : pg.adminstatus}
                  </span>
                  {pg.title}
                </h1>
                <div className="flex j a w50">
                  <div className="flex"></div>
                  <div>
                    <div className="updatetimes">
                      {" "}
                      등록일 : {fine ? getTime() : pg.createdtime}
                    </div>
                    {!fine ?
                      <div className="updatetimes">
                        {" "}
                        수정일 : {fine ? getTime() : pg.updatetime}
                      </div>
                      : null
                    }
                  </div>
                </div>
              </div>

              <div className="flex subcatebox a">
                <span className="mr10 catebox w_round_tag_cateBox fontWb">
                  {pg.cateother ? pg.cateother : pg.cate}
                </span>
                <div className="crossline"></div>
                <ul className="flex ml10 w_round_tag_c fontWb" >
                  {pg.minicateother ? (
                    <>
                      {" "}
                      {pg.minicate.map((value) => (
                        <li key={value} className="">
                          {value}
                        </li>
                      ))}
                      <li>{pg.minicateother}</li>
                    </>
                  ) : (
                    pg.minicate.map((value) => (
                      <li key={value} className="mr20">
                        {value}
                      </li>
                    ))
                  )}
                </ul>
              </div>
              <div className="table_container flex j">
                <div className="flex w100">
                  <ul className="w50 mr10">
                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">1. 희망 금액  </span>
                        <span>:</span>
                      </div>

                      <span className="flex j a w100">
                        {comma(
                          Number(removeComma(pg.price)) +
                          Number(removeComma(pg.price) * 0.1)
                        )}
                        원{admin && lastOffer ?
                          <span className="ml10 priceTag">
                            {lastOffer.num === 1 || 3 ? '최종 금액 : ' : '수정 금액 : '}

                            {comma(
                              Number(removeComma(lastOffer.price)) +
                              Number(removeComma(lastOffer.price) * 0.1)
                            )}
                            원
                          </span>
                          : null}
                      </span>
                    </li>

                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">2. 예상 기간  </span>
                        <span>:</span>
                      </div>
                      <span className="flex j a w100" > {pg.term}일
                        {admin && lastOffer ?
                          <span className="ml10 priceTag">
                            {lastOffer.num === 1 || 3 ? '최종 기간 : ' : '수정 기간 : '}
                            {lastOffer.term} 일
                          </span>
                          : null}

                      </span>
                    </li>

                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">3. 파트너 조건   </span>
                        <span>:</span>
                      </div>
                      <span className="listwrap">

                        {pg.conditions.map((value) => (
                          <div className="list" key={value}>
                            <span className="comma">
                              ,
                            </span>
                            <span className="mr5">
                              {value}
                            </span>
                          </div>
                        ))}
                      </span>
                    </li>
                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">4. 시작 일자  </span>
                        <span>:</span>
                      </div>

                      <span>{pg.startdate}</span>
                    </li>
                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">5. 진행 상태  </span>
                        <span>:</span>
                      </div>

                      <span>
                        {pg.pStatus === "new"
                          ? "신규로 진행합니다"
                          : pg.pStatus === "better"
                            ? "기존 진행했던 프로젝트의 개선"
                            : pg.pStatus === "ing"
                              ? "기존 진행했던 프로젝트의 유지보수"
                              : ""}
                      </span>
                    </li>
                  </ul>
                  <ul className="w50">
                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">6. 담당자 </span>
                        <span>:</span>
                      </div>
                      <span>{pg.client} ({pg.company})</span>
                    </li>
                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">7. 이메일 </span>
                        <span>:</span>
                      </div>

                      <span>{pg.email}</span>
                    </li>
                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">8. 주소지 </span>
                        <span>:</span>
                      </div>
                      <span>{pg.clientaddress}</span>
                    </li>
                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">9. 연락처 </span>
                        <span>:</span>
                      </div>
                      <span>{pg.clientphone}</span>
                    </li>
                    <li className="flex">
                      <div className="flex j mr10">
                        <span className="table_label  bold w110 mr10">10. 회의 방식 </span>
                        <span>:</span>
                      </div>
                      <h1>
                        {pg.meet === "on"
                          ? "온라인"
                          : pg.meet === "off"
                            ? "오프라인"
                            : pg.meet === "onoff"
                              ? "온오프라인"
                              : ""}
                      </h1>
                    </li>
                  </ul>
                </div>


              </div>

              <div className="pgbody">
                <div className="pgboxs">
                  <span className="name_subtitle f20">11. 프로젝트 개요 </span>
                  <h2 className="">
                    <TextAreas value={pg.outline} />
                  </h2>
                </div>
                <div className="pgboxs">
                  <span className="name_subtitle bold f20">12. 개발 핵심 기술</span>
                  <h2 className=""> {pg.needskill}</h2>
                </div>
                <div className="pgboxs">
                  <span className="name_subtitle bold f20">
                    13. 최종 산출물{" "}
                  </span>
                  <h2 className="">
                    <TextAreas value={pg.lastprojects} />
                  </h2>
                </div>
                <div className="pgboxs">
                  <span className="name_subtitle bold f20">
                    14. 프로젝트 상세 설명
                  </span>
                  <h2 className="">
                    <TextAreas value={pg.projects} />
                  </h2>
                </div>

                <div className="pgboxs">
                  <span className="name_subtitle bold f20">15. 프로젝트 관련 파일</span>
                  <ul className="list mt20">
                    {pg.files ? (
                      <Downloadfile lists={pg.files} type={"multi"} />
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </>
          ) : nums !== 3 ? (
            <>
              <div>
                <Pdfcontain
                  top={true}
                  list={
                    nums === 1 && contract && !contract.estimate
                      ? exdata.estimate
                      :
                      nums === 1 && contract && contract.estimate
                        ? contract.estimate
                        : nums === 2 && contract && contract.specification
                          ? contract.specification
                          : nums === 2 && contract && !contract.specification
                            ? exdata.specification
                            : nums === 4 && contract && contract.develop1
                              ? contract.develop1
                              : nums === 4 && contract && !contract.develop1
                                ? exdata.develop1
                                : nums === 5 && contract && contract.develop2
                                  ? contract.develop2
                                  : nums === 5 && contract && !contract.develop2
                                    ? exdata.develop2
                                    : nums === 6 && contract && contract.develop3
                                      ? contract.develop3
                                      : nums === 6 && contract && !contract.develop3
                                        ? exdata.develop3
                                        : ""
                  }
                  single={true}
                  type={
                    nums === 1
                      ? "estimate"
                      : nums === 2
                        ? "specification"
                        : nums === 4
                          ? "develop1Upload"
                          : nums === 5
                            ? "develop2Upload"
                            : nums === 6
                              ? "develop3Upload"
                              : ""
                  }
                  types="only"
                  num={nums}
                  mini={true}
                  file={imageSrc.base64}
                  agree={true}
                  nums={nums}
                  data={contract}
                  onClick={offerOks}
                />
                {nums === 1 ?
                  <Estimatecell array={offerlist} detailProject={pg} />
                  : null}
                {(nums === 4 && contract && !contract.d2f) ||
                  (nums === 5 && contract && !contract.d4f) ||
                  (nums === 6 && contract && !contract.d5f) ? (
                  <div></div>
                ) : null}
                {!contract ||
                  (nums === 1 && !contract.estimate) ||
                  (nums === 2 && !contract.specification) ||
                  (nums === 3 && contract && !contract.c2f) ||
                  (nums === 4 && !contract.develop1) ||
                  (nums === 5 && !contract.develop2) ||
                  (nums === 6 && !contract.develop3) ? (
                  <div className="text_absolute">
                    {nums === 1
                      ? "견적서"
                      : nums === 2
                        ? "사양서"
                        : nums === 3
                          ? "계약서"
                          : "개발보고서"}{" "}
                    예시입니다
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : nums === 3 && userinfo ? (
            <div>
              <Toptabs countNum={countNum} top={true} data={contract} userinfo={userinfo} value={'개발 계약서'} num={contractPage} />
              {contractPage === 0 ?
                <Out_Main_contract
                  contract={contract && contract.c2f ? contract : exdata}
                />
                : contractPage >= 1 && contractPage <= 4 ?
                  <Basic_contract_My

                    num={contractPage}
                    userinfo={contract && contract.c2f ? userinfo : exuserinfos}
                  />
                  : contractPage === 5 ?
                    <div className="p20tb">
                      <Out_Contract_signature
                        notmain={true}
                        userinfo={contract && contract.c2f ? userinfo : exuserinfos}
                        contract={contract && contract.c2f ? contract : exdata}
                      />
                    </div>
                    : null}
              {!contract || (nums === 3 && contract && !contract.c2f) ? (
                <div className="text_absolute">
                  {nums === 1
                    ? "사양서"
                    : nums === 2
                      ? "사양서"
                      : nums === 3
                        ? "계약서"
                        : nums === 4
                          ? "프로젝트정보"
                          : "개발보고서"}{" "}
                  예시입니다
                </div>
              ) : (
                ""
              )}
              {/* {contract && contract.c2f ? (
                <PDFDownloader contract={contract} userinfo={userinfo} />
              ) : null} */}
            </div>
          ) : nums === 1 ? (
            <></>
            // <Exestimate project={pg} array={offerlist} top={true} data={contract} userinfo={userinfo} />

          ) : null}

          {contract && userinfo && admin && pg && contract.c2f ? (
            <div>
              <div className="right_top">결제 내용 정보</div>
              <div className="mb40">
                {contract ? (
                  <div className="flex c">
                    <div className="money_top w100 mt40">
                      <h1>1. 계약 정보</h1>
                      <div className="money_top_table  ">
                        <div className="money_title mt10 mb10 flex">
                          <div className="p10 w100px">계약명</div>
                          <div className="p10 w_100px">{contract.title}</div>
                        </div>
                        <div className="out_cell p0">
                          <div className="flex j a cell_line cell_head">
                            <div className="w15 w">구분</div>
                            <div className="w25 w">지급금액(VAT제외)</div>
                            <div className="w25 w">대금 지급 조건 및 방법</div>
                            <div className="w35 w">결제 상태</div>
                          </div>
                          <div className="flex j cell_line a">
                            <div className="w15 w">선금</div>
                            <div className="w25 w">
                              {" "}
                              ￦{" "}
                              {comma(
                                (removeComma(contract.allprice) *
                                  Number(contract.beforeprice)) /
                                100
                              )}{" "}
                              원
                            </div>
                            <div className="w25 w">
                              {contract.beforepricecon}
                            </div>
                            <div className="w35 w flex a">
                              {buttonRender("선금", 1)}
                            </div>
                          </div>
                          <div className="flex j cell_line a">
                            <div className="w15 w">중도금</div>
                            <div className="w25 w">
                              {" "}
                              ￦{" "}
                              {comma(
                                (removeComma(contract.allprice) *
                                  Number(contract.middleprice)) /
                                100
                              )}{" "}
                              원
                            </div>
                            <div className="w25 w">
                              {contract.middlepricecon}
                            </div>
                            <div className="w35 w flex a">
                              {buttonRender("중도금", 2)}
                            </div>
                          </div>
                          <div className="flex j cell_line a">
                            <div className="w15 w">잔금</div>
                            <div className="w25 w">
                              {" "}
                              ￦{" "}
                              {comma(
                                (removeComma(contract.allprice) *
                                  Number(contract.afterprice)) /
                                100
                              )}{" "}
                              원
                            </div>
                            <div className="w25 w">
                              {contract.afterpricecon}
                            </div>
                            <div className="w35 w flex a">
                              {buttonRender("잔금", 3)}
                            </div>
                          </div>
                        </div>
                        {/* <div  className='  flex'>
            <div className='p10 w100px'>입금기한</div>
            <div className='p10 w_100px'>{addDaysToDate(detail.companydate,0)} - {addDaysToDate(detail.companydate,days)}</div>
          </div> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {!my && !admin&&!fine?
      <div className='pgbody mt40'>
        <h1>추천 프로젝트</h1>
       <Fivebox array={pg.cate} uid={pg.uid}/>
      </div>
      :''
      } */}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Projectpgs;