import React from "react";

function Rule_project1({ }) {
  return (
    <>
      <div className="right_top">
        프로젝트 등록
      </div>
      <div className="registration-container">

        <div className="vertical-flow">

          <div className="boxSpecial">클라이언트</div>
          <div className="step-box">
            <div>① 견적서 제출<br />(3일 이내, 무료)</div>
          </div>
          <div className="step-box">
            <div>② 프로젝트 정보작성</div>
          </div>
          <div className="step-wrap flex a">
            <div className="step-box">
              <div>③ 프로젝트 등록</div>
            </div>
            <div className="arrow_tip"></div>
            <div className="connecting-line"></div>
            <div className="relative">
              <div className="boxSpecial_ab mb10">니즈로봇</div>
              <div className="step-box">
                <div>① 견적서 제출<br />(3일 이내, 무료)</div>
              </div>
            </div>
          </div>
          <div className="step-box">
            <div>④ 비밀유지서약서<br />(선택사항)</div>
          </div>
          <div className="step-wrap flex a">
            <div className="step-box">
              <div>⑤ 개발사양서 요청<br />(선택사항, 유료)</div>
            </div>
            <div className="arrow_tip"></div>
            <div className="connecting-line"></div>
            <div className="relative">
              <div className="boxSpecial_ab mb10">니즈로봇</div>
              <div className="step-box">
                <div>② 개발사양서 제출<br />(상세 개발사양서)</div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div className="p2020 textCenter" >
        ※ 별도의 개발사양서 작성 요청은 유료(5만원)로 진행되고 프로젝트 계약시, 환급됩니다.
      </div>
    </>
  );
}

export default Rule_project1;