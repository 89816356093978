
import { useEffect, useState } from 'react';
import React from 'react';
import Navi from './Navi';
import { morelogin } from './service/project';
import { FaRegComment } from "react-icons/fa";
import { IoIosAlert } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useRealTimeData } from './RealTimeDataContext';

const Header = () => {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { setDisplayTime, messageNew, displayTime, token } = useRealTimeData();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 115) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <div className={isHeaderFixed ? 'header fix' : isHovered ? 'header hoverd' : isHovered && isHovered ? 'header fix hoverd' : 'header '}>
        <a className='logo' href='/'>
          <img src={'https://ifh.cc/g/bNC3w6.png'} alt='니즈로봇 외주개발 logo' />
        </a>
        <div>
          {displayTime ?
            <div className='miniTop flex r a'> 남은 시간  {displayTime}<button className='navibtn_hover border_white ps f12 ml10' onClick={() => morelogin(setDisplayTime)}>연장하기</button>
              <Link to={'/MYMESSAGE/all'} className='alertIconWrap flex a ml10'><FaRegComment className='alertIcon f22' />
                {messageNew ?
                  <div className='alertIcontop'>
                    <IoIosAlert className='f22' />
                  </div> : ''}
              </Link>
            </div>
            : ''
          }
          <Navi setIsHovered={setIsHovered} isHovered={isHovered} login={token} grade={token ? token.uGrade : null} setIsHeaderFixed={setIsHeaderFixed} />
        </div>
      </div>

    </>
  )
};
export default Header;