
import React from 'react';
import Projectpgs from '../Project/Projectpgs';
function Addprojectfine({ uid, projectdata, setProjectData, userinfo }) {


  return (
    <div className='add_right_box'>
      <div className='right_top'>
        프로젝트 등록 완료
      </div>
      <div className='right_body'>
        <div className='project_ban mb40'>
          프로젝트 등록이 정상 완료되었습니다. <span className='ml10'> {!userinfo.confidentiality ? '프로젝트의 비밀유지가 필요하다면, ＂상단의 비밀유지 서약서＂를 작성해 주세요.' : '프로젝트 진행 현황을 확인하려면, ＂마이페이지＂로 이동해 주세요'}</span>
        </div>
        <Projectpgs fine={true} path={uid} right={true} pg={projectdata} setProjectData={setProjectData} />

      </div>
    </div>
  );
}

export default Addprojectfine;