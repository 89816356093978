import React from "react";
import {
  alertreturn,
  comma,
  offerMessage,
  removeComma,
  uploaddocufile,
} from "../../service/project";
import Estimatecell from "../../service/components/Estimatecell";

function Project_estimate({
  handleChanges,
  offerlist,
  project,
  offer,
  contract,
  estimateImgRef,
  savePdfFile,
  userId,
}) {

  const onClickEstimate = () => {
    if ((contract && contract.estimate) && offer.adminprice && offer.adminterm || contract && contract.newestimate && offer.adminprice && offer.adminterm) {
      if (Array.isArray(offerlist) && offerlist.length % 2 === 0) {
        offerMessage(
          project.uid,
          offer.adminprice,
          offer.adminterm,
          offer.admincontent,
          "admin",
          offerlist.length > 0 ? offerlist.length + 1 : 1,
          project.userId,
          "estimate"
        ).then(function (response) {
          if (response.data) {
            alertreturn("success", "견적이");
            if (contract.newestimate) {
              uploaddocufile(
                contract.newestimate,
                project.uid,
                contract.newestimatename,
                "estimate",
                userId
              );
            }
          }
        });
      } else if (offerlist.length === 1 || offerlist.length === 3) {
        offerMessage(
          project.uid,
          offer.adminprice,
          offer.adminterm,
          offer.admincontent,
          "admin",
          offerlist.length === 1 ? 1 : 3,
          project.userId,
          "estimate"
        ).then(function (response) {
          if (response.data) {
            alertreturn("success2", "견적이");
            if (contract.newestimate) {
              uploaddocufile(
                contract.newestimate,
                project.uid,
                contract.newestimatename,
                "estimate",
                userId
              );
            }
          }
        });
      } else {
        alertreturn("lose", "견적을");
      }
    } else if (!offer.adminprice) {
      alertreturn("lose", "재견적비용");
    } else if (!offer.adminprice) {
      alertreturn("lose", "재견적기간");
    } else {
      alertreturn("lose", "견적파일");
    }
  };
  return (
    <div className="w100">
      <Estimatecell array={offerlist} detailProject={project} />

      <div className="right_body_wrap flex j w100">
        <div className=" minititle  p10 w50">
          <h2 className="mb10">재견적 요청 비용</h2>
          <input
            value={offer.adminprice}
            onChange={(e) => handleChanges("adminprice", e.target.value)}
            className="projectAdd_inputbox"
            placeholder="￦"
          />
          <p className="subtext p5">
            예상 결제 금액 : ￦
            {comma(
              Number(removeComma(offer.adminprice)) +
              Number(removeComma(offer.adminprice) * 0.1)
            )}
          </p>
        </div>
        <div className=" minititle  p10 w50">
          <h2 className="mb10">재견적 요청 기간</h2>

          <input
            value={offer.adminterm}
            onChange={(e) => handleChanges("adminterm", e.target.value)}
            type="number"
            className="projectAdd_inputbox"
            placeholder="일"
          />
        </div>
      </div>

      <div className="right_body_wrap w100 ">
        <div className="flex  minititle a p10">
          <h2 className="mr10">기타 요청 내용</h2>
          <p className="subtext">기타 외 수정 요청 사항을 입력해주세요</p>
        </div>
        <textarea
          value={offer.admincontent}
          onChange={(e) => handleChanges("admincontent", e.target.value)}
          className="projectAdd_inputbox"
        />
      </div>
      <div className="flex mt20">
        <label htmlFor="estimateFileInput" className="gbox_hover mr10">
          견적서 첨부
          <input
            ref={estimateImgRef}
            className="none"
            id="estimateFileInput"
            type="file"
            accept="*"
            onChange={(e) => {
              handleChanges(
                "newestimate",
                URL.createObjectURL(e.target.files[0])
              );
              handleChanges("newestimatename", e.target.files[0].name);
              savePdfFile(estimateImgRef.current);
            }}
          />
        </label>
        {contract ? (
          <div className="mr10 file-container ">
            {contract.newestimatename && contract.newestimatename
              ? contract.newestimatename
              : contract.newestimate && contract.newestimate
                ? contract.newestimate.split("/")[
                contract.newestimate.split("/").length - 1
                ]
                : contract.estimatename && contract.estimatename
                  ? contract.estimatename
                  : contract.estimate && contract.estimate
                    ? contract.estimate.split("/")[
                    contract.estimate.split("/").length - 1
                    ]
                    : "파일을 첨부해 주세요"}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="mt20">
        <button onClick={() => onClickEstimate()} className="navibtn_hover">
          견적서 제출
        </button>
      </div>
    </div>
  );
}

export default Project_estimate;
