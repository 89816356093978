
import React, { useState } from 'react';
import { companyinfo } from '../../service/dataarray';
import Swal from 'sweetalert2';
import { motion } from 'framer-motion';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";
function Basic_contract({ userinfo, mini }) {

  const [num, setNum] = useState(1)

  const counthandle = (value) => {
    if (num === 1 && value === 'm') {
      Swal.fire({
        title: '처음페이지 입니다',
        icon: 'warning'
      })
    } else if (num === 4 && value === 'p') {
      Swal.fire({
        title: '마지막페이지 입니다',
        icon: 'warning'
      })
    } else if (value === 'p') {
      setNum(num + 1)
    } else {
      setNum(num - 1)
    }
  }
  return (
    <div className={mini ? 'basic_contractbox mini relative' : 'basic_contractbox relative'}>
      <div className={num == 1 ? 'z4 paper' : num == 2 ? 'z3 paper' : num == 2 ? 'z2 paper' : 'z1 paper'}>
        <motion.div
          animate={{ opacity: num === 1 ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className=''>
            <div className='intitle'>
              계약일반조건
            </div>
            <h1 className='mb10'><span className='comPany'>{userinfo && userinfo.company}(이하‘갑’)</span>과 <span className='myCompany'>{companyinfo.mycompany}(이하‘을’)</span>는 신의와 성실의 원칙에 입각하여 다음과 같이 계약을 체결한다</h1>

            <strong>제1조 목적</strong>
            <p>본 계약은 ‘갑’이 발주한 용역을 '을'이 수행함에 있어 필요한 제반 사항을 정함을 목적으로 한다.</p>

            <strong>제2조 권리 및 의무</strong>
            <p>'을'은 '갑'이 정한 소정의 절차에 따라 해당 제품을 개발 납품하고 '갑'은 계약서에 정한 대가를 '을'에게 지급한다.</p>

            <strong>제3조 계약문서</strong>
            <p>1. 계약문서는 용역계약서, 계약일반조건, 견적서, 개발내역서, 기타 '갑'과 '을'이 합의한 계약 관련 문서 등으로 구성되며 상호 보완의 효력을 가진다.</p>
            <p>2. 계약 문서상의 의도하지 않은 상충이나 의미의 모호함이 발견된 때에는 '갑'과 '을'은 다음의 적용 우선순위에 따라 이를 해결한다.</p>
            <p>   ① 변경계약서</p>
            <p>   ② 기술규격서 변경 추록</p>
            <p>   ③ '갑'의 검토, 승인을 거친 '을' 제출 문서</p>

            <strong>제4조 문서의 제출 및 통지</strong>
            <p>1. '갑'은 본 계약의 원활한 문서 수․발신 및 사업관리를 위해 사업관리자 1인을 두어 '을'과의 업무 연락을 한다.</p>

            <strong>제5조 계약이행상의 감독</strong>
            <p>1. '갑'은 계약의 적정한 이행을 확보하기 위하여 재료 및 기타 제조공정에 대하여 감독할 수 있으며 '을'에 대하여 필요한 조치를 요구할 수 있다.</p>
            <p>2. 계약 특수조건이나 구매/개발 내역서에 공정 감독 또는 중간검사사항, 보고서 승인사항이 명시되어 있을 때에는 '을'은 사전에 공정계획서를 '갑'에게 제출하고  필요한 시점에 감독 또는 검사를 요청하여야 하며 생산 감독이나 중간검사를 요하는 사항은 이를 마치지 아니하고는 공정진행을 계속 할 수 없다.</p>
          </div>
        </motion.div>
      </div>
      <div className={num == 2 ? 'z4 paper' : num == 3 ? 'z3 paper' : num == 4 ? 'z2 paper' : 'z1 paper'}>
        <motion.div

          animate={{ opacity: num === 2 ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className=''>
            <strong>제6조 계약의 변경</strong>
            <p>1. '갑'은 계약 후 필요에 따라 물품/용역의 수량, 금액, 규격, 납기 등 계약조건을 상호 협의하여 변경할 수 있다.</p>
            <p>2. 계약변경으로 인하여 계약금액을 조정하거나 납기 등이 변경되는 경우에는 양당사자의 상호 합의에 의하여 이를 반영하는 계약변경을 해야 한다.</p>
            <p>   3. 계약변경사항은 양 당사자 간 서면으로 해야 하며 변경 계약서에 서명 날인한 날로부터 변경된 업무를 진행하여야 한다. 그러나 계약이행의 긴급성을 이유로 '갑'이 요청할 경우, '을'은 서면 계약변경 이전에 변경된 업무를 신속히 수행하여야 한다.</p>
            <strong>제7조 규격</strong>
            <p>1. 모든 물품/용역의 규격은 계약서에 명시된 규격명세(용역시방서 및 도면포함), 규격서 번호, '갑'이 제시한 견품의 규격에 맞아야 하며 구매 목적에 맞는 신품이어야 한다.</p>
            <p>2. 계약서에 규격이 명시되어 있지 아니한 경우에는 상관습과 기술적 타당성에 부합되는 품질에 이상이 없는 제품이어야 한다.</p>

            <strong>제8조 검사</strong>
            <p>1. '갑'은 개발 완료 후 7일 이내에 검사를 하고, 하자가 있는 경우에는 지체 없이 '을'에게 통지하여야 한다.</p>
            <p>2. '을'은 하자가 발생한 경우에 이를 신속하게 처리하여야 하며, '갑'과 별도 협의한 기한까지 재 납품 한다.</p>
            <p>3. 1항의 검사기간 내에 '갑'으로부터 별도의 통지가 없는 경우에는 검사에 합격한 것으로 간주한다. 이 경우에는 검사기간이 경과한 날의 익일에 검수가 된 것으로 간주한다.</p>
            <p>4. '을'은 인수검사 및 공장 확인 시험의 원활한 진행을 위하여 적극 협력하여야 한다.</p>

            <strong>제9조 납 품</strong>
            <p>1. 물품의 납품은 '갑'이 정한 절차에 따라 검사를 받은 합격품을 '갑'이 지정한 장소에서 '을'로부터 수령한 때에 완료된다. 다만 계약서상 설치, 또는 성능시험 등 별도의 조건이 있을 때에는 이를 완전히 수행한 때에 완료된다.</p>
            <p>2. '을'은 계약에 규정된 납품 조건 및 일정에 따라 물품을 납품하고 용역을 수행하여야 한다.</p>

            <strong>제10조 납품서류</strong>
            <p>'을'은 용역과 관련된 다음의 서류를 '갑'에게 제출해야 한다.</p>
            <p>1. 개발 완료보고서</p>
            <p>2. 펌웨어 BIN 파일</p>


          </div>
        </motion.div>
      </div>
      <div className={num == 3 ? 'z4 paper' : num == 4 ? 'z3 paper' : num == 1 ? 'z2 paper' : 'z1 paper'}>
        <motion.div
          animate={{ opacity: num === 3 ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className=''>
            <strong>제11조 대가의 지급</strong>
            <p>1. '을'은 물품의 납품 또는 용역의 수행을 완료한 후에 대가지급을 '갑'에게 청구 한다.</p>
            <p>2. 계약상 분납으로 되어 있거나 '갑'의 필요로 분납을 요청하였을 경우에는 '을'는 분납 결과물에 대하여 대가 지급을 청구할 수 있다.</p>
            <p>3. '갑'은 대가지급을 청구 받은 날로부터 특별한 사유가 없는 한 계약서에 정한 기일 계약서에 대가를 지급한다. 다만, '을' 청구내용의 전부 또는 일부가 부당함을 발견한 때에는 그 사유를 명시하여 '을'에게 당해 청구서를 재 반송 할 수 있으며 지급을 보류하거나 해당금액을 공제할 수 있다.</p>

            <strong>제12조 지체상금</strong>
            <p>1. '을'이 계약서에서 정한 계약기한 내에 완료하지 아니한 경우에 '갑'은 계약금액(부가세 포함)의 0.25%에 해당하는 금액(지체상금)을 대가지급 청구액에서 공제 한다.</p>
            <p>2. 단, 납품일 이전에 '을'에게 발생한 천재지변 등 불가항력 또는 '을'의 귀책이 아닌 경우 등 면책사유에 해당될 경우 지체상금을 부과 하지 않는다.</p>
            <p>3. '을'은 예상되는 계약지연에 대하여 신속히 '갑'에게 통지하여야 하며 계약지연을 만회하기 위한 최선의 노력을 다하여야 한다.</p>
            <strong>제13조 계약의 해제 및 해지</strong>
            <p>1. '갑'은 '을'이 다음 각 호의 1에 해당하는 경우에는 계약의 전부 또는 일부를 해제 또는 해지 할 수 있다.</p>
            <p>   ① 납기 내에 물품/용역을 납품하지 못하거나 '을'의 귀책사유로 납기 내 계약이행이 불가능 하다고 인정되는 경우.</p>
            <p>   ② '갑'의 요구하는 서류제출, 기타 협조 요구에 정당한 이유 없이 불응하거나 고의로 지연 시켰을 때.</p>
            <p>   ③ '갑'의 승낙 없이 제3자에게 하도급 시켰을 때.</p>
            <p>   ④ 기타 '을'이 본 계약사항을 위반하고 그 위반으로 인하여 계약목적을 달성 할 수 없다고 인정될 때.</p>

            <p>2. '을'은 '갑'이 다음 각 호 1에 해당하는 경우에는 계약의 전부 또는 일부를 해제 또는 해지 할 수 있다.</p>
            <p>   ① '을'이 용역 수행을 위해서 필요한 자료 등을 '갑'이 협의한 날짜까지 제공하지 않아 개발 일정 등에 차질이 예상 되는 경우.</p>
            <p>   ② '을'이 용역 수행 중 '갑'의 의사 결정이나 TEST 등이 필요하여 요청한 날로부터 7일이 경과하여 개발 일정 등에 차질이 예상 되는 경우.</p>
            <p>   ③ '을'이 용역 수행 중 '갑'의 요구 사항이 변경되어 개발 일정에 차질이 예상되는 경우.</p>



          </div>
        </motion.div>
      </div>
      <div className={num == 4 ? 'z4 paper' : num == 1 ? 'z3 paper' : num == 2 ? 'z2 paper' : 'z1 paper'}>
        <motion.div
          animate={{ opacity: num === 4 ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className=''>
            <strong>제14조 하도급</strong>
            <p>1. '을'은 '갑'의 승인 하에 업무의 일부를 하수급자에게 하도급 할 수 있다.</p>
            <p>2. 이미 승인된 하수급자가 승인조건에 위반할 경우 '갑'은 '을'에게 하수급자의 교체를 요청할 수 있다.</p>

            <strong>제15조 양도</strong>
            <p>'을'은 '갑'의 서면 승인 없이는 계약상 권리의무를 제3자에게 양도할 수 없다.</p>

            <strong>제16조 사용권 및 비밀 유지</strong>
            <p>1. 도면, 규격, 기타 모든 자료 및 결과물을 포함하여 계약에 의하여 '을'이 개발하였거나 제공한 결과물은 '갑'의 독점적 재산이 되며 단, '갑'는 동 기술 및 기술정보를 사용료 없이 이용할 권한을 갖는다.</p>
            <p>2. '을'은 당해 계약을 통하여 얻은 모든 정보 또는 '갑'의 기밀사항을 계약 이행의 전후를 막론하고 외부에 공개할 수 없다.</p>

            <strong>제17조 분쟁의 해결</strong>
            <p>계약의 이행과정에서 발생하는 문제에 관한 해결은 쌍방의 합의에 의해 이루어지며, 합의에 이르지 않을 경우 관련 법령과 규정에 따라 해결한다.</p>

            <strong>제18조 계약의 갱신 및 종료</strong>
            <p>1. 본 계약은 계약서에 명시된 기간까지 효력을 유지한다.</p>
            <p>2. 계약 기간 만료 전 30일 이전까지 '갑' 또는 '을'은 상호 합의하에 계약 갱신을 위한 조건 및 내용을 협의할 수 있다.</p>
            <p>3. 본 계약은 계약서에 명시된 기간 만료 시 종료된다. 단, 계약 조건에 따라 조기 종료될 수 있다.</p>

            <strong>제19조 기타조항</strong>
            <p>1. 본 계약서는 계약 당사자 간의 상호 합의에 따라 성립되었으며, 기타 부득이한 사유가 없는 한 수정이나 해지되지 않는다.</p>
            <p>2. 본 계약서에 명시되지 않은 사항 또는 본 계약서 내용과 상충되는 경우, 당사자 간의 합의에 따라 처리한다.</p>

            <strong>제20조 준거법 및 관할법원</strong>
            <p>1. 본 계약서는 대한민국 법률에 의하여 해석되며, 본 계약에 관한 분쟁에 대한 소송은 대한민국 법률이 적용되는 관할법원에서 진행한다.</p>
            <strong>제21조 계약의 체결</strong>
            <p>이상의 조항에 동의하여 본 계약을 체결합니다.</p>
          </div>
        </motion.div>
      </div>
      <button onClick={() => counthandle('m')} className='leftmiddle greenbtn'><FaAngleLeft /></button>
      <button onClick={() => counthandle('p')} className='rightmiddle greenbtn'><FaAngleRight /></button>
    </div>

  );
}

export default Basic_contract;