import React from "react";

export default function SidebarItem({ name, active, handleClick }) {
  return (
    <button
      className={`sidebar-item w33 ${active ? "active" : ""}`}
      onClick={handleClick}
    >
      {name}
    </button>
  );
}
