import React from "react";
import { useState } from "react";
import Out_Main_contract from "./Out_Main_contract";
import Out_Basic_contract from "./Out_Basic_contract";
import Out_Contract_signature from "./Out_Contract_signature";
import { StampModal } from "../../service/components/Modals";

import { useLocation } from "react-router-dom";
import Toptabs from "../../service/components/Toptabs";

function Out_contract({
  userId,
  detail,
  openModalHandler,
  isOpen,
  num,
  setNum,
  userinfo,
  setStampid,
  stampid,
  divref,
  setDetail,
}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [fresh, setFresh] = useState(false);

  const onFresh = () => {
    setFresh(!fresh);
  };

  return (
    <>
      {num > 0 ? (
        <div className="add_right_wrap outwrap mt0">
          <div className="flex contractstep_wrap w100">
            <Toptabs
              uni={true}
              data={detail}
              value={"개발 계약서"}
              userinfo={userinfo}
              setNum={setNum}
              num={num}
            />
          </div>
          <div className="flex center"></div>
          <div ref={divref}>
            <div className="quote-container mt10">
              <div>
                <Out_Main_contract contract={detail} />
                <Out_Basic_contract userinfo={userinfo} />
                <Out_Contract_signature
                  setStampid={setStampid}
                  stampid={stampid}
                  notmain={detail.c2f ? true : false}
                  fresh={fresh}
                  userinfo={userinfo}
                  setContract={setDetail}
                  contract={detail}
                />
              </div>

            </div>
          </div>
          {isOpen ? (
            <StampModal
              onFresh={onFresh}
              openModalHandler={openModalHandler}
              userId={userId}
              isOpen={isOpen}
            />
          ) : (
            ""
          )}
          {detail.uid === "exampledata" ? (
            <div className="text_absolute">계약서 예시입니다</div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Out_contract;
