
export const navi = [
    {
        label: 'ABOUT',
        value: '/Company',
        path: '/Company',
        children: [
            {
                label: '회사소개',
                path: '/Company'
            },
            //     {label : '포트폴리오',
            //     path : '/Portfolio',

            //   },
            //     {label : '연혁',
            //     path :'/history'},
            {
                label: '회사정보',
                path: '/location'
            },
        ]
    },

    {
        label: '프로젝트등록',
        value: ['/Addprojects', '/Addprojects/confidentiality,/Addprojects/rule'],
        path: '/Addprojects/add',
        children:
            [{
                label: '프로젝트 진행방법',
                path: '/Addprojects/rule'
            },
            {
                label: '프로젝트 등록',
                path: '/Addprojects/add'
            },
            // {label : '회원가입',
            // path :'/Addprojects'},


            {
                label: '비밀유지서약서',
                path: '/Addprojects/confidentiality'
            },
            ]
    },
    //   {label : '프로젝트현황',
    //   value:'/Projects',
    //   path : '/Projects/list',
    //   children: [
    //     {label : '프로젝트 등록현황',
    //     path :'/Projects/list'},
    //   ]
    // },
    {
        label: '프로젝트계약',
        value: '/Outsourcing',
        path: '/Outsourcing/estimate',
        children: [
            {
                label: '견적서',
                path: '/Outsourcing/estimate'
            },
            {
                label: '개발사양서',
                path: '/Outsourcing/specification'
            },
            {
                label: '계약서',
                path: '/Outsourcing/contract'
            },
            {
                label: '비용결제',
                path: '/Outsourcing/payment'
            },


        ]
    },
    {
        label: '공개프로젝트',
        value: '/Portfolio',
        path: '/Portfolio',
        children: [
            {
                label: '공개프로젝트',
                path: '/Portfolio'
            },

        ]
    },
    // {label : '파트너등록',
    // value:'/Partner',
    // path : '/Partner/add',
    // children: [
    //   {label : '파트너 정보등록',
    // path :'/Partner/add'},
    // // {label : '회원가입',
    // // path :'/Addprojects'},
    // ]
    // },

    {
        label: 'MYPAGE',
        value: '/Mypage',
        path: '/Mypage/Myprojects/myProject',
        children: [
            {
                label: '마이프로젝트',
                path: '/Mypage/Myprojects/myProject'
            },
            {
                label: '내정보관리',
                path: '/Mypage/Myinfo/clientinfo'
            },
        ]
    },
    {
        label: 'MYMESSAGE',
        value: '/MYMESSAGE',
        path: '/MYMESSAGE/all',
        children: [
            {
                label: '전체',
                path: '/MYMESSAGE/all'
            },
            {
                label: '공지',
                path: '/MYMESSAGE/notice'
            },
            {
                label: '계약논의',
                path: '/MYMESSAGE/estimate'
            },
        ]
    },
]

export const Gnavi = [
    {
        label: 'ABOUT',
        value: '/Company',
        path: '/Company',
        children: [
            {
                label: '회사소개',
                path: '/Company'
            },
            //     {label : '포트폴리오',
            //     path : '/Portfolio',

            //   },
            //     {label : '연혁',
            //     path :'/history'},
            {
                label: '회사정보',
                path: '/location'
            },
        ]
    },

    {
        label: '프로젝트등록',
        value: ['/Addprojects', '/Addprojects/confidentiality,/Addprojects/rule'],
        path: '/Addprojects/add',
        children: [
            {
                label: '프로젝트 진행방법',
                path: '/Addprojects/rule'
            },
            {
                label: '프로젝트 등록',
                path: '/Addprojects/add'
            },


            {
                label: '비밀유지서약서',
                path: '/Addprojects/confidentiality'
            },
        ]
    },
    //   {label : '프로젝트현황',
    //   value:'/Projects',
    //   path : '/Projects/list',
    //   children: [
    //     {label : '프로젝트 등록현황',
    //     path :'/Projects/list'},
    //   ]
    // },
    {
        label: '프로젝트계약',
        value: '/Outsourcing',
        path: '/Outsourcing/estimate',
        children: [
            {
                label: '견적서',
                path: '/Outsourcing/estimate'
            },
            {
                label: '개발사양서',
                path: '/Outsourcing/specification'
            },

            {
                label: '계약서',
                path: '/Outsourcing/contract'
            },
            {
                label: '비용결제',
                path: '/Outsourcing/payment'
            },
        ]
    },
    // {label : '파트너등록',
    // value:'/Partner',
    // path : '/Partner/add',
    // children: [
    //   {label : '파트너 정보등록',
    // path :'/Partner/add'},
    // // {label : '회원가입',
    // // path :'/Addprojects'},
    // ]
    // },
    {
        label: '공개프로젝트',
        value: '/Portfolio',
        path: '/Portfolio',
        children: [
            {
                label: '공개프로젝트',
                path: '/Portfolio'
            },

        ]
    },
    {
        label: 'ADMIN',
        value: '/Admin',
        path: '/Admin/Adminproject',
        children: [
            {
                label: '전체프로젝트관리',
                path: '/Admin/Adminproject'
            },
            {
                label: '회원현황',
                path: '/Admin/Users'
            },
        ]
    },
    // {
    //     label: 'OTHER',
    //     value: '/Others',
    //     path: '/Others/Agreement',
    //     children: [
    //         {
    //             label: '이용약관',
    //             path: '/Others/Agreement'
    //         },
    //         {
    //             label: '개인정보처리방침',
    //             path: '/Others/Privacypolicy'
    //         },
    //     ]
    // },
    {
        label: 'MYPAGE',
        value: '/Mypage',
        path: '/Mypage/Myprojects/myProject',
        children: [
            {
                label: '마이프로젝트',
                path: '/Mypage/Myprojects/myProject'
            },
            {
                label: '내정보관리',
                path: '/Mypage/Myinfo/clientinfo'
            },
        ]
    },
    {
        label: 'MYMESSAGE',
        value: '/MYMESSAGE',
        path: '/MYMESSAGE/all',
        children: [
            {
                label: '전체',
                path: '/MYMESSAGE/all'
            },
            {
                label: '받은메세지',
                path: '/MYMESSAGE/recieve'
            },
            {
                label: '메세지작성',
                path: '/MYMESSAGE/writeMessage'
            },
        ]
    },


]

export const historyarray = [
    {
        index: 1,
        year: '2002-07-01 ~ 2006-12-15',
        data: '이동 통신 단말기 개발 (주식회사 팬택)'
    },
    {
        index: 1,
        year: '2007-05-29 ~ 2016-02-28',
        data: '지능형 로봇 개발 (주식회사 이디)'
    },
    {
        index: 1,
        year: '2016-09-02 ~ 2018-05-31',
        data: '코딩 교육용 모듈러 로봇 개발 및 제작'
    },
    {
        index: 1,
        year: '2018-06-01 ~ 2018-07-30 ',
        data: 'BLDC 모터 드라이버 개발 및 제작'
    },
    {
        index: 1,
        year: '2018-07-01 ~ 2018-10-10',
        data: '생산용 밴딩 머신 제어기 개발 및 제작'
    },
    {
        index: 1,
        year: '2018-12-03 ~ 2019-02-28',
        data: 'IoT 기반의 실내외 공기질 측정기기 개발 및 제작'
    },
    {
        index: 2,
        year: '2019-01-04 ~ 2019-03-04',
        data: '욕실용 살균 장치 개발'
    },
    {
        index: 2,
        year: '2019-07-10 ~ 2019-09-09 ',
        data: '커피 입자 소분 및 계량 장치 개발 및 제작'
    },
    {
        index: 2,
        year: '2019-08-19 ~ 2019-12-31',
        data: '비접촉식 음압 마스크 제어기 개발 및 제작'
    },
    {
        index: 2,
        year: '2019-09-01 ~ 2019-11-25',
        data: '호텔용 대화형 AI 스피커 개발 및 제작'
    },
    {
        index: 2,
        year: '2020-06-15 ~ 2020-09-30',
        data: '스마트 피트니스 웨어 제어기 개발 및 제작'
    },
    {
        index: 2,
        year: '2020-07-20 ~ 2020-10-20',
        data: '스마트 어항 시스템 개발 및 제작'
    },
    {
        index: 2,
        year: '2020-10-12 ~ 2020-12-31',
        data: '웨어러블 마사지 슈트 제어기 개발 및 제작'
    },
    {
        index: 2,
        year: '2020-10-23 ~ 2021-01-21',
        data: '음파 진동 방식의 콜드 브루 커피 머신 개발 및 제작'
    },
    {
        index: 2,
        year: '2021-01-18 ~ 2021-05-31',
        data: 'IoT 기반의 생체데이터 측정장치 개발 및 제작'
    },
    {
        index: 2,
        year: '2021-07-01 ~ 2021-09-30 ',
        data: '스마트 수면관리 장치 개발 및 제작'
    },
    {
        index: 3,
        year: '2021-08-23 ~ 2022-03-31 ',
        data: 'IoT 기반의 출입 관리 시스템 개발 및 제작'
    },
    {
        index: 3,
        year: '2021-10-01 ~ 2022-06-30 ',
        data: '스마트팜 통합 제어기 및 관제시스템 개발 및 제작 '
    },
    {
        index: 3,
        year: '2022-01-24 ~ 2022-03-18  ',
        data: 'IoT 기반의 소형 제습기 개발 및 제작'
    },
    {
        index: 3,
        year: '2022-03-22 ~ 2022-08-31 ',
        data: '전기 자동차 완속 충전기 개발 및 제작'
    },
    {
        index: 3,
        year: '2022-08-16 ~ 2023-04-30 ',
        data: '물류 운반 로봇(AGV) 개발 및 제작'
    },
    {
        index: 3,
        year: '2022-10-12 ~ 2022-12-31 ',
        data: 'IoT 기반의 화재감지 및 소화 장비 개발 및 제작'
    },
    {
        index: 3,
        year: '2023-01-16 ~ 2023-04-15 ',
        data: '교육용 멀티미디어 콘텐츠 플레이어 개발 및 제작'
    },
    {
        index: 3,
        year: '2023-05-19 ~ 2023-07-10 ',
        data: '100채널 FSR 기반 데이터 수집 스마트 메트리스 개발 및 제작'
    }

]

export const minicate = [
    { label: '제품기획', value: '제품기획' },
    { label: '디자인', value: '디자인' },
    { label: '기구설계', value: '기구설계' },
    { label: '회로설계', value: '회로설계' },
    { label: '펌웨어', value: '펌웨어' },
    { label: '모바일웹', value: '모바일웹' },
    { label: '웹앱', value: '웹앱' },
    { label: '서버구축', value: '서버구축' },
    { label: '제품인증', value: '제품인증' },
    { label: '금형 및 사출', value: '금형 및 사출' },

    { label: '시제품제작', value: '시제품제작' },
    { label: '양산', value: '양산' },
    { label: '기타', value: '기타' },
]

export const sminicate = [
    { label: '제품기획', value: '제품기획' },
    { label: '디자인', value: '디자인' },
    { label: '기구설계', value: '기구설계' },
    { label: '회로설계', value: '회로설계' },
    { label: '펌웨어', value: '펌웨어' },
    { label: '모바일웹', value: '모바일웹' },
    { label: '웹앱', value: '웹앱' },
    { label: '서버구축', value: '서버구축' },
    { label: '제품인증', value: '제품인증' },
    { label: '금형 및 사출', value: '금형 및 사출' },
    { label: '시제품제작', value: '시제품제작' },
    { label: '양산', value: '양산' },
    { label: '기타', value: '기타' },
]

export const cate = [
    {
        label: 'IOT제품',
        value: 0
    },
    {
        label: '로봇',
        value: 1
    },
    {
        label: '의료기기',
        value: 2
    },
    {
        label: '헬스케어',
        value: 3
    },
    {
        label: '스마트팜',
        value: 4
    },
    {
        label: '기타',
        value: 5
    }
]
export const conditionA = [
    {
        label: '개인 OR 팀',
        value: '개인 OR 팀'
    },
    {
        label: '개인사업자',
        value: '개인사업자'
    },
    {
        label: '법인사업자',
        value: '법인사업자'
    },
    {
        label: '업력 1년 이상',
        value: '업력 1년 이상'
    },
    {
        label: '보험발급 가능',
        value: '보험발급 가능'
    },
    {
        label: '기타',
        value: '기타'
    }
]

export const ingStatus = [
    {
        label: '아이디어만 보유중',
        url: 'https://ifh.cc/g/Tm0z2v.png',
        status: '1'
    },
    {
        label: '간단한 기획안 작성됨',
        url: 'https://ifh.cc/g/z4M2WH.png',
        status: '2'
    },
    {
        label: '상세한 기획안 작성됨',
        url: 'https://ifh.cc/g/gCFF05.png',
        status: '3'
    }
]
export const pricearray = [
    {
        label: '100만원',
        value: 1000000
    },
    {
        label: '200만원',
        value: 2000000
    },
    {
        label: '300만원',
        value: 3000000
    },
    {
        label: '400만원',
        value: 4000000
    },
    {
        label: '500만원',
        value: 5000000
    },
    {
        label: '600만원',
        value: 6000000
    },
    {
        label: '700만원',
        value: 7000000
    },
    {
        label: '800만원',
        value: 8000000
    },
    {
        label: '900만원',
        value: 9000000
    },
    {
        label: '1000만원',
        value: 10000000
    },
    {
        label: '2000만원',
        value: 20000000
    },
    {
        label: '3000만원',
        value: 30000000
    },
    {
        label: '4000만원',
        value: 40000000
    },
    {
        label: '5000만원',
        value: 50000000
    },
    {
        label: '6000만원',
        value: 60000000
    },
    {
        label: '7000만원',
        value: 70000000
    },
    {
        label: '8000만원',
        value: 80000000
    },
    {
        label: '9000만원',
        value: 90000000
    },
    {
        label: '10,000만원',
        value: 100000000
    },
    {
        label: '30,000만원',
        value: 300000000
    },
    {
        label: '50,000만원',
        value: 500000000
    },
]


export const sort = [
    {
        label: '금액 높은순',
        value: 'priceH'
    },
    {
        label: '금액 적은순',
        value: 'priceL'
    },
    {
        label: '최근 등록순',
        value: 'recent'
    },
    {
        label: '마감 임박순',
        value: 'shortT'
    },
    {
        label: '많은 기간순',
        value: 'longT'
    }
]



export const companytype = [
    {
        label: '선택 해주세요',
        value: '0'
    },
    {
        label: '법인 사업자',
        value: 'public'
    },
    {
        label: '개인 사업자',
        value: 'personal'
    },
    {
        label: '개인 OR 팀',
        value: 'team'
    },

]

export const clientleft = [
    {
        label: '클라이언트 정보',
        path: 'clientinfo',
        value: 0
    },
    {
        label: '신원인증',
        path: 'identification',
        value: 1
    },
    {
        label: '전자서명 관리',
        path: 'sealingmethod',
        value: 2
    },
    // {
    //     label:'계정관리',
    //     path:'account',
    //     value:3
    // },


]
export const taxtype = [
    {
        label: '일반과세자',
        value: '일반과세자'
    },
    {
        label: '간이과세자',
        value: '간이과세자'
    },
    {
        label: '부가가치세 면세 사업자',
        value: '부가가치세 면세 사업자'
    },

]
export const accountleft = [
    {
        label: '비밀번호변경',
        value: 0
    },
    {
        label: '회원탈퇴',
        value: 1
    }
]

export const stampleft = [
    {
        label: '날인 리스트',
        value: 0
    },
    {
        label: '날인 등록, 편집',
        value: 1
    },

    {
        label: '등록 완료',
        value: 2
    },


]
export const position = [
    {
        label: '사원',
        value: 0
    },
    {
        label: '연구원',
        value: 1
    },
    {
        label: '대리',
        value: 2
    },
    {
        label: '과장',
        value: 3
    },
    {
        label: '차장',
        value: 4
    },
    {
        label: '부장',
        value: 5
    },
    {
        label: '대표',
        value: 6
    },

]
export const confi_left = [
    {
        label: '1.본문',
        value: 0
    },
    {
        label: '2.전자서명',
        value: 1
    },

    {
        label: '3.서약완료',
        value: 2
    },

]
export const rule_left = [
    {
        label: '프로젝트 등록',
        value: 0
    },
    {
        label: '프로젝트 계약 및 진행',
        value: 1
    },



]

export const contract_left = [

    {
        label: '전체',
        value: 4
    },
    {
        label: '계약내용',
        value: 1
    },
    {
        label: '계약조건',
        value: 2
    },

    {
        label: '전자서명',
        value: 3
    },
]
export const price_left = [
    {
        label: '리스트',
        value: 0
    },
    {
        label: '결제 내용',
        value: 1
    },
]

export const before_price = [
    {
        label: '선택 해주세요',
        value: 0
    },
    {
        label: '계약 후 3일 이내',
        value: 1
    },
    {
        label: '계약 후 7일 이내',
        value: 2
    },
    {
        label: '계약 후 10일 이내',
        value: 3
    }
]
export const middle_price = [
    {
        label: '선택 해주세요',
        value: 0
    },
    {
        label: '시제품 납품 후 3일 이내',
        value: 1
    },
    {
        label: '시제품 납품 후 7일이내',
        value: 2
    },
    {
        label: '시제품 납품 후 10일이내',
        value: 3
    }
]
export const after_price = [
    {
        label: '선택 해주세요',
        value: 0
    },
    {
        label: '인증 시료 납품 후 3일이내',
        value: 1
    },
    {
        label: '인증 시료 납품 후 7일이내',
        value: 2
    },
    {
        label: '인증 시료 납품 후 10일이내',
        value: 3
    }
]

export const companyinfo = {
    mycompany: '니즈로봇',
    representative: '박진영',
    address: '서울시 노원구 화랑로 815, 제1실습관 5017호',
    comnum: '471-10-00399',
}
export const exuserinfos =
{
    company: '(주)클라이언트',
    companyaddressf: '서울시 노원구 상계동',
    companyaddressm: '공릉동',
    companyregiNum: '123-45-67890',
    c2f: 0,
    representative: '클라이언트',
    userId: 'example',
}


export const projectPlaceholder =
    `ex) 1. 프로젝트 제품의 주요 기능 및 특징
- 영상처리 기반의 식물 모니터링: 카메라와 센서를 통해 식물의 상태를 실시간으로 모니터링하고, 생장 상태 및 건강을 평가
- 자동 관수 및 영양 공급: 식물의 수분 및 영양 요구를 감지하여 자동으로 관수와 영양 공급을 조절기능
- 환경 제어: 온도, 습도, 조명 등 환경 요소를 모니터링하고 자동으로 조절하여 최적의 조건을 유지하는 기능
- 스마트 앱 통합: 스마트폰 앱을 통해 제품을 원격으로 모니터링하고 제어
`



export const searchOption = [
    {
        label: '전체',
        value: 0
    },
    {
        label: '프로젝트명',
        value: 1
    },
    {
        label: '아이디',
        value: 2
    },

    {
        label: '회사명',
        value: 3
    },


]

export const adminRegistration = [
    {
        label: '기본정보',
        value: 0
    },
    {
        label: '회사정보',
        value: 1
    },
    {
        label: '신원인증',
        value: 2
    },

]
export const userTop = [
    {
        label: '회사명',
        value: 0,
        class: 'tw10 p55 textc flex j a'
    },
    {
        label: '아이디',
        value: 1,
        class: 'tw10 p55 textc flex j a'
    },
    {
        label: '이름',
        value: 2,
        class: 'tw8 p55 textc flex j a'
    },
    {
        label: '이메일',
        value: 3,
        class: 'tw16 p55 textc flex j a'
    },
    {
        label: '번호',
        value: 4,
        class: 'tw14 p55 textc flex j a'
    },
    {
        label: '날인',
        value: 5,
        class: 'tw8 p55 textc flex j a'
    },
    {
        label: '비밀서지계약서',
        value: 6,
        class: 'tw12 p55 textc flex j a'
    },
    {
        label: '등록상태',
        value: 7,
        class: 'tw10 p55 textc flex j a'
    },
    {
        label: '프로젝트',
        value: 8,
        class: 'tw14 p55 textc flex j a'
    },


]

export const moneystatus = [
    {
        label: '선금 납부중',
        value: 1
    },
    {
        label: '선금 완료',
        value: 2
    },
    {
        label: '중도금 납부중',
        value: 3
    },
    {
        label: '중도금 완료',
        value: 4
    },
    {
        label: '잔금 납부중',
        value: 5
    },
    {
        label: '잔금 완료',
        value: 6
    },

]

export const withdrawalreason = [
    {
        label: '고객서비스 (포장상태, 상담) 가 미흡해요',
        value: 0
    },
    {
        label: '교환/환불/반품이 불편해요',
        value: 1
    },
    {
        label: '배송이 너무 늦어요 ',
        value: 2
    },
    {
        label: '웹 기능이 불만족해요',
        value: 3
    },
    {
        label: '자주 사용 하지 않아요 ',
        value: 4
    },

    {
        label: '개인정보가 유출될까 우려되요',
        value: 5
    }
]

export const techBoxesData = [
    {
        imageSrc: 'https://ifh.cc/g/zBmTdw.png',
        altText: '외주개발 니즈로봇 메인 페이지 제품 기획',
        title: '제품기획',
        subTitle: '(product planning)',
        description: ['제품의 기획과 사양정의,', '컨설팅을 도와드립니다'],
        delay: 0.7,
    },
    {
        imageSrc: 'https://ifh.cc/g/qrT3q3.png',
        altText: '외주개발 니즈로봇 메인 페이지 제품개발',
        title: '제품개발',
        subTitle: '(product development)',
        description: ['회로 설계, PCB 설계, 기구설계, 소프트웨어(앱)', '개발 등의 제품 설계를 도와드립니다'],
        delay: 1,
    },
    {
        imageSrc: 'https://ifh.cc/g/9HO3Rv.png',
        altText: '외주개발 니즈로봇 메인 페이지 제품생산',
        title: '제품생산',
        subTitle: '(product production)',
        description: ['금형 제작 및 사출, 제품 시험성적서, 제품 인증', '제품 조립 및 양산을 지원해드립니다'],
        delay: 1.2,
    },
];

export const imageArray = {
    subtitle_portfolio: 'https://ifh.cc/g/xscnWB.jpg'
}