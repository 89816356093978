
import React, { useState } from 'react';
import { motion } from "framer-motion";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { adminRegistration } from '../../service/dataarray';
import { ModalInfoview } from '../../service/components/Modals';
import Swal from 'sweetalert2';
import { okidentitys } from '../../service/project';

function AdminUserbox({ value, projectlist, stamplist, acor, setAcor, setUserlist, userlist }) {
    const [isOpen, setIsOpen] = useState(false);
    const openModalHandler = () => {
        setIsOpen(!isOpen);
    };
    const okidentity = () => {

        Swal.fire({
            title: "신원확인을 완료하시겠습니까?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: `아니요`,
            confirmButtonText: '네'
        }).then((result) => {
            if (result.isConfirmed) {
                okidentitys(value.userId).then(function (response) {
                    const updatedUserlist = userlist.map((user) => {
                        if (user.userId === value.userId) {
                            return { ...user, identity: 1 };
                        }
                        return user;
                    });

                    // 변경된 객체 배열을 상태로 설정
                    setUserlist(updatedUserlist);

                    Swal.fire(
                        '신원인증이 완료',
                        '확인 버튼을 눌러 주세요',
                        'success'
                    )
                })
            }
        })
    }
    const myinfo = value.uPhone && value.position && value.companypart ? true : false;
    const company = value.company && value.representative && value.companytype && value.companyregiNum && value.sector1 && value.sector2 && value.companyNum && value.taxmail && value.companyaddressf && value.companyaddressm ? true : false
    const document = value.identity ? true : false
    const myproject = projectlist
    const item = {
        hidden: { y: 400, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };

    const returnValue = (value) => {
        return (
            value ? value : '미작성'
        )
    }
    return (
        <>
            {value && projectlist ?

                <motion.li
                    className='userwraps'
                    variants={item}>
                    <ul onClick={() => {
                        if (acor === value.id) {
                            setAcor('')
                        } else {
                            setAcor(value.id)
                        }
                    }
                    } className='userbox a flex j'>
                        <li className='tw10 p55'>
                            {value.company ? value.company : '미작성'}
                        </li>
                        <li className='tw10 p55'>
                            {value.userId}
                        </li>
                        <li className='tw8 p55'>
                            {value.uName}
                        </li>
                        <li className='tw16 p55'>
                            {value.email}
                        </li>
                        <li className='tw14 p55'>
                            {returnValue(value.uPhone)}
                        </li>
                        <li className='tw8 p55'>
                            {stamplist.length}개
                        </li>
                        <li className='tw12 p55'>
                            {value.confidentiality ? '작성완료' : '미작성'}
                        </li>
                        <li className='tw10 p55'>
                            <div >
                                {adminRegistration.map(value =>
                                    <div key={value.value} className={value.value === 0 && myinfo || value.value === 1 && company || value.value === 2 && document ? 'statustag color' : 'statustag'}>
                                        <span>{value.label}</span>
                                        {
                                            value.value === 0 && myinfo || value.value === 1 && company || value.value === 2 && document ?
                                                <AiOutlineCheckCircle />
                                                :
                                                <AiOutlineCloseCircle />
                                        }
                                    </div>
                                )}

                            </div>
                        </li>

                        <li className='tw14 p55'>
                            <div>
                                <div >
                                    <span>모든 프로젝트 :</span>
                                    <span>{myproject.length}개</span>
                                </div>
                                <div >
                                    <span>계약,진행중 :</span>
                                    <span>{myproject.filter(values => values.adminstatus === '계약중' || values.adminstatus === '프로젝트진행중').length}개</span>
                                </div>
                                <div >
                                    <span>완료 :</span>
                                    <span>{myproject.filter(values => values.adminstatus === '프로젝트완료').length}개</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    {value.id === acor ?
                        <ul className='flex detailuser'>
                            <li className='w33'>
                                <ul className='w100 detailuser_sub'>
                                    <li><span className='bold'>회사명</span> : {value.company ? value.company : '미작성'}</li>
                                    <li>
                                        <span className='bold'>대표자명</span>
                                        : {value.representative ? value.representative : '미작성'}</li>
                                    <li>
                                        <span className='bold'>회사 형태 </span>
                                        : {value.companytype ? value.companytype : '미작성'}</li>
                                    <li>
                                        <span className='bold'>사무실 주소</span>
                                        : {value.companyaddressf + value.companyaddressm ? value.companyaddressf + value.companyaddressm : '미작성'}</li>
                                </ul>
                            </li>
                            <li className='w33'>
                                <ul className='w100 detailuser_sub'>
                                    <li>
                                        <span className='bold'>사업자 등록번호</span>
                                        : {value.companyregiNum ? value.companyregiNum : '미작성'}</li>
                                    <li>
                                        <span className='bold'>업태</span>  : {value.sector1 ? value.sector1 : '미작성'}</li>
                                    <li>     <span className='bold'>종목</span>  : {value.sector2 ? value.sector2 : '미작성'}</li>
                                    <li>     <span className='bold'>사무실 연락처</span>  : {value.companyNum ? value.companyNum : '미작성'}</li>


                                </ul>

                            </li>
                            <li className='w33'>
                                <ul className='w100 detailuser_sub'>
                                    <li>     <span className='bold'>세금계산서 메일</span>  : {value.taxmail ? value.taxmail : '미작성'}</li>

                                    <li onClick={() => { setIsOpen(true) }}>     <span className='bold'>신원인증 파일</span> : <span className='pointer underline'>{value.file ? value.file.split('/')[value.file.split('/').length - 1] : '미작성'}</span></li>
                                    {
                                        value.identity ?
                                            <li className='bold' >신원인증 완료</li>
                                            :
                                            <button className='projectbtns' onClick={() => okidentity()}>신원인증 확인하기</button>
                                    }


                                </ul>

                            </li>
                        </ul>
                        : ''
                    }
                </motion.li>
                : ''
            }
            {isOpen ?
                <ModalInfoview fileinfo={[value.file, value.file.split('.').pop()]} isOpen={isOpen} userinfo={value.file} admin={true} openModalHandler={openModalHandler} />
                : ''
            }
        </>
    );
}

export default AdminUserbox;
