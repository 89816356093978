import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import { MenuItem } from "./MenuItem";
import { Gnavi, navi } from "../service/dataarray";
import Swal from 'sweetalert2';
const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};

export const Mnavi = ({ login, grade }) => {

  const [ver, setVer] = useState(navi)
  useEffect(() => {
    if (grade && login) {
      setVer(Gnavi.filter(values => values.label !== 'MYMESSAGE'))
    } else {
      setVer(navi.filter(values => values.label !== 'MYMESSAGE'))
    }


  }, [grade, login])
  const testHandler = (event, path) => {
    if (!login && path === '/Mypage/Myprojects/myProject') {
      event.preventDefault();
      Swal.fire({
        title: '로그인 해주세요',
        icon: 'warning'
      })
    }
  };
  const showConfirm = () => {
    Swal.fire({
      title: "로그아웃 하시겠습니까?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '네',
      cancelButtonText: `아니요`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: '로그아웃 되었습니다!',
          icon: 'success'
        }
        )
        localStorage.removeItem("token")
        localStorage.removeItem("userInfo")
        localStorage.removeItem("time")
        localStorage.removeItem("mlist")
        localStorage.removeItem("recent")
        window.location.href = '/'
      }
    })

  };



  return (
    <motion.ul className="mnavi" variants={variants}>
      {ver.map((value, i) => (
        <MenuItem onClick={(e) => testHandler(e, value.path)} value={value} i={i} key={i} />
      ))}
      {!login ?
        <li>
          <Link to={'/Login'} className='btnstyle'>로그인</Link>
        </li>
        :
        <li>
          <Link onClick={e => { showConfirm(e) }} className='btnstyle'>로그아웃</Link>
        </li>
      }
    </motion.ul>)
}

const itemIds = [0, 1, 2, 3, 4];
