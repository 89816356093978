import React from 'react';
import { Link } from 'react-router-dom';
import { getIcon } from '../project';


function ProjectTabs({ array, num, setNum, bigLabels }) {

  return (
    <div className='ProjectTabs w1480'>
      {bigLabels && num ? (
        <ul className="flex j">
          {Object.entries(bigLabels).map(([biglabel, values], index) => {

            const isActive = num[0] === biglabel;

            return (
              <li
                className={`${isActive ? 'active' : ''} `}
                onClick={() => setNum([biglabel, values[0].value])}
                key={values[0].value}
              >
                <Link className='top_tap' to={`${values[0].paths}`}>
                  <span className='tag_triangle'>

                  </span>
                  {getIcon(values[0].value)}
                  <span className='mr10'></span>
                  {biglabel}(
                  {biglabel === '전체' ? array.length : (
                    array && array.filter(proj => {
                      return bigLabels[biglabel].some(labelObj => labelObj.label === proj.adminstatus);
                    }).length
                  )})
                </Link>

              </li>
            );
          })}
        </ul>
      ) : (
        ''
      )}
    </div>
  );
}

export default ProjectTabs;