import React from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate, render } from "react-dom"
import App from './App';
import { CookiesProvider } from 'react-cookie';

import { HelmetProvider } from 'react-helmet-async';
const rootElement = ReactDOM.createRoot(document.getElementById('root'));
// if (rootElement.hasChildNodes()) {
//   hydrate(<HelmetProvider>
//     <CookiesProvider>
//       <App />
//     </CookiesProvider>
//   </HelmetProvider>, rootElement);
// } else {
//   render(<HelmetProvider>
//     <CookiesProvider>
//       <App />
//     </CookiesProvider>
//   </HelmetProvider>, rootElement);
// }
rootElement.render(<HelmetProvider>
  <CookiesProvider>
    <App />
  </CookiesProvider>
</HelmetProvider>);