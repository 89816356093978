import { imageArray } from "./dataarray"

export const metaInfoForMainPage = {
    title: '[외주개발 플랫폼] 가장 빠른 아웃소싱 업체 │ 니즈로봇',
    description:
        '당신의 아이디어를 가장 빠르게 현실로 만드는 외주개발 아웃소싱 플랫폼, 트리즈 기반의 저비용 외주개발(IoT, 로봇, 스마트팜, 헬스케어 등) 업체 니즈로봇',
    keywords: '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼',
    imgsrc: 'https://needsrobot.speedgabia.com/needsThumb.png', // 이미지 URL
    url: 'https://needsrobot.co.kr/', // 페이지 URL
}

//Company
export const metaInfoForCompanyPage = {
    title: '[외주개발 플랫폼] 회사소개, 저비용의 외주개발 플랫폼 │ 니즈로봇',
    description:
        '고객의 니즈를 최우선하는 플랫폼 기반의 가장 빠른 외주개발 아웃소싱 전문 회사, 저비용 외주개발 아웃소싱 플랫폼(IoT, 로봇, 스마트팜, 헬스케어 등) 니즈로봇',
    keywords: '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 회사소개',
    imgsrc: 'https://ifh.cc/g/bNC3w6.png', // 이미지 URL
    url: 'https://needsrobot.co.kr/Company', // 페이지 URL
}

export const metaInfoForProjectAddPage = {
    title: '[외주개발 플랫폼] 프로젝트 등록, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '니즈로봇과 협력이 필요한 외주개발 프로젝트를 무료로 등록해주세요. 전문가들과 소통하며 가장 빠르게 외주개발 아웃소싱을 시작하세요.',
    keywords: '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 등록',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Addprojects/add', // 페이지 URL
}
// 본 내용 작성

export const metaInfoForProjectAdd2Page = {
    title: '[외주개발 플랫폼] 프로젝트 작성, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '니즈로봇과 협력이 필요한 외주개발 프로젝트를 무료로 등록해주세요. 전문가들과 소통하며 가장 빠르게 외주개발 아웃소싱을 시작하세요.',
    keywords: '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 작성',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Addprojects/add/write', // 페이지 URL
}

export const metaJoin = {
    title: '[외주개발 플랫폼] 프로젝트 등록 가입, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '니즈로봇과 협력이 필요한 외주개발 프로젝트를 무료로 등록해주세요. 전문가들과 소통하며 가장 빠르게 외주개발 아웃소싱을 시작하세요.',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 등록 가입',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Addprojects/add/write', // 페이지 URL
}
export const metaJoins = {
    title: '[외주개발 플랫폼] 회원가입, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '회원가입을 통해서, 무료로 프로젝트를 등록하고 3일 이내에 무료 견적서를 제공받는 가장 빠른 외주개발 아우소싱 플랫폼입니다.',
    keywords: '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 회원가입',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Addprojects/add/write', // 페이지 URL
}
export const metaRule = {
    title: '[외주개발 플랫폼] 프로젝트 진행방법, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '무료로 프로젝트를 등록하고 3일 이내에 무료 견적서를 제공받고, 추가로 개발 비밀유지서약서, 개발 사양서, 개발 계약서 등을 외주개발 플랫폼을 통해서 진행합니다.',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 진행방법',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Addprojects/add/write', // 페이지 URL
}

export const metaConfi = {
    title: '[외주개발 플랫폼] 비밀유지서약서, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '전자서명 기반의 비밀유지서약서를 체결을 통해서, 클라이언트의 프로젝트 보안을 법적으로 보증하는 방식으로 외주개발 아웃소싱 프로젝트를 진행합니다.',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 비밀유지서약서',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Addprojects/confidentiality', // 페이지 URL
}

export const metaeEtimate = {
    title: '[외주개발 플랫폼] 견적서, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '무료로 프로젝트를 등록하고 3일 이내에 무료 견적서를 제공받는 가장 빠른 외주개발 아웃소싱 플래폼입니다. 추가로 프로젝트 개발 사양서도 계약시 제공합니다.',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 견적서',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Outsourcing/estimate', // 페이지 URL
}

export const metaContract = {
    title: '[외주개발 플랫폼] 계약서, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '외주개발 아웃소싱 플랫폼 기반으로 전자 계약서 및 비밀유지서약서를 작성하고 계약 이후에는 실시간으로 프로젝트 진행현황과 진행 보고서를 모니터링할 수 있습니다.',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 개발 계약서',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Outsourcing/contract', // 페이지 URL
}

export const metaSpecification = {
    title: '[외주개발 플랫폼] 사양서, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '외주개발 아웃소싱 플랫폼으로 프로젝트 개발에 필요한 상세한 개발 사양서를 제공합니다. 프로젝트의 요구사항과 사양을 상세하게 정리하여 체계적으로 외주개발을 지원합니다. ',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 개발 사양서',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Outsourcing/specification', // 페이지 URL
}

export const metaPanyment = {
    title: '[외주개발 플랫폼] 비용결제, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '비용결제 당신의 아이디어를 가장 빠르게 현실로 만드는 외주개발 아웃소싱 플랫폼, 트리즈 기반의 외주개발(IoT, 로봇, 스마트팜, 헬스케어 등)',
    description:
        '외주개발 아웃소싱 플랫폼으로 진행 중인 프로젝트의 비용결제 내역을 효율적으로 관리할 수 있습니다. 당신의 생각을 가장 빠르게 현실로 만드는 외주개발 플랫폼(IoT, 로봇, 스마트팜, 헬스케어 등)',

    keywords: '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 비용',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Outsourcing/payment', // 페이지 URL
}

export const metaAgreement = {
    title: '[외주개발 플랫폼] 이용약관, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '외주개발 아웃소싱 플랫폼은 회원의 권리, 의무, 서비스 등을 상세히 안내하고 있습니다. 당신의 생각을 가장 빠르게 현실로 만드는 외주개발 플랫폼(IoT, 로봇, 스마트팜, 헬스케어 등)',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 이용약관',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Outsourcing/Agreement', // 페이지 URL
}

export const metaPrivacypolicy = {
    title: '[외주개발 플랫폼] 개인정보처리방침, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '니즈로봇은 개인정보 보호법에 따라 정보 주체의 개인정보를 안전하게 처리합니다. 당신의 생각을 가장 빠르게 현실로 만드는 외주개발 플랫폼(IoT, 로봇, 스마트팜, 헬스케어 등)',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 개인정보처리방침',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Outsourcing/Privacypolicy', // 페이지 URL
}
export const metaLogin = {
    title: '[외주개발 플랫폼] 로그인, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '회원 가입을 통해, 무료로 프로젝트를 등록하고 3일 이내에 무료 견적서를 제공받는 가장 빠른 외주개발 아웃소싱 플래폼입니다. 추가로 프로젝트 개발 사양서도 계약시 제공합니다.',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 로그인',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Outsourcing/Login', // 페이지 URL
}

export const metaMypage = {
    title: '[외주개발 플랫폼] 마이페이지, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '외주개발 아웃소싱 플랫폼으로 프로젝트를 열람하고 현재의 진행 상태를 실시간으로 확인할 수 있습니다. 당신의 생각을 가장 빠르게 현실로 만드는 외주개발 플랫폼(IoT, 로봇, 스마트팜, 헬스케어 등)',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 마이페이지',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Mypage/Myprojects/myProject', // 페이지 URL
}

export const metaMyinfo = {
    title: '[외주개발 플랫폼] 내정보수정, 외주개발 아웃소싱 플랫폼 │ 니즈로봇',
    description:
        '나의 정보를 수정하여 내 계정을 관리해보세요. 당신의 생각을 가장 빠르게 현실로 만드는 외주개발 플랫폼(IoT, 로봇, 스마트팜, 헬스케어 등)',
    keywords:
        '외주개발, 외주개발 플랫폼, 아웃소싱, 아웃소싱 플랫폼, 가장 빠른 외주개발 아웃소싱 플랫폼, 프로젝트 내정보수정',
    imgsrc: imageArray.subtitle_portfolio, // 이미지 URL
    url: 'https://needsrobot.co.kr/Mypage/Myinfo/clientinfo', // 페이지 URL
}
