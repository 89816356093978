
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { cate, conditionA, minicate } from '../../service/dataarray';
import Checkbox from '../../service/components/Checkbox';
import Radiolabel from '../../service/components/Radiolabel';
import { TextAreas, updateProject } from '../../service/project';
import Swal from 'sweetalert2';
function MyProjects_r({ updateData, bProject, edit, setEdit, handleChange, before, nums }) {
  const navigate = useNavigate()
  const updateProjects = () => {
    if (edit) {
      updateProject(updateData.title, updateData.email, updateData.company, updateData.cate, updateData.cateother, updateData.minicate, updateData.minicateother, updateData.outline, updateData.lastprojects, updateData.projects, updateData.needskill, updateData.price, updateData.startdate, updateData.term, updateData.contractcost, updateData.contractterm, updateData.pStatus, updateData.meet, updateData.client, updateData.clientphone, updateData.clientaddress, updateData.conditions, updateData.conditionother, bProject.uid).then(function (response) {
        if (response.data) {
          Swal.fire(
            '수정 되었습니다',
            '확인 버튼을 눌러 주세요',
            'success'
          )
          navigate(before ? '/Admin/Adminproject' : '/Mypage/Myprojects')
          setEdit(false)
        } else {
          Swal.fire(
            '오류가 발생하였습니다',
            '확인 버튼을 눌러 주세요',
            'error'
          )
        }
      })
    } else {
      setEdit(true)
    }
  }
  return (
    <>
      {bProject && updateData && nums ?
        <div className='myproject_right'>
          <button onClick={() => updateProjects()} className='btnstyle'>{!edit ? '수정' : '전송'}</button>
          <div className='myproject_box'>
            <h1>프로젝트 명</h1>
            {!edit ?
              <p>{bProject.title}</p>
              : <input
                onChange={e => handleChange('title', e.target.value)}
                className='project_input' defaultValue={updateData.title} />
            }
          </div>
          <div className='myproject_box'>
            <h1>프로젝트 사업분야</h1>
            {!edit ?
              <div className='flex catewrap'>
                {bProject.cate}
              </div>
              :
              <ul className='list list-group '>
                {cate && cate.map((value, index) => (
                  <Checkbox
                    category={'cate'}
                    key={value.label}
                    radio={true}
                    className={updateData.cate && updateData.cate == value.label ? 'active list-group-control' : 'list-group-control'}
                    value={value}
                    check={updateData.cate}
                    other={updateData.cateother}
                    onClick={() => handleChange('cate', value.label)}
                    onChange={(e) => handleChange('cateother', e.target.value)} />
                ))}
              </ul>
            }
          </div>
          <div className='myproject_box'>
            <h1>프로젝트  의뢰분야 (중복선택가능)</h1>
            {!edit ?
              <div className='flex catewrap'>
                {bProject.minicate.map(values => (
                  <p className='mr10' key={values}>{values}</p>
                ))
                }
              </div>
              :
              <ul className=' list list-group'>
                {minicate && minicate.map((value) => (
                  <Checkbox
                    category={'minicate'}
                    key={value.value}
                    className={updateData.minicate && updateData.minicate.includes(value.value) ? 'active list-group-control' : 'list-group-control'}
                    value={value}
                    check={updateData.minicate}
                    other={updateData.minicateother}
                    onClick={(e) => handleChange('minicate', value.value)}
                    onChange={(e) => handleChange('minicateother', e.target.value)} />
                ))}
              </ul>
            }
          </div>

          <div className='right_body_wrap mb40'>
            <h1 className='mb10'>6. 프로젝트 개요</h1>
            {!edit ?
              <TextAreas value={bProject.outline} />
              :
              <input
                defaultValue={edit ? bProject.outline : ''}
                onChange={e => handleChange('outline', e.target.value)}
                className='project_input mt0' placeholder='ex) 내용' />
            }
          </div>
          <div className='right_body_wrap mb40'>
            <h1 className='mb10'>7. 최종 산출물</h1>
            {!edit ?
              <TextAreas value={bProject.outline} />
              :
              <input
                defaultValue={edit ? bProject.lastprojects : ''}
                onChange={e => handleChange('lastprojects', e.target.value)}
                className='project_input mt0' placeholder='ex) 내용' />
            }
          </div>
          <div className='right_body_wrap mb40'>
            <h1 className='mb10'>8. 프로젝트 상세 설명 또는 자료 첨부</h1>
            {!edit ?
              <TextAreas value={bProject.outline} />
              :
              <input
                defaultValue={edit && bProject.projects ? bProject.projects : ''}
                onChange={e => handleChange('projects', e.target.value)}
                className='project_input mt0' placeholder='ex) 내용' />
            }

          </div>
          <div className='right_body_wrap mb80'>
            <h1 className='mb10'>9. 필요 기술</h1>
            {!edit ?
              <div className='modalt'>{bProject.needskill}</div>
              :
              <input
                defaultValue={edit ? bProject.needskill : ''}
                onChange={e => handleChange('needskill', e.target.value)}
                className='project_input mt0' placeholder='ex) AGV 로봇 제조 경험 필수' />
            }
          </div>
          <div className='flex j mb40 wrap triple'>
            <div className='right_body_wrap w33'>
              <h1 className='mb10'>10. 예상 비용</h1>
              {!edit ?
                <div className='modalt'>{bProject.price}</div>
                :
                <input
                  defaultValue={edit ? bProject.price : ''}
                  onChange={e => handleChange('price', e.target.value)} className='project_input' placeholder='￦' />
              }
              <p className='subtext'>예상 결제 금액 : ￦{Number(bProject.price) + Number(bProject.price * 0.1)}</p>
            </div>
            <div className='right_body_wrap w33'>
              <h1 className='mb10'></h1>
              {!edit ?
                <div className='modalt'>{bProject.startdate}</div>
                :
                <input type='date'
                  defaultValue={edit ? bProject.startdate : ''}
                  onChange={e => handleChange('startdate', e.target.value)} className='project_input' />
              }

            </div>
            <div className='right_body_wrap w33'>
              <h1 className='mb10'>11. 예상 기간</h1>
              {!edit ?
                <div className='modalt'>{bProject.term}</div>
                :
                <input type='number'
                  defaultValue={edit ? bProject.term : ''}
                  onChange={e => handleChange('term', e.target.value)} className='project_input' placeholder='일' />
              }
            </div>
          </div>
          <div className='right_body_wrap mb40'>
            <h1>12. 프로젝트 진행 상태</h1>
            {!edit &&
              bProject.pStatus === 'new' ? '신규로 진행합니다' : !edit && bProject.pStatus === 'better' ? '기존 진행했던 프로젝트의 개선' : !edit && bProject.pStatus === 'ing' ? '기존 진행했던 프로젝트의 유지보수' : !edit && bProject.pStatus === '' ? '' :
                <div className='flex wrap'>
                  <Radiolabel
                    onChange={e => handleChange('pStatus', 'new')}
                    checked={updateData.pStatus === 'new'}
                    name={'sprojectStatus'}
                    value={'신규로 진행합니다'}
                  />
                  <Radiolabel
                    onChange={e => handleChange('pStatus', 'better')}
                    checked={updateData.pStatus === 'better'}
                    name={'sprojectStatus'}
                    value={'기존 진행했던 프로젝트의 개선'}
                  />
                  <Radiolabel
                    onChange={e => handleChange('pStatus', 'ing')}
                    checked={updateData.pStatus === 'ing'}
                    name={'sprojectStatus'}
                    value={'기존 진행했던 프로젝트의 유지보수'}
                  />
                </div>
            }
          </div>
          <div className='right_body_wrap mb40'>
            <h1 className='mb10'>13. 프로젝트 회의 방식 </h1>
            <div>
              {!edit && bProject.meet === 'on' ? '온라인' : !edit && bProject.meet === 'off' ? '오프라인' : !edit && bProject.meet === 'onoff' ? '온오프라인' : !edit && bProject.meet === '' ? '' :
                <div className='flex wrap'>
                  <Radiolabel
                    onChange={e => handleChange('meet', 'on')}
                    checked={updateData.meet === 'on'}
                    name={'meet'}
                    value={'온라인'}
                  />
                  <Radiolabel
                    onChange={e => handleChange('meet', 'off')}
                    checked={updateData.meet === 'off'}
                    name={'meet'}
                    value={'오프라인'}
                  />
                  <Radiolabel
                    onChange={e => handleChange('meet', 'onoff')}
                    checked={updateData.meet === 'onoff'}
                    name={'meet'}
                    value={'온오프라인'}
                  />
                </div>
              }
            </div>
          </div>
          <div className='flex mb40 wrap j triple'>
            <div className='right_body_wrap w33'>
              <h1 className='mb10'>14. 프로젝트 의뢰 담당자</h1>
              {!edit ?
                <div className='modalt'>{bProject.client}</div>
                :
                <input
                  defaultValue={edit ? updateData.client : ''}
                  onChange={e => handleChange('client', e.target.value)}
                  className='project_input' placeholder='ex) 홍길동' />
              }


            </div>
            <div className='right_body_wrap w33'>
              <h1 className='mb10'>15. 프로젝트 의뢰 연락처</h1>
              {!edit ?
                <div className='modalt'>{bProject.clientphone}</div>
                :
                <input
                  defaultValue={edit ? updateData.clientphone : ''}
                  onChange={e => handleChange('clientphone', e.target.value)}
                  className='project_input' placeholder='ex) 01012348565' />
              }




            </div>
            <div className='right_body_wrap w33'>
              <h1 className='mb10'>16. 프로젝트 의뢰지 주소</h1>
              {!edit ?
                <div className='modalt'>{bProject.clientaddress}</div>
                :
                <input
                  defaultValue={edit ? updateData.clientaddress : ''}
                  onChange={e => handleChange('clientaddress', e.target.value)}
                  className='project_input' placeholder='ex)서울시 중랑구' />
              }

            </div>
          </div>
          <div className='right_body_wrap mb40'>
            <h1 className='mb10'>17. 파트너 조건</h1>
            {!edit ?
              <div className='flex catewrap'>
                {bProject.conditions && bProject.conditions.map(values => (
                  <p className='mr10' key={values}>{values}</p>
                ))}
              </div>
              :
              <ul className='list list-group'>
                {conditionA && conditionA.map((value) => (
                  <Checkbox
                    category={'conditions'}
                    key={value.value}
                    className={bProject.conditions && bProject.conditions.includes(value.value) ? 'active list-group-control' : 'list-group-control'}
                    value={value}
                    check={updateData.conditions}
                    other={updateData.conditionother}
                    onClick={(e) => handleChange('conditions', value.value)}
                    onChange={(e) => handleChange('conditionother', e.target.value)} />
                ))}
              </ul>

            }
          </div>
        </div>
        :
        <div className='myproject_right'>
          프로젝트를 선택해주세요
        </div>
      }
    </>

  );
}

export default MyProjects_r;