import React from "react";
import { fetchData } from "./instance";
import Swal from "sweetalert2";
import axios from "axios";
import {
  AiOutlineHome,
  AiFillDatabase,
  AiOutlineFileSearch,
  AiOutlineFileDone,
  AiOutlineFundView,
  AiOutlineCheckCircle,
  AiOutlineForm,
} from "react-icons/ai";
import { projectTabs } from "./tabarray";
//const allurl = "http://localhost:8000";
//const allurl = 'http://nzr.iptime.org:54327'
const allurl = 'https://www.needsrobot.co.kr'

const allportfolio = async () => {
  return fetchData("/api/portfolio");
};
const allProjects = async () => {
  return fetchData("/api/Allprojects");
};
const lastjoin = async (uPassword, email, userId) => {
  return fetchData("/api/join", { uPassword, email, userId });
};
// 프로젝트 작성중 회원가입
const lastjoinafter = async (uPassword, email, userId, uid) => {
  return fetchData("/api/joinafter", { uPassword, email, userId, uid });
};
const useridchecks = async (userId) => {
  return fetchData("/api/useridcheck", { userId });
};
const logins = async (userId, uPassword) => {
  return fetchData("/api/login", { userId, uPassword });
};
// 아이디에 맞는 프로젝트 가져오기
const myproject = async (userId) => {
  return fetchData("/api/Myprojects", { userId });
};
// 아이디에 맞는 프로젝트 가져오기,예시도 가져오기
const exmyproject = async (userId) => {
  return fetchData("/api/Myprojects_ex", { userId });
};
// uid에 맞는 프로젝트 가져오기
const uidproject = async (uid) => {
  return fetchData("/api/uidproject", { uid });
};
// 프로젝트 즐겨찾기 추가
const interestProject = async (id, userId) => {
  return fetchData("/api/interestProject", { id, userId });
};
const applyProject = async (id, userId) => {
  return fetchData("/api/applyProject", { id, userId });
};
//즐겨찾기 리스트 가져오기
const interestList = async (userId) => {
  return fetchData("/api/interestlist", { userId });
};
//즐겨찾기 리스트 와 조인한 것 가져오기
const interestLists = async (userId) => {
  return fetchData("/api/interestLists", { userId });
};
//신청한 프로젝트 리스트 가져오기
const applyList = async (userId) => {
  return fetchData("/api/applylist", { userId });
};

//신청한 리스트 와 조인한 것 가져오기
const applyLists = async (userId) => {
  return fetchData("/api/applyLists", { userId });
};
//나의 정보 가져오기
const myinfos = async (userId) => {
  return fetchData("/api/myinfos", { userId });
};
//로그인전 임시저장 리스트 가져오기
const saveBlogin = async (array) => {
  return fetchData("/api/saveBlogin", { array });
};
//추천 프로젝트 가져오기
const fiveList = async (array, uid) => {
  return fetchData("/api/fivelist", { array, uid });
};
// 비밀유지계약서유무확인
const confidentialitys = async (date, userId, stampid) => {
  return fetchData("/api/confidentialitys", { date, userId, stampid });
};
// 진행단계 확인
const upgradeCheck = async (uid, adminstatus, path) => {
  return fetchData("/api/upgradeCheck", { uid, adminstatus, path });
};
//클라이언트 계약서 서명
const clientContract = async (stampid, uid) => {
  return fetchData("/api/clientContract", { stampid, uid });
};
//내정보 업데이트
const updateinfo = async (
  uName,
  email,
  uPhone,
  position,
  companypart,
  company,
  representative,
  companytype,
  companyregiNum,
  sector1,
  sector2,
  companyNum,
  taxmail,
  companyaddressf,
  companyaddressm,
  companyaddNum,
  taxtype,
  userId
) => {
  return fetchData("/api/updateinfo", {
    uName,
    email,
    uPhone,
    position,
    companypart,
    company,
    representative,
    companytype,
    companyregiNum,
    sector1,
    sector2,
    companyNum,
    taxmail,
    companyaddressf,
    companyaddressm,
    companyaddNum,
    taxtype,
    userId,
  });
};

//비밀번호 확인
const checkpass = async (userId, uPassword) => {
  return fetchData("/api/checkpass", { userId, uPassword });
};
//비밀번호 변경
const updatepass = async (userId, uPassword) => {
  return fetchData("/api/updatepass", { userId, uPassword });
};
const getContract = async (uid) => {
  return fetchData("/api/getContract", { uid });
};

// 날인 가져오기
const Userstamp = async (userId) => {
  return fetchData("/api/Userstamp", { userId });
};
const Allstamps = async (userId) => {
  return fetchData("/api/Allstamp", { userId });
};
// 대표 날인 설정
const mainstamps = async (id, userId) => {
  return fetchData("/api/mainstamps", { id, userId });
};
// 날인 삭제
const deletestamps = async (id, userId) => {
  return fetchData("/api/deletestamp", { id, userId });
};

const allusers = async () => {
  return fetchData("/api/alluser", {});
};
const okidentitys = async (userId) => {
  return fetchData("/api/identity", { userId });
};

// 입금내용 완료
const moneyDates = async (uid, tab, date, money) => {
  return fetchData("/api/moneyDates", { uid, tab, date, money });
};

const withdrawalMem = async (userId, time, reason) => {
  return fetchData("/api/withdrawalMem", { userId, time, reason });
};
const deleteProject = async (uid) => {
  return fetchData("/api/deleteProject", { uid });
};
// 오퍼 내용 보내기
const offerMessage = async (
  uid,
  price,
  term,
  content,
  userId,
  num,
  messageId,
  type
) => {
  return fetchData("/api/offerMessage", {
    uid,
    price,
    term,
    content,
    userId,
    num,
    messageId,
    type,
  });
};
// 오퍼 내용 불러오기
const offerUid = async (uid) => {
  return fetchData("/api/offerUid", { uid });
};
// 오퍼 내용 불러오기
const receivemessage = async (userId) => {
  return fetchData("/api/receivemessage", { userId });
};
// 견적,사양서 관련 승인
export const offerok = async (uid, date, path, state, lengthPoint, userId) => {
  return fetchData("/api/offerok", { uid, date, path, state, lengthPoint, userId });
};
// 메세지 보내기
const sendMessages = async (caller, receiver, title, contents) => {
  return fetchData("/api/sendMessages", { caller, receiver, title, contents });
};
// 메세지 읽음확인
const sendMessagetrue = async (id) => {
  return fetchData("/api/sendMessagetrue", { id });
};
// 메세지 삭제하기
export const deleteMessage = async (id) => {
  return fetchData("/api/deleteMessage", { id });
};
// 프로젝트 날짜 가져오기
export const getprojecthistory = async (id) => {
  return fetchData("/api/getprojecthistory", { id });
};

// 모달창 구현 함수
const Ptext = ({ value }) => {
  return (
    <>
      {value ? (
        <div className="modalt">{value}</div>
      ) : (
        <p>내용을 입력 해주세요</p>
      )}
    </>
  );
};
// 텍스트아리아 엔터 적용 함수
const TextAreas = ({ value }) => {
  return (
    <>
      {value ? (
        <div className="modalt">
          {value.split("\n").map((line, i) => {
            return (
              <span className="flex" key={i}>

                <span></span>
                {line}
                <br />
              </span>
            );
          })}
        </div>
      ) : (
        <p>내용을 입력 해주세요</p>
      )}
    </>
  );
};
function addDays(date, days) {
  const result = new Date(date);
  const today = new Date();
  const timeDiff =
    result.getTime() - today.getTime() + days * 24 * 60 * 60 * 1000;
  const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysLeft;
}
function minusday(date) {
  const targetDate = new Date(date);

  const today = new Date();
  const timeDiff = targetDate.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
  const dDayString = (daysDiff > 0 ? "-" : "+") + Math.abs(daysDiff) + "일";
  return dDayString;
}

// 프로젝트 저장
const addProject = async (
  title,
  cate,
  email,
  company,
  cateother,
  minicate,
  minicateother,
  outline,
  lastprojects,
  projects,
  needskill,
  price,
  startdate,
  term,
  contractcost,
  contractterm,
  pStatus,
  meet,
  client,
  clientphone,
  clientaddress,
  condition,
  conditionother,
  userId,
  uid
) => {
  return fetchData("/api/addProject", {
    title,
    cate,
    email,
    company,
    cateother,
    minicate,
    minicateother,
    outline,
    lastprojects,
    projects,
    needskill,
    price,
    startdate,
    term,
    contractcost,
    contractterm,
    pStatus,
    meet,
    client,
    clientphone,
    clientaddress,
    condition,
    conditionother,
    userId,
    uid,
  });
};
// 프로젝트 임시 저장
const TaddProject = async (
  title,
  email,
  company,
  cate,
  cateother,
  minicate,
  minicateother,
  outline,
  lastprojects,
  projects,
  needskill,
  price,
  startdate,
  term,
  contractcost,
  contractterm,
  pStatus,
  meet,
  client,
  clientphone,
  clientaddress,
  condition,
  conditionother,
  userId,
  uid
) => {
  return fetchData("/api/TaddProject", {
    title,
    email,
    company,
    cate,
    cateother,
    minicate,
    minicateother,
    outline,
    lastprojects,
    projects,
    needskill,
    price,
    startdate,
    term,
    contractcost,
    contractterm,
    pStatus,
    meet,
    client,
    clientphone,
    clientaddress,
    condition,
    conditionother,
    userId,
    uid,
  });
};
// 프로젝트 수정
const updateProject = async (
  title,
  email,
  company,
  cate,
  cateother,
  minicate,
  minicateother,
  outline,
  lastprojects,
  projects,
  needskill,
  price,
  startdate,
  term,
  contractcost,
  contractterm,
  pStatus,
  meet,
  client,
  clientphone,
  clientaddress,
  condition,
  conditionother,
  uid
) => {
  return fetchData("/api/UpdateProjects", {
    title,
    email,
    company,
    cate,
    cateother,
    minicate,
    minicateother,
    outline,
    lastprojects,
    projects,
    needskill,
    price,
    startdate,
    term,
    contractcost,
    contractterm,
    pStatus,
    meet,
    client,
    clientphone,
    clientaddress,
    condition,
    conditionother,
    uid,
  });
};

// 계약내용저장
const contracts = async (
  uid,
  title,
  deliverylocation,
  allprice,
  deposit,
  detail,
  startdate,
  lastdate,
  beforeprice,
  beforepricecon,
  middleprice,
  middlepricecon,
  afterprice,
  afterpricecon,
  time,
  done,
  newspec,
  oldspec,
  userId
) => {
  return fetchData("/api/contract", {
    uid,
    title,
    deliverylocation,
    allprice,
    deposit,
    detail,
    startdate,
    lastdate,
    beforeprice,
    beforepricecon,
    middleprice,
    middlepricecon,
    afterprice,
    afterpricecon,
    time,
    done,
    newspec,
    oldspec,
    userId,
  });
};

function unixtime(unixtime) {
  const date = new Date(unixtime * 1000);

  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
        .getSeconds()
        .toString()
        .padStart(2, "0")}`;

  return formattedDate;
}

function setItemWithExpireTime(keyName, keyValue, tts) {
  if (tts) {
    const obj = {
      value: keyValue,
      expire: Date.now() + tts,
    };
    const objString = JSON.stringify(obj);
    window.localStorage.setItem(keyName, objString);
  } else {
    const obj = {
      value: keyValue,
      expire: null,
    };
    const objString = JSON.stringify(obj);
    window.localStorage.setItem(keyName, objString);
  }
}
// 토큰 외
export function getLocal(keyName) {
  const objString = window.localStorage.getItem(keyName);
  if (!objString) {
    return null;
  }
  const obj = JSON.parse(objString);
  return obj.value;
}
function getItemWithExpireTime(keyName) {
  const objString = window.localStorage.getItem(keyName);
  if (!objString) {
    return null;
  }

  const obj = JSON.parse(objString);

  if (Date.now() > obj.expire) {
    if (keyName === "token") {
      Swal.fire("세션이 만료되어 로그아웃 되었습니다", "success");
      window.localStorage.removeItem(keyName);
      window.localStorage.removeItem("userInfo");
      window.localStorage.removeItem("mlist");
      window.location.href = "/";
    }
    return null;
  }

  return obj.value;
}

// 연장
function extendExpirationTime(keyName, additionalTts, setDisplayTime) {
  const storedValue = window.localStorage.getItem(keyName);

  if (storedValue) {
    const storedObj = JSON.parse(storedValue);
    if (storedObj.expire) {
      // 현재 시간을 가져와서 추가 시간을 더합니다.
      storedObj.expire = Date.now() + additionalTts;

      // 다시 로컬 스토리지에 저장합니다.
      window.localStorage.setItem(keyName, JSON.stringify(storedObj));
      setDisplayTime(returnLogintime(storedObj.expire));
    }
  }
}
const returnLogintime = (now) => {
  const currentTime = new Date(); // 현재 시간 생성
  const targetTime = new Date(now); // 주어진 'now' 값을 기준으로 시간 생성

  // 두 날짜 간의 차이 계산 (밀리초 단위)
  const timeDiff = targetTime - currentTime;

  // 밀리초를 시간과 분으로 변환
  const hoursLeft = Math.floor(timeDiff / (1000 * 60 * 60));
  const minutesLeft = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
  const last = `${hoursLeft}시간 ${minutesLeft}분`;
  return last;
};
const sortList = (filteredArray, sortType) => {
  switch (sortType) {
    case "recent":
      return filteredArray.sort(
        (a, b) => new Date(b.updatetime) - new Date(a.updatetime)
      );
    case "priceL":
      return filteredArray.sort(
        (a, b) => removeComma(a.price) - removeComma(b.price)
      );
    case "priceH":
      return filteredArray.sort(
        (a, b) => removeComma(b.price) - removeComma(a.price)
      );
    case "shortT":
      return filteredArray.sort((a, b) => b.applyterm - a.applyterm);
    case "longT":
      return filteredArray.sort((a, b) => b.term - a.term);
    default:
      return filteredArray;
  }
};

const sortLists = (filteredArray, sortType, updown, setUserlist, stamplist) => {
  let sortedArray = [...filteredArray];

  if (updown === "up") {
    switch (sortType) {
      case 0:
        sortedArray.sort((a, b) => a.userId.localeCompare(b.userId));
        break;
      case 1:
        sortedArray.sort((a, b) => a.uName && a.uName.localeCompare(b.uName));
        break;
      case 2:
        sortedArray.sort((a, b) => a.email.localeCompare(b.email));
        break;
      case 3:
        sortedArray.sort(
          (a, b) => a.uPhone && a.uPhone.localeCompare(b.uPhone)
        );
        break;
      case 4:
        sortedArray.sort(
          (a, b) =>
            stamplist.filter((stamp) => stamp.userId === a.userId).length -
            stamplist.filter((stamp) => stamp.userId === b.userId).length
        );
        break;
      case 5:
        sortedArray.sort(
          (a, b) =>
            a.confidentiality &&
            a.confidentiality.localeCompare(b.confidentiality)
        );
        break;

      default:
        break;
    }
  } else {
    switch (sortType) {
      case 0:
        sortedArray.sort((a, b) => b.userId.localeCompare(a.userId));
        break;
      case 1:
        sortedArray.sort((a, b) => b.uName && b.uName.localeCompare(a.uName));
        break;
      case 2:
        sortedArray.sort((a, b) => b.email && b.email.localeCompare(a.email));
        break;
      case 3:
        sortedArray.sort(
          (a, b) => b.uPhone && b.uPhone.localeCompare(a.uPhone)
        );
        break;
      case 4:
        sortedArray.sort(
          (a, b) =>
            stamplist.filter((stamp) => stamp.userId === b.userId).length -
            stamplist.filter((stamp) => stamp.userId === a.userId).length
        );
        break;
      case 5:
        sortedArray.sort(
          (a, b) =>
            b.confidentiality &&
            b.confidentiality.localeCompare(a.confidentiality)
        );
        break;
      default:
        break;
    }
  }

  setUserlist(sortedArray);
};

function getTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  return formattedTime;
}
function getDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");

  const formattedTime = `${year}-${month}-${day}`;
  return formattedTime;
}
// 배열 존재 여부 확인 함수
const checkArray = (array, key, value) => {
  return array.some((obj) => obj[key] === value);
};

function comma(number) {
  if (number === 0) {
    return "";
  }

  const numberString = number.toString();
  const reversedArray = numberString.split("").reverse();
  const commaSeparatedArray = [];
  for (let i = 0; i < reversedArray.length; i++) {
    if (i > 0 && i % 3 === 0) {
      commaSeparatedArray.push(",");
    }
    commaSeparatedArray.push(reversedArray[i]);
  }
  const result = commaSeparatedArray.reverse().join("");

  return result;
}
function removeComma(str) {
  if (typeof str === "string") {
    const numericValue = parseFloat(str.replace(/,/g, ""));
    return isNaN(numericValue) ? 0 : numericValue;
  } else if (typeof str === "number") {
    return str;
  } else {
    return 0;
  }
}

// 날짜 더하기
function addDaysToDate(dateStr, days) {
  try {
    const dateObj = new Date(dateStr);

    dateObj.setDate(dateObj.getDate() + days);

    const newDateStr = dateObj.toISOString().split("T")[0];
    return newDateStr;
  } catch (error) {
    return "날짜 형식이 잘못되었습니다.";
  }
}

const setDay = (data, setFunc) => {
  if (
    data === "계약 후 3일 이내" ||
    data === "시제품 납품 후 3일 이내" ||
    data === "인증 시료 납품 후 3일이내"
  ) {
    setFunc(3);
  } else if (
    data === "계약 후 7일 이내" ||
    data === "시제품 납품 후 7일이내" ||
    data === "인증 시료 납품 후 7일이내"
  ) {
    setFunc(7);
  } else {
    setFunc(10);
  }
};

const needsstamp = (
  <img
    className=""
    src="https://ifh.cc/g/2X8axA.png.png"
    alt="니즈로봇 외주개발 도장"
  />
);
export const exstamp = (
  <img
    className=""
    src="https://ifh.cc/g/n10nhs.png"
    alt="니즈로봇 외주개발 도장"
  />
);
const userwriting = (
  <img
    className="writing"
    src="https://ifh.cc/g/MYhwcg.png"
    alt="니즈로봇 외주개발 서명"
  />
);

const registApi = async (jwt, title, contents, passWord, selectedFiles) => {
  try {
    const response = await fetchData(
      "/user/itemQna",
      {
        title: title,
        contents: contents,
        password: passWord,
      },
      {
        headers: {
          Authorization: jwt,
        },
      }
    );
    // 응답에서 아이디 값을 받아온다.
    const itemId = response.data.id;

    // 아이디 값을 사용하여 첨부 파일을 업로드한다.
    await registFile(jwt, itemId, selectedFiles);
  } catch (err) {
    console.error(`오류: ${err}`);
    throw err;
  }
};
const registFile = async (jwt, itemId, selectedFiles) => {
  try {
    // 여기에서 selectedFiles를 서버에 업로드하는 로직을 구현하면 됩니다.
    // 예를 들어 FormData를 사용하여 파일을 서버에 업로드할 수 있습니다.
    const formData = new FormData();
    formData.append("itemId", itemId);
    for (const file of selectedFiles) {
      formData.append("file", file);
    }

    await fetchData.post(`/user/uploadFiles/${itemId}`, formData, {
      headers: {
        Authorization: jwt,
        "Content-Type": "multipart/form-data",
      },
    });
  } catch (err) {
    console.error(`오류: ${err}`);
    throw err;
  }
};
// d아이디보호
function maskId(userId) {
  if (userId.length <= 8) {
    return userId.substring(0, 2) + "*".repeat(userId.length - 2);
  } else {
    return userId.substring(0, 4) + "*".repeat(userId.length - 4);
  }
}

const encodeFileToBase64 = (fileBlob) => {
  const reader = new FileReader();
  reader.readAsDataURL(fileBlob);
  return new Promise((resolve) => {
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};
const loadImages = async (array, setFunc) => {
  const srcs =
    array &&
    (await Promise.all(
      array.map(async (value) => {
        const response = await fetch(
          `${allurl}/api/download/stamp/${value.stampname.split("/").pop()}`
        );
        const blob = await response.blob();
        const base64 = await encodeFileToBase64(blob);
        // 객체를 생성하여 id와 이미지 데이터를 저장
        return { id: value.id, base64 };
      })
    ));

  setFunc(srcs);
};
const loadImage = async (img, setFunc, type) => {

  const response =
    type === "info"
      ? await fetch(
        `${allurl}/api/download/document/${img && img.split("/").pop()}`
      )
      : type === "estimate"
        ? await fetch(
          `${allurl}/api/download/estimate/${img && img.estimate.split("/").pop()
          }`
        )
        : type === "specification"
          ? await fetch(
            `${allurl}/api/download/specification/${img && img.specification.split("/").pop()
            }`
          )
          : type === "develop1"
            ? await fetch(
              `${allurl}/api/download/develop1Upload/${img && img.develop1.split("/").pop()
              }`
            )
            : type === "develop2"
              ? await fetch(
                `${allurl}/api/download/develop2Upload/${img && img.develop2.split("/").pop()
                }`
              )
              : type === "develop3"
                ? await fetch(
                  `${allurl}/api/download/develop3Upload/${img && img.develop3.split("/").pop()
                  }`
                )
                : await fetch(
                  `${allurl}/api/download/stamp/${img && img.stampname.split("/").pop()
                  }`
                );
  const blob = await response.blob();
  const base64 = await encodeFileToBase64(blob);
  // 객체를 생성하여 id와 이미지 데이터를 저장
  const imageData = { id: img && img.id, base64 };

  setFunc(imageData); // 이미지 데이터를 설정
  return imageData; // 이미지 데이터를 반환
};
// ------------------------------------------------------
const Ptext_commaNum = ({ value }) => {
  const formattedValue = comma(value);
  return (
    <>
      {value ? (
        <div className="modalt">{formattedValue}원</div>
      ) : (
        <p>내용을 입력 해주세요</p>
      )}
    </>
  );
};

//싱글파일 업로드
const uploadFilesingle = (file, userinfo, func) => {
  try {
    const base64String = file;

    if (base64String) {
      const matches = base64String.match(/^data:(.+);base64,(.+)$/);

      if (!matches || matches.length !== 3) {
        console.error("올바른 Base64 데이터 형식이 아닙니다.");
        return;
      }

      const mimeType = matches[1];
      const base64Data = matches[2];
      // Base64 디코딩
      const decodedData = atob(base64Data);
      const byteNumbers = new Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        byteNumbers[i] = decodedData.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blobData = new Blob([byteArray], { type: mimeType });
      const extension = mimeType.split("/")[1];
      const formData = new FormData();
      formData.append(
        "file",
        blobData,
        `${encodeURIComponent(userinfo.userId)}.${extension}`
      );
      axios
        .post(`${allurl}/api/uploadstamp/${userinfo.userId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          Swal.fire("저장 되었습니다", "확인 버튼을 눌러 주세요", "success");

          func(2);
        })
        .catch((error) => {
          console.error("파일 업로드 중 오류 발생:", error);
          Swal.fire(
            "파일 업로드 중 오류가 발생했습니다",
            "확인 버튼을 눌러 주세요",
            "error"
          );
        });
    } else {
      console.error("filteredImage 값이 올바른 Base64 문자열이 아닙니다.");
    }
  } catch (error) {
    console.error("Base64 디코딩 중 오류 발생:", error);
    Swal.fire(
      "파일 디코딩 중 오류가 발생했습니다",
      "확인 버튼을 눌러 주세요",
      "error"
    );
  }
};

//싱글파일 업로드
const uploaddocufile = (file, userinfo, name, type, userId) => {
  // 파일을 가져와서 Blob으로 변환
  fetch(file)
    .then((response) => response.blob())
    .then((blobData) => {
      try {
        const mimeType = blobData.type;
        const extension = mimeType.split("/")[1];
        const formData = new FormData();
        formData.append("file", blobData, `${encodeURIComponent(name)}`);
        if (type === "userinfo") {
          axios
            .post(`${allurl}/api/uploaddocument/${userinfo.userId}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              Swal.fire(
                "저장 되었습니다",
                "확인 버튼을 눌러 주세요",
                "success"
              );
            })
            .catch((error) => {
              console.error("파일 업로드 중 오류 발생:", error);
              Swal.fire(
                "파일 업로드 중 오류가 발생했습니다",
                "확인 버튼을 눌러 주세요",
                "error"
              );
            });
        } else if (type === "estimate") {
          axios
            .post(
              `${allurl}/api/estimateUpload/${userinfo}/${userId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(function (response) {
              Swal.fire(
                "저장 되었습니다",
                "확인 버튼을 눌러 주세요",
                "success"
              );
            })
            .catch((error) => {
              console.error("파일 업로드 중 오류 발생:", error);
              Swal.fire(
                "파일 업로드 중 오류가 발생했습니다",
                "확인 버튼을 눌러 주세요",
                "error"
              );
            });
        } else if (type === "specification") {
          axios
            .post(
              `${allurl}/api/specificationUpload/${userinfo}/${userId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(function (response) {
              Swal.fire(
                "저장 되었습니다",
                "확인 버튼을 눌러 주세요",
                "success"
              );
            })
            .catch((error) => {
              console.error("파일 업로드 중 오류 발생:", error);
              Swal.fire(
                "파일 업로드 중 오류가 발생했습니다",
                "확인 버튼을 눌러 주세요",
                "error"
              );
            });
        } else if (type === "develop1") {
          axios
            .post(`${allurl}/api/develop1/${userinfo}/${userId}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              Swal.fire(
                "저장 되었습니다",
                "확인 버튼을 눌러 주세요",
                "success"
              );
            })
            .catch((error) => {
              console.error("파일 업로드 중 오류 발생:", error);
              Swal.fire(
                "파일 업로드 중 오류가 발생했습니다",
                "확인 버튼을 눌러 주세요",
                "error"
              );
            });
        } else if (type === "develop2") {
          axios
            .post(`${allurl}/api/develop2/${userinfo}/${userId}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              Swal.fire(
                "저장 되었습니다",
                "확인 버튼을 눌러 주세요",
                "success"
              );
            })
            .catch((error) => {
              console.error("파일 업로드 중 오류 발생:", error);
              Swal.fire(
                "파일 업로드 중 오류가 발생했습니다",
                "확인 버튼을 눌러 주세요",
                "error"
              );
            });
        } else if (type === "develop3") {
          axios
            .post(`${allurl}/api/develop3/${userinfo}/${userId}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(function (response) {
              Swal.fire(
                "저장 되었습니다",
                "확인 버튼을 눌러 주세요",
                "success"
              );
            })
            .catch((error) => {
              console.error("파일 업로드 중 오류 발생:", error);
              Swal.fire(
                "파일 업로드 중 오류가 발생했습니다",
                "확인 버튼을 눌러 주세요",
                "error"
              );
            });
        }
      } catch (error) {
        console.error("Base64 디코딩 중 오류 발생:", error);
        Swal.fire(
          "파일 디코딩 중 오류가 발생했습니다",
          "확인 버튼을 눌러 주세요",
          "error"
        );
      }
    })
    .catch((error) => {
      console.error("파일을 Blob으로 변환 중 오류 발생:", error);
      Swal.fire(
        "파일 변환 중 오류가 발생했습니다",
        "확인 버튼을 눌러 주세요",
        "error"
      );
    });
};

// 멀티파일 업로드
const uploadFilesmultie = (projectId, array) => {
  const formData = new FormData();
  array.forEach((fileData, index) => {
    const base64String = fileData.blob;
    const base64Data = base64String.split(",")[1];
    try {
      // Base64 디코딩 시도
      const decodedData = atob(base64Data);
      const byteNumbers = new Array(decodedData.length);
      for (let i = 0; i < decodedData.length; i++) {
        byteNumbers[i] = decodedData.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blobData = new Blob([byteArray], {
        type: "application/octet-stream",
      });

      formData.append("files", blobData, encodeURIComponent(fileData.fileName));
    } catch (error) {
      console.error("Base64 디코딩 중 오류 발생:", error);
      console.log("문제가 있는 base64 문자열:", base64Data);
      Swal.fire(
        "파일 디코딩 중 오류가 발생했습니다",
        "확인 버튼을 눌러 주세요",
        "error"
      );
    }
  });
  axios
    //.post(`http://nzr.iptime.org:54327/api/uploadFiles/${projectId}`
    .post(
      `${allurl}/api/uploadFiles/${projectId}`,

      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then(function (response) {
      if (response.data && response.data.success) {
        Swal.fire("저장 되었습니다", "확인 버튼을 눌러 주세요", "success");
      } else {
        Swal.fire(
          "파일 업로드 중 오류가 발생했습니다",
          "확인 버튼을 눌러 주세요",
          "warning"
        );
      }
    })
    .catch((error) => {
      console.error("파일 업로드 중 오류 발생:", error);
      Swal.fire(
        "파일 업로드 중 오류가 발생했습니다",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
    });
};

const searchPlays = (arrayP, arrayU, setP, setU, path, type, text) => {
  const newlist =
    path == "list" && type == 0
      ? arrayP.filter(
        (values) =>
          (values.title &&
            values.title.toUpperCase().includes(text.toUpperCase())) ||
          (values.userId &&
            values.userId.toUpperCase().includes(text.toUpperCase())) ||
          (values.userId ==
            arrayU.filter(
              (user) =>
                user.company &&
                user.company.toUpperCase().includes(text.toUpperCase())
            ) &&
            [0].userId)
      )
      : path == "list" && type == 1
        ? arrayP.filter(
          (values) =>
            values.title &&
            values.title.toUpperCase().includes(text.toUpperCase())
        )
        : path == "list" && type == 2
          ? arrayP.filter((values) =>
            values.userId.toUpperCase().includes(text.toUpperCase())
          )
          : path == "list" && type == 3
            ? arrayP.filter(
              (values) =>
                values.userId ==
                arrayU.filter(
                  (values) =>
                    values.company &&
                    values.company.toUpperCase().includes(text.toUpperCase())
                )[0].userId
            )
            : path == "Adminproject" && type == 0
              ? arrayP.filter(
                (values) =>
                  (values.title &&
                    values.title.toUpperCase().includes(text.toUpperCase())) ||
                  (values.userId &&
                    values.userId.toUpperCase().includes(text.toUpperCase())) ||
                  (values.userId ==
                    arrayU.filter(
                      (user) =>
                        user.company &&
                        user.company.toUpperCase().includes(text.toUpperCase())
                    ) &&
                    [0].userId)
              )
              : path == "Adminproject" && type == 1
                ? arrayP.filter(
                  (values) =>
                    values.title &&
                    values.title.toUpperCase().includes(text.toUpperCase())
                )
                : path == "Adminproject" && type == 2
                  ? arrayP.filter((values) =>
                    values.userId.toUpperCase().includes(text.toUpperCase())
                  )
                  : path == "Adminproject" && type == 3
                    ? arrayP.filter(
                      (values) =>
                        values.userId ==
                        arrayU.filter(
                          (values) =>
                            values.company &&
                            values.company.toUpperCase().includes(text.toUpperCase())
                        )[0].userId
                    )
                    : path == "Users" && type == 0
                      ? arrayU.filter(
                        (values) =>
                          (values.title &&
                            values.title.toUpperCase().includes(text.toUpperCase())) ||
                          (values.userId &&
                            values.userId.toUpperCase().includes(text.toUpperCase())) ||
                          (values.company &&
                            values.company.toUpperCase().includes(text.toUpperCase()))
                      )
                      : path == "Users" && type == 1
                        ? arrayU.filter(
                          (values) =>
                            values.title &&
                            values.title.toUpperCase().includes(text.toUpperCase())
                        )
                        : path == "Users" && type == 2
                          ? arrayU.filter(
                            (values) =>
                              values.userId &&
                              values.userId.toUpperCase().includes(text.toUpperCase())
                          )
                          : arrayU.filter(
                            (values) =>
                              values.company &&
                              values.company.toUpperCase().includes(text.toUpperCase())
                          );

  if (path === "Adminproject" || path === "list") {
    setP(newlist);
  } else {
    setU(newlist);
  }
};
const morelogin = (setDisplayTime) => {
  Swal.fire({
    title: "로그인 시간을 연장하시겠습니까?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "네",

    cancelButtonText: `아니요`,
  }).then((result) => {
    if (result.isConfirmed) {
      Swal.fire({
        title: "연장 되었습니다!",
        icon: "success",
      });

      extendExpirationTime("token", 3 * 60 * 60 * 1000, setDisplayTime);
    }
  });
};
function addCommasToNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// 그라데이션
const getColorByStep = (step, initialColor, lighteningFactor) => {
  const r = parseInt(initialColor.slice(1, 3), 16);
  const g = parseInt(initialColor.slice(3, 5), 16);
  const b = parseInt(initialColor.slice(5, 7), 16);
  const newR = Math.min(255, r + step * lighteningFactor);
  const newG = Math.min(255, g + step * lighteningFactor);
  const newB = Math.min(255, b + step * lighteningFactor);
  const newColor = `#${newR.toString(16).padStart(2, "0")}${newG
    .toString(16)
    .padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;

  return newColor;
};
const alertreturn = (type, con) => {
  if (type === "notready") {
    Swal.fire(`${con}를 작성중 입니다`, "확인 버튼을 눌러 주세요", "error");
  } else if (type === "contractdone") {
    Swal.fire(
      "계약서 작성이 완료 되었습니다",
      "확인 버튼을 눌러 주세요",
      "success"
    );
  } else if (type === "xsgin") {
    Swal.fire(
      "전자서명이 취소되었습니다",
      "확인 버튼을 눌러 주세요",
      "warning"
    );
  } else if (type === "nosign") {
    Swal.fire(
      "전자서명이 완료되지 않았습니다",
      "확인 버튼을 눌러 주세요",
      "warning"
    );
  } else if (type === "error") {
    Swal.fire("에러", "확인 버튼을 눌러 주세요", "error");
  } else if (type === "good") {
    Swal.fire("메세지를 보냈습니다", "확인 버튼을 눌러 주세요", "success");
  } else if (type === "lose") {
    Swal.fire(`${con} 을 입력해주세요`, "확인 버튼을 눌러 주세요", "warning");
  } else if (type === "loses") {
    Swal.fire(`${con} 입력해주세요`, "확인 버튼을 눌러 주세요", "warning");
  } else if (type === "checkno") {
    Swal.fire(`회원가입에 동의해주세요`, "확인 버튼을 눌러 주세요", "warning");
  } else if (type === "delete") {
    Swal.fire(`삭제 되었습니다`, "확인 버튼을 눌러 주세요", "success");
  } else if (type === "success") {
    Swal.fire(`${con} 전송 되었습니다`, "확인 버튼을 눌러 주세요", "success");
  } else if (type === "success2") {
    Swal.fire(`${con} 수정 되었습니다`, "확인 버튼을 눌러 주세요", "success");
  } else if (type === "eximatefail") {
    Swal.fire(
      `${con}회차 견적이 이미 존재합니다`,
      "확인 버튼을 눌러 주세요",
      "warning"
    );
  }
};
export function optionAlert(icon, title, text, footer, path) {
  Swal.fire({
    customClass: {
      container: "optionalert"
    },
    icon: icon,
    title: title,
    text: text,
    footer: `<a className='f16 blackfont bold' href="${path}">${footer}</a>`,
  });
}

export function getIcon(value) {
  switch (value) {
    case 0:
      return <AiFillDatabase className="tapicon" />;
    case 1:
      return <AiOutlineForm className="tapicon" />;
    case 3:
      return <AiOutlineFileSearch className="tapicon" />;
    case 8:
      return <AiOutlineFileDone className="tapicon" />;
    case 12:
      return <AiOutlineFundView className="tapicon" />;
    case 31:
      return <AiOutlineCheckCircle className="tapicon" />;
    default:
      return <AiOutlineHome className="tapicon" />;
  }
}

export const findadminvalue = (value) => {
  const findvalue = projectTabs.find((item) => item.label === value);
  return findvalue;
};
export {
  saveBlogin,
  Ptext,
  uidproject,
  getTime,
  TaddProject,
  myproject,
  allProjects,
  addProject,
  allportfolio,
  lastjoin,
  useridchecks,
  logins,
  setItemWithExpireTime,
  getItemWithExpireTime,
  sortList,
  updateProject,
  interestProject,
  applyProject,
  interestList,
  applyList,
  checkArray,
  interestLists,
  applyLists,
  myinfos,
  fiveList,
  lastjoinafter,
  TextAreas,
  addDays,
  updateinfo,
  checkpass,
  updatepass,
  getDate,
  needsstamp,
  userwriting,
  confidentialitys,
  upgradeCheck,
  contracts,
  comma,
  getContract,
  clientContract,
  registApi,
  registFile,
  Ptext_commaNum,
  Allstamps,
  mainstamps,
  encodeFileToBase64,
  loadImages,
  loadImage,
  deletestamps,
  uploadFilesingle,
  uploaddocufile,
  uploadFilesmultie,
  allusers,
  Userstamp,
  searchPlays,
  sortLists,
  addDaysToDate,
  setDay,
  okidentitys,
  allurl,
  moneyDates,
  withdrawalMem,
  minusday,
  deleteProject,
  removeComma,
  unixtime,
  returnLogintime,
  extendExpirationTime,
  morelogin,
  addCommasToNumber,
  getColorByStep,
  offerMessage,
  alertreturn,
  offerUid,
  exmyproject,
  receivemessage,
  sendMessages,
  sendMessagetrue,
};
