
import { useEffect } from 'react';
import React from 'react';
import { motion } from "framer-motion";
import Place from '../service/components/Place';
import MetaTag from '../MetaTag';
import { metaInfoForCompanyPage } from '../service/mataTags';
function Company() {

  useEffect(() => {

  }
    , [])

  return (
    <>
      <div className="main">
        <MetaTag  {...metaInfoForCompanyPage} />
        <div className='companyback'>
          <img src='https://ifh.cc/g/Kmwlbh.jpg' alt='니즈로봇 회사소개 상단 배경' />
          <div className='mainb backback'></div>
          <div className='w1480 h100 flex center'>
            <div className='companyT m300'>
              <motion.div
                className='divbox'
                animate={{ y: -150 }}
                transition={{ ease: "easeOut", duration: 1.5 }}
              >
                <p className='big'>According to your <span>Needs</span></p>
                <p className='big mb10'>We manufacture <span>Robot</span></p>

              </motion.div>
            </div>
            <div className='companyT'>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 1.5 }}

              >
                <p>니즈로봇(NEEDS ROBOT)은 고객의 니즈를 최우선하는 플랫폼 기반의 외주개발 아웃소싱 전문 회사입니다.</p>
                <p>트리즈 방법론에 기초한 최첨단 기술과 창의적인 솔루션을 통해 고객의 비즈니스 성공을 가장 빠르게 지원합니다.</p>
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 1.5 }}
              className='top100'

            >
              <a href='/Addprojects/add' className='navibtn_hover bigbtn mt30'>무료 견적 요청하기</a>
            </motion.div>
          </div>
        </div>
        <div className='companyback flex js'>
          <img src='https://ifh.cc/g/VbHmGs.jpg' alt='니즈로봇 외주개발 회사소개 배경' />
          <div className='flex companymiddle'>
            <div className='w50_ ohidden'>
              <img src='  https://ifh.cc/g/gTFv8L.jpg' alt='니즈로봇 외주개발 회사소개 배경' />
            </div>

            <div className='w50_ midText'> 니즈로봇은 4차 산업혁명에 기반이 되는 IoT 제품, 로봇, 스마트팜 관련 제품을 연구개발하고 제조 및 판매하고 있습니다. </div >
          </div>
          <div className='flex companymiddle'>
            <div className='w50_ midText'> 또한, 트리즈 방법론을 기반으로 수요 기업의 제품의 기획, 사양 정의 및 아키텍처 설계 등의 컨설팅과 외주개발을 수행하고 있습니다. </div>
            <div className='w50_'>
              <img src='https://ifh.cc/g/hjCHTJ.jpg' alt='니즈로봇 외주개발 회사소개 배경' />
            </div>

          </div>


        </div>
        <div className='companyback flex js'>
          <Place />

        </div>
      </div>
    </>
  );
}

export default Company;
