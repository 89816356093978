
import React from 'react';
import { MAllnavi } from './MAllnavi';
import { useRealTimeData } from '../RealTimeDataContext';

const MHeaders = () => {
  const { token } = useRealTimeData();
  return (
    <>
      <div className='flex j a mheader'>
        <a className='logo' href='/'>
          <img src={'https://ifh.cc/g/67p2mK.png'} alt='니즈로봇 외주개발 모바일버전 로고' />
        </a>

        <MAllnavi login={token} grade={token ? token.uGrade : null} />

      </div>
      {/* <div className={isHovered ? 'headbg' : 'headbg none'}>

 </div> */}
    </>
  )
};
export default MHeaders;