
import React from 'react';
function Offerbtnbox({ offerOks, type, setIsOpens }) {

  return (
    <div className='offerbtn_wrap mt20'>
      {type === 'estimate' || type === 'specification' ?
        <div className='flex mt10 a'>
          <button onClick={() => setIsOpens(true)} className='custom-btn'>견적 재요청</button>
          <button onClick={() => offerOks()} className='custom-btn' >견적 승인</button>
        </div>
        : ''
      }
    </div>
  );
}

export default Offerbtnbox;