import React from "react";
import { TextAreas, findadminvalue, interestProject } from "../project";
import { motion } from "framer-motion";
import { FiTrash2 } from 'react-icons/fi';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FaUser, FaMapMarkerAlt, FaPhoneAlt, FaInfoCircle } from 'react-icons/fa'

function Projectsbox({
  value,
  userId,
  array,
  arrayfunc,
  admin,
  my,
  deleteProjects,

}) {
  const item = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  const interestbtn = () => {
    interestProject(value.id, userId).then(function (response) {
      if (response.data) {
        arrayfunc((prevData) => [
          ...prevData,
          { projectNum: value.id, userId: userId },
        ]);
        Swal.fire("즐겨찾기에서 추가되었습니다.", "success");
      } else {
        const updatedInterest = array.filter(
          (item) => item.projectNum !== value.id
        );
        arrayfunc(updatedInterest);
        Swal.fire("즐겨찾기에서 삭제되었습니다.", "success");
      }
    });
  };
  function getClientAddress(address) {
    const addressArray = address.split(" ");
    const 시구주소 = addressArray.slice(0, 2).join(" ");
    return 시구주소;
  }
  return (
    <>
      {value ? (
        <motion.li className="pro_box li" variants={item}>
          <div className="pro_box_in flex centerj">
            <div className="flex l a j">
              <div className="flex a">
                <div className="navi_round_tag">{value.adminstatus}</div>
                <div className="box_title ml20">
                  {my ? (
                    <Link to={`?uid=${value.uid}`}>{value.title}</Link>
                  ) : admin ? (
                    <Link to={`?uid=${value.uid}`}>{value.title}</Link>
                  ) : (
                    <a href={`/Projects/list/${value.uid}`}>{value.title}</a>
                  )}
                </div>
                {value.adminstatus == "임시저장" && my ? (
                  <button
                    onClick={(e) => deleteProjects(value.uid)}
                    className="xbtnstyle ml10"
                  >
                    <FiTrash2 className="f20 " />
                  </button>
                ) : (
                  ""
                )}
              </div>


              <div className="pro_updatetime">
                <div>등록일자 : {value.createdtime}</div>
                <div>수정일자 : {value.updatetime}</div>
              </div>
            </div>
            <div className="flex l a">
              <div className="w_round_tag_b ">
                <span>{value.cate}</span>
              </div>
              <div className="flex w_round_tag ml20">
                {value.minicate.map((values) => (
                  <span key={values}>{values}</span>
                ))}
              </div>
            </div>
            <div className="box_width_tag blue flex a">
              <div className="flex a">
                <span className="tagT mr10">예상 금액</span>
                <span className="bar mr10"></span>
                <span>{value.price}원</span>
              </div>
              <div className="flex a">
                <span className="tagT mr10">예상 기간</span>
                <span className="bar mr10"></span>
                <span>{value.term}</span>
              </div>
              <div className="flex a">
                <span className="tagT mr10">회의 방식</span>
                <span className="bar mr10"></span>
                <span>
                  {" "}
                  {value.meet === "on"
                    ? "온라인"
                    : value.meet === "off"
                      ? "오프라인"
                      : value.meet === "onoff"
                        ? "온오프라인"
                        : ""}{" "}
                </span>
              </div>
              <div className="flex a">
                <span className="mr10">진행 상태</span>
                <span className="bar mr10"></span>
                <span>
                  {value.pStatus === "new"
                    ? "신규"
                    : value.pStatus === "better"
                      ? "개선"
                      : value.pStatus === "ing"
                        ? "유지보수"
                        : ""}{" "}
                </span>
              </div>
              <div className="flex a">
                {findadminvalue(value.adminstatus).value >= 8 ? (
                  <div className="flex a">
                    <span className="mr10">결재 상태</span>
                    <span className="bar mr10"></span>
                    {findadminvalue(value.adminstatus).value <= 8
                      ? "선금대기"
                      : findadminvalue(value.adminstatus).value <= 9
                        ? "선금완료"
                        : findadminvalue(value.adminstatus).value <= 10
                          ? "중도금대기"
                          : findadminvalue(value.adminstatus).value <= 11
                            ? "중도금완료"
                            : findadminvalue(value.adminstatus).value <= 13
                              ? "잔금대기"
                              : findadminvalue(value.adminstatus).value <= 14
                                ? "잔금완료"
                                : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="pro_body_b flex j a">
              <div className="w80_ flex j a">
                <div className="w50 navi_border_left p10">
                  <h1>프로젝트 개요</h1>
                  <div className="pro_subtext">
                    <TextAreas value={value.outline} />
                  </div>
                </div>
                <div className="w50 navi_border_left p10">
                  <h1>최종 산출물</h1>
                  <div className="pro_subtext">
                    <TextAreas value={value.lastprojects} />

                  </div>
                </div>
              </div>
              <div className="w20_ navi_border_left p10">
                <h1 className="flex a">
                  <FaInfoCircle className="mr10" />
                  <span>담당자 정보</span>
                </h1>
                <div className="pro_subtext">
                  <p>
                    <FaUser className="mr10" />
                    <span>
                      담당자 : {value.client}
                    </span>
                  </p>
                  <p>
                    <FaMapMarkerAlt className="mr10" />
                    <span>
                      주소지 : {getClientAddress(value.clientaddress)}
                    </span>
                  </p>
                  <p>
                    <FaPhoneAlt className="mr10" />
                    <span>
                      연락처 :{" "}
                      {value.clientphone ? value.clientphone : ""}
                    </span>
                  </p>
                </div>

              </div>
            </div>


          </div>
        </motion.li>
      ) : (
        ""
      )}
    </>
  );
}

export default Projectsbox;