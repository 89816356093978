
import React,{useRef} from 'react';
import { searchOption } from '../dataarray';

function Search({searchChange,searchPlay,searchTypes}) {
  const handleOnKeyPress = e => {
    if (e.key === 'Enter') {
      searchPlay(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };
  const focusRef = useRef();
  return (
    <div className='flex search_box'>
    <select onChange={e=>searchTypes(e.target.value)} className='selectbox w120'>
   {searchOption.map(values=>
    <option value={values.value} key={values.value}>{values.label}</option>)}
    </select>
    <div className='flex'>
      <input 
                ref={focusRef}
                onKeyPress={handleOnKeyPress}
      onChange={(e)=>searchChange(e.target.value)} className='search_input'/>
      <button onClick={()=>searchPlay()} className='searchbtn '>검색</button>
    </div>
  </div>
  );
}

export default Search;