
import React from 'react';
import Admin_left from './Admin_left';
import Admin_right from './Admin_right';

function AdminProject({ handleChange, allP, projectlist, setProjectlist, interest, setInterest, userId }) {

  return (
    <>
      <div className='w1480 h100 flex pro_contain'>
        <Admin_left handleChange={handleChange} />
        <Admin_right allP={allP} projectlist={projectlist} setProjectlist={setProjectlist} interest={interest} setInterest={setInterest} userId={userId} />
      </div>

    </>
  );
}

export default AdminProject;