
import React from 'react';
import { Link } from 'react-router-dom';
import MetaTag from '../../MetaTag';
import { metaInfoForProjectAddPage } from '../../service/mataTags';

function Addprojectssave({ savelist }) {

  return (
    <div className='add_right_box'>
      <MetaTag {...metaInfoForProjectAddPage} />
      <div className='right_top'>
        프로젝트 등록 시작하기
      </div>
      <div className='right_body'>
        <div className='project_ban mb40'>
          <p> 니즈로봇과 협력이 필요한 외주개발 프로젝트를 무료로 견적 받아보세요 </p>
          <p>  전문가들과 소통하며 가장 빠르게 외주개발 아웃소싱을 시작하세요</p>
        </div>
        <div className='flex j save_wrap'>
          <div className='savebox '>
            <h1>프로젝트 진행방법</h1>
            <div className='subtext f14'>프로젝트의 전체 진행방법을 알아보세요 </div>
            <Link className='navibtn_hover absolute' to={'/Addprojects/rule'} state={{ data: 'new' }}>진행방법 알아보기</Link>
          </div>
          <div className='savebox '>
            <h1>프로젝트 정보 작성</h1>

            <div className='subtext f14'>클라이언트님의 아이디어의 절대적인 보안을 약속합니다</div>
            <div className='mt10 colorRed f14'>프로젝트 등록후, 3일 이내 견적서를 제공합니다 </div>
            <Link className='navibtn_hover absolute' to={'/Addprojects/add/write'} state={{ data: 'new' }}>무료 견적 요청하기</Link>
          </div>
          <div className='savebox '>
            <h1>임시 저장된 프로젝트</h1>
            <div className='savecon'>
              {savelist && savelist.length > 0 ?
                savelist.map(value =>
                  <Link key={value.uid} to={`/Addprojects/add/write?uid=${value.uid}`}
                  >

                    <div>{value.title ? value.title : '임시저장된 프로젝트'}</div>

                  </Link>
                )
                :
                <div className='f14'>저장된 프로젝트가 없습니다</div>

              }
            </div>

          </div>

        </div>
      </div>
    </div>
  );
}

export default Addprojectssave;