import React from "react";
import ReactToPrint from "react-to-print";
import { useState, useEffect, useRef } from "react";
import Subhead from "../Subhead";
import {
  alertreturn,
  confidentialitys,
  getDate,
  myinfos,
  optionAlert,
} from "../../service/project";
import { confi_left, imageArray } from "../../service/dataarray";
import Confident_main from "./Confident_main";
import Confident_sing from "./Confident_sing";
import Swal from "sweetalert2";
import { StampModal } from "../../service/components/Modals";
import { BiPrinter } from "react-icons/bi";
import { TfiWrite } from "react-icons/tfi";
import MetaTag from "../../MetaTag";
import { metaConfi } from "../../service/mataTags";

function Confidentiality({ userId, login }) {
  // 스탬프변경을 위한
  const [fresh, setFresh] = useState(false);

  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isHeaderFixeds, setIsHeaderFixeds] = useState(false);

  const [num, setNum] = useState(0);
  const [userinfo, setuserinfo] = useState("");
  const [stampid, setStampid] = useState("");
  const openModalHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleScrollAndResize = () => {
    const newIsHeaderFixed = window.scrollY >= 330;
    setIsHeaderFixeds(newIsHeaderFixed);

    const parentElement = document.querySelector(".addproject");
    const fixedElement = document.querySelector(
      ".add_left_projectlistbox.fixed"
    );
    const fixedElements = document.querySelector(".add_left_projectlistbox");

    if (parentElement && fixedElement) {
      const parentRect = parentElement.getBoundingClientRect();
      fixedElement.style.left = parentRect.left + "px";
    } else {
      fixedElements.style.left = 0 + "px";
    }
  };
  useEffect(() => {
    if (login) {
      myinfos(userId).then(function (response) {
        if (response.data) {
          if (!response.data[0].company) {
            optionAlert(
              "success",
              "회사 정보를 입력해주세요",
              "",
              "회사 정보 입력으로 이동하기",
              "/Mypage/Myinfo/clientinfo"
            );
          } else if (response.data[0].confidentiality) {
            setuserinfo(response.data[0]);
            setNum(2);
          } else {
            setuserinfo(response.data[0]);

            setNum(0);
          }
        } else {
          Swal.fire("조회에 실패했습니다", "확인 버튼을 눌러 주세요", "error");
        }
      });
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        event.returnValue = "";
      };
      window.addEventListener("resize", handleScrollAndResize);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("resize", handleScrollAndResize);
      };
    } else {
      Swal.fire({
        title: '로그인 해주세요',
        icon: 'warning'
      })
    }
    const handleScroll = () => {
      if (window.innerWidth >= 981) {
        handleScrollAndResize();
      } else if (window.scrollY <= 980) {
      }
    };
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
    };
  }, []);


  const confidentiality = () => {
    if (
      userinfo.company &&
      userinfo.representative &&
      userinfo.companyregiNum &&
      stampid && login && !userinfo.confidentiality
    ) {
      Swal.fire({
        title: "비밀유지서약에 계약하시겠습니까?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `아니요`,
        confirmButtonText: "네",
      }).then((result) => {
        if (result.isConfirmed) {

          confidentialitys(getDate(), userId, stampid).then(function (
            response
          ) {
            if (response.data) {
              Swal.fire({
                title: "비밀유지서약서 계약이 완료되었습니다",
                icon: "sucess",
              });
              setNum(2);
            }
          });
        }
      });
    } else if (!login) {
      Swal.fire({
        title: '로그인 해주세요',
        icon: 'warning'
      })
    } else if (login && !stampid) {
      optionAlert(
        "success",
        "날인을 등록 해주세요",
        "",
        "회사 정보 입력으로 이동하기",
        "/Mypage/Myinfo/sealingmethod"
      );
    } else {
      alertreturn("loses", "마이페이지 정보를");
    }
  };

  const onFresh = () => {
    setFresh(!fresh);
  };
  const countNext = (value) => {
    if (value === 1) {
      setNum(num <= 1 && num < 2 ? num + 1 : 1)
    } else if (value === -1) {
      setNum(num > 0 ? num - 1 : 0)
    }
  }


  return (
    <div className='heightAuto'>
      <MetaTag {...metaConfi} />
      <div className="backback greyb"></div>
      <div className="project">
        <Subhead
          value={"CONFIDENTIALITY PLEDGE"}
          subvalue={"비밀유지서약서"}
          img={
            imageArray.subtitle_portfolio
          }
        />
        {num >= 0 ? (
          <div
            className={
              isHeaderFixeds
                ? "w1480 h100 flex addproject r"
                : "w1480 h100 flex addproject j"
            }
          >
            <div
              className={
                isHeaderFixeds
                  ? "add_left_projectlistbox fixed"
                  : "add_left_projectlistbox "
              }
            >
              <div className="step_title flex a mb10">
                <TfiWrite style={{ fontSize: "20px", marginBottom: "10px" }} />
                <h1 className="ml10 f20">비밀유지 서약서</h1>
              </div>
              <div className="horizontal-line"></div>
              <div className="flex centerl projectnav">
                {confi_left.map((value) => (
                  <p
                    key={value.value}
                    className={num === value.value ? "active" : ""}
                  >
                    {value.label}
                  </p>
                ))}
                <div className="offerbtn_wrap flex absol r a">
                  <button
                    className={"ps navibtn_hover w33 ml10"}
                    onClick={() => countNext(-1)}
                  >
                    이전
                  </button>
                  <button
                    className={"navibtn_hover w33 ps ml10"}
                    onClick={() => {
                      confidentiality();
                    }}
                    disabled={userinfo.confidentiality || num !== 1}
                  >
                    전자서명
                  </button>
                  {num < 2 && (
                    <button
                      className={"navibtn_hover w33 ps ml10"}
                      onClick={() => countNext(1)}
                    >
                      다음
                    </button>
                  )}

                  <ReactToPrint
                    trigger={() => (
                      <button
                        className={num === 2 ? "navibtn_hover w33 ps mr20 ml10" : "none"}
                        onClick={() => {
                          num === 2 ? confidentiality() : setNum(num + 1);
                        }}
                      >
                        <BiPrinter className="f20" />
                      </button>
                    )}
                    content={() => ref.current}
                  />
                </div>
              </div>
            </div>
            <div className="add_right_wrap outwrap ">
              {num === 0 ? (
                <Confident_main userinfo={userinfo} />
              ) : num === 1 ? (
                <Confident_sing
                  stampid={stampid}
                  num={num}
                  setIsOpen={setIsOpen}
                  setStampid={setStampid}
                  fresh={fresh}
                  userinfo={userinfo}
                />
              ) : num === 2 ? (
                <div ref={ref}>
                  <Confident_main userinfo={userinfo} />
                  <Confident_sing
                    stampid={stampid}
                    notmain={true}
                    setStampid={setStampid}
                    fresh={fresh}
                    userinfo={userinfo}
                  />
                </div>
              ) : (
                ""
              )}
              {isOpen ? (
                <StampModal
                  onFresh={onFresh}
                  openModalHandler={openModalHandler}
                  userId={userId}
                  isOpen={isOpen}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Confidentiality;
