
import React from 'react';

function Radiolabel({ onChange, checked, name, value }) {

  return (
    <div className='radiolabel'>
      <label>
        <input
          onChange={onChange}
          checked={checked}
          type='radio'
          name={name}
        />
        {value}
      </label>
    </div>
  );
}

export default Radiolabel;