
import React from 'react';
import { useEffect } from 'react';
import { motion } from "framer-motion";
import { interestLists } from '../../service/project';
import { useState } from 'react';
import Sortbox from '../../service/components/Sortbox';
import Projectsbox from '../../service/components/Projectsbox';
import Swal from 'sweetalert2';

function Interestedproject({ userId }) {
  const [interP, setInterP] = useState([])
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  useEffect(() => {
    interestLists(userId).then(function (response) {
      if (response.data) {
        setInterP(response.data)
      } else {
        Swal.fire(
          '데이터가 존재하지 않습니다',
          '확인 버튼을 눌러 주세요',
          'error'
        )
      }
    }
    )

  }, [])
  return (
    <div className='interestProject'>
      <div className='pro_box s'>
        {interP.length} 개의 프로젝트
        <Sortbox array={interP} func={setInterP} />
      </div>
      {interP ?
        <motion.ul
          className="project__wrap"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {interP && interP.map((value, index) => (
            <Projectsbox key={index} userId={userId} value={value} />
          ))}
        </motion.ul>
        : ''
      }
    </div>
  );
}

export default Interestedproject;