import React from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation()

  const path = location.pathname.split("/")[1]

  return (
    <div className={!path ? ' none' : 'footer'}>

      <div className='footerbox p20tb w1480 flex j '>
        <ul>
          <li>서울시 노원구 화랑로 815, 제1실습관 5017호(공릉동)</li>
          <li>사업자 등록 번호 : 471-10-00399</li>
          <li>대표전화 : 070-8281-7976</li>
          <li>이메일 : nzr@needsrobot.com</li>

        </ul>
        <ul className='flex footRight'>
          <li className='mr20'>
            <a href='/Company'>
              회사소개
            </a>
          </li>
          <li className='mr20'>
            <a href='/Company'>
              오시는길
            </a>
          </li>
          <li className='mr20'>
            <a href='/Others/Agreement'>
              이용약관
            </a>
          </li>
          <li >
            <a href='/Others/Privacypolicy'>
              개인정보처리방침
            </a>
          </li>
        </ul>
      </div>

    </div>

  )
};
export default Footer;