import React, { useState } from 'react'
import { motion } from "framer-motion";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import AdminUserbox from './AdminUserbox';
import { userTop } from '../../service/dataarray';
import { sortLists } from '../../service/project';
function AdminUser_right({ userlist, setUserlist, projectlist, stamplist }) {
  const [acor, setAcor] = useState('')
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };



  return (
    <div className='w100 userContain_wrap'>
      <div className='userContain'>
        <div className='flex user_top j a'>
          {userTop.map(value =>
            <div key={value.value} className={value.class}>
              <span>
                {value.label}
              </span>
              {value.value <= 5 ?
                <span className='user_desc'>
                  <FaLongArrowAltUp onClick={() => sortLists(userlist, value.value, 'up', setUserlist, stamplist)} />
                  <FaLongArrowAltDown onClick={() => sortLists(userlist, value.value, 'down', setUserlist, stamplist)} />
                </span>
                : ''
              }
            </div>)}
        </div>
        {userlist[0] ?
          <motion.ul
            className="userwrap "
            variants={container}
            initial="hidden"
            animate="visible"
          >

            {userlist.filter(values => values.withdrawal === null || values.withdrawal === '').map((value) =>
              <AdminUserbox userlist={userlist} setUserlist={setUserlist} acor={acor} setAcor={setAcor} key={value.id} projectlist={projectlist && projectlist.filter(values => values.userId === value.userId)} stamplist={stamplist && stamplist.filter(values => values.userId === value.userId)} value={value} />
            )}


          </motion.ul>
          : ''
        }
      </div>
    </div>
  );
}

export default AdminUser_right;