import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
    TaddProject,
    addCommasToNumber,
    getTime,
    myinfos,
    myproject,
    saveBlogin,
    uidproject,
} from '../../service/project'
import Subhead from '../Subhead'
import Addproject from './Addproject'
import Addprojectsjoin from './Addprojectsjoin'
import { v4 as uuidv4 } from 'uuid'
import Addprojectssave from './Addprojectssave'
import Addprojectfine from './Addprojectfine'
import { useCookies } from 'react-cookie'
import Swal from 'sweetalert2'
import { VscSaveAs } from 'react-icons/vsc'

import { FaRegistered } from 'react-icons/fa'
import { metaInfoForProjectAddPage } from '../../service/mataTags'
import MetaTag from '../../MetaTag'

function Addprojectsadd({ userId, login }) {
    const location = useLocation()
    const [uid, setUid] = useState() //uid  저장
    const [saveTime, setSaveTime] = useState() // 저장시간 ㅇ미시 설정
    const pathParts = location.pathname.split('/')

    const path =
        pathParts.length >= 4
            ? '/' + location.pathname.split('/')[2] + '/' + location.pathname.split('/')[3]
            : '/' + location.pathname.split('/')[2]
    const paths =
        pathParts.length >= 4
            ? '/' + location.pathname.split('/')[2] + '/' + location.pathname.split('/')[3].split('&')[0]
            : '/' + location.pathname.split('/')[2]

    const [isHeaderFixeds, setIsHeaderFixeds] = useState(false)
    const [width980, setWidth980] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['id'])
    const searchParams = new URLSearchParams(location.search)
    const num = searchParams.get('uid')
    const [savelist, setSavelist] = useState() //임시저장된 항목 불러오기
    const [userinfo, setUserinfo] = useState('')
    const [projectdata, setProjectData] = useState({
        uid: '',
        title: '',
        email: '',
        company: '',
        cate: '',
        cateother: '',
        minicate: [],
        minicateother: '',
        outline: '',
        lastprojects: '',
        projects: '',
        needskill: '',
        price: '',
        startdate: '',
        term: '',
        contractcost: '',
        contractterm: '',
        pStatus: '',
        meet: '',
        client: '',
        clientphone: '',
        clientaddress: '',
        conditions: [],
        conditionother: '',
        adminstatus: '',
    })

    function getCookieIds() {
        const cookieValue = cookies.id ? cookies.id.join(',') : ''
        try {
            return cookieValue ? cookieValue.split(',') : []
        } catch (error) {
            console.error('Error parsing cookie value:', error)
            return []
        }
    }

    const existingIds = getCookieIds()

    function setCookieIds(ids) {
        const encodedIds = JSON.stringify(ids)
        setCookie('id', encodedIds, {
            path: '/',
            maxAge: 60 * 60 * 24 * 30,
        })
    }
    const updatedIds = [...existingIds, projectdata.uid]
    const handleScrollAndResize = () => {
        const newIsHeaderFixed = window.scrollY >= 330
        setIsHeaderFixeds(newIsHeaderFixed)

        const parentElement = document.querySelector('.addproject')
        const fixedElement = document.querySelector('.add_left_projectlistbox.fixed')
        const fixedElements = document.querySelector('.add_left_projectlistbox')

        if (parentElement && fixedElement) {
            const parentRect = parentElement.getBoundingClientRect()
            fixedElement.style.left = parentRect.left + 'px'
        } else {
            fixedElements.style.left = 0 + 'px'
        }
    }
    useEffect(() => {
        const loadData = async () => {
            if (userId) {
                try {
                    const response = await myproject(userId)
                    const saveListWithConvertedMinicate = response.data
                        .map((item) => {
                            if (item.minicate) {
                                item.minicate = JSON.parse(item.minicate)
                                item.conditions = JSON.parse(item.conditions)
                            }
                            return item
                        })
                        .filter((value) => value.adminstatus === '임시저장')

                    setSavelist(saveListWithConvertedMinicate)

                    const infoResponse = await myinfos(userId)
                    if (infoResponse.data) {
                        setUserinfo(infoResponse.data[0])
                    }
                } catch (error) {
                    // Handle errors
                    console.error('Error occurred while fetching data:', error)
                }
            } else if (paths !== '/add/fine' && path !== '/add/fine') {
                try {
                    const response = await saveBlogin(existingIds)
                    const saveListWithConvertedMinicate = response.data.map((item) => {
                        if (item.minicate) {
                            item.minicate = JSON.parse(item.minicate)
                            item.conditions = JSON.parse(item.conditions)
                        }
                        return item
                    })

                    setSavelist(saveListWithConvertedMinicate)
                } catch (error) {
                    // Handle errors
                    console.error('Error occurred while fetching data:', error)
                }
            }
        }

        loadData()
    }, [path, paths])

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth >= 981) {
                handleScrollAndResize()
            } else if (window.innerWidth <= 980 && window.scrollY >= 184) {
                setWidth980(true)
            } else if (window.innerWidth <= 980 && window.scrollY <= 184) {
                setWidth980(false)
            } else if (window.innerWidth <= 980) {
                setWidth980(true)
            }
        }
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('resize', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleScroll)
        }
    }, [])
    const TsubmitProjects = () => {
        if (!projectdata.title) {
            Swal.fire('제목을 입력해주세요', '제목,담당자,연락처,회사 위치를 임시저장 전 작성 해주세요', 'error')
        } else if (!projectdata.client) {
            Swal.fire('담당자를 입력해주세요', '제목,담당자,연락처,회사 위치를 임시저장 전 작성 해주세요', 'error')
        } else if (!projectdata.clientphone) {
            Swal.fire('연락처를 입력해주세요', '제목,담당자,연락처,회사 위치를 임시저장 전 작성 해주세요', 'error')
        } else if (!projectdata.clientaddress) {
            Swal.fire('회사위치 입력해주세요', '제목,담당자,연락처,회사 위치를 임시저장 전 작성 해주세요', 'error')
        } else {
            TaddProject(
                projectdata.title,
                projectdata.cate,
                projectdata.email,
                projectdata.company,
                projectdata.cateother,
                projectdata.minicate,
                projectdata.minicateother,
                projectdata.outline,
                projectdata.lastprojects,
                projectdata.projects,
                projectdata.needskill,
                projectdata.price,
                projectdata.startdate,
                projectdata.term,
                projectdata.contractcost,
                projectdata.contractterm,
                projectdata.pStatus,
                projectdata.meet,
                projectdata.client,
                projectdata.clientphone,
                projectdata.clientaddress,
                projectdata.conditions,
                projectdata.conditionother,
                userId,
                projectdata.uid,
            ).then(function (response) {
                if (response.data) {
                    setSaveTime(getTime())
                    if (!userId && existingIds.includes(projectdata.uid)) {
                        Swal.fire('저장된 내용이 수정되었습니다', '확인 버튼을 눌러 주세요', 'success')
                        const updatedProjectData = {
                            ...projectdata,
                            adminstatus: '임시저장',
                        }
                        setProjectData(updatedProjectData)
                        return
                    } else if (!userId && !existingIds.includes(projectdata.uid)) {
                        Swal.fire('저장된 내용이 수정되었습니다', '확인 버튼을 눌러 주세요', 'success')
                        const updatedProjectData = {
                            ...projectdata,
                            adminstatus: '임시저장',
                        }
                        setProjectData(updatedProjectData)
                        setCookieIds(updatedIds)
                    } else if (userId) {
                        setSavelist([...savelist, projectdata])
                        const updatedProjectData = {
                            ...projectdata,
                            adminstatus: '임시저장',
                        }
                        setProjectData(updatedProjectData)
                        Swal.fire('저장된 내용이 수정되었습니다', '확인 버튼을 눌러 주세요', 'success')
                    }
                } else {
                    Swal.fire('오류가 발생했습니다', '확인 버튼을 눌러 주세요', 'error')
                }
            })
        }
    }

    const handleChange = (category, itemName) => {
        if (category === 'minicate' || category === 'conditions') {
            const updatedMinicate = projectdata[category].includes(itemName)
                ? projectdata[category].filter((item) => item !== itemName)
                : [...projectdata[category], itemName]

            setProjectData((prevData) => ({
                ...prevData,
                [category]: updatedMinicate,
            }))
        } else if (category === 'price') {
            const numericValue = parseFloat(itemName.replace(/,/g, ''))

            // 입력값이 숫자일 경우에만 설정
            if (!isNaN(numericValue)) {
                setProjectData((prevState) => ({
                    ...prevState,
                    [category]: addCommasToNumber(numericValue),
                }))
            } else {
                setProjectData((prevState) => ({
                    ...prevState,
                    [category]: '',
                }))
            }
        } else if (category === 'clientphone') {
            // 하이픈(-) 제거
            const numericValue = itemName.replace(/-/g, '')

            // 10자리 또는 11자리인 경우 하이픈 추가
            if (numericValue.length === 10) {
                setProjectData((prevState) => ({
                    ...prevState,
                    [category]: numericValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
                }))
            } else if (numericValue.length === 11) {
                setProjectData((prevState) => ({
                    ...prevState,
                    [category]: numericValue.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
                }))
            } else {
                setProjectData((prevState) => ({
                    ...prevState,
                    [category]: itemName,
                }))
            }
        } else {
            setProjectData((prevState) => ({
                ...prevState,
                [category]: itemName,
            }))
        }
    }

    useEffect(() => {
        if (num) {
            uidproject(num)
                .then(function (response) {
                    if (response.data && response.data.length > 0) {
                        const projectData = response.data

                        const saveListWithConvertedMinicate = projectData.map((item) => {
                            if (item.minicate) {
                                item.minicate = JSON.parse(item.minicate)
                                item.conditions = JSON.parse(item.conditions)
                            }
                            return item
                        })

                        setProjectData(saveListWithConvertedMinicate[0])
                        setUid(projectData.uid)
                    }
                })
                .catch(function (error) {
                    console.error('Error fetching data:', error)
                })
        } else {
            setSaveTime('')
            setProjectData({
                uid: uuidv4(),
                title: '',
                cate: '',
                email: '',
                company: '',
                cateother: '',
                minicate: [],
                minicateother: '',
                outline: '',
                lastprojects: '',
                projects: '',
                needskill: '',
                price: '',
                startdate: '',
                term: '',
                contractcost: '',
                contractterm: '',
                pStatus: '',
                meet: '',
                client: '',
                clientphone: '',
                clientaddress: '',
                conditions: [],
                conditionother: '',
                adminstatus: '',
            })
        }
    }, [num])

    return (
        <div className='heightAuto'>
            <div className="backback greyb"></div>
            <div className="project">
                <Subhead
                    userId={userId}
                    value={'REGISTER PROJECT'}
                    subvalue={'협력이 필요한 프로젝트를 등록해주세요'}
                    img={'https://ifh.cc/g/xscnWB.jpg'}
                    path={'/Addprojects/add'}
                />
                <div className={isHeaderFixeds ? 'w1480 h100 flex addproject r' : 'w1480 h100 flex addproject j'}>
                    <div
                        className={
                            isHeaderFixeds || width980 ? 'add_left_projectlistbox fixed' : 'add_left_projectlistbox'
                        }
                    >
                        <div className="step_title flex a mb10">
                            <FaRegistered style={{ fontSize: '20px', marginBottom: '10px' }} />
                            <h1 className="mr10 ml5 f20">프로젝트 등록</h1>
                        </div>
                        <div className="horizontal-line"></div>
                        <div className="flex centerl projectnav">
                            <p className={paths === '/add' || path === '/add ' ? 'active' : ''}>
                                {!width980 ? '1. 프로젝트 시작하기' : '시작하기'}
                            </p>
                            <p className={paths === '/add/write' || path === '/add/write' ? 'active' : ''}>
                                {!width980 ? '2. 프로젝트 정보 작성' : '정보 작성'}
                            </p>
                            {!userId ? (
                                <p className={paths === '/add/join' || path === '/add/join' ? 'active' : ''}>
                                    3. 회원가입(미가입시)
                                </p>
                            ) : (
                                ''
                            )}
                            {!userId ? (
                                <p className={paths === '/add/fine' || path === '/add/fine' ? 'active' : ''}>
                                    {!width980 ? '4. 프로젝트 등록 완료' : '등록 완료'}
                                </p>
                            ) : (
                                <p className={paths === '/add/fine' || path === '/add/fine' ? 'active' : ''}>
                                    {!width980 ? '3. 프로젝트 등록 완료' : '등록 완료'}
                                </p>
                            )}

                            {paths === '/add/write' || path === '/add/write' ? (
                                <div className="savebtnwrap flex center">
                                    <div
                                        className="flex 
              "
                                    >
                                        <button onClick={() => TsubmitProjects()} className="navibtn_hover">
                                            <VscSaveAs className="iconSize" />
                                            임시저장
                                        </button>
                                    </div>
                                    {saveTime || projectdata.updatetime ? (
                                        <div className="savetime">{saveTime || projectdata.updatetime}</div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="add_right_wrap">
                        {paths === '/add' || path === '/add' ? (
                            <Addprojectssave savelist={savelist} userId={userId} projectdata={projectdata} />
                        ) : paths === '/add/write' || path === '/add/write' ? (
                            <Addproject
                                setProjectData={setProjectData}
                                setSaveTime={setSaveTime}
                                num={num}
                                savelist={savelist}
                                setCookieIds={setCookieIds}
                                existingIds={existingIds}
                                uid={num ? uid : projectdata.uid}
                                setUid={setUid}
                                userId={userId}
                                handleChange={handleChange}
                                projectdata={projectdata}
                            />
                        ) : paths === '/add/join' || path === '/add/join' ? (
                            <Addprojectsjoin
                                getCookieIds={getCookieIds}
                                setCookie={setCookie}
                                setCookieIds={setCookieIds}
                                uid={projectdata.uid}
                            />
                        ) : paths === '/add/fine' || path === '/add/fine' ? (
                            <Addprojectfine
                                userinfo={userinfo}
                                setProjectData={setProjectData}
                                projectdata={projectdata}
                                uid={projectdata.uid}
                            />
                        ) : (
                            <Addprojectssave savelist={savelist} userId={userId} projectdata={projectdata} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Addprojectsadd
