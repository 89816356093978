import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { SiCodereview } from "react-icons/si";
import { FaRegistered } from "react-icons/fa";
import {
  cate,
  conditionA,
  minicate,
  projectPlaceholder,
} from "../../service/dataarray";

import {
  addProject,
  comma,
  removeComma,
  updateProject,
  uploadFilesmultie,
} from "../../service/project";
import Radiolabel from "../../service/components/Radiolabel";
import Checkbox from "../../service/components/Checkbox";
import { Modalproject } from "../../service/components/Modals";
import Addfile from "../../service/components/Addfile";
import MetaTag from "../../MetaTag";
import { metaInfoForProjectAdd2Page } from "../../service/mataTags";


function Addproject(props) {
  const navigate = useNavigate();

  const {
    projectdata,
    userId,
    uid,
    existingIds,
    setCookieIds,
    savelist,
    num,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // 추가된 부분
  const emailRef = useRef(null);
  const companyRef = useRef(null);

  const titleRef = useRef(null);
  const cates = useRef(null);
  const minicates = useRef(null);
  const outline = useRef(null);
  const lastprojects = useRef(null);
  const projects = useRef(null);
  const needskill = useRef(null);
  const price = useRef(null);
  const startdate = useRef(null);
  const term = useRef(null);
  const pStatus = useRef(null);
  const meet = useRef(null);
  const client = useRef(null);
  const clientphone = useRef(null);
  const clientaddress = useRef(null);
  const conditionss = useRef(null);
  const submitButtonRef = useRef(null);

  const uploadFiles = (projectId) => {
    uploadFilesmultie(projectId, selectedFiles);
  };

  const submitProject = () => {
    let validationPassed = true;

    // 추가된 검증 부분
    if (!projectdata.email) {
      Swal.fire("이메일을 입력해주세요", "확인 버튼을 눌러 주세요", "warning");
      emailRef.current.focus();
      validationPassed = false;
    } else if (!projectdata.company) {
      Swal.fire("회사를 입력해주세요", "확인 버튼을 눌러 주세요", "warning");
      companyRef.current.focus();
      validationPassed = false;
    } else if (!projectdata.title) {
      Swal.fire("제목을 입력해주세요", "확인 버튼을 눌러 주세요", "warning");
      titleRef.current.focus();
      validationPassed = false;
    } else if (!projectdata.client) {
      Swal.fire(
        "프로젝트 담당자를 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      client.current.focus();
      validationPassed = false;
    } else if (!projectdata.clientphone) {
      Swal.fire(
        "프로젝트 담당자 연락처를 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      clientphone.current.focus();
      validationPassed = false;
    } else if (!projectdata.clientaddress) {
      Swal.fire(
        "프로젝트 의뢰지 주소를 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      clientaddress.current.focus();
      validationPassed = false;
    } else if (!projectdata.cate) {
      Swal.fire(
        "프로젝트 사업분야를 선택해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      cates.current.focus();
      validationPassed = false;
    } else if (projectdata.minicate.length === 0) {
      Swal.fire(
        "프로젝트 의뢰분야를 선택해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      minicates.current.focus();
      validationPassed = false;
    } else if (!projectdata.needskill) {
      Swal.fire(
        "프로젝트 필요기술을 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      needskill.current.focus();
      validationPassed = false;
    } else if (!projectdata.price) {
      Swal.fire(
        "프로젝트 예산을 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      price.current.focus();
      validationPassed = false;
    } else if (!projectdata.startdate) {
      Swal.fire(
        "프로젝트 시작예정일을 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      startdate.current.focus();
      validationPassed = false;
    } else if (!projectdata.term) {
      Swal.fire(
        "프로젝트 기간을 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      term.current.focus();
      validationPassed = false;
    } else if (!projectdata.pStatus) {
      Swal.fire(
        "프로젝트 진행상태를 선택해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      pStatus.current.focus();
      validationPassed = false;
    } else if (!projectdata.meet) {
      Swal.fire(
        "프로젝트 회의방식을 선택해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      meet.current.focus();
      validationPassed = false;
    } else if (projectdata.conditions.length === 0) {
      Swal.fire(
        "프로젝트 파트너 조건을 선택해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      conditionss.current.focus();
      validationPassed = false;
    } else if (!projectdata.outline) {
      Swal.fire(
        "프로젝트 개요를 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      outline.current.focus();
      validationPassed = false;
    } else if (!projectdata.lastprojects) {
      Swal.fire(
        "최종 산출물을 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      lastprojects.current.focus();
      validationPassed = false;
    } else if (!projectdata.projects) {
      Swal.fire(
        "프로젝트 상세설명을 입력해주세요",
        "확인 버튼을 눌러 주세요",
        "warning"
      );
      projects.current.focus();
      validationPassed = false;
    } else {
      if (validationPassed) {
        if (projectdata.adminstatus === "임시저장") {
          updateProject(
            projectdata.title,
            projectdata.email,
            projectdata.company,
            projectdata.cate,
            projectdata.cateother,
            projectdata.minicate,
            projectdata.minicateother,
            projectdata.outline,
            projectdata.lastprojects,
            projectdata.projects,
            projectdata.needskill,
            projectdata.price,
            projectdata.startdate,
            projectdata.term,
            projectdata.contractcost,
            projectdata.contractterm,
            projectdata.pStatus,
            projectdata.meet,
            projectdata.client,
            projectdata.clientphone,
            projectdata.clientaddress,
            projectdata.conditions,
            projectdata.conditionother,
            projectdata.uid
          ).then(function (response) {
            if (response.data && userId) {
              Swal.fire(
                "프로젝트가 등록되었습니다",
                "담당자가확인 후 연락 드리겠습니다",
                "success"
              );
              uploadFiles(response.data.id);
              navigate(`/Addprojects/add/fine?uid=${projectdata.uid}`);
            } else if (response.data && !userId) {
              uploadFiles(response.data.id);
              setCookieIds(existingIds.filter((item) => item !== uid));
              Swal.fire(
                "프로젝트가 등록되었습니다",
                "담당자가확인 후 연락 드리겠습니다",
                "success"
              );
              navigate(`/Addprojects/add/join?uid=${projectdata.uid}`);
            } else {
              Swal.fire("에러가 발생했습니다", "다시 시도해주세요", "error");
            }
          });
        } else {
          addProject(
            projectdata.title,
            projectdata.cate,
            projectdata.email,
            projectdata.company,
            projectdata.cateother,
            projectdata.minicate,
            projectdata.minicateother,
            projectdata.outline,
            projectdata.lastprojects,
            projectdata.projects,
            projectdata.needskill,
            projectdata.price,
            projectdata.startdate,
            projectdata.term,
            projectdata.contractcost,
            projectdata.contractterm,
            projectdata.pStatus,
            projectdata.meet,
            projectdata.client,
            projectdata.clientphone,
            projectdata.clientaddress,
            projectdata.conditions,
            projectdata.conditionother,
            userId,
            uid
          ).then(function (response) {
            if (response.data && userId) {
              Swal.fire(
                "프로젝트가 등록되었습니다",
                "담당자가 확인 후 연락 드리겠습니다",
                "success"
              );
              uploadFiles(response.data.id);
              savelist.filter((item) => item.uid !== uid);
              navigate(`/Addprojects/add/fine?uid=${projectdata.uid}`);
            } else if (response.data && !userId) {
              uploadFiles(response.data.id);
              Swal.fire(
                "프로젝트가 등록되었습니다",
                "담당자가 확인 후 연락 드리겠습니다",
                "success"
              );
              navigate(`/Addprojects/add/join?uid=${projectdata.uid}`);
            } else {
              Swal.fire("에러가 발생했습니다", "다시 시도해주세요", "error");
            }
          });
        }
      } else {
        submitButtonRef.current.focus();
      }
    }
  };

  const openModalHandler = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <MetaTag {...metaInfoForProjectAdd2Page} />
      <div className="add_right_box">
        <div className="right_top_project_infowrite_Title">
          프로젝트 정보 작성
        </div>
        <div className="right_body">
          <div className="right_top_s">기초 정보</div>
          <div className="projectInfo-container">
            <div className="right_body_wrap mb40">
              <div className="flex mb10 minititle a">
                <h1 className="mr10">1. 프로젝트 명</h1>
                <p className="subtext">
                  프로젝트가 게시 되기 전에는 반드시 사전 공지를 드리게 됩니다
                </p>
              </div>
              <input
                ref={titleRef}
                defaultValue={num ? props.projectdata.title : ""}
                onChange={(e) => props.handleChange("title", e.target.value)}
                className="projectAdd_inputbox"
                placeholder="ex) 스마트팜을 위한 AGV로봇 제조"
              />
            </div>
            <div className="flex mb40 wrap j triple">
              <div className="right_body_wrap w33">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">2. 의뢰 담당자</h1>
                  <p className="subtext">담당자의 성함을 입력해주세요</p>
                </div>
                <input
                  ref={client}
                  defaultValue={num ? props.projectdata.client : ""}
                  onChange={(e) => props.handleChange("client", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="ex) 홍길동"
                />
              </div>
              <div className="right_body_wrap w33">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">3. 연락처</h1>
                  <p className="subtext">전화번호를 입력하세요</p>
                </div>
                <input
                  maxLength={13}
                  ref={clientphone}
                  value={props.projectdata.clientphone}
                  onChange={(e) =>
                    props.handleChange("clientphone", e.target.value)
                  }
                  className="projectAdd_inputbox"
                  placeholder="ex) 010-1234-8565"
                />
              </div>

              <div className="right_body_wrap w33">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">4. 이메일</h1>
                  <p className="subtext">연락 가능한 이메일 주소를 입력해주세요</p>
                </div>
                <input
                  ref={emailRef}
                  value={projectdata.email || ""}
                  onChange={(e) => props.handleChange("email", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="ex) example@example.com"
                />
              </div>
              <div className="right_body_wrap w50 mt20">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">5. 회사위치</h1>
                  <p className="subtext">
                    미팅 장소를 참고 할 수 있도록 기재해주세요
                  </p>
                </div>
                <input
                  ref={clientaddress}
                  defaultValue={num ? props.projectdata.clientaddress : ""}
                  onChange={(e) =>
                    props.handleChange("clientaddress", e.target.value)
                  }
                  className="projectAdd_inputbox"
                  placeholder="ex)서울시 중랑구"
                />
              </div>
              <div className="right_body_wrap w50 mt20">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">6. 회사명</h1>
                  <p className="subtext">회사의 이름을 입력해주세요</p>
                </div>
                <input
                  ref={companyRef}
                  value={projectdata.company || ""}
                  onChange={(e) => props.handleChange("company", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="ex) ABC Corp"
                />
              </div>
            </div>
            <div className="right_body_wrap mb40">
              <div className="flex mb10 minititle a">
                <h1 className="mr10">7. 프로젝트 사업분야</h1>
                <input ref={cates} className="opacitynone" />
              </div>

              <ul className="list list-group cate">
                {cate &&
                  cate.map((value, index) => (
                    <Checkbox
                      placeholder='간략하게 해당 사업분야를 기입해 주세요'
                      pg={projectdata}
                      category={"cate"}
                      key={value.label}
                      labelName={value.label}
                      radio={true}
                      value={value}
                      check={props.projectdata.cate}
                      other={props.projectdata.cateother}
                      onClick={() => props.handleChange("cate", value.label)}
                      onChange={(e) =>
                        props.handleChange("cateother", e.target.value)
                      }
                    />
                  ))}
              </ul>
            </div>
            <div className="right_body_wrap mb80">
              <div className="flex mb10 minititle a">
                <h1 className="mr10">8. 프로젝트 의뢰분야 (중복선택가능)</h1>
                <input ref={minicates} className="opacitynone" />
              </div>

              <ul className="list minicate list-group">
                {minicate &&
                  minicate.map((value) => (
                    <Checkbox
                      pg={projectdata}
                      placeholder='간략하게 해당 프로젝트 의뢰분야를 기입해 주세요'
                      ref={minicates}
                      category={"minicate"}
                      key={value.value}
                      labelName={value.value}
                      value={value}
                      check={props.projectdata.minicate}
                      other={props.projectdata.minicateother}
                      onClick={(e) =>
                        props.handleChange("minicate", value.value)
                      }
                      onChange={(e) =>
                        props.handleChange("minicateother", e.target.value)
                      }
                    />
                  ))}
              </ul>
            </div>
          </div>

          <div className="projectInfo-container">
            <div className="right_body_wrap mb40">
              <div className="flex mb10 minititle a">
                <h1 className="mr10">9. 개발 핵심 기술</h1>
                <p className="subtext">
                  본 프로젝트 진행에 필요한 가장 중요한 기술을 입력해주세요
                </p>
              </div>
              <input
                ref={needskill}
                defaultValue={num ? props.projectdata.needskill : ""}
                onChange={(e) =>
                  props.handleChange("needskill", e.target.value)
                }
                className="projectAdd_inputbox"
                placeholder="Ex) AGV 설계 및 제작 기술"
              />
            </div>
            <div className="right_top_s">예산 및 일정</div>
            <div className="flex j mb40 wrap triple">
              <div className="right_body_wrap w33">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">10. 예상 비용</h1>
                  <p className="subtext">
                    가능한 예산을 입력해 주세요
                  </p>
                </div>
                <input
                  ref={price}
                  value={props.projectdata.price}
                  onChange={(e) => props.handleChange("price", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="￦"
                />
                <div className="flex j a">
                  <p className="subtext p5">
                    예상 결제 금액 : ￦
                    {comma(
                      Number(removeComma(props.projectdata.price)) +
                      Number(removeComma(props.projectdata.price) * 0.1)
                    )}
                  </p>
                  <p className="subtext">부가세 10% 포함된 금액 입니다.</p>
                </div>
              </div>
              <div className="right_body_wrap w33">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">11. 시작 일자</h1>
                  <p className="subtext">
                    파트너가 프로젝트에 착수하는 날짜입니다
                  </p>
                </div>

                <input
                  ref={startdate}
                  type="date"
                  defaultValue={num ? props.projectdata.startdate : ""}
                  onChange={(e) =>
                    props.handleChange("startdate", e.target.value)
                  }
                  className="projectAdd_inputbox"
                />
              </div>
              <div className="right_body_wrap w33">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">12. 예상 기간</h1>
                  <p className="subtext">예상 기간을 입력해주세요</p>
                </div>

                <input
                  ref={term}
                  type="number"
                  defaultValue={num ? props.projectdata.term : ""}
                  onChange={(e) => props.handleChange("term", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="일"
                />
              </div>
            </div>

            <div className="right_top_s">프로젝트 진행 정보</div>
            <div className="right_body_wrap mb40">
              <div className="flex mb10 minititle a">
                <h1 className="mr10">13. 진행 상태</h1>
                <input ref={pStatus} className="opacitynone" />
              </div>

              <div className="flex wrap">
                <Radiolabel
                  onChange={(e) => props.handleChange("pStatus", "new")}
                  checked={props.projectdata.pStatus === "new"}
                  name={"sprojectStatus"}
                  value={"New Project(신규)"}
                />
                <Radiolabel
                  onChange={(e) => props.handleChange("pStatus", "better")}
                  checked={props.projectdata.pStatus === "better"}
                  name={"sprojectStatus"}
                  value={"Improve existing projects(개선)"}
                />
                <Radiolabel
                  onChange={(e) => props.handleChange("pStatus", "ing")}
                  checked={props.projectdata.pStatus === "ing"}
                  name={"sprojectStatus"}
                  value={"Maintenance of existing projects(유지보수)"}
                />
              </div>
            </div>
            <div className="right_body_wrap mb40">
              <div className="flex">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">14. 회의 방식</h1>
                  <p className="subtext">파트너와 미팅 방식을 조율합니다</p>
                </div>
                <input ref={meet} className="opacitynone" />
              </div>
              <div className="flex wrap">
                <Radiolabel
                  onChange={(e) => props.handleChange("meet", "on")}
                  checked={props.projectdata.meet === "on"}
                  name={"meet"}
                  value={"온라인"}
                />
                <Radiolabel
                  onChange={(e) => props.handleChange("meet", "off")}
                  checked={props.projectdata.meet === "off"}
                  name={"meet"}
                  value={"오프라인"}
                />
                <Radiolabel
                  onChange={(e) => props.handleChange("meet", "onoff")}
                  checked={props.projectdata.meet === "onoff"}
                  name={"meet"}
                  value={"온오프라인"}
                />
              </div>
            </div>

            <div className="right_body_wrap mb40">
              <div className="flex">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">15. 파트너 조건</h1>
                  <p className="subtext">필요로 하는 내용을 적어주세요</p>
                </div>
                <input ref={conditionss} className="opacitynone" />
              </div>
              <ul className="list list-group">
                {conditionA &&
                  conditionA.map((value) => (
                    <Checkbox
                      placeholder="간략하게 파트너 조건을 기입해주세요"
                      category={"conditions"}
                      key={value.value}
                      className={
                        props.projectdata.conditions &&
                          props.projectdata.conditions.includes(value.value)
                          ? "active list-group-control"
                          : "list-group-control"
                      }
                      value={value}
                      check={props.projectdata.conditions}
                      other={props.projectdata.conditionother}
                      onClick={(e) =>
                        props.handleChange("conditions", value.value)
                      }
                      onChange={(e) =>
                        props.handleChange("conditionother", e.target.value)
                      }
                    />
                  ))}
              </ul>
            </div>
          </div>
          <div className="right_top_s">프로젝트 상태</div>
          <div className="projectInfo-container">
            <div className="right_body_wrap mb40">
              <div className="flex mb10 minititle a">
                <h1 className="mr10">16. 프로젝트 개요</h1>
                <p className="subtext">프로젝트의 개요를 간략하게 설명해주세요</p>
              </div>
              <textarea
                ref={outline}
                rows="3"
                name="textvalue"
                onChange={(e) => props.handleChange("outline", e.target.value)}
                className="projectAdd_inputbox"
                placeholder="ex) 영상처리 기반의 Smart 식물 재배기 개발 프로젝트는 안전하고 효율적인 환경을 조성하여 사용자들에게 편리한 식물 재배를 제공하고 건강한 웰빙 먹거리를 제공하기 위한 프로젝트 입니다."
                value={projectdata.outline}
              />
            </div>
            <div className="right_body_wrap mb40">
              <div className="flex mb10 minititle a">
                <h1 className="mr10">17. 최총 산출물</h1>
                <p className="subtext">자세하게 기재 해주세요</p>
              </div>
              <textarea
                ref={lastprojects}
                rows="3"
                name="lastprojects"
                onChange={(e) =>
                  props.handleChange("lastprojects", e.target.value)
                }
                className="projectAdd_inputbox"
                placeholder="ex) 1.하드웨어 설계 : 아트워크 파일, 회로도, 부품리스트
                                 2.펌웨어 : 펌웨어 코드
                                 3.소프트웨어 : 프론트,백앤드 코드 및 애플리케이션 코드"

                value={num && props.projectdata.lastprojects}
              />
            </div>
            <div className="right_body_wrap mb20">
              <div className="flex mb10 minititle a">
                <h1 className="mr10">18. 프로젝트 상세 설명 </h1>
                <p className="subtext">프로젝트의 전반적인 내용과 세부 사항을 자세히 설명해주세요</p>
              </div>
              <textarea
                rows="5"
                ref={projects}
                name="projects"
                placeholder={projectPlaceholder}
                onChange={(e) => props.handleChange("projects", e.target.value)}
                className="projectAdd_inputbox special"
                defaultValue={num ? projectdata.projects : ""}
              />
            </div>
            <div className="flex mb10 minititle a mt10">
              <h1 className="mr10 mb10 fontWb">19. 프로젝트 관련 파일첨부</h1>
              <p className="subtext mb10">파일을 첨부해주세요</p>
            </div>
            <span className="mb10">
              <Addfile setFunc={setSelectedFiles} array={selectedFiles} />
            </span>
          </div>
          <div className="flex p2020 a c relative">
            <div className="relative ">
              <button
                className="navibtn_hover  mr10"
                onClick={() => setIsOpen(true)}
              >
                <SiCodereview className="iconSize" />
                미리보기
              </button>
              <button

                className="navibtn_hover "
                onClick={submitProject}
              >
                <FaRegistered className="iconSize" />
                프로젝트 등록
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen ? (
        <Modalproject
          data={projectdata}
          openModalHandler={openModalHandler}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedFiles={selectedFiles}
          className="modal-container"
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Addproject;
