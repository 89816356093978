
import React from 'react';
import Pdfcontain from '../../service/components/Pdfcontain';
import Estimatecell from '../../service/components/Estimatecell';
import { useState } from 'react';

function Out_estimate({ imageSrcs, offerlist, detailProject, detail }) {
  const [hidden, setHidden] = useState(false)
  const onClick = () => {
    setHidden(!hidden)
  }
  return (

    <div className='add_right_wrap outwrap'>

      <Pdfcontain list={detail.estimate} single={true} type={'estimate'} file={imageSrcs.base64} />
      <Estimatecell array={offerlist} detailProject={detailProject} />
      {detail && detail.uid === 'exampledata' ?
        <div className='text_absolute'>
          견적서 예시입니다
        </div>
        : null
      }
    </div>
  );
}

export default Out_estimate;