import React, { useState, useRef, useEffect } from "react";
import "./filter.css";
import FilterSlider from "./FilterSlider";
import SidebarItem from "./SidebarItem";

const DEFAULT_OPTIONS = [
  {
    name: "빍기",
    property: "brightness",
    value: 100,
    range: {
      min: 0,
      max: 200
    },
    unit: "%"
  },
  {
    name: "대비",
    property: "contrast",
    value: 100,
    range: {
      min: 0,
      max: 200
    },
    unit: "%"
  },
  {
    name: "채도",
    property: "saturate",
    value: 100,
    range: {
      min: 0,
      max: 200
    },
    unit: "%"
  },
  // {
  //   name: "Grayscale",
  //   property: "grayscale",
  //   value: 0,
  //   range: {
  //     min: 0,
  //     max: 100
  //   },
  //   unit: "%"
  // },
  // {
  //   name: "Sepia",
  //   property: "sepia",
  //   value: 0,
  //   range: {
  //     min: 0,
  //     max: 100
  //   },
  //   unit: "%"
  // },
  // {
  //   name: "Hue Rotate",
  //   property: "hue-rotate",
  //   value: 0,
  //   range: {
  //     min: 0,
  //     max: 360
  //   },
  //   unit: "deg"
  // },
  // {
  //   name: "Blur",
  //   property: "blur",
  //   value: 0,
  //   range: {
  //     min: 0,
  //     max: 20
  //   },
  //   unit: "px"
  // }
];



function Filterimg({ imgsrc, setFilteredImage }) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [options, setOptions] = useState(DEFAULT_OPTIONS);
  const selectedOption = options[selectedOptionIndex];

  const canvasRef = useRef(null);

  useEffect(() => {
    // 컴포넌트가 처음 마운트될 때와 imgsrc가 변경될 때마다 호출
    applyFilterAndSaveImage();
  }, [imgsrc, selectedOption, options]);

  function handleSliderChange({ target }) {
    setOptions((prevOptions) => {
      return prevOptions.map((option, index) => {
        if (index !== selectedOptionIndex) return option;
        return { ...option, value: target.value };
      });
    });
  }

  function applyFilterAndSaveImage() {
    const image = new Image();
    image.crossOrigin = "anonymous"; // 크로스 도메인 문제 해결을 위해 추가

    image.onload = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.width = image.width;
      canvas.height = image.height;

      // 이미지에 현재 필터 스타일을 적용
      context.filter = getImageStyle().filter;
      context.drawImage(image, 0, 0, image.width, image.height);

      // Canvas에서 Blob 생성
      canvas.toBlob((blob) => {
        // Blob을 FileReader를 사용하여 Base64 문자열로 변환
        const reader = new FileReader();
        reader.onloadend = () => {
          setFilteredImage(reader.result);
        };
        reader.readAsDataURL(blob);
      }, "image/png");
    };

    // 이미지 URL 설정 (크로스 도메인 문제를 해결하기 위해)
    image.src = imgsrc;
  }

  function getImageStyle() {
    const filters = options.map((option) => {
      return `${option.property}(${option.value}${option.unit})`;
    });

    return { filter: filters.join(" ") };
  }

  return (
    <div className="containers">
      <div className="mainconwrap">
        <div className="main-image">
          <img src={imgsrc} style={getImageStyle()} alt="니즈로봇 외주개발 이미지 스타일" />

        </div>


      </div>

      <div className="sidebar flex j">
        {options.map((option, index) => {
          return (
            <SidebarItem
              key={index}
              name={option.name}
              active={index === selectedOptionIndex}
              handleClick={() => setSelectedOptionIndex(index)}
            />
          );
        })}

      </div>
      <FilterSlider
        min={selectedOption.range.min}
        max={selectedOption.range.max}
        value={selectedOption.value}
        handleChange={handleSliderChange}
      />
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
}

export default Filterimg;