
import React from 'react';
import { useEffect, useState } from 'react';
import { allportfolio } from '../service/project';
import { ModalPortfolio } from '../service/components/Modals';
import { motion } from "framer-motion";
import Subhead from '../components/Subhead';
import { imageArray } from '../service/dataarray';



function Portfolio() {
  const [projectlist, setProjectlist] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    allportfolio()
      .then(function (response) {
        setProjectlist(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const openModalHandler = (project) => {

    setSelectedProject(project);
    setIsOpen(!isOpen);
  };
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { y: 400, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };
  return (
    <div className="project  ">
      <Subhead value={'공개프로젝트'} subvalue={'NeedsRobot의 혁신적인 기술로 제작되는 공개프로젝트입니다'} img={imageArray.subtitle_portfolio} />
      {projectlist[0] ?
        <motion.ul
          className="container project p20 w1480 project_wrap"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {projectlist && projectlist.map((value, index) => (
            <motion.li
              onClick={() => openModalHandler(value)}
              className='project_box item'
              variants={item}

              key={index}
            >

              <div className='project_thumb'>
                <img src={value.contents} alt={`Project ${index + 1}`} />
              </div>
            </motion.li>
          ))}
        </motion.ul>
        : ''
      }
      <ModalPortfolio
        data={selectedProject}
        openModalHandler={openModalHandler}
        isOpen={isOpen}
      />
    </div>
  );
}

export default Portfolio;