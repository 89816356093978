import styled from "styled-components";
import Draggable from "react-draggable";
import React, { useState, useEffect } from "react";
import {
  TextAreas,
  comma,
  loadImage,
  removeComma,
  getTime,
} from "../project";
import DaumPostcode from "react-daum-postcode";
import Allstampbox from "../../components/Mypage/Allstampbox";
import Estimatecell from "./Estimatecell";
import Pdfcontain from "./Pdfcontain";


export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalBackdrop = styled.div`
  // Modal이 떴을 때의 배경을 깔아주는 CSS를 구현
  z-index: 100; //위치지정 요소
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 8%);
  border-radius: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: grab;
`;

export const ModalBtn = styled.button`
  background-color: var(--coz-purple-600);
  text-decoration: none;
  border: none;
  padding: 20px;
  color: white;
  border-radius: 30px;
  cursor: grab;
`;

export const ExitBtn = styled(ModalBtn)`
  background-color: #d9d9d9;
  border-radius: 10px;
  text-decoration: none;
  padding: 5px 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000;
  position:absolute;
  right:10px;
  top:5px;
  z-index:100;
`;
export const ExitBtn2 = styled(ModalBtn)`
  background-color: #d9d9d9;
  border-radius: 10px;
  text-decoration: none;
  padding: 5px 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #000;
  position:absolute;
  right:10px;
  top:-3px;
  z-index:100;
`;
export const ModalView = styled.div.attrs((props) => ({
  role: "dialog",
}))`
  width: 50%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  max-width: 100%;
  height: fit-content;
  padding: 20px 20px;
  background-color: #ffffff;
  > div.desc {
    margin: 50px;
    font-size: 20px;
    color: var(--coz-purple-600);
  }
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
`;
export const ModalViews = styled.div.attrs((props) => ({
  role: "dialog",
}))`
  width: 80%;
  height: 70vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  max-width: 100%;
  padding: 20px 20px;
  background-color: #ffffff;
  > div.desc {
    margin: 50px;
    font-size: 20px;
    color: var(--coz-purple-600);
  }
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
`;
export const ModalViews2 = styled.div.attrs((props) => ({
  role: "dialog",
}))`

  width: 85%;
  height: 80vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  max-width: 1000px;
  padding: 10px 10px;
  background-color: #ffffff;
  > div.desc {
    margin: 50px;
    font-size: 20px;
    color: var(--coz-purple-600);
  }
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  @media (max-width: 980px) {
    height: 65vh;
  }
`;
// 사용중
export const ModalAddress = ({
  openModalHandler,
  isOpen,
  onToggleZipcode,
  handleComplete,
}) => {
  return (
    <>
      <ModalContainer>
        {/* 조건부 렌더링을 활용해서 Modal이 열린 상태(isOpen이 true인 상태)일 때만 모달창과 배경이 뜰 수 있게 구현 */}
        {isOpen ? (
          <ModalBackdrop onClick={openModalHandler}>
            <Draggable handle="header">
              <ModalView onClick={(e) => e.stopPropagation()}>
                <ExitBtn onClick={openModalHandler}>x</ExitBtn>
                <header className="modal_header flex r a ">
                  <span className="modal_header_in">주소 검색</span>

                </header>
                <DaumPostcode
                  onComplete={handleComplete}
                  onToggleZipcode={onToggleZipcode}
                  handleComplete={handleComplete}
                />
              </ModalView>
            </Draggable>
          </ModalBackdrop>
        ) : null}
      </ModalContainer>
    </>
  );
};




export const ModalInfoview = ({
  openModalHandler,
  isOpen,
  userinfo,
  inputImage,
  admin,
  fileinfo,
}) => {
  const [imageSrcs, setImageSrcs] = useState([]);
  useEffect(() => {
    if (admin) {
      loadImage(userinfo, setImageSrcs, "info");
    } else {
      loadImage(userinfo.file, setImageSrcs, "info");
    }
  }, []);
  return (
    <>
      <ModalContainer>
        {/* 조건부 렌더링을 활용해서 Modal이 열린 상태(isOpen이 true인 상태)일 때만 모달창과 배경이 뜰 수 있게 구현 */}
        {isOpen ? (
          <Draggable handle="header">
            <ModalBackdrop onClick={openModalHandler}>
              <ModalView onClick={(e) => e.stopPropagation()}>
                <ExitBtn onClick={openModalHandler}>x</ExitBtn>
                <header className="modal_header flex r a ">
                </header>
                <div>
                  {fileinfo ? (
                    fileinfo && fileinfo[1] === "pdf" ? (
                      <Pdfcontain
                        file={
                          !admin && userinfo.name
                            ? inputImage
                            : imageSrcs.base64
                        }
                      />
                    ) : (
                      <img
                        src={
                          !admin && userinfo.name
                            ? inputImage
                            : imageSrcs.base64
                        }
                        alt="User Image"
                      />
                    )
                  ) : (
                    <div>파일을 첨부해 주세요</div>
                  )}
                </div>
              </ModalView>
            </ModalBackdrop>
          </Draggable>
        ) : null}
      </ModalContainer>
    </>
  );
};

export const Modalproject = ({
  openModalHandler,
  isOpen,
  data,
  selectedFiles,
}) => {
  return (
    <>
      <ModalContainer>
        {/* 조건부 렌더링을 활용해서 Modal이 열린 상태(isOpen이 true인 상태)일 때만 모달창과 배경이 뜰 수 있게 구현 */}
        {isOpen ? (
          <ModalBackdrop onClick={openModalHandler}>
            <Draggable handle="header">
              <ModalViews onClick={(e) => e.stopPropagation()}>

                <ExitBtn onClick={openModalHandler}>x</ExitBtn>
                <header className="modal_header flex j  a ">
                  <span className="modal_header_in">
                    프로젝트 등록 미리보기
                  </span>
                </header>
                <div className="w100 scrolls scrollbox">
                  <div className="pgbox_top flex j a mb20 ">
                    <h1 className="pgT mb0 w100 mt10">
                      <span className="mr10 gbox_hover">
                        프로젝트 등록
                      </span>
                      {data.title}
                    </h1>
                    <div className="flex j a w50">
                      <div className="flex"></div>
                      <div>
                        <div className="updatetimes">
                          {" "}
                          등록일 :  {getTime()}
                        </div>
                        <div className="updatetimes">
                          수정일 : {getTime()}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex subcatebox a">
                    <span className="mr10 catebox w_round_tag_cateBox fontWb">
                      {data.cateother ? data.cateother : data.cate}
                    </span>
                    <div className="crossline"></div>
                    <ul className="flex ml10 w_round_tag_c fontWb" >
                      {data.minicateother ? (
                        <>
                          {" "}
                          {data.minicate.map((value) => (
                            <li key={value} className="">
                              {value}
                            </li>
                          ))}
                          <li>{data.minicateother}</li>
                        </>
                      ) : (
                        data.minicate.map((value) => (
                          <li key={value} className="mr20">
                            {value}
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                  <div className="table_container flex j">
                    <ul className="w50">
                      <li className="flex">
                        <span className="table_label  bold">1. 희망 금액 : </span>
                        <span>
                          {comma(
                            Number(removeComma(data.price)) +
                            Number(removeComma(data.price) * 0.1)
                          )}
                          원
                        </span>
                      </li>

                      <li className="flex">
                        <span className="table_label  bold ">2. 예상 기간 :</span>
                        <span> {data.term}일</span>
                      </li>

                      <li className="flex">
                        <span className="table_label  bold ">3. 파트너 조건 :</span>
                        <span className="listwrap">

                          {data.conditions.map((value) => (
                            <div className="list" key={value}>
                              <span className="comma">
                                ,
                              </span>
                              <span className="mr5">
                                {value}
                              </span>
                            </div>
                          ))}
                        </span>
                      </li>
                    </ul>
                    <ul className="w50">
                      <li className="flex">
                        <span className="table_label bold">
                          4. 시작 일자 :{" "}
                        </span>
                        <span>{data.startdate}</span>
                      </li>
                      <li className="flex">
                        <span className="table_label bold ">5. 진행 상태 :</span>
                        <span>
                          {data.pStatus === "new"
                            ? "신규로 진행합니다"
                            : data.pStatus === "better"
                              ? "기존 진행했던 프로젝트의 개선"
                              : data.pStatus === "ing"
                                ? "기존 진행했던 프로젝트의 유지보수"
                                : ""}
                        </span>
                      </li>
                      <li className="flex">
                        <span className="table_label bold">6. 회의 방식 :</span>
                        <h1>
                          {data.meet === "on"
                            ? "온라인"
                            : data.meet === "off"
                              ? "오프라인"
                              : data.meet === "onoff"
                                ? "온오프라인"
                                : ""}
                        </h1>
                      </li>
                    </ul>
                  </div>

                  <div className="pgbody">
                    <div className="pgboxs">
                      <span className="name_subtitle f20">7. 프로젝트 개요 </span>
                      <h2 className="">
                        {" "}
                        <TextAreas value={data.outline} />
                      </h2>
                    </div>
                    <div className="pgboxs">
                      <span className="name_subtitle bold f20">8. 개발 핵심 기술</span>
                      <h2 className="">: {data.needskill}</h2>
                    </div>
                    <div className="pgboxs">
                      <span className="name_subtitle bold f20">
                        9. 최종 산출물{" "}
                      </span>
                      <h2 className="">
                        <TextAreas value={data.lastprojects} />
                      </h2>
                    </div>
                    <div className="pgboxs">
                      <span className="name_subtitle bold f20">
                        10. 프로젝트 상세 설명
                      </span>
                      <h2 className="">
                        <TextAreas value={data.projects} />
                      </h2>
                    </div>

                    <div className="pgboxs">
                      <span className="name_subtitle bold f20">11. 프로젝트 관련 파일</span>
                      <ul className="list mt20">
                        {selectedFiles ? (
                          selectedFiles.map(value => <p>{value.fileName}</p>)
                        ) : (
                          ""
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </ModalViews>
            </Draggable>
          </ModalBackdrop>
        ) : null}
      </ModalContainer>
    </>
  );
};

export const ModalUser = ({ isOpens, openModalHandlers, data }) => {

  return (
    <>
      <ModalContainer>

        {isOpens ? (
          <ModalBackdrop onClick={openModalHandlers}>
            <Draggable handle="header">
              <ModalView onClick={(e) => e.stopPropagation()}>
                <ExitBtn onClick={openModalHandlers}>x</ExitBtn>
                <header className="modal_header flex j a ">
                  <div className="colorH">{data && data.userId} 님의 정보</div>

                </header>
                {data ? (
                  <div className="flex j w100">
                    <div className="w50">
                      <ul>
                        <li>이름 : {data.uName}</li>
                        <li>이메일 : {data.email}</li>
                        <li>연락처 : {data.uPhone}</li>
                        <li>직책 : {data.position}</li>
                        <li>분야 : {data.companypart}</li>
                      </ul>
                    </div>
                    <div className="w50">
                      <ul>
                        <li>대표자 : {data.representative}</li>
                        <li>회사명 : {data.company}</li>
                        <li>회사형태 : {data.companytype}</li>
                        <li>사업자등록번호 : {data.companyregiNum}</li>
                        <li>업태 : {data.sector1}</li>
                        <li>종목 : {data.sector2}</li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </ModalView>
            </Draggable>
          </ModalBackdrop>
        ) : null}
      </ModalContainer>
    </>
  );
};

export const StampModal = ({ openModalHandler, isOpen, userId, onFresh }) => {
  return (
    <>
      <ModalContainer>

        {isOpen ? (
          <ModalBackdrop onClick={openModalHandler}>
            <Draggable handle="header">
              <ModalView onClick={(e) => e.stopPropagation()}>
                <ExitBtn onClick={openModalHandler}>x</ExitBtn>
                <header className="modal_header flex j a ">
                  <div className="myStyledModalText">날인변경</div>

                </header>



                <Allstampbox onFresh={onFresh} userId={userId} />
              </ModalView>
            </Draggable>
          </ModalBackdrop>
        ) : null}
      </ModalContainer>
    </>
  );
};

export const Modaloffer = ({
  detailProject,
  onClick,
  isOpens,
  openModalHandlers,
  detail,
  type,
  handleChange,
  newestimate,
  offerlist,
}) => {
  return (
    <>
      <ModalContainer>
        {isOpens ? (
          <ModalBackdrop onClick={openModalHandlers}>
            <Draggable handle="header">
              <ModalView onClick={(e) => e.stopPropagation()}>
                <ExitBtn onClick={openModalHandlers}>x</ExitBtn>
                <header className="modal_header flex j a ">
                  {detail ? (
                    <div>{detailProject.title} 프로젝트 견적요청</div>
                  ) : (
                    ""
                  )}

                </header>
                {detail && type === "estimate" ? (
                  <div className="w100">
                    <div className="table_container w100">
                      <Estimatecell
                        array={offerlist}
                        detailProject={detailProject}
                      />
                      {offerlist.length >= 3 ? (
                        <div className="mt20">
                          이후 재견적 사항은 전화로 문의 부탁드립니다
                        </div>
                      ) : (
                        <>
                          <div className="right_body_wrap flex j w100">
                            <div className=" minititle  p10 w50">
                              <h2 className="mr10">재견적 요청 비용</h2>
                              <p className="subtext">
                                프로젝트에 지출 가능한 예산을 입력해 주세요
                              </p>

                              <input
                                value={newestimate.price}
                                onChange={(e) =>
                                  handleChange("price", e.target.value)
                                }
                                className="projectAdd_inputbox"
                                placeholder="￦"
                              />
                              <p className="subtext p5">
                                예상 결제 금액 : ￦
                                {comma(
                                  Number(
                                    removeComma(
                                      newestimate && newestimate.price
                                    )
                                  ) +
                                  Number(
                                    removeComma(
                                      newestimate && newestimate.price
                                    ) * 0.1
                                  )
                                )}
                              </p>
                              <p className="subtext">
                                부가세 10% 포함된 금액 입니다.
                              </p>
                            </div>
                            <div className=" minititle  p10 w50">
                              <h2 className="mr10">재견적 요청 기간</h2>
                              <p className="subtext">
                                계약 완료 후 소요되는 기간을 입력해주세요
                              </p>

                              <input
                                value={newestimate.term}
                                onChange={(e) =>
                                  handleChange("term", e.target.value)
                                }
                                type="number"
                                className="projectAdd_inputbox"
                                placeholder="일"
                              />
                            </div>
                          </div>

                          <div className="right_body_wrap w100 ">
                            <div className="flex mb10 minititle a p10">
                              <h2 className="mr10">기타 요청 내용</h2>
                              <p className="subtext">
                                기타 외 수정 요청 사항을 입력해주세요
                              </p>
                            </div>
                            <textarea
                              value={newestimate.content}
                              onChange={(e) =>
                                handleChange("content", e.target.value)
                              }
                              className="projectAdd_inputbox"
                            />
                          </div>
                          <div className="flex c p10">
                            <button
                              className="next_btn_hover"
                              onClick={() => onClick()}
                            >
                              {offerlist.length === 2 ? "수정하기" : "요청하기"}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : detail && type === "specification" ? (
                  <div></div>
                ) : (
                  ""
                )}
              </ModalView>
            </Draggable>
          </ModalBackdrop>
        ) : null}
      </ModalContainer>
    </>
  );
};


export const ModalPortfolio = ({ openModalHandler, isOpen, data }) => {
  const [zoomLevel, setZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 3)); // Max zoom level of 3
  };

  const handleZoomOut = () => {
    setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 1)); // Min zoom level of 1
  };

  return (
    <>
      <ModalContainer>
        {isOpen ? (
          <ModalBackdrop onClick={openModalHandler}>
            <Draggable handle="header">
              <ModalViews2 onClick={(e) => e.stopPropagation()}>
                <ExitBtn2 onClick={openModalHandler}>x</ExitBtn2>
                <header className="modal_header2 flex r a ">
                  <span className="modal_header_in2">{data.title}</span>
                </header>
                <div className="flex ac portfolio w100">
                  <div className="w90_">
                    <div className="info-table">
                      <div className="info-row flex j">
                        <div className="info-cell w200px br0">
                          <span>작성자</span>
                          <p>박진영</p>
                        </div>
                        <div className="info-cell specials w_200 bl0 flex r">
                          <span>진행기간</span>
                          <p className="w200px block">{data.term}</p>
                        </div>
                      </div>
                      <div className="info-row">
                        <div className="info-cell">
                          <span>참여분야</span>
                          <p>{data.category}</p>
                        </div>
                      </div>
                      <div className="info-row">
                        <div className="info-cell">
                          <span>내용요약</span>
                          <div>{data.subcontents}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='project_img_container'>
                    <div className='zoom_controls'>
                      <button onClick={handleZoomIn}>+</button>
                      <button onClick={handleZoomOut}>-</button>
                    </div>
                    <div className='project_img' >
                      <img style={{ transform: `scale(${zoomLevel})` }} src={data.contents} alt="Project" />
                    </div>
                  </div>
                </div>
              </ModalViews2>
            </Draggable>
          </ModalBackdrop>
        ) : null}
      </ModalContainer>
    </>
  );
};

