import React from "react";
import axios from "axios";
import FileSaver from "file-saver";
import { allurl } from "../project";

import { BiPrinter } from 'react-icons/bi';


const FileDownloadAndPrint = ({ list, type }) => {
  const fileName = list && list.substring(list.lastIndexOf('/') + 1); // 파일 이름 추출
  const displayName = list && fileName.replace(/\.pdf$/, ""); // 확장자 제거
  const downloadAndPrintFile = async () => {
    try {
      const response = await axios({
        url: `${allurl}/api/download/${type}/${list.split("/").pop()}`,
        method: "GET",
        responseType: "blob",
        withCredentials: true,
      });

      // 파일을 다운로드
      FileSaver.saveAs(response.data, `${displayName}.pdf`);

      // 파일을 프린트
      // const file = new Blob([response.data], { type: "application/pdf" });
      // const fileURL = URL.createObjectURL(file);
      // const printWindow = window.open(fileURL, "_blank");
      // printWindow.print();
    } catch (error) {
      console.error("파일 다운로드 및 프린트 중 오류:", error);
    }
  };

  return (
    <div>
      <button className="pdfDownloadBTN mt10" onClick={downloadAndPrintFile}><BiPrinter className="f20" /></button>
    </div>
  );
};

export default FileDownloadAndPrint;