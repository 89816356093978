import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Sortbox from '../../service/components/Sortbox';
import Projectsbox from '../../service/components/Projectsbox';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

function Admin_right({ userId, projectlist, setProjectlist, interest, setInterest }) {
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 5; // 한 페이지에 표시할 프로젝트 수

  // 전체 페이지 수 계산
  const totalPageCount = Math.ceil(projectlist.length / perPage);

  // 현재 페이지에 해당하는 프로젝트 목록을 계산합니다.
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const currentProjects = projectlist.slice(startIndex, endIndex);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  // 페이지를 변경하는 함수
  const changePage = (newPage) => {
    setCurrentPage(newPage);
  };

  // 페이지 번호 목록을 생성하는 함수
  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={i === currentPage ? 'pgnation active' : 'pgnation'}
          onClick={() => changePage(i)}
        >
          {i}
        </span>
      );
    }

    return pageNumbers;
  };

  return (
    <div className='project_right'>
      <div className='pro_box s'>
        <div className='mb10'>{projectlist.length} 개의 프로젝트</div>
        <Sortbox array={projectlist} func={setProjectlist} changePage={changePage} />
      </div>
      {currentProjects[0] ? (
        <motion.ul className='project__wrap' variants={container} initial='hidden' animate='visible'>
          {currentProjects.map((values, index) => (
            <Projectsbox admin={true} arrayfunc={setInterest} array={interest} key={index} userId={userId} value={values} />
          ))}
        </motion.ul>
      ) : (
        ''
      )}
      {/* 페이지네이션 컴포넌트 */}
      <div className='pagination'>
        <button className='navibtn_hover p3_3 mr10' onClick={() => changePage(currentPage - 1)} disabled={currentPage === 1}>
          <AiOutlineLeft />
        </button>
        {renderPageNumbers()}
        <button className='navibtn_hover p3_3 ml10' onClick={() => changePage(currentPage + 1)} disabled={currentProjects.length < perPage}>
          <AiOutlineRight />
        </button>
      </div>
    </div>
  );
}

export default Admin_right;