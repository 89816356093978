
import React from 'react';

function Stamp2() {
  return (
    <div className='client_right flex center'>
      <div className='stamptextWrap'>
        <h1 className='mb10'>서명등록이 완료 되었습니다</h1>
        <p className='mb10'>니즈르봇과 함께 프로젝트에 함께해주세요</p>
        <div className='flex c'>
          <a className='navibtn_hover  mr10' href='/Mypage/Myinfo/sealingmethod'>
            날인목록보기
          </a>
          <a className='navibtn_hover ' href='/'>
            홈으로
          </a>
        </div>

      </div>
    </div>
  );
}

export default Stamp2;