import axios from "axios";
import React from "react";
import { allurl } from "../project";

function Downloadfile({ lists, single, type }) {
  const list = single ? lists : lists.split(",");
  const downloadFile = (url, type) => {
    axios({
      url: `${allurl}/api/download/${type}/${url.split("/").pop()}`,
      method: "get",
      responseType: "blob", // 파일 다운로드를 위해 blob 형식으로 응답 받음
      withCredentials: true,
    })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = fileURL;
        link.setAttribute("download", url.split("/").pop());
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.error("파일 다운로드 중 오류 발생:", error);
      });
  };

  return (
    <>

      {single
        ? list.map((value, index) => (
          <li
            className={"file-container"}
            key={value.id}
            onClick={() => downloadFile(value.stampname)}
          >
            {index + 1}. {value.stampname.split("/").pop()}
          </li>
        ))
        : list.map((value, index) => (
          <li
            className={"file-container"}
            key={value}
            onClick={() => downloadFile(value, type)}
          >
            {index + 1}. {value.split("/")[value.split("/").length - 1]}
          </li>
        ))}

    </>
  );
}

export default Downloadfile;