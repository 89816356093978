
import React, { useState, useRef } from 'react';
import { taxtype } from '../../service/dataarray';
import { ModalInfoview } from '../../service/components/Modals';

function Clientinfo3({ userinfo, handleChange }) {
  const [inputImage, setInputImage] = useState(null);
  const [fileinfo, setFileInfo] = useState(null);
  const imgRef = useRef();
  const saveImgFile = () => {
    const file = imgRef.current.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setInputImage(reader.result);
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop();
      setFileInfo([fileName, fileExtension])
    };
  };
  const [isOpen, setIsOpen] = useState(false);
  const openModalHandler = () => {
    setIsOpen(!isOpen);
  };
  const removeSelectedImage = (valueToRemove) => {
    setInputImage((previousImages) =>
      previousImages.filter((e) => e !== valueToRemove)
    );
  };

  return (
    <div className='w_200px'>
      <div className='client_top'>
        <div className='innerbox mb20'>
          <h1>신원 인증을 통해 프로젝트를 보다 더 안전하게 계약 할 수 있습니다</h1>
          <h2>1. 신원인증 등록서류</h2>
          <p>프로젝트의 계약을 위해, 신원 인증 서류 등록이 꼭 필요합니다.</p>
          <p>신원 인증 서류는 계약서 작성 시에 이용됩니다.</p>
          <div className='innermbox flex mt10 mb20'>
            <div className='left'>
              <div>법인,개인사업자</div>
              <div>개인,팀</div>
            </div>
            <div className='right'>
              <div>사업자등록증</div>
              <div>주민등록증, 운전면허증</div>
            </div>
          </div>
          <h2>2. 신원인증 유의사항</h2>
          <p>신원인증은 약 48시간이 소요 됩니다</p>
          <p className='mb20'>서류상 문제가 있을시 이메일이나 전화번호로 연락을 드립니다</p>
          <h2>3. 신원인증 완료</h2>
          <p>신원 인증이 완료되면 완료된 결과를 문자로 보내드립니다</p>
          <p>정보가 변경될시에 신원인증을 갱신하여 세부 정보를 수정 할 수 있습니다</p>
        </div>

      </div>
      <div className='flex j '>
        {userinfo.companytype === '개인 사업자' ?
          <div className='infobox w50'>
            <h1>개인사업자형태</h1>
            <select
              defaultValue={userinfo.taxtype}
              className='selectboxs mt10' onChange={(e) => handleChange('taxtype', e.target.value)}>
              {taxtype.map(value =>
                <option
                  key={value.value}
                >
                  {value.label}
                </option>)}
            </select>
          </div>
          : ''
        }
        <div className={userinfo.companytype === '개인 사업자' ? 'infobox w50 ' : 'infobox w100 '}>
          <div className='flex a'>
            <h1 className='mr10'>신원인증 파일첨부</h1>
            <label htmlFor='fileInput' className="navibtn_hover">
              파일선택
              <input ref={imgRef} className="none" id='fileInput' type="file" accept="*"

                onChange={(e) => {
                  handleChange('file', URL.createObjectURL(e.target.files[0]))
                  handleChange('name', e.target.files[0].name)
                  saveImgFile()
                }
                } />
            </label>
          </div>
          <div className='flex a'>
            <div className='mr10 file-container '>
              {userinfo.name ?
                userinfo.name
                : userinfo.file ?
                  userinfo.file.split('/')[userinfo.file.split('/').length - 1]
                  : '파일을 첨부해 주세요'}
            </div>
            <button className='navibtn_hover' onClick={() => { setIsOpen(true) }}>미리보기</button>
          </div>
          {userinfo.identity === 1 && userinfo.file ?
            <div className='identity after'>신원인증이 완료되었습니다.</div>
            : userinfo.file ?
              <div className='identity before'>신원인증 대기중입니다.</div>
              : ''
          }

        </div>

      </div>
      {isOpen ?
        <ModalInfoview fileinfo={fileinfo} isOpen={isOpen} userinfo={userinfo} inputImage={inputImage} openModalHandler={openModalHandler} />
        : ''
      }

    </div>
  );
}

export default Clientinfo3;