
import React from 'react';
import { comma, removeComma, } from '../../service/project';
function OutCell({ contract }) {

  return (

    <div className='out_cell mt10 mb20'>
      <div className='flex j a cell_line cell_head'>
        <div className='w15 w'>구분</div>
        <div className='w15 w'>지급요율</div>
        <div className='w35 w'>지급금액(VAT제외)</div>
        <div className='w35 w'>대금 지급 조건 및 방법</div>
      </div>
      <div className='flex j cell_line a'>
        <div className='w15 w'>선금</div>
        <div className='w15 w'>{contract.beforeprice} %</div>
        <div className='w35 w'> ￦ {comma(removeComma(contract.allprice) * Number(contract.beforeprice) / 100)} 원</div>
        <div className='w35 w'>{contract.beforepricecon}</div>
      </div>
      <div className='flex j cell_line a'>
        <div className='w15 w'>중도금</div>
        <div className='w15 w'>{contract.middleprice} %</div>
        <div className='w35 w'> ￦ {comma(removeComma(contract.allprice) * Number(contract.middleprice) / 100)} 원</div>
        <div className='w35 w'>{contract.middlepricecon}</div>
      </div>
      <div className='flex j cell_line a'>
        <div className='w15 w'>잔금</div>
        <div className='w15 w'>{contract.afterprice} %</div>
        <div className='w35 w'> ￦ {comma(removeComma(contract.allprice) * Number(contract.afterprice) / 100)} 원</div>
        <div className='w35 w'>{contract.afterpricecon}</div>
      </div>

    </div>

  );
}

export default OutCell;