import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Subhead from '../Subhead';
import { alertreturn, allusers, deleteMessage, getItemWithExpireTime, sendMessages, sendMessagetrue } from '../../service/project';
import Mymessage_write from './Mymessage_write';
import Mymessage_receive from './Mymessage_receive';
import Mymessage_messageBox from './Mymessage_messageBox';
import Swal from 'sweetalert2';
import { imageArray } from '../../service/dataarray';

function Mymessage({ userId, mlist, setMlist }) {

  const location = useLocation();
  const navigate = useNavigate()
  const path = location.pathname.split('/')[2];
  const pars = getItemWithExpireTime('token') && getItemWithExpireTime('token').uGrade;


  const [alluserlist, setallUserlist] = useState('');
  const [writes, setWrites] = useState({
    user: '',
    title: '',
    contents: '',
  });

  const [detail, setDetail] = useState('');
  const [isHeaderFixeds, setIsHeaderFixeds] = useState(false);

  const handleScrollAndResize = () => {
    const newIsHeaderFixed = window.scrollY >= 330;
    setIsHeaderFixeds(newIsHeaderFixed);

    const parentElement = document.querySelector('.addproject');
    const fixedElement = document.querySelector('.add_left.fixed');
    const fixedElements = document.querySelector('.add_left');

    if (parentElement && fixedElement) {
      const parentRect = parentElement.getBoundingClientRect();
      fixedElement.style.left = parentRect.left + 'px';
    } else {
      fixedElements.style.left = 0 + 'px';
    }
  };
  useEffect(() => {
    allusers().then(function (response) {
      if (response.data) {
        setallUserlist(response.data);
      }
    });
    if (mlist) {
      const sortedMessages = mlist.slice().sort((a, b) => {
        if (a.openmessage === null && b.openmessage === null) return 0;
        if (a.openmessage === null) return -1;
        if (b.openmessage === null) return 1;

        if (a.openmessage === 0 && b.openmessage === 0) return 0;
        if (a.openmessage === 0) return -1;
        if (b.openmessage === 0) return 1;

        return 0; // openmessage 값이 1인 경우 순서 변경 없음
      });
      setMlist(sortedMessages)
    }
    const handleScroll = () => {
      if (window.innerWidth >= 981) {
        handleScrollAndResize();
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };

  }, []);
  const handle = (id, value) => {
    if (id === 'users') {
      setWrites((prevState) => ({
        ...prevState,
        'user': value
      }));
    }
    else if (path === 'writeMessage') {
      if (pars) {
        if (writes[id] === value) {
          setWrites((prevState) => ({
            ...prevState,
            [id]: ''
          }));
        } else {
          setWrites((prevState) => ({
            ...prevState,
            [id]: value
          }));
        }
      } else if (writes.user === 'admin') {
        setWrites((prevState) => ({
          ...prevState,
          [id]: value
        }));
      }
      else {

        navigate('/MYMESSAGE/all')
        const selectedMessage = mlist.find((message) => message.detail.id === value);
        if (selectedMessage && !selectedMessage.detail.openmessage) {

          selectedMessage.detail.openmessage = 1;
          setMlist([...mlist]);

          sendMessagetrue(value).then(function (response) {
            if (response.data) {
              setMlist([...mlist]);
            } else {
              alertreturn('error');
            }
          });
        }
        setDetail(selectedMessage);
      }
    } else {
      const selectedMessage = mlist.find((message) => message.detail.id === value);
      if (selectedMessage && !selectedMessage.detail.openmessage) {
        selectedMessage.detail.openmessage = 1;
        setMlist([...mlist]);

        sendMessagetrue(value).then(function (response) {
          if (response.data) {
            setMlist([...mlist]);

          } else {
            alertreturn('error');
          }
        });
      }
      setDetail(selectedMessage);
    };
  }
  const sendMessage = () => {
    if (!writes.user) {
      alertreturn('lose', '받는이를')
    } else if (!writes.title) {
      alertreturn('lose', '제목을')
    } else if (!writes.contents) {
      alertreturn('lose', '내용을')
    } else {
      sendMessages(pars ? 'admin' : userId, writes.user, writes.title, writes.contents).then(function (response) {
        if (response.data) {
          alertreturn('good');
          setWrites({
            user: '',
            title: '',
            contents: '',
          });
        }
      });
    }
  };
  const deleteMessages = () => {
    Swal.fire({
      title: "삭제 하시겠습니까?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: `아니요`,
      confirmButtonText: '네'
    }).then((result) => {
      if (result.isConfirmed) {
        alertreturn('delete')
        deleteMessage(detail && detail.id).then(function (response) {
          if (response.data) {
            alertreturn('delete')
            const newlist = mlist.filter(values => values.id !== detail.id)

            setMlist(newlist)
            setDetail('')
          }
        })
      }
    })

  };
  return (
    <div className='heightAuto'>
      <div className='backback greyb'></div>
      <div className='project'>
        <Subhead value={'MY MESSAGE'} subvalue={'나의 메세지'} img={imageArray.subtitle_portfolio} />
        <div className={isHeaderFixeds ? 'w1480 h100 flex addproject r' : 'w1480 h100 flex addproject j'}>
          <div className={isHeaderFixeds && path !== 'writeMessage' ? 'add_left fixed bigwide big' : isHeaderFixeds && path === 'writeMessage' ? 'add_left fixed ' : path === 'writeMessage' ? 'add_left' : 'add_left bigwide'}>
            <h1>{path === 'writeMessage' && pars ? '유저목록' : '메세지 목록'}</h1>
            <div className='flex centerl projectnav scrolls'>
              {pars && path === 'writeMessage'
                ? alluserlist && alluserlist.map((value) => (
                  <Mymessage_messageBox path={path} value={value} handle={handle} type='user' />
                ))
                : path === 'notice'
                  ? mlist && mlist.filter((type) => type.detail.type === 2).map((value) => (
                    <Mymessage_messageBox path={path} value={value} handle={handle} type='other' />
                  ))
                  : path === 'estimate'
                    ? mlist && mlist.filter((type) => type.detail.type === 1).map((value) => (
                      <Mymessage_messageBox value={value} path={path} handle={handle} type='other' />
                    ))
                    : mlist && mlist.map((value) => (
                      <Mymessage_messageBox value={value} path={path} handle={handle} type='other' />
                    ))
              }
            </div>
          </div>
          <div className={path === 'writeMessage' ? 'add_right_wrap' : 'add_right_wrap bigwide'}>
            {pars && path === 'writeMessage' ?
              <Mymessage_write alluserlist={alluserlist} handle={handle} func={sendMessage} writes={writes} />
              : !pars && path === 'writeMessage' ?
                <Mymessage_write user={true} alluserlist={alluserlist} handle={handle} func={sendMessage} writes={writes} />
                : detail
                  ? <Mymessage_receive handle={handle} deleteMessages={deleteMessages} detail={detail} />
                  : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mymessage;