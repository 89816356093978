
import React, { useState, useEffect } from 'react';
import Subhead from '../Subhead';
import { useLocation } from 'react-router-dom';
import { myinfos, updateinfo, uploaddocufile } from '../../service/project';
import Sealingmethod from './Sealingmethod';
import Clientinfo_left from './Clientinfo_left';
import Clientinfo1 from './Clientinfo1';
import Clientinfo3 from './Clientinfo3';
import Swal from 'sweetalert2';
import Changepass from './Changepass';
import MetaTag from '../../MetaTag';
import { metaMyinfo } from '../../service/mataTags';
import { imageArray } from '../../service/dataarray';

function Myinfo({ userInfo }) {

  const location = useLocation()
  const pathParts = location.pathname.split("/")[3];
  const change = location.pathname.split("/")[4] === 'passchange';

  const [check, setCheck] = useState(false);
  const [userinfo, setUserinfo] = useState({
    userId: '',
    uPassword: '',
    uName: '',
    email: '',
    uPhone: '',
    position: '', //직책
    companypart: '', //내 분야  

    company: '',//회사명
    representative: '', //대표자
    companytype: '',
    companyregiNum: '', //사업자등록번호
    sector1: '',//업태
    sector2: '',//종목
    companyNum: '', //사무실번호
    taxmail: '',//세금계산서이메일
    companyaddressf: '',//사무실 주소
    companyaddressm: '',//사무실 주소
    companyaddNum: '',//우편번호
    taxtype: '', //간이과세자 등등
    file: '',
    name: ''
  })

  const [pass, setPass] = useState()
  const handleChange = (category, itemName) => {
    if (category === 'uPhone') {
      if (itemName.length === 10) {
        itemName = itemName.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      } else if (itemName.length === 13) {
        itemName = itemName.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      } else {
        setUserinfo(prevState => ({
          ...prevState,
          [category]: itemName
        }));
      }
    } else if (category === 'companyNum') {
      if (itemName.length === 10) {
        itemName = itemName.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      } else if (itemName.length === 13) {
        itemName = itemName.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      } else {
        setUserinfo(prevState => ({
          ...prevState,
          [category]: itemName
        }));
      }
    } else if (category === 'companyregiNum') {
      if (itemName.length === 9) {
        itemName = itemName.replace(/-/g, '').replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
      } else {
        setUserinfo(prevState => ({
          ...prevState,
          [category]: itemName
        }));
      }
    }

    setUserinfo(prevState => ({
      ...prevState,
      [category]: itemName
    }));
  };
  useEffect(() => {
    if (userInfo && userInfo.userId) {
      myinfos(userInfo && userInfo.userId).then(function (response) {
        setUserinfo(response.data && response.data[0])
        setCheck(response.data && response.data[0].taxmail === response.data && response.data[0].email)
      })
    } else {
      Swal.fire({
        title: '로그인 해주세요',
        icon: 'warning'
      })
    }
  }, [])

  const onClick = () => {
    updateinfo(userinfo.uName, userinfo.email, userinfo.uPhone, userinfo.position, userinfo.companypart, userinfo.company, userinfo.representative, userinfo.companytype, userinfo.companyregiNum, userinfo.sector1, userinfo.sector2, userinfo.companyNum, userinfo.taxmail, userinfo.companyaddressf, userinfo.companyaddressm, userinfo.companyaddNum, userinfo.taxtype, userinfo.userId).then(function (response) {
      if (response.data) {
        if (userinfo.name) {
          uploaddocufile(userinfo.file, userinfo, userinfo.name, 'userinfo')
        } else {
          Swal.fire(
            '수정 되었습니다',
            '확인 버튼을 눌러 주세요',
            'success'
          )
        }
      } else {
        Swal.fire(
          '오류가 발생하였습니다',
          '확인 버튼을 눌러 주세요',
          'error'
        )
      }
    })
  }
  return (
    <div className='heightAuto'>
      <MetaTag {...metaMyinfo} />
      <div className='backback greyb'></div>
      <div className="project  ">
        <Subhead value={'MY INFO'} subvalue={'나의 기본 정보를 확인하고 변경해주세요'} img={imageArray.subtitle_portfolio} />
        <div className=' h100 mypro_contain w1480'>
          <div className='client_top'>
            <p className='subtext'>상세정보를 추가 할 수록 능력있는 클라이언트를 만나게 됩니다</p>
            <div className='innerbox'>
              <h1>아직 마땅한 클라이언트를 만나지 못하셨나요?</h1>
              <p>회사(개인) 의 상세 정보를 적어주시면 신뢰가 되는 요청을 할수 있습니다</p>
              <p>회사(개인) 의 역량을 보여주세요! 프로젝트 경험 및 다양한 자격을 기재해주세요</p>
            </div>
          </div>
          {userinfo ?
            <div className='client_body flex j '>
              <Clientinfo_left pathParts={pathParts} onClick={onClick} />
              {pathParts === 'clientinfo' && !change ?
                <div className='w_200px'>
                  <Clientinfo1 setCheck={setCheck} check={check} handleChange={handleChange} userinfo={userinfo}
                    setUserinfo={setUserinfo} />

                </div>
                : pathParts === 'identification' ?
                  <Clientinfo3 handleChange={handleChange} userinfo={userinfo}
                    setUserinfo={setUserinfo} />
                  : pathParts === 'sealingmethod' ?
                    <Sealingmethod userinfo={userinfo} userId={userInfo && userInfo.userId} />
                    : change ?
                      <Changepass setPass={setPass} pass={pass} userinfo={userinfo} />
                      : ''
                // <Account  setPass={setPass} pass={pass} userinfo={userinfo}/>
              }
            </div>
            : ""}
        </div>

      </div>
    </div>
  );
}

export default Myinfo;