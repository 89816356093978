import React from "react";
import { PDFDownloader } from "./Mycontract";
import ReactToPrint from 'react-to-print';
import { BiPrinter } from 'react-icons/bi';
function Toptabs({ data, value, userinfo, div, top, countNum, num, uni }) {



  return (
    <div className="flex j a   w100 mb10">
      <span className='est-dev-contract'>{value}</span>
      <div className="flex mr10 btnTop">
        <button
          className={"next_btn_hover p10 mt10 ml10  "}
          disabled={!top}
          onClick={top ? () => { countNum && countNum(-1) } : null}
        >
          이전
        </button>
        <span className="pdf_border-box p10 mt10 ml10">
          {value === '견적서' || value === '비용 결제내역' || value === '개발 계약서' && uni ? '1 / 1' : `${num + 1} / 6`}
        </span>
        <button
          className={"next_btn_hover p10 mt10 ml10 mr10"}
          disabled={!top}
          onClick={top ? () => { countNum && countNum(1) } : null}
        >
          다음
        </button>
        <div>
          {div ?
            <ReactToPrint
              trigger={() => <button className="pdfDownloadBTN mt10"><BiPrinter className="f20" /></button>}
              content={() => div}
            />
            : <PDFDownloader contract={data} userinfo={userinfo} />
          }
        </div>
        <div>
          <div className={top ? "horizontal-eastimate-bar t140 " : "horizontal-eastimate-bar t80 "}></div>
        </div>
      </div>
    </div>
  );
}

export default Toptabs;
