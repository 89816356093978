import React, { useState, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { uploadFilesingle } from '../project';

const CanvasWrapper = styled.div`
  position: relative;
`;

const CanvasPlaceholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
`;

const Signature = ({ userinfo, setNum }) => {
  const canvasRef = useRef(null);
  const [isSigned, setIsSigned] = useState(false);

  const save = async () => {
    if (canvasRef.current.isEmpty()) {
      alert('서명이 없습니다. 서명을 먼저 추가해주세요.');
      return;
    }
    Swal.fire({
      title: "날인을 등록하시겠습니까?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '네',

      cancelButtonText: `아니요`,
    }).then((result) => {
      if (result.isConfirmed) {
        uploadFilesingle(canvasRef.current.toDataURL('image/png'), userinfo, setNum, 'stamp')
      }
    })
  };


  return (
    <>
      <div className='flex j'>
        <button
          className='navibtn_hover '
          onClick={() => {
            canvasRef.current.clear(); // 리셋
            setIsSigned(false);
          }}
        >
          초기화
        </button>
        <button
          className='navibtn_hover '
          disabled={!isSigned}
          onClick={save}
        >
          저장
        </button>
      </div>
      <div className='flex c'>
        <div className='sigWrap'>
          <CanvasWrapper>
            {!isSigned && (
              <CanvasPlaceholder>
                여기에 서명을 해주세요.
              </CanvasPlaceholder>
            )}
            <SignatureCanvas
              canvasProps={{ width: 360, className: 'sigCanvas' }}
              ref={canvasRef}
              onBegin={() => {
                setIsSigned(true);
              }}
            />
          </CanvasWrapper>
        </div>

      </div>

    </>
  );
};

export default Signature;