import React, { useState } from "react";
import { Link } from "react-router-dom";
import { companytype } from "../../service/dataarray";
import { ModalAddress } from "../../service/components/Modals";
import { ImProfile } from "react-icons/im";

function Clientinfo1({ userinfo, handleChange, setUserinfo, check, setCheck }) {
  const [isOpen, setIsOpen] = useState(false);
  const openModalHandler = () => {
    setIsOpen(!isOpen);
  };
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let zonecode = data.zonecode;
    setUserinfo({
      ...userinfo,
      companyaddressf: fullAddress,
      companyaddNum: zonecode,
    });
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className="client_right">
        <div className="flex l" style={{ borderBottom: "2px solid rgba(16, 36, 148, 0.096" }}>
          <ImProfile className="f20 mr10 mt10" />
          <h1 className="clientInfo_title">User Info</h1>
        </div>
        <section className="infowrap wrap flex j">
          <div className="infobox w50 mt15">
            <h1 className="fontWb">1.이름</h1>
            <input
              onChange={(e) => handleChange("uName", e.target.value)}
              defaultValue={userinfo.uName}
              className="projectAdd_inputbox"
            />
          </div>
          <div className="infobox w50">
            <div className="flex a mb10 l">
              <h1 className="mr10 fontWb">2.비밀번호</h1>
              <Link
                to={"/Mypage/Myinfo/clientinfo/passchange"}
                className="navibtn_hover ps  f14"
                style={{
                  width: "120px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                비밀번호 변경
              </Link>
            </div>
            <input
              type="password"
              value={userinfo.uPassword}
              disabled
              className="projectAdd_inputbox"
            />
          </div>
        </section>
        <section>
          <div className="infowrap wrap flex j">
            <div className="infobox w50">
              <div className="flex a mb10">
                <h1 className="mr10 fontWb">3.휴대폰 번호</h1>
                <div className="subtext">전화번호를 입력하세요</div>
              </div>
              <input
                value={userinfo.uPhone}
                onChange={(e) => handleChange("uPhone", e.target.value)}
                placeholder="휴대폰번호를 입력해주세요"
                className="projectAdd_inputbox"
              />
            </div>
            <div className="infobox w50">
              <h1 className="fontWb">4.이메일</h1>
              <input
                defaultValue={userinfo.email}
                disabled
                className="projectAdd_inputbox"
              />
            </div>
          </div>
          <div className="infowrap wrap flex j">
            <div className="infobox w50">
              <div className="flex a mb10">
                <h1 className="mr10 fontWb">5.직책</h1>
                <div className="subtext">사내 맡으신 직책을 선택해주세요</div>
              </div>
              <input
                defaultValue={userinfo.position}
                onChange={(e) => handleChange("position", e.target.value)}
                placeholder="직책을 입력해주세요"
                className="projectAdd_inputbox"
              />
            </div>

            <div className="infobox w50">
              <div className="flex a mb10">
                <h1 className="mr10 fontWb">6.담당분야</h1>
                <div className="subtext">사내 맡으신 분야를 적어주세요</div>
              </div>
              <input
                defaultValue={userinfo.companypart}
                onChange={(e) => handleChange("companypart", e.target.value)}
                placeholder="S/W 개발"
                className="projectAdd_inputbox"
              />
            </div>
          </div>
        </section>
      </div>
      <div className="client_right">
        <div className="flex l mb10" style={{ borderBottom: "2px solid rgba(16, 36, 148, 0.096" }}>
          <ImProfile className="f20 mr10 mt10" />
          <h1 className="clientInfo_title">Company Info</h1>
        </div>
        <div className="flex j ">
          <div className="infobox w50">
            <h1 className="fontWb">1.회사명</h1>
            <input
              defaultValue={userinfo.company}
              onChange={(e) => handleChange("company", e.target.value)}
              className="projectAdd_inputbox"
            />
          </div>
          <div className="infobox w50">
            <h1 className="fontWb">2.대표자 명</h1>
            <input
              defaultValue={userinfo.representative}
              onChange={(e) => handleChange("representative", e.target.value)}
              placeholder="대표자 명을 입력해주세요"
              className="projectAdd_inputbox"
            />
          </div>
        </div>
        <div className="infowrap wrap  flex j a">
          <div className="infobox w50">
            <h1 className="fontWb mt15">3.회사 형태</h1>
            <select
              defaultValue={userinfo.companytype}
              className="projectAdd_inputbox mb10"
              onChange={(e) => handleChange("companytype", e.target.value)}
            >
              {companytype.map((value) => (
                <option key={value.value}>{value.label}</option>
              ))}
            </select>
          </div>
          <div className="infobox w50">
            <h1 className="fontWb">4.사업자 등록번호</h1>
            <input
              value={userinfo.companyregiNum}
              onChange={(e) => handleChange("companyregiNum", e.target.value)}
              placeholder="사업자 등록 번호를 입력해주세요"
              className="projectAdd_inputbox"
            />
          </div>
        </div>
        <div className="infowrap wrap  flex j a">
          <div className="infobox w50">
            <div className="flex a mb10">
              <h1 className="mr10 fontWb">5.업태</h1>
              <p className="subtext m0">
                사업자 등록증에 기재되어 있는 ‘업태’ 기입
              </p>
            </div>

            <input
              defaultValue={userinfo.sector1}
              onChange={(e) => handleChange("sector1", e.target.value)}
              placeholder="업태를 입력해주세요"
              className="projectAdd_inputbox"
            />
          </div>
          <div className="infobox w50">
            <div className="flex a mb10">
              <h1 className="mr10 fontWb">6.종목</h1>
              <p className="subtext m0">
                사업자 등록증에 기재되어 있는 ‘종목’ 기입
              </p>
            </div>

            <input
              defaultValue={userinfo.sector2}
              onChange={(e) => handleChange("sector2", e.target.value)}
              placeholder="종목을 입력해주세요"
              className="projectAdd_inputbox"
            />
          </div>
        </div>
        <div className="infowrap wrap  flex j a">
          <div className="infobox w50">
            <h1 className="fontWb">7.사무실 연락처</h1>
            <input
              value={userinfo.companyNum}
              onChange={(e) => handleChange("companyNum", e.target.value)}
              placeholder="사무실 연락처를 입력해주세요"
              className="projectAdd_inputbox"
            />
          </div>
          <div className="infobox w50">
            <div className="flex a">
              <h1 className="mb10 fontWb">8.세금계산서 이메일</h1>
              <label className="f12">
                <input
                  type="checkbox"
                  checked={userinfo.email === userinfo.taxmail}
                  onChange={(e) => {
                    setCheck(!check);
                    handleChange("taxmail", check ? userinfo.email : "");
                  }}
                />
                기본정보등록 메일과 동일
              </label>
            </div>
            <input
              value={userinfo.taxmail}
              onChange={(e) => handleChange("taxmail", e.target.value)}
              placeholder="세금계산서 발행할 이메일을 입력해주세요"
              className="projectAdd_inputbox"
            />
          </div>
        </div>
        <div className="infowrap wrap  flex j a">
          <div className="infobox w100">
            <div className="flex a mb10">
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 className="mr10 fontWb">9.사무실주소</h1>
                <button
                  className="navibtn_hover"
                  style={{
                    width: "120px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  주소검색
                </button>
              </div>
            </div>
            <div className="flex j end">
              <div className="flex  w100">
                <input
                  value={userinfo.companyaddressf}
                  readOnly
                  className="projectAdd_inputbox btn w50 mr10"
                />
                <input
                  defaultValue={userinfo.companyaddressm}
                  onChange={(e) =>
                    handleChange("companyaddressm", e.target.value)
                  }
                  placeholder="상세주소 까지 입력해주세요"
                  className="projectAdd_inputbox btn w50 ml20"
                />
              </div>
            </div>
          </div>
        </div>
        {isOpen ? (
          <ModalAddress
            handleComplete={handleComplete}
            openModalHandler={openModalHandler}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className="modal-container"
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Clientinfo1;
