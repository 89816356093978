import Maps from "./Map";
import React from "react";
import { FcHome, FcOvertime, FcPhone } from "react-icons/fc";
import { CiMail } from "react-icons/ci";

const Place = () => {
  const contactData = [
    { icon: <FcHome />, title: 'Adress', content: '서울시 노원구 화랑로 815, 제1실습관 5017호(공릉동)' },
    { icon: <FcOvertime />, title: 'Business hours', content: '09:00 ~ 18:00 (월~금)' },
    { icon: <CiMail />, title: 'E-mail', content: 'nzr@needsrobot.com' },
    { icon: <FcPhone />, title: 'Hunting Line', content: '070-8281-7976' },
  ];
  return (

    <div className="w1480 h100 flex center">
      <div className="roladMap-box">
        <div>
          <h1 className="roadtitle">찾아오시는 길</h1>
          <div className="title-separator"></div>
        </div>
        <Maps />
        {/* <div className="tablewrap"></div> */}
        <div className="grid-container">
          {contactData.map((item, index) => (
            <div className="grid-item tableT" key={index}>
              <h2 className="flex c">
                <div className="mr10 ">{item.icon}</div>
                {item.title}
              </h2>
              <p>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Place;
