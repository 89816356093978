import React from 'react'
function Privacypolicy() {

    return (

        <div className='pro_contain w1480 p2020'>
            <div className='Tmessage'>
                <h1>개인정보처리방침</h1>
            </div>
            <div className='title mb10'>(주)니즈로봇 개인정보처리방침</div>
            <div>
                <p className='startT'>(주)니즈로봇(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립․공개합니다.</p>
                <div className='textwrap'>
                    <p className='subtitle'>제1조(개인정보의 처리목적)</p>
                    <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
                    <p>1. 홈페이지 회원 가입 및 관리</p>
                    <p>   회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격 유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지․통지, 고충처리 등을 목적으로 개인정보를 처리합니다.</p>
                    {/* <p> 만 14세 미만 아동의 회원가입을 받지 않습니다.</p> */}
                    <p> 2. 재화 또는 서비스 제공</p>
                    <p>  물품배송, 서비스 제공, 계약서․청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제․정산, 채권추심 등을 목적으로 개인정보를 처리합니다.</p>
                    <p>3. 고충처리</p>
                    <p>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리결과 통보 등의 목적으로 개인정보를 처리합니다.</p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제2조(개인정보의 처리 및 보유기간)</p>
                    <p>① 회사는 법령에 따른 개인정보 보유․이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다. </p>
                    <p>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
                    <p>1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴시까지</p>
                    <p>다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지 </p>
                    <p className="pl10">1) 관계 법령 위반에 따른 수사․조사 등이 진행중인 경우에는 해당 수사․조사 종료시까지 </p>
                    <p className="pl10">2) 홈페이지 이용에 따른 채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지 </p>
                    <p> 2. 재화 또는 서비스 제공 : 재화․서비스 공급완료 및 요금결제․정산 완료시까지</p>
                    <p>다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지 </p>
                    <p className="pl10">1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시․광고, 계약내용 및 이행 등 거래에 관한 기록 </p>
                    <p className="pl20">- 표시․광고에 관한 기록 : 6월 </p>
                    <p className="pl20">- 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년  </p>
                    <p className="pl20">- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 </p>
                    <p className="pl10">2)「통신비밀보호법」제41조에 따른 통신사실확인자료 보관</p>
                    <p className="pl20">- 가입자 전기통신일시, 개시․종료시간, 상대방 가입자번호, 사용도수, 발신기지국 위치추적자료 : 1년 </p>
                    <p className="pl20">- 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월</p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제3조(개인정보의 제3자 제공)</p>
                    <p>① 회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다. </p>
                    <p>② 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</p>
                    <p className="pl20">- 개인정보를 제공받는 자 : (주) OOO 카드 </p>
                    <p className="pl20">- 제공받는 자의 개인정보 이용목적 : 이벤트 공동개최 등 업무제휴 및 제휴 신용카드 발급  </p>
                    <p className="pl20">- 제공하는 개인정보 항목 : 성명, 주소, 전화번호, 이메일주소, 카드결제계좌정보, 신용도정보 </p>
                    <p className="pl20">- 제공받는 자의 보유․이용기간 : 신용카드 발급계약에 따른 거래기간동안</p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제4조(개인정보처리의 위탁)</p>
                    <p>① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다. </p>
                    <p>1. 전화 상담센터 운영 </p>
                    <p className="pl20"> - 위탁받는 자 (수탁자) : OOO 컨택센터 </p>
                    <p className="pl20"> - 위탁하는 업무의 내용 : 전화상담 응대, 부서 및 직원 안내 등 </p>
                    <p>2. A/S 센터 운영 </p>
                    <p className="pl20"> - 위탁받는 자 (수탁자) : OOO 전자 </p>
                    <p className="pl20"> - 위탁하는 업무의 내용 : 고객 대상 제품 A/S 제공 </p>
                    <p>② 회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. </p>
                    <p>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제5조(정보주체의 권리․의무 및 행사방법)</p>
                    <p>① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. </p>
                    <p>1. 개인정보 열람요구</p>
                    <p>2. 오류 등이 있을 경우 정정 요구</p>
                    <p>3. 삭제요구</p>
                    <p>4. 처리정지 요구</p>
                    <p>② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다. </p>
                    <p>③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.  </p>
                    <p>④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. </p>
                    <p>⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다. </p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제5조(정보주체의 권리․의무 및 행사방법)</p>
                    <p>① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다. </p>
                    <p>1. 개인정보 열람요구</p>
                    <p>2. 오류 등이 있을 경우 정정 요구</p>
                    <p>3. 삭제요구</p>
                    <p>4. 처리정지 요구</p>
                    <p>② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다. </p>
                    <p>③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.  </p>
                    <p>④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. </p>
                    <p>⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다. </p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제6조(처리하는 개인정보 항목)</p>
                    <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
                    <p>1. 홈페이지 회원 가입 및 관리 </p>
                    <p className="pl20">․필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 성별, 이메일주소, 아이핀번호 </p>
                    <p className="pl20">․선택항목 : 결혼여부, 관심분야 </p>
                    <p>2. 재화 또는 서비스 제공  </p>
                    <p className="pl20">․필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호, 이메일주소, 아이핀번호, 신용카드번호, 은행계좌정보 등 결제정보  </p>
                    <p className="pl20">․선택항목 : 관심분야, 과거 구매내역   </p>
                    <p> 3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다. </p>
                    <p className="pl20">․IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등 </p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제7조(개인정보의 파기)</p>
                    <p>① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. </p>
                    <p>① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. </p>
                    <p>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다. </p>
                    <p> 1. 파기절차  </p>
                    <p className="pl10">회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다. </p>
                    <p> 1. 파기방법 </p>
                    <p className="pl10">회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.  </p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제8조(개인정보의 안전성 확보조치)</p>
                    <p>회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. </p>
                    <p>1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등 </p>
                    <p> 2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치  </p>
                    <p>3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제  </p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제9조 쿠키의 운영</p>
                    <p>사이트는 이용자에 대한 정보를 저장하고 수시로 찾아내는 쿠키(cookie)를 사용합니다. 쿠키는 웹사이트서버가 사용자의 컴퓨터 브라우저(인터넷 익스플로러, 사파리, 파이어폭스 등)에게 전송하는 소량의 문자열 정보입니다. 쿠키는 이용자의 컴퓨터는 식별하지만 이용자를 개인적으로 식별하지는 않습니다.</p>
                    <p>① 쿠키에 대한 선택권</p>
                    <p>이용자의 웹브라우저를 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있습니다. 다만 쿠키의 저장을 거부하는 경우에는디바이스마트에서 로그인이 필요한 일부 서비스는 이용하실 수 없습니다.</p>
                    <p>쿠키 설치 허용 여부를 지정하는 방법(인터넷 익스플로러의 경우)은 다음과 같습니다.</p>
                    <p className="pl10">1. [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</p>
                    <p className="pl10">2. [개인정보 탭]을 클릭합니다.</p>
                    <p className="pl10">3. [개인정보 설정]에서 "모든쿠키허용-낮음-보통-보통 높음-높음-모든쿠키차단"을 설정하시면 됩니다.</p>
                    <p>쿠키는 브라우저의 종료시나 로그아웃시 만료됩니다.</p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제10조(개인정보 보호책임자)</p>
                    <p>① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. </p>
                    <p className="pl10">▶ 개인정보 보호책임자 </p>
                    <p className="pl20">성명 : 강현정</p>
                    <p className="pl20">직책 : 연구원</p>
                    <p className="pl20">이메일 : nzr@needsrobot.com</p>
                    <p className="pl20">※ 개인정보 보호 담당부서로 연결됩니다. </p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'>제11조(권익침해 구제방법)</p>
                    <p>정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. </p>
                    <p>아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다</p>
                    <p className="pl10"> ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)</p>
                    <p className="pl20">- 소관업무 : 개인정보 침해사실 신고, 상담 신청 </p>
                    <p className="pl20">- 홈페이지 : privacy.kisa.or.kr </p>
                    <p className="pl20">- 전화 : (국번없이) 118 </p>
                    <p className="pl20">- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</p>
                    <p className="pl10"> ▶ 개인정보 분쟁조정위원회 (한국인터넷진흥원 운영) </p>
                    <p className="pl20">- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)</p>
                    <p className="pl20">- 홈페이지 : privacy.kisa.or.kr </p>
                    <p className="pl20">- 전화 : (국번없이) 118 </p>
                    <p className="pl20">- 주소 : (138-950) 서울시 송파구 중대로 135 한국인터넷진흥원 개인정보침해신고센터</p>
                    <p className="pl10"> ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr) </p>
                    <p className="pl10"> ▶ 경찰청 사이버테러대응센터 : 1566-0112 (www.netan.go.kr) </p>
                </div>
                <div className='textwrap'>
                    <p className='subtitle'> 제12조(개인정보 처리방침 변경) </p>
                    <p>① 이 개인정보 처리방침은 20XX. X. X부터 적용됩니다.</p>
                    <p>② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다. </p>
                    <p className="pl20">② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다. </p>
                    <p className="pl20">② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다. </p>
                </div>



            </div>
        </div>
    );
}

export default Privacypolicy;
