import React from "react";
import { cate, minicate } from "../../service/dataarray";
import Checkbox from "../../service/components/Checkbox";
import Radiolabel from "../../service/components/Radiolabel";
import { conditionA } from "../../service/dataarray";
import { AiFillEdit } from "react-icons/ai";
import { comma, removeComma } from "../../service/project";
import Addfile from "../../service/components/Addfile";

function Projectedit({
  admin,
  pg,
  my,
  handleChange,
  updates,
  selectedFiles,
  setSelectedFiles,
  setupPg,
}) {
  return (
    <>
      {pg ? (
        <div className={!my ? "add_right_wrap wide pgdetail" : "wide"}>
          <div className="right_top_project_infowrite_Title">
            <AiFillEdit style={{ marginRight: "10px" }} />
            프로젝트 정보 수정
          </div>
          <div className="right_body">
            <div className="right_top_s">기초 정보</div>
            <div className="editprojectInfo-container">
              <div className="right_body_wrap mb40">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">1. 프로젝트 명</h1>
                  <p className="subtext">
                    프로젝트가 게시 되기 전에는 반드시 사전 공지를 드리게 됩니다
                  </p>
                </div>
                <input
                  defaultValue={pg.title}
                  onChange={(e) => handleChange("title", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="ex) 스마트팜을 위한 AGV로봇 제조"
                />
              </div>
              <div className="flex mb40 wrap j triple">
                <div className="right_body_wrap w33">
                  <div className="flex mb10 minititle a">
                    <h1 className="mr10">2. 의뢰 담당자</h1>
                    <p className="subtext">담당자의 성함을 입력해주세요</p>
                  </div>
                  <input
                    defaultValue={pg.client}
                    onChange={(e) => handleChange("client", e.target.value)}
                    className="projectAdd_inputbox"
                    placeholder="ex) 홍길동"
                  />
                </div>
                <div className="right_body_wrap w33">
                  <div className="flex mb10 minititle a">
                    <h1 className="mr10">3. 연락처</h1>
                    <p className="subtext">전화번호를 입력하세요</p>
                  </div>
                  <input
                    value={pg.clientphone}
                    onChange={(e) =>
                      handleChange("clientphone", e.target.value)
                    }
                    className="projectAdd_inputbox"
                    placeholder="ex) 01012348565"
                  />
                </div>
                <div className="right_body_wrap w33">
                  <div className="flex mb10 minititle a">
                    <h1 className="mr10">4. 회사위치</h1>
                    <p className="subtext">
                      미팅 장소를 참고 할 수 있도록 기재해주세요
                    </p>
                  </div>
                  <input
                    defaultValue={pg.clientaddress}
                    onChange={(e) =>
                      handleChange("clientaddress", e.target.value)
                    }
                    className="projectAdd_inputbox"
                    placeholder="ex)서울시 중랑구"
                  />
                </div>
              </div>
              <div className="right_body_wrap mb40">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">5. 프로젝트 사업분야</h1>
                </div>
                <ul className="list list-group ">
                  {cate &&
                    cate.map((value, index) => (
                      <Checkbox
                        category={"cate"}
                        key={value.label}
                        radio={true}
                        className={
                          pg.cate && pg.cate.includes(value.label)
                            ? "active list-group-control"
                            : "list-group-control"
                        }
                        value={value}
                        check={pg.cate}
                        other={pg.cateother}
                        onClick={() => handleChange("cate", value.label)}
                        onChange={(e) =>
                          handleChange("cateother", e.target.value)
                        }
                      />
                    ))}
                </ul>
              </div>
              <div className="right_body_wrap mb80">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">6. 프로젝트 의뢰분야 (중복선택가능)</h1>
                </div>
                <ul className="list list-group">
                  {minicate &&
                    minicate.map((value) => (
                      <Checkbox
                        category={"minicate"}
                        key={value.value}
                        className={
                          pg.minicate && pg.minicate.includes(value.value)
                            ? "active list-group-control"
                            : "list-group-control"
                        }
                        value={value}
                        check={pg.minicate}
                        other={pg.minicateother}
                        onClick={(e) => handleChange("minicate", value.value)}
                        onChange={(e) =>
                          handleChange("minicateother", e.target.value)
                        }
                      />
                    ))}
                </ul>
              </div>
            </div>
            <div className="editprojectInfo-container">
              <div className="right_body_wrap mb80">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">7. 필요 기술</h1>
                  <p className="subtext">
                    해당 비즈니스에 더욱 도움이 될수 있는 기술을 입력해주세요
                  </p>
                </div>
                <input
                  defaultValue={pg.needskill}
                  onChange={(e) => handleChange("needskill", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="ex) AGV 설계 및 제작 기술"
                />
              </div>
              <div className="right_top_s">예산 및 일정</div>
              <div className="flex j mb40 wrap triple">
                <div className="right_body_wrap w33">
                  <div className="flex mb10 minititle a">
                    <h1 className="mr10">8. 예상 비용</h1>
                    <p className="subtext">
                      가능한 예산을 입력해 주세요
                    </p>
                  </div>
                  <input
                    value={pg.price}
                    onChange={(e) => handleChange("price", e.target.value)}
                    className="projectAdd_inputbox"
                    placeholder="￦"
                  />
                  <p className="subtext p5">
                    예상 결제 금액 : ￦
                    {comma(removeComma(pg.price) + removeComma(pg.price) * 0.1)}
                  </p>
                  <p className="subtext">부가세 10% 포함된 금액 입니다.</p>
                </div>
                <div className="right_body_wrap w33">
                  <div className="flex mb10 minititle a">
                    <h1 className="mr10">9. 시작 일자</h1>
                    <p className="subtext">
                      파트너가 프로젝트에 착수하는 날짜입니다
                    </p>
                  </div>
                  <input
                    type="date"
                    defaultValue={pg.startdate}
                    onChange={(e) => handleChange("startdate", e.target.value)}
                    className="projectAdd_inputbox"
                  />
                </div>
                <div className="right_body_wrap w33">
                  <div className="flex mb10 minititle a">
                    <h1 className="mr10">10. 예상 기간</h1>
                    <p className="subtext">예상 기간을 입력해주세요</p>
                  </div>
                  <input
                    type="number"
                    defaultValue={pg.term}
                    onChange={(e) => handleChange("term", e.target.value)}
                    className="projectAdd_inputbox"
                    placeholder="일"
                  />
                </div>
              </div>

              <div className="right_top_s">프로젝트 진행 정보</div>
              <div className="right_body_wrap mb40">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">11. 진행 상태</h1>
                </div>
                <div className="flex wrap">
                  <Radiolabel
                    onChange={(e) => handleChange("pStatus", "new")}
                    checked={pg.pStatus === "new"}
                    name={"sprojectStatus"}
                    value={"New Project(신규)"}
                  />
                  <Radiolabel
                    onChange={(e) => handleChange("pStatus", "better")}
                    checked={pg.pStatus === "better"}
                    name={"sprojectStatus"}
                    value={"Improve existing projects(개선)"}
                  />
                  <Radiolabel
                    onChange={(e) => handleChange("pStatus", "ing")}
                    checked={pg.pStatus === "ing"}
                    name={"sprojectStatus"}
                    value={"Maintenance of existing projects(유지보수)"}
                  />
                </div>
              </div>
              <div className="right_body_wrap mb40">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">12. 회의 방식</h1>
                  <p className="subtext">파트너와 미팅 방식을 조율합니다</p>
                </div>
                <div className="flex wrap">
                  <Radiolabel
                    onChange={(e) => handleChange("meet", "on")}
                    checked={pg.meet === "on"}
                    name={"meet"}
                    value={"온라인"}
                  />
                  <Radiolabel
                    onChange={(e) => handleChange("meet", "off")}
                    checked={pg.meet === "off"}
                    name={"meet"}
                    value={"오프라인"}
                  />
                  <Radiolabel
                    onChange={(e) => handleChange("meet", "onoff")}
                    checked={pg.meet === "onoff"}
                    name={"meet"}
                    value={"온오프라인"}
                  />
                </div>
              </div>
              <div className="right_body_wrap mb40">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">13. 파트너 조건</h1>
                  <p className="subtext">필요로 하는 내용을 적어주세요</p>
                </div>
                <ul className="list list-group">
                  {conditionA &&
                    conditionA.map((value) => (
                      <Checkbox
                        category={"conditions"}
                        key={value.value}
                        className={
                          pg.conditions && pg.conditions.includes(value.value)
                            ? "active list-group-control"
                            : "list-group-control"
                        }
                        value={value}
                        check={pg.conditions}
                        other={pg.conditionother}
                        onClick={(e) => handleChange("conditions", value.value)}
                        onChange={(e) =>
                          handleChange("conditionother", e.target.value)
                        }
                      />
                    ))}
                </ul>
              </div>
            </div>

            <div className="right_top_s">프로젝트 상태</div>
            <div className="editprojectInfo-container">
              <div className="right_body_wrap mb40">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">14. 프로젝트 개요</h1>
                  <p className="subtext">프로젝트의 개요를 간략하게 설명해주세요</p>
                </div>
                <textarea
                  rows="3"
                  defaultValue={pg.outline}
                  onChange={(e) => handleChange("outline", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="ex) 내용"
                />
              </div>
              <div className="right_body_wrap mb40">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">15. 최총 산출물</h1>
                  <p className="subtext">자세하게 기재 해주세요</p>
                </div>
                <textarea
                  rows="3"
                  defaultValue={pg.lastprojects}
                  onChange={(e) => handleChange("lastprojects", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="ex) 내용"
                />
              </div>

              <div className="right_body_wrap mb20_s">
                <div className="flex mb10 minititle a">
                  <h1 className="mr10">16. 프로젝트 상세 설명</h1>
                  <p className="subtext">프로젝트의 전반적인 내용과 세부 사항을 자세히 설명해주세요</p>
                </div>
                <textarea
                  rows="5"
                  defaultValue={pg.projects}
                  onChange={(e) => handleChange("projects", e.target.value)}
                  className="projectAdd_inputbox"
                  placeholder="ex) 내용"
                />
              </div>
              <div className="flex mb10 minititle a mt10">
                <h1 className="mr10 mb10 fontWb">17. 프로젝트 관련 파일첨부</h1>
                <p className="subtext mb10">파일을 첨부해주세요</p>
              </div>
              <span className="mb10">
                <Addfile
                  setFunc={setSelectedFiles}
                  admin={admin}
                  my={my}
                  files={pg.files}
                  array={selectedFiles}
                />
              </span>
            </div>

            <div className="flex c">
              {my ? (
                <button
                  className="navibtn_hover ps ml10 c"
                  onClick={() => updates()}
                >
                  저장
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Projectedit;
