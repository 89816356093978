
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { checkpass, updatepass } from '../../service/project';
import { useState } from 'react';
import { regPass } from '../../service/validation';
import Swal from 'sweetalert2';
function Changepass({ userinfo, pass, setPass }) {
  const [newpass, setnewpass] = useState('')
  const [newpasss, setnewpasss] = useState('')
  const navigate = useNavigate()
  const [change, setChange] = useState(false)

  const onClick = () => {
    checkpass(userinfo.userId, pass).then(function (response) {
      if (response.data) {
        setChange(true)
        setPass('')
      } else {
        Swal.fire(
          '비밀번호를 확인 해주세요',
          '확인 버튼을 눌러 주세요',
          'warning'
        )
      }
    })
  }
  const uponClick = () => {
    if (userinfo.uPassword == newpass) {
      Swal.fire(
        '기존 비밀번호와 같습니다',
        '확인 버튼을 눌러 주세요',
        'warning'
      )
    } else if (newpass === '') {
      Swal.fire(
        '비밀번호를 입력해주세요',
        '확인 버튼을 눌러 주세요',
        'warning'
      )
    } else if (newpass && newpass.length <= 6) {
      Swal.fire(
        '비밀번호 길이를 확인해주세요',
        '확인 버튼을 눌러 주세요',
        'warning'
      )
    } else {
      updatepass(userinfo.userId, newpasss).then(function (response) {
        if (response.data) {
          navigate('/Mypage/Myinfo/Account')
          Swal.fire(
            '비밀번호가 변경되었습니다',
            '확인 버튼을 눌러 주세요',
            'success'
          )
          window.location.reload();
          setChange(false)
        } else {
          Swal.fire(
            '비밀번호를 확인 해주세요',
            '확인 버튼을 눌러 주세요',
            'warning'
          )
        }
      })
    }

  }

  return (

    <div className='mypgbody '>
      <div className='mypghead'>
        <h1 className='mb20'>{!change ? '비밀번호 확인' : '비밀번호 변경'}</h1>
        <p className='mb20'>소중한 개인정보를 안전하게 보호하기 위해 다시 한번 인증해 주세요.</p>
      </div>
      {!change ?

        <div>
          <input
            name='pass'
            type="password"
            placeholder='기존 비밀번호를 입력해주세요'
            onChange={e => setPass(e.target.value)}
            className='projectAdd_inputbox'>
          </input>
          {pass ?
            <p className={!regPass.test(pass) ? 'passerror' : 'pass_success'}>	영문/숫자/특수문자 조합(6~20자) {regPass.test(pass) ? <span>○</span> : ''}</p>
            : ""
          }

          <button onClick={() => onClick()} className='projectbtns mt40'>인증</button>
        </div>
        :
        <div>

          <input
            className='projectAdd_inputbox mb20'
            value={newpass}
            type="password" placeholder="변경할 비밀번호" onChange={e => setnewpass(e.target.value)} />
          {pass ?
            <p className={!regPass.test(newpass) ? 'passerror' : 'pass_success'}>	영문/숫자/특수문자 조합(6~20자) {regPass.test(newpass) ? <span>○</span> : ''}</p>
            : ""
          }
          <input
            className='projectAdd_inputbox'
            value={newpasss}
            name='new-password' type="password" placeholder="변경할 비밀번호확인" onChange={e => setnewpasss(e.target.value)} />
          {newpass === newpasss && newpasss >= 6 ?
            <p className='pass_success'>비밀번호가 일치합니다</p>
            : newpass !== newpasss && newpasss.length >= 2 ? <p className='passerror'>비밀번호가 일치하지 않습니다</p>
              : ''
          }
          <button onClick={() => uponClick()} className='projectbtns mt40'>변경</button>
        </div>
      }

    </div>
  )
}

export default Changepass;