
import React from 'react';
import { projectTabs } from '../../service/tabarray';
import Swal from 'sweetalert2';
import { FaFileCircleCheck, FaFileCirclePlus, FaFilePen, FaMarker } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { GiTeamUpgrade } from "react-icons/gi";
function Projectuserbox({ edit, pg, allpg, updates, setEdit, userId, admin, upgrade, downgrade, openModalHandlers, dedit, setDedit }) {
  const tabs = projectTabs.filter(values => ["견적진행", "계약진행", "프로젝트 진행"].includes(values.biglabel))

  const onClickEdit = (value) => {
    if (dedit) {
      Swal.fire({
        title: '단계정보 작성중입니다',
        icon: 'warning'
      })
    } else if (value === true) {
      Swal.fire({
        title: "프로젝트를 수정하시겠습니까?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `아니요`,
        confirmButtonText: "네",
      }).then((result) => {
        if (result.isConfirmed) {
          setEdit(value)
        }
      });

    } else {
      setEdit(value)
    }
  }

  const onClickDedit = () => {
    if (edit) {
      Swal.fire({
        title: '프로젝트정보 수정중입니다',
        icon: 'warning'
      })
    } else {
      setDedit(!dedit)
    }
  }
  return (
    <>
      <div className='p20tb'>
        <h1 className='mb0'>클라이언트 정보</h1>
      </div>
      <div className=' flex  userinfoBox'>
        <div className='flex j a borderBottom borderTop p20tb'>
          <h2 className='mr10'><span className='mr5'>ID :</span>{pg.userId !== 'null' ? pg.userId : <span className='f12'>미등록 아이디</span>}</h2>
          <button onClick={() => openModalHandlers()} className='navibtn_hover p10 f12 w60px'>더보기</button>
        </div>
        <ul className='p20tb'>

          <li className='flex a j mb5'>
            <span className='flex a'><span className='midcircle  mr5'><FaFilePen /></span> 등록 프로젝트 </span><span>
              {allpg.filter(value => value.userId === pg.userId).filter(item => item.a2 !== null && item.c2 === null).length}개
            </span>
          </li>
          <li className='flex j mb5'><span className='flex a'><span className='midcircle mr5'><FaFileCircleCheck /></span>진행 프로젝트 </span><span>
            {allpg.filter(value => value.userId === pg.userId).filter(item => item.c2 !== null && item.e1 === null).length}개
          </span></li>
          <li className='flex j mb5'><span className='flex a'><span className='midcircle mr5'><FaFileCirclePlus /></span> 완료 프로젝트 </span><span>
            {allpg.filter(value => value.userId === pg.userId).filter(item => item.e1 !== null).length}개
          </span></li>


        </ul>
        <ul className='p20tb borderTop borderBottom '>
          {userId === pg.userId || admin ?
            <li className='mb5'>

              {admin ?
                <div className='flex  j a'><span className='flex a'><span className='midcircle mr5'><GiTeamUpgrade /></span> 단계변동 </span>
                  <div>
                    <button onClick={() => { upgrade() }} className='navibtn_hover  p10 w60px mr5 f12 '>↑</button>
                    <button onClick={() => { downgrade() }} className='navibtn_hover p10 w60px f12'>↓</button>
                  </div>
                </div>

                : ''
              }


            </li>
            : ''
          }
          <li className='flex  a j mb5' >
            <span className='flex a'><span className='midcircle mr5'><FaMarker /></span> {pg.adminstatus} </span>
            {!dedit ?
              <button onClick={() => onClickDedit()} className='navibtn_hover p10 w60px f12'>작성</button>
              :
              <button onClick={() => onClickDedit()} className='navibtn_hover p10 w60px f12'>이전</button>
            }
          </li>
          <li className='flex  a j'><span className='flex a'><span className='midcircle mr5'><FaEdit /></span> 프로젝트정보 </span>

            {!edit ?
              <button onClick={() => onClickEdit(true)} className='navibtn_hover p10 w60px f12'>수정</button>
              :
              <div className='flex'>
                <button onClick={() => onClickEdit(false)} className='navibtn_hover p10 w60px mr10 f12'>이전</button>
                <button onClick={() => updates()} className='navibtn_hover p10 w60px f12'>저장</button>
              </div>
            }
          </li>
        </ul>
      </div>
    </>
  );
}

export default Projectuserbox;