import React from "react";
import Pdfcontain from "../../service/components/Pdfcontain";

function Out_specification({ imageSrcs, detail, nodata }) {
  return (
    <div className="add_right_wrap outwrap mt0">
      <Pdfcontain
        list={detail.specification}
        single={true}
        type={"specification"}
        file={imageSrcs.base64}
      />

      {detail.uid === "exampledata" ? (
        <div className="text_absolute">사양서 예시입니다</div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Out_specification;
