
import React from 'react';
import Basic_contract from './Basic_contract';
import ContractSlides from './ContractSlides';
import { PC, Tablet } from "../../MediaQuery"

function Out_Basic_contract({ userinfo, mini }) {

  return (
    <div className='out_detail'>
      <PC>
        <Basic_contract userinfo={userinfo} mini={mini} />
      </PC>
      <Tablet>
        <ContractSlides userinfo={userinfo} mini={mini} />
      </Tablet>

    </div>
  );
}

export default Out_Basic_contract;