import { FullPage, Slide } from 'react-full-page';
import React from 'react';
import { motion } from "framer-motion"
import { TechBoxes } from '../service/components/Techbox';
import MetaTag from '../MetaTag';
import { metaInfoForMainPage } from '../service/mataTags';

function Main() {

  return (

    <div className='mainpg'>
      <MetaTag {...metaInfoForMainPage} />
      <FullPage className="slide-navigation" controls>
        <Slide>
          <div className='mainback'>
            <img src={'https://ifh.cc/g/b5GPmV.jpg'} alt='외주개발 니즈로봇 메인 페이지' />
            <div className='mainb backback'></div>
            <motion.div
              className=' w1480 h100'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}

              transition={{ ease: "easeOut", duration: 1 }}
            >
              <div className='mainbackT mainbackmiddle flex center'>
                <p className='big mb20'>당신이 생각하는 것을 </p>
                <p className='big'>가장 빠르게 현실로 만들어 드립니다</p>
                <div className='mt30'>
                  <h5>We will turn your idea into a product as quickly as possible.</h5>
                  <h5> Imagine your customers loving your product.</h5>
                </div>
                <a href='/Addprojects/add' className='navibtn_hover bigbtn mt45'>무료 견적 요청하기</a>

              </div>
            </motion.div>

          </div>
        </Slide>
        <Slide>
          <div className='mainback'>
            <img src={'https://ifh.cc/g/rRdOQ8.jpg'} alt='외주개발 니즈로봇 메인 페이지' />
            <div className='mainb backback'></div>
            <div className='w1480 h100'>
              <motion.div
                className='mainbackT  subT'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1, delay: 0 }}
              >

                <p className='middle'><span className='w'>WE MAKE</span><span>WE OFFER</span><span>WE PROCEED</span></p>
              </motion.div>
              <motion.div
                className='mainbackT  subTbottom'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1, delay: 0.3 }}
              >
                <p className='big'> Your <span>Thinking</span></p>
              </motion.div>
              <motion.div
                className='mainbackT cr'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1, delay: 0.5 }}
              >

                <p className='normal'>니즈로봇은 당신의 생각을 존중합니다.</p>
              </motion.div>
              <motion.div
                className='mainbackT br'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1, delay: 0.7 }}
              >
                <div style={{ textAlign: 'right' }}>
                  <p className='small' >운명은 없다,  오직 결정만이 있을 뿐이다.</p>
                </div>
              </motion.div>
            </div>
          </div>
        </Slide>
        <Slide>
          <div className='mainback'>
            <div className='mainpback'></div>

            <img className='boardimg' src={'https://ifh.cc/g/O8g5oA.jpg'} alt='외주개발 니즈로봇 메인 페이지 보드이미지' />
            <div className='w1480 h100'>
              <motion.div
                className='mainbackT subT'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1, delay: 0 }}
              >

                <p className='middle'><span>WE MAKE</span><span className='w'>WE OFFER</span><span>WE PROCEED</span></p>
              </motion.div>
              <motion.div
                className='mainbackT subTbottom'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1, delay: 0.3 }}
              >
                <p> Outsourcing <span>Development</span></p>
              </motion.div>
              <TechBoxes />
            </div>
          </div>
        </Slide>
        <Slide>
          <div className='mainback'>
            <img src={'https://ifh.cc/g/682rlP.jpg'} alt='외주개발 니즈로봇 메인 페이지' />
            <div className='mainbb backback'></div>
            <div className='w1480 h100'>
              <motion.div
                className='mainbackT subT'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1.5, delay: 0 }}
              >

                <p className='middle'><span>WE MAKE</span><span  >WE OFFER</span><span className='w'>WE PROCEED</span></p>
              </motion.div>
              <motion.div
                className='mainbackT subTbottom'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1, delay: 0.7 }}
              >
                <p> Reliably &  <span>Systematically</span></p>
              </motion.div>
              <motion.div
                className='imgmain'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                transition={{ ease: "easeOut", duration: 1, delay: 1 }}
              >

                <img src={'https://ifh.cc/g/6pq6AD.png'} alt='외주개발 니즈로봇 메인 페이지 iot 이미지' />
              </motion.div>
            </div>
          </div>
        </Slide>

      </FullPage>

    </div>
  );
}

export default Main;
