import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FaListUl } from "react-icons/fa";
import Subhead from "../Subhead";
import Out_contract from "./Out_contract";
import Out_price from "./Out_price";
import Out_estimate from "./Out_estimate";
import Out_specification from "./Out_specification";
import Offerbtnbox from "../../service/components/Offerbtnbox";
import { exuserinfos, imageArray } from "../../service/dataarray";
import { Modaloffer } from "../../service/components/Modals";
import {
  addCommasToNumber,
  alertreturn,
  exmyproject,
  getContract,
  getTime,
  loadImage,
  myinfos,
  offerMessage,
  offerUid,
  offerok,
  optionAlert,
  uidproject,
} from "../../service/project";
import { projectTabs } from "../../service/tabarray";
import MetaTag from "../../MetaTag";
import { metaContract, metaPanyment, metaSpecification, metaeEtimate } from "../../service/mataTags";

function Outsourcing({ userId }) {
  const divref = useRef();
  const navigate = useNavigate();
  const [isHeaderFixeds, setIsHeaderFixeds] = useState(false);
  const [outlist, setOutlist] = useState("");
  const location = useLocation();
  const expath = "exampledata";
  const [exdata, setExdata] = useState("");
  const path = location.pathname.split("/")[2];
  const tabs = projectTabs.filter((values) =>
    ["견적진행", "계약진행", "프로젝트 진행", "프로젝트 완료"].includes(values.biglabel)
  );

  const [projectPath, setProjectPath] = useState("");
  const [previousProjectPath, setPreviousProjectPath] = useState(null);
  const [detail, setDetail] = useState("");
  const [imageSrcs, setImageSrcs] = useState([]);
  const [isOpens, setIsOpens] = useState(false);
  const [detailProject, setDetailProject] = useState("");
  const [newestimate, setNewestimate] = useState({
    price: "",
    term: "",
    content: "",
  });
  const [num, setNum] = useState(1);
  const [userinfo, setUserInfo] = useState("");
  const [offerlist, setOfferlist] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [stampid, setStampid] = useState("");

  const openModalHandler = () => setIsOpen(!isOpen);
  const openModalHandlers = () => setIsOpens(!isOpens);

  const handle = (value) => {
    setProjectPath(value);
    navigate("/Outsourcing/estimate");
  };

  useEffect(() => {
    if (!userId) {
      Swal.fire({
        title: "로그인 해주세요",
        icon: "warning",
      });
    }
    exmyproject(userId)
      .then((response) => {
        const saveListWithConvertedMinicate = response.data
          .map((item) => {
            if (item.minicate) {
              item.minicate = JSON.parse(item.minicate);
              item.conditions = JSON.parse(item.conditions);
            }
            return item;
          })
          .filter((value) => tabs.some((tab) => tab.label === value.adminstatus));

        setOutlist(saveListWithConvertedMinicate);
        setProjectPath(saveListWithConvertedMinicate && saveListWithConvertedMinicate[0]?.uid);
        setPreviousProjectPath(saveListWithConvertedMinicate[0]?.uid);
      })
      .catch((error) => {
        console.error("오류 발생:", error);
      });

    myinfos(userId).then((response) => {
      if (response.data) {
        if (response.data[0] && !response.data[0].company) {
          optionAlert(
            "success",
            "회사 정보를 입력해주세요",
            "",
            "회사 정보 입력으로 이동하기",
            "/Mypage/Myinfo/clientinfo"
          );
        } else {
          setUserInfo(response.data[0]);
        }
      } else {
        Swal.fire("조회에 실패했습니다", "확인 버튼을 눌러 주세요", "error");
      }
    });

    const handleScrollAndResize = () => {
      const newIsHeaderFixed = window.scrollY >= 330;
      setIsHeaderFixeds(newIsHeaderFixed);

      const parentElement = document.querySelector(".addproject");
      const fixedElement = document.querySelector(".add_left_projectlistbox.fixed");
      const fixedElements = document.querySelector(".add_left_projectlistbox");

      if (parentElement && fixedElement) {
        const parentRect = parentElement.getBoundingClientRect();
        fixedElement.style.left = parentRect.left + "px";
      } else {
        fixedElements.style.left = 0 + "px";
      }
    };

    window.addEventListener("scroll", handleScrollAndResize);
    window.addEventListener("resize", handleScrollAndResize);

    handleScrollAndResize(); // 초기에도 위치를 설정해야 함

    return () => {
      window.removeEventListener("scroll", handleScrollAndResize);
      window.removeEventListener("resize", handleScrollAndResize);
    };
  }, []);

  useEffect(() => {
    getContract(expath).then((response) => response.data && setExdata(response.data[0]));

    if (projectPath) {
      getContract(projectPath).then((response) => {
        const contractData = response.data[0];
        if (contractData) {
          if (contractData.estimate && path === "estimate") {
            loadImage(contractData, setImageSrcs, path);
            setDetail(contractData);
          } else if (contractData.specification && path === "specification") {
            loadImage(contractData, setImageSrcs, path);
            setDetail(contractData);
          } else if (!contractData.specification && path === "specification") {
            alertreturn("notready", "사양서");
            getContract(expath).then((response) => response.data && loadImage(response.data[0], setImageSrcs, path));
            setDetail(exdata);
          } else if (path === "contract") {
            if (contractData.writedone) {
              setDetail(contractData);
              setNum(contractData.c2f ? 4 : 1);
            } else {
              alertreturn("notready", "계약서");
              setDetail(exdata);
              offerUid(expath).then((response) => response.data && setOfferlist(response.data));
            }
          } else if ((path === "contract" && !contractData.writedone) || (path === "payment" && !contractData.writedone)) {
            alertreturn("notready", "계약서");
            setDetail(exdata);
            offerUid(expath).then((response) => response.data && setOfferlist(response.data));
          } else if ((path === "estimate" && !contractData.estimate) || (path === "specification" && !contractData.specification)) {
            alertreturn("notready", "계약서");
            setDetail(exdata);
            offerUid(expath).then((response) => response.data && setOfferlist(response.data));
          } else {
            setDetail(contractData);
          }
        } else {
          alertreturn("notready", "견적서");
          setDetail(exdata);
          offerUid(expath).then((response) => response.data && setOfferlist(response.data));
        }
      });

      uidproject(projectPath)
        .then((response) => {
          const saveListWithConvertedMinicate = response.data.map((item) => {
            if (item.minicate) {
              item.minicate = JSON.parse(item.minicate);
              item.conditions = JSON.parse(item.conditions);
            }
            return item;
          });
          setDetailProject(saveListWithConvertedMinicate[0]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      offerUid(projectPath).then((response) => response.data && setOfferlist(response.data));
    }
  }, [path, projectPath]);

  const handleChange = (category, itemName) => {
    if (category === "price") {
      const numericValue = parseFloat(itemName.replace(/,/g, ""));
      const updatedValue = isNaN(numericValue) ? "" : addCommasToNumber(numericValue);

      setNewestimate((prevState) => ({
        ...prevState,
        [category]: updatedValue,
      }));
    } else {
      setNewestimate((prevState) => ({
        ...prevState,
        [category]: itemName,
      }));
    }
  };

  const onClick = () => {
    let message = "";
    if (offerlist.length === 1) {
      message = "요청사항이 전송되었습니다";
    } else if (offerlist.length === 2) {
      message = "요청사항이 수정되었습니다";
    } else {
      message = "견적요청이 불가합니다";
    }

    if (offerlist.length === 1 || offerlist.length === 2) {
      offerMessage(
        detail.uid,
        newestimate.price,
        newestimate.term,
        newestimate.content,
        userId,
        2,
        userId,
        path
      )
        .then((response) => {
          if (response.data) {
            Swal.fire({
              title: message,
              icon: "success",
            });
          }
        })
        .catch((error) => {
          console.error("Error occurred:", error);
        });
    } else {
      Swal.fire({
        title: message,
        icon: "error",
      });
    }
  };

  const offerOks = () => {
    if (offerlist.length === 1 || offerlist.length === 3) {
      Swal.fire({
        title: "승인 하시겠습니까?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `아니요`,
        confirmButtonText: "네",
      }).then((result) => {
        const lengthPoint = offerlist.length
        if (result.isConfirmed) {
          offerok(detail.uid, getTime(), path, detailProject.adminstatus, lengthPoint, userId).then((response) => {
            if (response.data) {
              const updatedOulist = outlist.map((item) => {
                if (item.uid === detail.uid) {
                  return { ...item, adminstatus: "견적 승인" };
                }
                return item;
              });
              setOutlist(updatedOulist);
              setDetail((prevData) => ({
                ...prevData,
                b3f: getTime(),
              }));
              Swal.fire({
                title: "견적이 승인되었습니다",
                icon: "success",
              });
            }
          });
        }
      });
    } else {
      Swal.fire({
        title: '견적 중입니다',
        icon: "error",
      });
    }
  };

  const getOfferBtnBox = () => {
    return (
      outlist &&
      projectPath &&
      path === "estimate" &&
      detail &&
      !detail.b3f && (
        <Offerbtnbox
          userinfo={detail.uid === "exampledata" ? exuserinfos : userinfo}
          contract={detail}
          offerOks={offerOks}
          divref={divref}
          setIsOpen={setIsOpen}
          num={num}
          setIsOpens={setIsOpens}
          setNum={setNum}
          type={path}
        />
      )
    );
  };
  const pathChange = path === "specification" ? metaSpecification : path === "contract" ? metaContract : path === "estimate" ? metaeEtimate : metaPanyment

  return (
    <div className='heightAuto'>
      <MetaTag {...pathChange} />

      <div className="backback greyb"></div>

      <div className="project">
        <Subhead
          value={"OUTSOURCING"}
          subvalue={"프로젝트 계약에 필요한 정보들입니다"}
          img={
            imageArray.subtitle_portfolio
          }
          path={"/Outsourcing/document"}
        />
        <div
          className={
            isHeaderFixeds
              ? "w1480 h100 flex addproject r"
              : "w1480 h100 flex addproject j"
          }
        >
          <div className={isHeaderFixeds ? "add_left_projectlistbox fixed" : "add_left_projectlistbox"}>
            <h1 className="textLeft"><span className="f20 mr10"><FaListUl /></span> 프로젝트 목록</h1>
            <div className="projectnavss scrollss">
              {outlist &&
                outlist.map((values, index) => (
                  <div
                    onClick={() => handle(values.uid)}
                    className={
                      values.uid === projectPath
                        ? "projectboxing active"
                        : "projectboxing"
                    }
                  >
                    <div className="mt-10">
                      {index + 1}. {values.title}
                    </div>{" "}
                    <span className="f12 flex a mt-10">
                      <span className="lightbulb-icon"></span>{" "}
                      <span>{values.adminstatus}</span>
                    </span>
                  </div>
                ))}
            </div>
            {getOfferBtnBox()}
          </div>
          {path === "estimate" ? (
            <Out_estimate
              detail={detail}
              detailProject={detailProject}
              offerlist={offerlist}
              userinfo={detail.uid === "exampledata" ? exuserinfos : userinfo}
              imageSrcs={imageSrcs}
            />
          ) : path === "specification" ? (
            <Out_specification detail={detail} imageSrcs={imageSrcs} />
          ) : path === "contract" ? (
            <Out_contract
              userId={userId}
              detailProject={detailProject}
              divref={divref}
              stampid={stampid}
              setStampid={setStampid}
              isOpen={isOpen}
              openModalHandler={openModalHandler}
              detail={detail}
              setDetail={setDetail}
              projectPath={projectPath}
              num={num}
              setNum={setNum}
              userinfo={detail.uid === "exampledata" ? exuserinfos : userinfo}
              path={path}
              offerlist={offerlist}
            />
          ) : path === "payment" ? (
            <Out_price detail={detail} />
          ) : (
            ""
          )}
        </div>
        {isOpens && (
          <Modaloffer
            offerlist={offerlist}
            userId={userId}
            newestimate={newestimate}
            handleChange={handleChange}
            detail={detail}
            detailProject={detailProject}
            openModalHandlers={openModalHandlers}
            isOpens={isOpens}
            type={path}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
}

export default Outsourcing;