import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { allurl } from '../project';
import { MdOutlineLibraryAdd } from "react-icons/md";
function Addfile({ setFunc, files, admin, my }) {
  const [selectedImages, setSelectedImages] = useState([]);


  const attachFile = selectedImages.map((image, index) => (
    <div className='flex a ' key={image.fileName}>
      <div className='file-container'>
        <div className='flex a'>
          <span className='circless'>{index + 1} </span> {/* 특수 문자로 표시 */}
          <span style={{ marginLeft: '2ch' }}>{image}</span>
        </div>
      </div>
      <div className='delete-button-container'>
        <button className='delete-button' onClick={() => removeSelectedImage(image)}>X</button>
      </div>
    </div>
  ));


  const inputStyle = {
    display: 'none',
  };

  const convertUrlsToBlobAndSet = (fileUrls) => {
    for (let i = 0; i < fileUrls.length; i++) {
      const fileUrl = fileUrls[i];
      const fileName = fileUrl.split('/').pop();

      // Blob 생성
      axios({
        url: `${allurl}/api/download/files/${fileName}`,
        method: 'GET',
        responseType: 'blob', // 파일 다운로드를 위해 blob 형식으로 응답 받음
      })
        .then((response) => {
          const blob = response.data; // response.data로 Blob 데이터를 가져옵니다.
          const reader = new FileReader();
          reader.onload = (event) => {
            const base64String = event.target.result;
            setFunc((previousFiles) => [
              ...previousFiles,
              { blob: base64String, fileName },
            ]);
            setSelectedImages((previousFiles) => [
              ...previousFiles,
              fileName,
            ])
          };


          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          console.error('파일을 Blob으로 변환하는 동안 오류 발생:', error);
        });
    }
  };

  useEffect(() => {
    if (admin && files || my && files) {
      const fileUrls = files && files.split(',');
      // 선택한 파일 URL을 Blob으로 변환하고 처리
      convertUrlsToBlobAndSet(fileUrls);

    }
  }, []);
  const onSelectFile = (e) => {
    e.preventDefault();
    const selectedFiles = e.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileName = file.name;

      // Blob 생성
      const blob = new Blob([file], { type: file.type });

      // Blob을 Base64 문자열로 변환
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target.result;
        setFunc((previousFiles) => [...previousFiles, { blob: base64String, fileName }]);
      };
      reader.readAsDataURL(blob);
    }

    // 이미지 이름만 저장
    const imageArray = Array.from(selectedFiles).map((file) => file.name);
    setSelectedImages((previousImages) => [...previousImages, ...imageArray]);

    e.target.value = ''; // 파일 입력 필드 초기화
  };

  const removeSelectedImage = (valueToRemove) => {
    setSelectedImages((previousImages) =>
      previousImages.filter((e) => e !== valueToRemove)
    );
    if (!admin && !my) {
      setFunc((previousArray) =>
        previousArray.filter((item) => item.fileName !== valueToRemove)
      );
    } else {
      setFunc((previousArray) =>
        previousArray.filter((item) => item.fileName !== valueToRemove)
      );
    }
  };

  return (
    <div style={{ flexDirection: 'row' }}>
      <label htmlFor='fileInput' className='filebtn_hover'>
        <MdOutlineLibraryAdd style={{ marginRight: '5px', fontSize: '16px' }} />
        파일추가
        <input id='fileInput' type='file' onChange={onSelectFile} multiple style={inputStyle} />
      </label>
      <div className='right_body_wrap_column flex'>
        <div className='posionA'>
          {selectedImages.length !== 0 ? (
            <div className='mt20 w100'>{attachFile}</div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );

}

export default Addfile;

