import React from 'react';
import Allstampbox from './Allstampbox';

function Stamp0({ setNum, userId }) {

  return (
    <div className='client_right'>
      <Allstampbox original={true} setNum={setNum} userId={userId} />
    </div>
  );
}

export default Stamp0;