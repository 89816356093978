import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  updateProject,
  uploadFilesmultie,
  allurl,
  deleteProject,
  addCommasToNumber,
  getIcon,
  minusday,
  offerUid,
  getContract,
  getprojecthistory,
  myinfos,
} from "../../service/project";
import { FaRegFolderOpen } from "react-icons/fa";
import ProjectTabs from "../../service/components/ProjectTabs";
import Projectsbox from "../../service/components/Projectsbox";
import Projectpgs from "../Project/Projectpgs";
import Projectedit from "../Project/Projedtedit";
import Swal from "sweetalert2";
import { projectTabs } from "../../service/tabarray";
import { RiEdit2Fill } from "react-icons/ri";

function MyProject({ project, setProject, userId }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const path = searchParams.get("uid");
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [edit, setEdit] = useState(false);
  const [bProject, setbProject] = useState([]);
  const [num, setNum] = useState(["전체", 0]);
  const [pg, setPgs] = useState();
  const [uppg, setupPgs] = useState();
  const [contract, setContract] = useState([]);
  const [bigL, setBigl] = useState("");
  const bigLabels = {};
  const [offerlist, setOfferlist] = useState("");
  const [exdata, setExdata] = useState("");
  const [projecthistory, setprojecthistory] = useState("");
  const [userinfo, setUserinfo] = useState("");

  projectTabs.forEach((tab) => {
    const { biglabel, value, path, label, paths } = tab;

    if (!bigLabels[biglabel]) {
      bigLabels[biglabel] = [];
    }

    bigLabels[biglabel].push({ value, path, label, paths });
  });
  const handleChanges = (value) => {
    setNum(value);
    const biglabel = bigLabels[value[0]];
    if (biglabel) {
      const filteredProjects = project.filter((proj) => {
        return biglabel.some((labelObj) => labelObj.label === proj.adminstatus);
      });

      setbProject(filteredProjects);
    } else {
      setbProject([]);
    }
  };
  const handleChange = (category, itemName) => {
    if (category === "minicate" || category === "conditions") {
      const updatedMinicate = uppg[category].includes(itemName)
        ? uppg[category].filter((item) => item !== itemName)
        : [...uppg[category], itemName];

      setupPgs((prevData) => ({
        ...prevData,
        [category]: updatedMinicate,
      }));
    } else if (category === "price") {
      const numericValue = parseFloat(itemName.replace(/,/g, ""));

      // 입력값이 숫자일 경우에만 설정
      if (!isNaN(numericValue)) {
        setupPgs((prevState) => ({
          ...prevState,
          [category]: addCommasToNumber(numericValue),
        }));
      } else {
        setupPgs((prevState) => ({
          ...prevState,
          [category]: "",
        }));
      }
    } else if (category === "clientphone") {
      const numericValue = itemName.replace(/-/g, "");
      if (numericValue.length === 10) {
        setupPgs((prevState) => ({
          ...prevState,
          [category]: numericValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3"),
        }));
      } else if (numericValue.length === 11) {
        setupPgs((prevState) => ({
          ...prevState,
          [category]: numericValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"),
        }));
      } else {
        setupPgs((prevState) => ({
          ...prevState,
          [category]: itemName,
        }));
      }
    } else {
      setupPgs((prevState) => ({
        ...prevState,
        [category]: itemName,
      }));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (path) {
      offerUid(path).then(function (response) {
        if (response.data) {
          setOfferlist(response.data);
        }
      });
      getContract(path).then(function (response) {
        if (response.data) {
          setContract(response.data[0]);
        }
      });
      getprojecthistory(path).then(function (response) {
        if (response.data) {
          setprojecthistory(response.data[0]);
        }
      });
    }
  }, [path]);

  useEffect(() => {
    getContract("exampledata").then(function (response) {
      if (response.data) {
        setExdata(response.data[0]);

      }
    });
    myinfos(userId).then(function (response) {
      if (response.data) {
        setUserinfo(response.data[0]);
      }
    });
  }, []);

  const uploadFiles = (projectId) => {
    uploadFilesmultie(projectId, selectedFiles);
  };

  const updates = () => {
    updateProject(
      uppg.title,
      uppg.email,
      uppg.company,
      uppg.cate,
      uppg.cateother,
      uppg.minicate,
      uppg.minicateother,
      uppg.outline,
      uppg.lastprojects,
      uppg.projects,
      uppg.needskill,
      uppg.price,
      uppg.startdate,
      uppg.term,
      uppg.contractcost,
      uppg.contractterm,
      uppg.pStatus,
      uppg.meet,
      uppg.client,
      uppg.clientphone,
      uppg.clientaddress,
      uppg.conditions,
      uppg.conditionother,
      uppg.uid
    ).then(function (response) {
      if (response.data) {
        Swal.fire("수정 되었습니다", "확인 버튼을 눌러 주세요", "success");
        uploadFiles(response.data.id);
        const selectedFiless = selectedFiles
          .map((fileObj) => {
            return `${allurl}/file_uploads/${fileObj.fileName}`;
          })
          .join(",");
        uppg.files = selectedFiless;

        navigate(`/Mypage/Myprojects/myProject?uid=${path}`);
        setEdit(!edit);
        setPgs({ ...uppg });
      } else {
        Swal.fire("오류가 발생하였습니다", "확인 버튼을 눌러 주세요", "error");
      }
    });
  };

  const deleteProjects = (uid) => {
    Swal.fire({
      title: "임시 프로젝트를 삭제하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "네",
      cancelButtonText: `아니요`,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProject(uid).then(function (response) {
          Swal.fire({
            title: "삭제되었습니다!",
            icon: "success",
          });
          const newdata = project.filter((values) => values.uid !== uid);
          setProject(newdata);
        });
      }
    });
  };

  return (
    <>
      <ProjectTabs
        type={"myproject"}
        array={project}
        num={num}
        setNum={handleChanges}
        bigLabels={bigLabels}
      />
      <div className="">
        {path && !edit ? (
          <div className={"w1480 h100 flex addproject j"}>
            <div className={"add_left_Myproject"}>
              {Object.entries(bigLabels).map(([biglabel, values]) => {
                const otherValues = bigLabels && bigLabels[bigL && bigL];

                const newvalue =
                  otherValues &&
                  otherValues.find((values) => values.label === pg.adminstatus);

                const historyfind = (value) => {
                  if (value !== 'e1') {
                    let foundItem = null;
                    if (projecthistory && typeof projecthistory === "object") {
                      Object.keys(projecthistory).forEach((key) => {
                        if (key === value) {
                          foundItem =
                            projecthistory[key] &&
                            projecthistory[key].split(" ")[0];
                        }
                      });
                    }

                    return foundItem;
                  } else {
                    return projecthistory && projecthistory.a2.split(" ")[0]
                  }
                };

                const historyfindf = (value) => {
                  if (value !== 'e1') {
                    let foundItem = null;
                    if (projecthistory && typeof projecthistory === "object") {
                      Object.keys(projecthistory).forEach((key) => {
                        const values = value + "f";
                        if (key === values) {
                          foundItem =
                            projecthistory[key] &&
                            projecthistory[key].split(" ")[0];
                        }
                      });
                    }
                    return foundItem;
                  } else {
                    return projecthistory && projecthistory.e1.split(" ")[0]
                  }
                };
                const filteredValues = values.filter(
                  (value) => value.label !== "전체"
                );
                const returndiv = (path, value, label) => {
                  const isInProgress =
                    (biglabel !== "프로젝트등록" &&
                      !historyfindf(path) &&
                      otherValues &&
                      otherValues.find((item) => item.label === pg.adminstatus)
                        ?.value >= value) ||
                    (biglabel === "프로젝트등록" &&
                      otherValues &&
                      otherValues.find((item) => item.label === pg.adminstatus)
                        ?.value <= value &&
                      label === pg.adminstatus);

                  const isCompleted =
                    otherValues &&
                    Number(
                      otherValues.find((item) => item.label === pg.adminstatus)
                        ?.value
                    ) >= value;

                  const statusText = isInProgress ? (
                    <span className="proceeding-box w50px ps">진행중</span>
                  ) : isCompleted ? (
                    <span className="complete-box w50px ps">완료</span>
                  ) : <span className="complete-box w50px ps">대기</span>;
                  return statusText;
                };

                if (biglabel == bigL && pg) {
                  return (
                    <div key={values[0].value}>
                      <div className="step-container">
                        <div className="step-content">
                          <div className="step_title flex a mb10 j">
                            <span className="flex a mb20">
                              {getIcon(values[0].value)}
                              <span className="mr10 ml5 f20">{biglabel}</span>
                            </span>
                            {biglabel !== "프로젝트등록" && (
                              <span className="dotted_boxs mr55 mb20">
                                D{minusday(pg.startdate, pg.term)}
                              </span>
                            )}
                          </div>
                          <div className="step_contain">
                            <ul className="step_wrap w100 mb20">
                              {filteredValues.map((values, index) => (
                                <>
                                  <div className="mb20">
                                    <li className=" flex a p5 j">
                                      <span className="flex a">
                                        <div
                                          className={
                                            pg.adminstatus === values.label
                                              ? "active mr10 minicircle"
                                              : " mr10 minicircle"
                                          }
                                        >
                                          {index + 1}
                                        </div>
                                        <span
                                          className={
                                            pg.adminstatus === values.label
                                              ? "active mr10 fontStyle-active"
                                              : " fontStyle-complete mr10"
                                          }
                                        >
                                          {values.label}
                                        </span>
                                      </span>
                                      {returndiv(
                                        values.path,
                                        values.value,
                                        values.label
                                      )}
                                    </li>
                                    <li className="flex">
                                      <div className="n-shape">
                                        <div className="vertical-bar"></div>
                                        <div className="horizontal-bar"></div>
                                        <span style={{ margin: '10px', padding: '8px', textAlign: 'center' }}>
                                          <span className="fontStyle-day">
                                            {historyfind(values.path)}
                                          </span>
                                          <span
                                            className={
                                              biglabel === "프로젝트등록" ||
                                                values.path === "견적서 수신"
                                                ? "none"
                                                : "fontStyle-day"
                                            }
                                          >
                                            {"\u00A0"}{"\u00A0"}~{"\u00A0"}{"\u00A0"}
                                          </span>
                                          <span
                                            className={
                                              biglabel === "프로젝트등록"
                                                ? "none"
                                                : "fontStyle-day"
                                            }
                                          >
                                            {historyfindf(values.path)}
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                  </div>
                                </>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="flex r">
                        {path &&
                          !edit &&
                          (pg.adminstatus === "임시저장" ||
                            pg.adminstatus === "프로젝트 등록") ? (
                          <div className="flex l w100">
                            <span className="flex mt20 mr10 f22">
                              <RiEdit2Fill />
                            </span>
                            <button
                              onClick={() => setEdit(!edit)}
                              className="retouch-box"
                            >
                              프로젝트 수정
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <Projectpgs
              offerlist={offerlist}
              userinfo={userinfo}
              exdata={exdata}
              setBigl={setBigl}
              bigLabels={bigLabels}
              num={num}
              setNum={setNum}
              contract={contract}
              setContract={setContract}
              my={true}
              setupPgs={setupPgs}
              edit={edit}
              setEdit={setEdit}
              pg={pg}
              setPgs={setPgs}
              right={true}
              path={path}
              setprojecthistory={setprojecthistory}
            />
          </div>
        ) : path && edit ? (
          <Projectedit
            setSelectedFiles={setSelectedFiles}
            selectedFiles={selectedFiles}
            my={true}
            updates={updates}
            path={path}
            handleChange={handleChange}
            pg={uppg}
          />
        ) : (
          <ul className="project__wrap">
            {num[1] === 0 && project.length > 0 ? (
              project.map((value) => (
                <Projectsbox
                  deleteProjects={deleteProjects}
                  contract={contract}
                  data={project}
                  my={true}
                  key={value.id}
                  value={value}
                />
              ))
            ) : bProject && bProject.length === 0 ? (
              <div className="empty flex c a min300 ">
                <FaRegFolderOpen />
                <span className="ml10">등록된 프로젝트가 없습니다</span>
              </div>
            ) : (
              bProject &&
              bProject.map((value) => (
                <Projectsbox
                  contract={contract}
                  deleteProjects={deleteProjects}
                  data={bProject}
                  my={true}
                  key={value.id}
                  value={value}
                />
              ))
            )}
          </ul>
        )}
      </div>
    </>
  );
}

export default MyProject;
