
import React from 'react';
import { companyinfo } from '../../service/dataarray';

function Confident_main({ userinfo }) {

  return (
    <div className='confiden_box'>
      <div className='intitle'>비밀유지 서약서</div>
      <h1 className='important mb20'><span className='comPany'>{userinfo.company}</span>(이하 “갑”이라 한다)와(과) <span className='myCompany'>{companyinfo.mycompany}</span>(주)(이하 “을”이라 한다)는(은) 비밀정보의 제공과 관련하여 다음과 같이 비밀유지협약을 체결한다.</h1>

      <strong>제1조(협약의 목적)</strong>
      <p>본 협약은 갑과 을 『(업무 요지 기재) (이하 “본 업무”라 한다)』와 관련하여 각자 상대방에게 제공하는 비밀정보를 비밀로 유지하고 보호하기 위하여 필요한 제반 사항을 규정함을 목적으로 한다.</p>

      <strong>제2조(비밀정보의 정의)</strong>
      <p>① 본 협약에서 ‘비밀정보’라 함은, 본 협약 체결 사실 자체 및 본 업무와 관련하여, 어느 일방 당사자(이하 “정보제공자“라 한다)가 반대 당사자(이하 “정보자“라 한다)에게 제공하는 일체의 정보로서, 유·무형의 여부 및 그 기록 형태를 불문한다.</p>
      <p>② 제1항의 비밀정보는 서면(전자문서를 포함하며, 이하 같음), 구두 혹은 기타 방법으로 제공되는 모든 노하우, 공정, 도면, 설계, 실험결과, 샘플, 사양, 데이터, 공식, 제법, 프로그램, 가격표, 거래명세서, 생산단가, 아이디어 등 모든 기술상 혹은 경영상의 정보와 그러한 정보가 수록된 물건 또는 장비 등을 모두 포함한다.</p>

      <strong>제3조(비밀의 표시)</strong>
      <p>① 정보제공자가 정보수령자에게 서면 제출, 이메일 전송, 물품 인도 등 유형적 형태로 비밀정보를 제공하는 경우, 비밀임을 알리는 문구(“비밀” 또는 이와 유사한 표지)를 명확히 표시해야 한다.</p>
      <p>② 정보제공자가 정보수령자에게 구두나 영상 또는 당사자의 시설, 장비 샘플 기타 품목들을 관찰･조사하게 하는 방법으로 비밀정보를 제공할 경우에는, 그 즉시 정보수령자에게 해당 정보가 비밀정보에 속한다는 사실을 고지하고, 비밀정보 제공일로부터 30일 이내에 상대방에게 공개 범위, 공개 일자, 공개 장소 및 공개 대상자 등이 명시된 요약본을 서면 제출, 이메일 전송 등의 유형적인 기록 형태로 제공하여야 한다.</p>
      <p>③ 정보제공자가 비밀정보에 해당함에도 불구하고, 제공 당시에 비밀정보임을 명확하게 표시하지 못하였거나 고지하지 못한 때에는 정보제공자는 지체없이 정보수령자에 대하여 해당 정보는 비밀정보임을 고지함과 동시에 공개 범위, 공개 일자, 공개 장소 및 공개 대상자 등이 명시된 요약본을 서면 제출, 이메일 전송 등의 유형적인 기록 형태로 제공하여야 하고, 이때로부터 비밀정보로서 효력을 가진다.</p>
      <strong>제4조(비밀 유지 기간 등)</strong>

      <p>① 본 협약은 본 협약 체결일로부터 5년간 그 효력을 가진다. 단, 본 협약상 비밀유지 의무는 협약기간의 만료 등의 사유로 본 협약이 종료된 이후에도 3년간 효력을 가진다.</p>
      <p>② 제1항에도 불구하고, 본 협약에서 그 성질상 계속하여 효력을 유지하여야 하는 조항은 본 협약이 종료되거나 전항의 기간들이 만료된 이후에도 계속하여 효력을 가진다.</p>


      <strong>제5조(정보의 사용용도 및 정보취급자 제한)</strong>
      <p>① 정보수령자는 정보제공자의 사전 서면 승인이 없는 한 정보제공자의 비밀정보를 “본 업무”의 수행 또는 “본 업무”와 관련된 계약에서 정한 본래의 목적 및 용도로만 사용하여야 하며, “본 업무”와 관련하여 사용하는 경우에도 필요한 업무 수행의 범위를 초과하여 임의로 비밀정보를 복제, 수정, 저장, 변형 또는 분석할 수 없다.</p>
      <p>② 정보수령자는 직접적･간접적으로 “본 업무”를 수행하는 임직원들에 한하여 정보제공자의 비밀정보를 취급할 수 있도록 필요한 조치를 취하여야 하며, 해당 임직원 각자에게 정보제공자의 비밀정보에 대한 비밀유지의무를 주지시켜야 한다. 이때 정보제공자는 정보수령자에게 해당 임직원으로부터 비밀유지서약서를 제출 받는 등의 방법으로 해당 정보의 비밀성을 유지하기 위하여 필요한 조치를 요구할 수 있다.</p>
      <p>③ 정보수령자가 ‘본 업무’의 수행을 위하여 정보제공자의 비밀정보를 “본 업무”를 수행하는 임직원들 이외의 제3자에게 제공하고자 할 때에는 사전에 정보제공자로부터 서면에 의한 동의를 얻어야 하며, 그 제3자와 사이에 해당 비밀정보의 유지 및 보호를 목적으로 하는 별도의 비밀유지협약을 체결한 이후에 그 제3자에게 해당 비밀정보를 제공하여야 한다.</p>
      <strong>제6조(비밀유지의무)</strong>
      <strong>제5조(정보의 사용용도 및 정보취급자 제한)</strong>
      <p>① 정보수령자는 정보제공자의 사전 서면승낙 없이 비밀정보를 포함하여 본 협약의 내용, ‘본 업무’의 내용 등을 공표하거나 제3자에게 알려서는 아니 된다. 다만, 객관적인 증거를 통하여 다음 각 호에 해당함이 입증되는 정보는 비밀정보가 아니거나 비밀유지의무가 없는 것으로 간주한다.</p>
      <ol>
        <li>비밀정보 제공 이전에 정보수령자가 이미 알고 있거나 알 수 있는 정보</li>
        <li>정보수령자의 고의 또는 과실에 의하지 않고 공지의 사실로 된 정보</li>
        <li>정보수령자가 정당하고 적법하게 제3자로부터 제공받은 정보</li>
        <li>정보수령자가 비밀정보를 이용하지 아니하고 독자적으로 개발하거나 알게 된 정보</li>
        <li>정보제공자가 비밀정보임을 고지하지 아니하고, 비밀정보에 속한다는 취지의 서면을 발송하지도 아니한 정보</li>
        <li>법원 기타 공공기관의 판결, 명령 또는 관련법령에 따른 공개의무에 따라서 공개한 정보</li>
      </ol>
      <p>② 정보수령자가 제1항 제6호에 따라 정보를 공개할 경우에는 사전에 정보제공자에게 그 사실을 서면으로 통지하고, 상대방으로 하여금 적절한 보호 및 대응조치를 할 수 있도록 하여야 한다.</p>
      <p>③ 정보수령자는 비밀정보를 보호하고 관리하는 데에 필요한 모든 노력을 다하여야 한다. 다만 천재지변, 화재 등 불가항력 사유에 의해 비밀정보가 유출된 경우에는 유출에 대한 책임을 지지 않는다.</p>
      <strong>  제7조(손해배상, 위약벌)
      </strong>

      <p>① 정보수령자가 본 협약을 위반한 경우, 정보수령자는 이로 인하여 정보제공자가 입은 모든 손해를 배상하는 것을 포함하여, 법률상 배상 책임을 다하여야 한다.</p>
      <p>② 정보수령자가 본 협약을 위반한 경우, 정보수령자는 제1항의 손해배상과 별도로 정보제공자에게 위약벌로서 금 3억원 및 “본 업무” 관련 정보제공자 및 정보수령자 간 계약금액 중 큰 금원을 지급하여야 한다.</p>


      <strong>제8조(비밀정보의 반환 등)
      </strong>
      <p>① 정보수령자는 협약기간의 만료 등의 사유로 본 협약이 종료된 경우,  본 업무가 종료 또는 중단된 경우 또는 정보제공자의 요청이 있는 경우에는 지체없이 정보제공자의 비밀정보가 기재되어 있거나 이를 포함하고 있는 제반 자료, 장비, 서류, 샘플, 기타 유체물(복사본, 복사물, 모방물건, 모방장비 등을 포함)을 즉시 정보제공자에게 반환하거나, 정보제공자의 선택에 따라 이를 폐기하고 그 폐기를 증명하는 서류를 그 때로부터 10일 내에 정보제공자에게 제공하여야 한다.</p>

      <strong>제9조(권리의 부존재 등)
      </strong>
      <p>① 본 협약에 따라 제공되는 비밀정보에 관한 소유권, 지식재산권 등의 모든 권리는 정보제공자에 속하며, 비밀정보를 통하여 특허출원 등이 가능할 경우 특허 등을 출원할 권리는 정보제공자에게 있다.</p>
      <p>② 본 협약은 어떠한 경우에도 정보수령자에게 비밀정보에 관한 어떠한 권리나 권리의 실시권 또는 사용권을 부여하는 것으로 해석되지 않는다.</p>
      <p>③ 본 협약은 어떠한 경우에도 당사자 간에 향후 어떠한 확정적인 협약의 체결, 제조물의 판매나 구입, 실시권의 허락 등을 암시하거나 이를 강제하지 않으며, 기타 본 협약의 당사자가 비밀정보와 관련하여 다른 제3자와 어떠한 거래나 협약관계에 들어가는 것을 금지하거나 제한하지 아니한다.</p>
      <p>④ 정보제공자는 비밀정보의 현 상태 그대로 제공하며, 비밀정보의 정확성 및 완전성이나 사업 목적에 대한 적합성 및 제3자의 권리 침해 여부에 대한 어떠한 보증도 하지 않는다.</p>
      <p>⑤ 정보제공자는 정보수령자가 비밀정보를 사용함에 따른 결과에 대하여 어떠한 책임도 지지 아니한다.</p>
      <p>⑥ 각 당사자는 본 협약의 목적을 위하여 상대방의 시설을 방문하거나 이를 이용할 경우에는 상대방의 제반 규정 및 지시사항을 준수하여야 한다.</p>
      <strong>제10조(권리의무의 양도, 협약의 변경)
      </strong>
      <p>① 각 당사자는 상대방의 사전 서면동의 없이 본 협약상의 권리의무를 각 당사자 이외의 제3자에게 양도하거나 이전할 수 없다.</p>
      <p>② 본 협약의 수정이나 변경은 양 당사자의 정당한 대표자가 기명날인 또는 서명한 서면 합의로만 이루어질 수 있다.</p>
      <strong>제11조(협약의 분리가능성)</strong>

      <p>본 협약 중 어느 규정이 법원에 의하여 위법, 무효 또는 집행 불가능 하다고 선언될 경우에도, 이는 본 협약의 나머지 규정의 유효성에 영향을 미치지 아니한다.</p>

      <strong>제12조(분쟁의 해결)</strong>
      <p>① 본 협약과 관련하여 분쟁이 발생한 경우 당사자의 상호 협의에 의한 해결을 모색하되, 분쟁에 관한 합의가 이루어지지 아니한 경우에는 중소기업기술 보호 지원에 관한 법률에 따른 중소기업기술분쟁조정·중재위원회의 조정에 따라 해결한다.</p>
      <p>② 위 조정이 성립하지 아니한 경우 [예시: 서울중앙지방법원]을 제1심 관할법원으로 하여 소송을 통해 분쟁을 해결하기로 한다.</p>

    </div>
  );
}

export default Confident_main;