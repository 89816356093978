
import React from 'react';
import { useState } from 'react';
import { Userstamp, exstamp, loadImage, optionAlert } from '../project';
import { useEffect } from 'react';
;
function Mainstamp({ userId, fresh, setStampid, notmain, stampnum, admin, stampid }) {

  const [imageSrcs, setImageSrcs] = useState([]);
  useEffect(() => {
    if (userId && userId !== 'example') {
      Userstamp(userId).then(function (response) {
        if (response.data && !notmain) {
          setStampid(response.data.filter(value => value.main === 1)[0].id)
          loadImage(response.data.filter(value => value.main === 1)[0], setImageSrcs)
        } else if (response.data && notmain || response.data && admin) {

          loadImage(response.data.filter(value => value.id == stampnum)[0], setImageSrcs)
        } else if (!response.data && admin) {

        }
        else {
          optionAlert('success', '날인을 등록해주세요', '', '날인등록 페이지로 이동', '/Mypage/Myinfo/sealingmethod')
        }

      })
    }
  }, [fresh, userId, stampnum])

  return (

    <>
      {userId ?
        <div className='stampbox'>
          {stampid && userId !== 'example' ?
            <img src={imageSrcs && imageSrcs.base64} alt='니즈로봇 외주개발 이미지' />
            :
            <span className='stampbox'>{exstamp}</span>}

        </div>
        : null}
    </>
  );
}

export default Mainstamp;