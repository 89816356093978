import React, { useRef, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { developtap } from "../../service/tabarray";
import Project_estimate from "./Project_estimate";
import Project_contract from "./Project_contract";
import Project_file from "./Project_file";


function Projectdevelop({ my, contract, project, offer, handleChanges, offerlist, onClick, onClickss, userId }) {
  const estimateImgRef = useRef();
  const specificationImgRef = useRef();
  const develop1Ref = useRef();
  const develop2Ref = useRef();
  const develop3Ref = useRef();
  const [inputImage, setInputImage] = useState(null);
  const [num, setNum] = useState(1)
  const savePdfFile = (imgRef) => {
    const file = imgRef.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setInputImage(reader.result);
    };
  };
  return (
    <>
      {project ?
        <>
          <div className={!my ? "add_right_wrap wide pgdetail" : "wide"}>
            <div className='ModalMypageInfo develop'>
              <div className="right_top_project_infowrite_Title">
                <AiFillEdit style={{ marginRight: "10px" }} />
                {project.title}
              </div>
              <nav>
                <ul className="w100 flex">
                  {developtap.map((item) => (
                    <li
                      key={item.value}
                      className={item.value === num ? "selected p10" : "p10"}
                      onClick={() => setNum(item.value)}>
                      {`${item.label}`}
                    </li>))}
                </ul>
              </nav>
              {num === 1 ?
                <Project_estimate userId={userId} savePdfFile={savePdfFile} estimateImgRef={estimateImgRef} handleChanges={handleChanges} offerlist={offerlist} project={project} offer={offer} contract={contract} />
                : num === 2 ?
                  <Project_contract userId={userId} savePdfFile={savePdfFile} handleChanges={handleChanges} contract={contract} project={project} onClick={onClick} onClickss={onClickss} specificationImgRef={specificationImgRef} />
                  : num === 3 ?
                    <Project_file userId={userId} savePdfFile={savePdfFile} handleChanges={handleChanges} contract={contract} project={project} onClick={onClick} onClickss={onClickss} develop1Ref={develop1Ref} develop2Ref={develop2Ref} develop3Ref={develop3Ref} />
                    : null}
            </div>
          </div>
        </>
        : null
      }
    </>
  );
}

export default Projectdevelop;