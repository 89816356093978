import React from 'react';
import { Link } from 'react-router-dom';
import { BsFillSendFill, BsFillReplyFill } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
import { AiOutlineClockCircle } from 'react-icons/ai';
function Mymessage_receive({ detail, deleteMessages, handle }) {

  const inputString = detail && detail.detail.title;
  const regex = /계약서가 작성이 완료되었습니다|사양서가 제출되었습니다|견적이 제출되었습니다|1차개발보고서가 제출되었습니다|2차개발보고서가 제출되었습니다|최종개발보고서가 제출되었습니다|견적이 수정되었습니다|계약서가 작성이 완료되었습니다|견적이 승인되었습니다/g;
  const matches = inputString && inputString.match(regex);

  return (
    <>
      <div className='w100 writebox'>
        <div className='receiveBox'>
          <div className='recevie_top flex j a'>
            <p><BsFillSendFill className='mr10 f20' /> {detail && detail.detail.caller}</p>
            <div>
              <button onClick={() => deleteMessages()} className='navibtn_hover mr10'><FiTrash2 className='f20' /></button>
              <Link onClick={(e) => { handle('users', detail.detail.caller); }} to={'/MYMESSAGE/writeMessage'} className='navibtn_hover'><BsFillReplyFill className='f20' /></Link>
            </div>
          </div>
          <h3>{detail.detail.type === 1 ? `${detail.projectDetail.title} 프로젝트의 ${matches && matches[0]}` : detail.detail.title}</h3>
          <h4 className='flex a'><AiOutlineClockCircle className='mr10' />{detail && detail.detail.createdtime}</h4>
          <div className='recevie_body'>
            {detail && detail.detail.contents}
          </div>
        </div>
      </div>
    </>
  );
}

export default Mymessage_receive;