import React, { useRef } from "react";
import { comma, removeComma } from "../../service/project";
import Toptabs from "../../service/components/Toptabs";
import OutCell from "./OutCell";
function Out_price({ detail }) {
  const divref = useRef();
  return (

    <div className="add_right_wrap outwrap mt0">
      <div className="flex contractstep_wrap w100">
        <Toptabs data={detail} value={"비용 결제내역"} div={divref.current} />
      </div>
      <div ref={divref} className="w100 mt10">
        <div className="quote-container mt10">
          {detail && detail.writedone ? (
            <div className="flex c">
              <div className="money_top ">
                <h1>1. 계약 정보</h1>
                <div className="money_top_table ">
                  <div className="money_title mt10 mb10 flex">
                    <div className="p10 w100px">계약명</div>
                    <div className="p10 w_100px">{detail.title}</div>
                  </div>
                  <OutCell contract={detail} />
                  {/* <div  className='  flex'>
            <div className='p10 w100px'>입금기한</div>
            <div className='p10 w_100px'>{addDaysToDate(detail.companydate,0)} - {addDaysToDate(detail.companydate,days)}</div>
          </div> */}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {detail && detail.writedone ? (
            <div className="flex c mt20">
              <div className="money_top ">
                <h1>2. 결제 정보</h1>
                <div className="money_top_table ">
                  <div className="  flex">
                    <div className="p10 w100px">입금은행</div>
                    <div className="p10 w_100px">국민은행</div>
                  </div>
                  <div className="  flex">
                    <div className="p10 w100px">계좌번호</div>
                    <div className="p10 w_100px">759901-00-058096</div>
                  </div>
                  <div className="  flex">
                    <div className="p10 w100px">예금주</div>
                    <div className="p10 w_100px">박진영(니즈로봇)</div>
                  </div>
                  <div className="  flex">
                    <div className="p10 w100px">입금액</div>
                    <div className="p10 w_100px">
                      {" "}
                      {comma(
                        Number(removeComma(detail.allprice)) +
                        Number(removeComma(detail.allprice) * 0.1)
                      )}
                      원
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {detail.uid === "exampledata" ? (
          <div className="text_absolute">비용결제 예시입니다</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Out_price;
