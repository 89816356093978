import axios from "axios";

//const allurl = 'http://localhost:8000'
//const allurl = 'http://nzr.iptime.org:54327'
const allurl = 'https://www.needsrobot.co.kr'


const instance = axios.create({
  baseURL: allurl,
  //baseURL: "http://nzr.iptime.org:54327",
  withCredentials: true, // 인증 정보를 포함하는 옵션 추가
});
const fetchData = async (url, params) => {
  try {
    const response = await instance.get(url, {
      params,
      headers: {
        "Content-Type": "application/json",
      },

    });
    return response;
  } catch (err) {
    return err;
  }
};

export { fetchData };