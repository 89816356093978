
import React from 'react';
import { useEffect, useState } from 'react';
import AdminUser_right from './AdminUser_right';
import { Allstamps } from '../../service/project';

function AdminUser({ userlist, setUserlist, projectlist, alluserlist }) {

  const [stamplist, setStamplist] = useState('')


  useEffect(() => {

    Allstamps().then(function (response) {
      if (response.data) {
        setStamplist(response.data)
      }
    })

  }, [])



  return (
    <>
      <div className='w1480 h100 flex pro_contain'>

        <AdminUser_right alluserlist={alluserlist} stamplist={stamplist} projectlist={projectlist} setUserlist={setUserlist} userlist={userlist} />
      </div>
    </>
  );
}

export default AdminUser;