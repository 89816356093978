import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { navi, Gnavi } from './service/dataarray';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const Navi = ({ login, grade, setIsHeaderFixed, isHovered, setIsHovered }) => {
  const pathname = useLocation().pathname;
  const [naviList, setNaviList] = useState([]);

  useEffect(() => {
    if (grade && login) {
      setNaviList(Gnavi.filter(navItem => navItem.label !== 'MYMESSAGE'));
    } else {
      setNaviList(navi.filter(navItem => navItem.label !== 'MYMESSAGE'));
    }
  }, [grade, login]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const showConfirm = () => {
    Swal.fire({
      title: "로그아웃 하시겠습니까?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: `아니요`,
      confirmButtonText: '네'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: '로그아웃 되었습니다!',
          icon: 'success'
        });
        localStorage.clear();
        window.location.href = '/';
      }
    });
  };

  const testHandler = (event, path) => {
    if (!login && path === '/Mypage/Myprojects/myProject') {
      event.preventDefault();
      Swal.fire({
        title: '로그인 해주세요',
        icon: 'warning'
      });
    }
  };

  return (
    <ul onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={isHovered ? 'navi' : 'navi ohidden'}>
      {naviList.map((navItem, index) => (
        <li className={navItem.value && navItem.value.includes(pathname) && pathname !== '/' ? 'active' : ''} key={index}>
          <a onClick={(e) => testHandler(e, navItem.path)} href={navItem.path}>
            {navItem.label}
          </a>
        </li>
      ))}
      {!login ?
        <li>
          <Link to={'/Login'} className='btnstyle'>로그인</Link>
        </li>
        :
        <li>
          <Link onClick={showConfirm} className='btnstyle'>로그아웃</Link>
        </li>
      }
    </ul>
  );
};

export default Navi;