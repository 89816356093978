import React from "react";

function Rule_project2() {

  const renderDiv = (first, second) => {
    return (
      <div className="step-wrap flex a">
        <div className="step-box">
          <div>{first}</div>
        </div>
        <div className="arrow_tip"></div>
        <div className="connecting-line"></div>
        <div className="arrow_tip" style={{ transform: 'rotate(90deg) translateY(5px)' }}></div>
        <div className="step-box">
          <div>{second}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="right_top">프로젝트 계약 및 진행</div>
      <div className="flex c a mt10">
        <div className="w650 ">
          <div className="boxSpecial" style={{ marginLeft: '5px' }}>
            클라이언트
          </div>
          <div className="boxSpecial" style={{ marginRight: '5px' }}>
            니즈로봇
          </div>

        </div>
      </div>
      <div className="registration-container">

        <div className="vertical-flow">
          {renderDiv('① 견적서 승인', '① 견적서 제출\n(3일 이내)')}
          {renderDiv('② 개발사양서 승인', '② 개발사양서 제출\n(5일 이내)')}
          {renderDiv('③ 계약서 전자서명', '③ 계약서 제출\n(2일 이내)')}
          {renderDiv('① 비용 지급\n(선금, 중도금, 잔금)', '④ 진행 보고서 제출\n(1차, 2차, 최종)')}
          {renderDiv('⑤ 계약 종료', '⑤ 계약 산출물 제출')}
        </div>
      </div>
    </>
  );
}

export default Rule_project2;