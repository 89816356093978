
import React from 'react';
import { motion } from 'framer-motion';
import { Gnavi, navi } from '../dataarray';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getItemWithExpireTime } from '../project';

function Subnav({ userId, value }) {
  const pars = getItemWithExpireTime('token') && getItemWithExpireTime('token')
  const location = useLocation()
  const pathname3 = '/' + location.pathname.split("/")[1] + '/' + location.pathname.split("/")[2]
  const pathname2 = location.pathname
  const pathname1 = '/' + location.pathname.split("/")[1]
  const verNavi = pars && pars.uGrade ? Gnavi : navi


  return (
    <div className="subnav_wrap">
      <motion.div
        className={pathname1 === '/Outsourcing' ? 'ml370 subnav_box w1480' :
          pathname1 === '/Addprojects' ? 'ml370 subnav_box w1480' :
            'subnav_box w1480'}
        initial={{ opacity: 1, scale: 1.2 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1
        }}
      >
        {verNavi.filter(value => value.value && value.value.includes(pathname1)).map(item =>
          item.children.map(child => <div className={pathname1 == '/Addprojects' && `${child.path}` === pathname3 ? 'active'
            :
            pathname1 == '/Projects' && `${child.path}` === pathname3 ? 'active'
              :
              pathname1 == '/Mypage' && `/${child.path.split("/")[1]}/${child.path.split("/")[2]}` === pathname3
                ? 'active'
                :
                pathname1 !== '/Addprojects' && pathname1 !== '/Projects' && child.path == pathname2 ? 'active'
                  : pathname1 == '/Outsourcing' && `${child.path}` === pathname3 ? 'active' :
                    ''} key={child.label}>
            {value !== 'OUTSOURCING' && value !== 'MY MESSAGE' ?
              <a href={child.path}>{child.label}</a>
              : <Link to={child.path}>{child.label}</Link>
            }

          </div>)
        )}
      </motion.div>

    </div>
  );
}

export default Subnav;
