import React from "react";
import { getDate, needsstamp } from "../../service/project";
import { companyinfo, exuserinfos } from "../../service/dataarray";
import Mainstamp from "../../service/components/Mainstamp";

function Confident_sing({
  userinfo,
  fresh,
  setStampid,
  notmain,
  setIsOpen,
  num,
  stampid,
}) {
  return (
    <div className="confiden2 confiden_box">
      <h2>
        ‘<span className="comPany">{userinfo.company}</span>’과 ‘
        <span className="myCompany">{companyinfo.mycompany}</span>’은 본 협약의
        성립을 증명하기 위하여 본 협약서 2부를 작성하여 각각 서명(또는
        기명날인)한 후 각자 1부씩 보관한다.
      </h2>
      <div className="mt40">
        <p className="date mb20">
          서약일자 : {userinfo.confidentiality ? userinfo.confidate : getDate()}
        </p>
        <div className="flex j a bottombox">
          <div className="clientbox w50">
            <p>
              (주소){" "}
              <span className="ml10">
                {userinfo.companyaddressf
                  ? userinfo.companyaddressf
                  : "마이페이지에 주소를"}{" "}
                {userinfo.companyaddressm
                  ? userinfo.companyaddressm
                  : "입력해주세요"}
              </span>
            </p>
            <p>
              (회사명){" "}
              <span className="comPany ml10">
                {userinfo.company ? userinfo.company : exuserinfos.company}(
                {userinfo.companyregiNum
                  ? userinfo.companyregiNum
                  : "123-45-67890"}
                )
              </span>
            </p>

            <div className="mt10">
              (대표자)
              <span className="ml10">
                {userinfo.representative
                  ? userinfo.representative
                  : exuserinfos.representative}
              </span>
              (인)
              <span className="stampwrap">
                <Mainstamp
                  stampnum={
                    userinfo.confidentstamp
                      ? userinfo.confidentstamp
                      : stampid
                        ? stampid
                        : null
                  }
                  stampid={stampid}
                  notmain={notmain}
                  setStampid={setStampid}
                  fresh={fresh}
                  userId={userinfo.userId}
                />
              </span>
            </div>
            <div className="mt10">
              {num === 1 && !userinfo.confidentiality ? (
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  className="ps w100px navibtn_hover"
                  style={{ width: '100px' }}
                >
                  날인변경
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="rightclientbox w50 ">
            <p>
              (주소)<span className="ml10">{companyinfo.address}</span>
            </p>
            <p>
              (회사명){" "}
              <span className="myCompany ml10">
                {companyinfo.mycompany}({companyinfo.comnum})
              </span>
            </p>

            <div className="mt10">
              (대표자){" "}
              <span className="ml10">{companyinfo.representative}</span> (인)
              <span className="stampwrap">
                <div className="stampbox">{needsstamp}</div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Confident_sing;
