
import React from 'react';
import Subhead from '../Subhead';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { allProjects, allusers, interestList, searchPlays, updateProject, uploadFilesmultie, allurl, addCommasToNumber } from '../../service/project';
import Searchwrap from '../../service/components/Searchwrap';
import Projectpgbox from '../Project/Projectpgbox';
import Swal from 'sweetalert2';
import AdminUser from './AdminUser';
import AdminProject from './AdminProject';
import { imageArray } from '../../service/dataarray';

function Admin({ userId }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname.split("/")[2]
  const searchParams = new URLSearchParams(location.search);
  const nums = searchParams.get('uid');
  const [projectlist, setProjectlist] = useState([]);
  const [allP, setallp] = useState([]);
  const [pg, setPgs] = useState()
  const [interest, setInterest] = useState([])
  const [edit, setEdit] = useState(false)
  const [uppg, setupPgs] = useState()
  const [searchText, setSearchText] = useState('')
  const [searchType, setSearchType] = useState(0)
  const [userlist, setUserlist] = useState('')
  const [alluserlist, setallUserlist] = useState('')
  const [sortArray, setSortArray] = useState({
    cate: [],
    minicate: [],
    adminstatus: [],
    moneystatus: [],
    prices: '',
    pricef: '',
    terms: '',
    termf: '',
    starts: '',
    startf: '',
  })

  const searchTypes = (value) => {
    setSearchType(value)
  }
  const searchChange = (value) => {
    setSearchText(value)
  }
  // 검색 기능
  const searchPlay = () => {
    searchPlays(allP, alluserlist, setProjectlist, setUserlist, path, searchType, searchText)
  }

  const uploadFiles = (projectId) => {
    uploadFilesmultie(projectId, selectedFiles)
  };

  const updates = () => {
    updateProject(uppg.title, uppg.email, uppg.company, uppg.cate, uppg.cateother, uppg.minicate, uppg.minicateother, uppg.outline, uppg.lastprojects, uppg.projects, uppg.needskill, uppg.price, uppg.startdate, uppg.term, uppg.contractcost, uppg.contractterm, uppg.pStatus, uppg.meet, uppg.client, uppg.clientphone, uppg.clientaddress, uppg.conditions, uppg.conditionother, uppg.uid).then(function (response) {
      if (response.data) {
        Swal.fire(
          '성공적으로 수정되었습니다',
          '확인 버튼을 눌러 주세요',
          'success'
        )
        uploadFiles(response.data.id);
        const selectedFiless = selectedFiles.map((fileObj) => {
          return `${allurl}/file_uploads/${fileObj.fileName}`;
        }).join(',');
        uppg.files = selectedFiless;
        navigate(`/Admin/Adminproject?uid=${nums}`)
        setEdit(!edit);
        setPgs({ ...uppg });
      } else {
        Swal.fire(
          '오류가 발생했습니다',
          '확인 버튼을 눌러 주세요',
          'error'
        )
      }

    })
  }
  const [filteredProjects, setFilteredProjects] = useState([]);

  const filterProjectList = (projectlist, sortArray) => {
    const categoryFilters = ['cate', 'minicate', 'adminstatus', 'moneystatus']
    const filteredProjects = projectlist.filter(project => {
      return categoryFilters.every(category => {
        if (Array.isArray(sortArray[category]) && sortArray[category].length > 0) {
          if (Array.isArray(project[category])) {
            return project[category].some(item => sortArray[category].includes(item));
          } else {
            return sortArray[category].includes(project[category]);
          }
        }
        return true;
      });
    });

    return filteredProjects;
  };

  const handleChange = (category, itemName) => {
    if (category === 'cate' || category === 'adminstatus' || category === 'moneystatus') {
      const updatedSortArray = {
        ...sortArray,
        [category]: sortArray[category].includes(itemName)
          ? sortArray[category].filter(item => item !== itemName)
          : [...sortArray[category], itemName],
      };

      setSortArray(updatedSortArray);
      const filteredProjects = filterProjectList(allP, updatedSortArray);
      setProjectlist(filteredProjects);
    } else if (category === 'minicate') {
      const updatedSortArray = {
        ...sortArray,
        [category]: sortArray[category].includes(itemName)
          ? sortArray[category].filter(item => item !== itemName)
          : [...sortArray[category], itemName],
      };

      setSortArray(updatedSortArray);
      const filteredProjects = filterProjectList(allP, updatedSortArray);
      setProjectlist(filteredProjects);
    } else {
      setSortArray(prevState => ({
        ...prevState,
        [category]: itemName,
      }));
    }
  };
  const handleChanges = (category, itemName) => {
    if (category === 'minicate' || category === 'conditions') {
      const updatedMinicate = uppg[category].includes(itemName)
        ? uppg[category].filter(item => item !== itemName)
        : [...uppg[category], itemName];

      setupPgs(prevData => ({
        ...prevData,
        [category]: updatedMinicate
      }));
    } else if (category === 'price') {
      const numericValue = parseFloat(itemName.replace(/,/g, ""));

      // 입력값이 숫자일 경우에만 설정
      if (!isNaN(numericValue)) {
        setupPgs(prevState => ({
          ...prevState,
          [category]: addCommasToNumber(numericValue)
        }));
      }
      else {
        setupPgs(prevState => ({
          ...prevState,
          [category]: ''
        }));
      }
    } else if (category === 'clientphone') {
      // 하이픈(-) 제거
      const numericValue = itemName.replace(/-/g, '');

      // 10자리 또는 11자리인 경우 하이픈 추가
      if (numericValue.length === 10) {
        setupPgs(prevState => ({
          ...prevState,
          [category]: numericValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
        }));
      } else if (numericValue.length === 11) {
        setupPgs(prevState => ({
          ...prevState,
          [category]: numericValue.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
        }));
      } else {
        setupPgs(prevState => ({
          ...prevState,
          [category]: itemName
        }));
      }
    } else {
      setupPgs(prevState => ({
        ...prevState,
        [category]: itemName
      }));
    }
  };

  useEffect(() => {
    allProjects()
      .then(function (response) {
        const saveListWithConvertedMinicate = response.data.filter(v => v.uid !== 'exampledata').map(item => {
          if (item.minicate) {
            item.minicate = JSON.parse(item.minicate);
            item.conditions = JSON.parse(item.conditions);
          }
          return item;
        });
        setallp(saveListWithConvertedMinicate);
        setProjectlist(saveListWithConvertedMinicate)

        const initialFilteredProjects = filterProjectList(saveListWithConvertedMinicate, sortArray);
        setFilteredProjects(initialFilteredProjects);
      })
      .catch(function (error) {
        console.log(error);
      });

    interestList(userId).then(function (response) {
      setInterest(response.data);
    });
    allusers().then(function (response) {
      if (response.data) {
        setUserlist(response.data);
        setallUserlist(response.data);
      }
    });
  }, []);

  return (
    <div className='heightAuto'>
      <div className='backback greyb'></div>
      <div className="project  ">
        <Subhead value={'ADMIN'} subvalue={'관리자페이지입니다'} img={imageArray.subtitle_portfolio} />
        <Searchwrap searchTypes={searchTypes} searchPlay={searchPlay} searchChange={searchChange} value={path == 'Adminproject' ? '전체 프로젝트 관리' : '회원현황'} />
        {path == 'Adminproject' ?
          <>
            {!nums ?
              <AdminProject handleChange={handleChange} allP={allP} projectlist={projectlist} setProjectlist={setProjectlist} interest={interest} setInterest={setInterest} userId={userId} />
              : nums ?
                <Projectpgbox setSelectedFiles={setSelectedFiles} admin={true} setupPgs={setupPgs} path={nums} handleChange={handleChanges} edit={edit} pg={pg} allpg={allP} setPgs={setPgs} uppg={uppg} setallPg={setallp} updates={updates} setEdit={setEdit} userId={userId} />
                :
                ''
            }
          </>
          : path == 'Users' ?
            <AdminUser alluserlist={alluserlist} projectlist={projectlist} setUserlist={setUserlist} userlist={userlist} />
            : ''
        }


      </div>
    </div>
  );
}

export default Admin;