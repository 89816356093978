
import React from 'react';
import { useEffect, useState } from 'react';
import MyProjects_l from './MyProjects_l';
import MyProjects_r from './MyProjects_r';
import ProjectTabs from '../../service/components/ProjectTabs';
import { applyLists } from '../../service/project';
function Applyproject({ userId }) {
  const [applylist, setApplylist] = useState([])
  const [bapplylist, setBapplylist] = useState([])
  const [num, setNum] = useState(['전체', 0])
  useEffect(() => {
    applyLists(userId).then(function (response) {
      setApplylist(response.data)
    })

  }, [])
  const onClick = (id) => {
    const mypg = [...applylist]
    setBapplylist(mypg.filter(value => value.id === id))

  }
  return (
    <>
      <ProjectTabs type={'apply'} array={applylist} num={num} setNum={setNum} />
      <MyProjects_l num={num} onClick={onClick} project={applylist} />
      <MyProjects_r updateData={bapplylist && bapplylist[0]} bProject={bapplylist && bapplylist[0]} />

    </>

  );
}

export default Applyproject;