
import React from 'react';
import Search from './Search';

function Searchwrap({ value, searchChange, searchPlay, searchTypes }) {

  return (
    <div className='page_title flex j a w1480'>
      <h1>{value}</h1>
      <Search searchTypes={searchTypes} searchPlay={searchPlay} searchChange={searchChange} />
    </div>
  );
}

export default Searchwrap;