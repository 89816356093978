import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { logins, setItemWithExpireTime, receivemessage, alertreturn } from '../service/project'
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie'
import { useEffect } from 'react'
import { metaLogin } from '../service/mataTags'
import MetaTag from '../MetaTag'
import { FaHome } from 'react-icons/fa'
import { useRealTimeData } from '../RealTimeDataContext';
function Login() {
    const { setToken, setMlist, setmessageNew } = useRealTimeData();
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['rememberUserId'])
    const [isRemember, setIsRemember] = useState(false)
    const [addUser, setAddUser] = useState({
        userId: '',
        uPassword: '',
    })

    const handleChange = (id, value) => {
        setAddUser((prevState) => ({
            ...prevState,
            [id]: value,
        }))
    }

    // const onClicklogin = (e) => {
    //     e.preventDefault()
    //     logins(addUser.userId, addUser.uPassword)
    //         .then(function (response) {
    //             if (response.data) {
    //                 setItemWithExpireTime('token', response.data, 3 * 60 * 60 * 1000)
    //                 setToken(response.data)

    //                 receivemessage(addUser.userId).then(function (response) {
    //                     if (response.data) {
    //                         const sortedMessages = response.data.slice().sort((a, b) => {
    //                             if (a.openmessage === null && b.openmessage === null) return 0
    //                             if (a.openmessage === null) return -1
    //                             if (b.openmessage === null) return 1

    //                             if (a.openmessage === 0 && b.openmessage === 0) return 0
    //                             if (a.openmessage === 0) return -1
    //                             if (b.openmessage === 0) return 1
    //                             return 0
    //                         })
    //                         setMlist(sortedMessages)
    //                         const nullOrZeroCount = response.data.filter(
    //                             (item) => item.detail.openmessage === null || item.detail.openmessage === 0,
    //                         ).length
    //                         setmessageNew(nullOrZeroCount)

    //                     } else {
    //                         alertreturn('error')
    //                     }
    //                 })

    //                 Swal.fire('로그인 되었습니다', '확인 버튼을 눌러 주세요', 'success')
    //                 navigate('/')
    //                 if (isRemember) {
    //                     setCookie('rememberUserId', addUser.userId, { maxAge: 60 * 60 * 24 * 30 })
    //                 } else {
    //                     removeCookie('rememberUserId')
    //                 }
    //             } else {
    //                 Swal.fire('아이디/비밀번호를 확인해주세요.', '확인 버튼을 눌러 주세요', 'warning')
    //             }
    //         })
    //         .catch(function (error) {
    //             Swal.fire('에러가 발생했습니다', '확인 버튼을 눌러 주세요', 'error')
    //         })
    // }
    const onClicklogin = (e) => {
        e.preventDefault();
        logins(addUser.userId, addUser.uPassword)
            .then(function (response) {
                if (response.data) {
                    setItemWithExpireTime('token', response.data, 3 * 60 * 60 * 1000);
                    setToken(response.data);

                    receivemessage(addUser.userId).then(function (response) {
                        if (response.data) {
                            const sortedMessages = response.data.slice().sort((a, b) => {
                                if (a.openmessage === null && b.openmessage === null) return 0;
                                if (a.openmessage === null) return -1;
                                if (b.openmessage === null) return 1;

                                if (a.openmessage === 0 && b.openmessage === 0) return 0;
                                if (a.openmessage === 0) return -1;
                                if (b.openmessage === 0) return 1;
                                return 0;
                            });
                            setMlist(sortedMessages);
                            const nullOrZeroCount = response.data.filter(
                                (item) => item.detail.openmessage === null || item.detail.openmessage === 0,
                            ).length;
                            setmessageNew(nullOrZeroCount);

                        } else {
                            alertreturn('error');
                        }
                    });

                    Swal.fire('로그인 되었습니다', '확인 버튼을 눌러 주세요', 'success');
                    navigate('/');
                    if (isRemember) {
                        setCookie('rememberUserId', addUser.userId, { maxAge: 60 * 60 * 24 * 30 });
                    } else {
                        removeCookie('rememberUserId');
                    }
                } else {
                    Swal.fire('아이디/비밀번호를 확인해주세요.', '확인 버튼을 눌러 주세요', 'warning');
                }
            })
            .catch(function (error) {
                Swal.fire('에러가 발생했습니다', '확인 버튼을 눌러 주세요', 'error');
            });
    };
    useEffect(() => {
        if (cookies.rememberUserId !== undefined) {
            setAddUser((prevState) => ({
                ...prevState,
                userId: cookies.rememberUserId,
            }))
            setIsRemember(true)
        }
    }, [])

    const handleOnChange = (e) => {
        setIsRemember(e.target.checked)
        if (!e.target.checked) {
            removeCookie('rememberUserId')
        }
    }

    return (
        <section className="login">
            <MetaTag {...metaLogin} />
            <div className="login_box">
                <div className="left">
                    <div className="top_link">
                        <a className="flex a" href="/">
                            <FaHome />
                            <p className="ml5">Return home</p>
                        </a>
                    </div>
                    <div className="contact">
                        <form action="">
                            <h3>SIGN IN</h3>
                            <input
                                onChange={(e) => handleChange('userId', e.target.value)}
                                type="text"
                                placeholder="아이디를 입력해주세요"
                                defaultValue={addUser.userId}
                            />
                            <input
                                onChange={(e) => handleChange('uPassword', e.target.value)}
                                type="password"
                                placeholder="비밀번호를 입력해주세요"
                            />
                            <label className="flex a" for="saveId">
                                아이디저장
                                <input
                                    type="checkbox"
                                    className="saveId ml10"
                                    name="saveId"
                                    onChange={(e) => {
                                        handleOnChange(e)
                                    }}
                                    checked={isRemember}
                                />{' '}
                            </label>
                            <button className="submit" onClick={(e) => onClicklogin(e)}>
                                로그인
                            </button>

                            <Link to={'/Signin'} className="join">
                                회원가입
                            </Link>
                        </form>
                    </div>
                </div>
                <div className="right">
                    <img
                        src="https://ifh.cc/g/FgfJ48.jpg"
                        alt="login page 니즈로봇 외주개발 홈페이지 로그인"
                    />
                    <div className="rightbg"></div>
                    <div className="right-text">
                        <h2>JOIN US</h2>
                        <h5>NEEDS ROBOT</h5>
                    </div>
                    <div className="right-inductor"></div>
                </div>
            </div>
        </section>
    )
}

export default Login
