
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Stamp1 from './Stamp1';
import Stamp0 from './Stamp0';
import Stamp2 from './Stamp2';
import { Userstamp } from '../../service/project';
import Swal from 'sweetalert2';
function Sealingmethod({ userId, userinfo }) {
  const [num, setNum] = useState(0)
  const [slist, setSlist] = useState([])
  const navigate = useNavigate();


  useEffect(() => {
    if (userId) {
      Userstamp(userId).then(function (response) {
        if (response.data) {
          setSlist(response.data)
        } else {
          console.log('값이 없음')
        }

      })
    } else {
      Swal.fire({
        title: '로그인 해주세요',
        icon: 'warning'
      })
      navigate('/Addprojects/confidentiality')
    }
  }, [])



  return (
    <div className='w_200px'>
      {num === 0 ?

        <Stamp0 userId={userId} lists={slist} setNum={setNum} />
        : num === 1 ?
          <Stamp1 setNum={setNum} userinfo={userinfo} />
          : num === 2 ?
            <Stamp2 userinfo={userinfo} />

            :
            ''

      }

    </div>
  );
}

export default Sealingmethod;