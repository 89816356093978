
import React from 'react';
import { TextAreas, comma, removeComma, } from '../../service/project';
import OutCell from './OutCell';
function Out_Main_contract({ contract, detail, my }) {

  return (
    <>
      <div className='intitle'>개발 계약서</div>
      <h2>1. 계 약 명 : {contract.title}</h2>
      <h2>2. 계약 기간 : {contract.startdate} ~ {contract.lastdate}</h2>
      <h2>3. 계약 내용    <TextAreas value={contract.detail} /></h2>
      <h2>4. 계약 금액 :   ￦{comma(removeComma(contract.allprice) + removeComma(contract.allprice) / 10)} 원 (공급가액 : ￦{comma(removeComma(contract.allprice))}, 부가세 : ￦{comma(removeComma(contract.allprice) / 10)})</h2>

      <OutCell contract={contract} />
      {contract.deposit > 0 ?
        <h2>5. 계약 보증금 :   ￦{comma(contract.deposit)} 원(부가세 별도)</h2>
        : <h2>5. 계약 보증금 : 유예 </h2>
      }
      <h2>6. 납품 장소 :  {contract.deliverylocation}</h2>
      {!my && detail && detail.uid === 'exampledata' ?
        <div className='text_absolute'>
          비용결제 예시입니다
        </div>
        : null
      }
    </>

  );
}

export default Out_Main_contract;