
import React, { useRef,useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Filterimg from "./filter/Fiterimg";
import Swal from "sweetalert2";
import Signature from "../../service/components/Signature";
import { stampTap } from "../../service/tabarray";
import { uploadFilesingle } from "../../service/project";
function Stamp1({userinfo,setNum}) {
    const cropperRef = useRef(null);
    const [tap, setTap] =useState(0)
    const [inputImage, setInputImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
      const [filteredImage, setFilteredImage] = useState(null); 
    const onCrop = () => {
      const imageElement = cropperRef?.current;
      const cropper  = imageElement?.cropper;
      setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    };
    const uploadFile = () => {
      Swal.fire({
        title: "날인을 등록하시겠습니까?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '네',
        cancelButtonText: `아니요`,
      }).then((result) => {
        if (result.isConfirmed) {
          uploadFilesingle(filteredImage,userinfo,setNum,'stamp')
        }
      })
      
    };
     
    
      
  return (
    <div className='client_right'>
      <div className="flex stampTapwrap mb20">
        {stampTap.map(value=>
          <div className={tap===value.value ? "mr10 active" :"mr10"} onClick={()=>setTap(value.value)} key={value.value}>{value.label}</div>)}
      </div>
      {tap ===0 ?
      <>
      <div className="flex j mb10"> 
      <label htmlFor='fileInput' className="navibtn_hover ">
        파일선택
    <input className="none" id='fileInput' type="file" accept="image/*" onChange={(e) => setInputImage(URL.createObjectURL(e.target.files[0]))} />
    </label>
    <button className="navibtn_hover" onClick={()=>{uploadFile()}}>저장</button>
    </div>
    <div className="flex j">
        <Cropper
        src={inputImage}
        style={{ height: 400, width: "50%" }}
        // Cropper.js options
        initialAspectRatio={16 / 9}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
        />
        <div className="w50 imgwrap">
    
      <Filterimg setFilteredImage={setFilteredImage} imgsrc={croppedImage}/>
        </div>
    </div>
    </>
    : tap===1 ?
    <>
    <Signature setNum={setNum} userinfo={userinfo}/>
    </>
    :''
      }
      
        </div>
  );
}

export default Stamp1;