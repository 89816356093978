
import React from 'react';
import { clientleft } from '../../service/dataarray';
import { Link } from 'react-router-dom';
function Clientinfo_left({ onClick, pathParts }) {

  return (
    <div className='client_left'>
      <ul>
        {clientleft && clientleft.map(values =>
          <li className={pathParts === values.path ? 'flex active a' : 'flex a'} key={values.value} >
            <span className={'circle mr10'}>{values.value + 1}</span>
            <span >
              <Link to={`/Mypage/Myinfo/${values.path}`}>
                {values.label}
              </Link>

            </span>
          </li>

        )}
        {pathParts === 'clientinfo' || pathParts === 'identification' ?
          <button onClick={() => onClick()} className='navibtn_hover ml20' style={{ width: '120px', height: '40px', padding: '8px' }}>내 정보 저장</button>



          : ''
        }
      </ul>

    </div>
  );
}

export default Clientinfo_left;