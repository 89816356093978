
import React from 'react';
import { motion } from 'framer-motion';
import Subnav from '../service/components/Subnav';
function Subhead({ value, subvalue, img, userId, marginBottom }) {
  return (
    <div className="subhead" style={{ marginBottom: marginBottom }}>
      <div className=' mainb backbacksub'></div>
      <motion.div
        className='subheadimg'
        initial={{ opacity: 1, scale: 1.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1
        }}
      >
        <img src={img} alt='외주개발 니즈로봇 하단 헤더 이미지' />
      </motion.div>
      <div className='subheadText'>
        <p className='subheadT'>{value}</p>
        <p className='subheadTb'>{subvalue}</p>
      </div>
      <Subnav value={value} userId={userId} />
    </div>

  );
}

export default Subhead;
