import React, { useEffect, useState } from 'react';
import { Userstamp, deletestamps, loadImages, mainstamps } from '../../service/project';
import Swal from 'sweetalert2';

function Allstampbox({ setNum, userId, original, onFresh }) {
  const [slist, setSlist] = useState([])
  const [imageSrcs, setImageSrcs] = useState([]);
  const [select, setSelect] = useState()
  useEffect(() => {
    Userstamp(userId).then(function (response) {
      if (response.data) {
        setSlist(response.data)
        loadImages(response.data, setImageSrcs)
        setSelect(response.data.filter(value => value.main === 1)[0])
      } else {

      }

    })
  }, [])


  const mainstamp = (id) => {
    mainstamps(id, userId).then(function (response) {
      if (response.data) {
        Swal.fire({
          title: '대표 날인으로 설정 되었습니다',
          icon: 'success'
        })
        {
          !original &&
            onFresh()
        }
      }
    })
  }
  const deletestamp = (id) => {
    Swal.fire({
      title: "날인을 삭제하시겠습니까?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: `아니요`,
      confirmButtonText: '네'
    }).then((result) => {
      if (result.isConfirmed) {
        if (slist.filter(item => item.main === 1)[0].id === id) {
          Swal.fire({
            title: '대표 날인은 삭제할 수 없습니다',
            icon: 'success'
          });
        } else {
          deletestamps(id, userId).then(function (response) {
            if (response.data) {
              // filter 함수의 결과를 다시 imageSrcs 변수에 할당
              const newarray = imageSrcs.filter(value => value.id !== id);
              setImageSrcs(newarray)
              Swal.fire({
                title: '날인이 삭제되었습니다',
                icon: 'success'
              })
            }
          })
        }
      }
    })
  };




  return (
    <div className='w100'>
      {slist && slist.length === 0 && original ? (
        <>
          <div className='client_top'>
            <p className='importtext'>등록된 날인이 없습니다</p>
            <div className='innerbox'>
              <h1>편리하고 빠른 계약을 원하시나요?</h1>
              <p>전자 서명이 가능한 날인을 등록해서 편리하게 사용하세요</p>
              <p>안심 하실수 있는 안전한 계약을 해보세요</p>
            </div>
          </div>
          <div className='flex c'>
            <button onClick={() => setNum(1)} className='rightbtn'>
              날인등록
            </button>
          </div>
        </>
      ) : (
        <>

          <div className='flex r'>
            {slist && slist.length > 0 ?
              <button onClick={() => mainstamp(select.id)} className='navibtn_hover mr10'>
                대표설정
              </button>
              : null}
            {original ?
              <button onClick={() => setNum(1)} className='navibtn_hover mr10'>
                날인등록
              </button>
              : ''
            }
            {original ?
              <button onClick={() => deletestamp(select.id)} className='navibtn_hover colorR'>
                삭제
              </button>
              : ''
            }
          </div>
          {slist && slist.length > 0 ?
            <div className='flex stampwrap'>
              {imageSrcs.map((src, index) => (
                <div className={src.id === select.id ? 'imgbox active' : 'imgbox'} onClick={() => setSelect(src)} key={index}>
                  <img src={src.base64} alt={`니즈로봇 외주개발 미리보기 ${index}`} />
                </div>
              ))}
            </div>
            :
            <div className='flex center'>
              <div style={{ color: "red", fontWeight: "bold" }}>
                등록된 날인이 없습니다. (마이페이지에서 등록해주세요)
              </div>
              <a className='navibtn_hover mt10' href="/Mypage/Myinfo/sealingmethod">마이페이지 이동</a>
            </div>
          }
        </>
      )}
    </div>
  )
}

export default Allstampbox;