
import React from 'react';
import { sortList } from '../project';
import { sort } from '../dataarray';
function Sortbox({ array, func, changePage }) {
  const sorting = (filter) => {
    const sortedArray = sortList([...array], filter); // Make a copy of the array
    func(sortedArray);
    changePage(1)
  };

  return (
    <ul className='sort_box flex'>
      {sort && sort.map((value, index) =>
        <li key={index} onClick={() => sorting(value.value)}>{value.label}</li>)}
    </ul>
  );
}

export default Sortbox;