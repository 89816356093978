import React, { createContext, useContext, useEffect, useState } from 'react';
import { alertreturn, getItemWithExpireTime, getLocal, receivemessage, returnLogintime, setItemWithExpireTime } from "./service/project";
const RealTimeDataContext = createContext();

const RealTimeDataProvider = ({ children }) => {
    const [token, setToken] = useState(getItemWithExpireTime('token'));
    const [time, setTime] = useState('');
    const [displayTime, setDisplayTime] = useState('');
    const [mlist, setMlist] = useState();
    const [messageNew, setmessageNew] = useState('')
    const updateDisplayTime = () => {
        if (time) {
            setDisplayTime(returnLogintime(time));
        }
    };
    useEffect(() => {
        const nullOrZeroCount = mlist && mlist.filter(item => item.detail.openmessage === null || item.detail.openmessage === 0).length;
        setmessageNew(nullOrZeroCount);
    }, [])
    useEffect(() => {
        if (token) {
            const objString = window.localStorage.getItem('token');
            if (!objString) {
                return null;
            }
            const obj = JSON.parse(objString);
            setTime(obj.expire);
            updateDisplayTime(); // 초기에도 호출

        }
    }, [token]);

    useEffect(() => {
        if (token) {
            recieveM()
        }
    }, [token]);
    const recieveM = () => {
        receivemessage(getItemWithExpireTime('token') && getItemWithExpireTime('token').userId).then(function (response) {
            if (response.data) {
                const sortedMessages = response.data.slice().sort((a, b) => {
                    if (a.openmessage === null && b.openmessage === null) return 0;
                    if (a.openmessage === null) return -1;
                    if (b.openmessage === null) return 1;
                    if (a.openmessage === 0 && b.openmessage === 0) return 0;
                    if (a.openmessage === 0) return -1;
                    if (b.openmessage === 0) return 1;

                    return 0;
                });
                setMlist(sortedMessages)
                const nullOrZeroCount = response.data.filter(item => item.detail.openmessage === null || item.detail.openmessage === 0).length;
                setmessageNew(nullOrZeroCount);

            } else {
                alertreturn('error');
            }
        });
    }

    useEffect(() => {
        if (token) {
            updateDisplayTime();
            const interval = setInterval(() => {
                updateDisplayTime();
            }, 60 * 1000);

            const receiveMessagesInterval = setInterval(() => {
                recieveM();
            }, 600000);

            return () => {
                clearInterval(interval);
                clearInterval(receiveMessagesInterval);
            };
        }
    }, [token, time]);





    return (
        <RealTimeDataContext.Provider
            value={{
                setmessageNew,
                setToken,
                token,
                setTime,
                time,
                setDisplayTime,
                displayTime,
                setMlist,
                mlist,
                messageNew
            }}
        >
            {children}
        </RealTimeDataContext.Provider>
    );
};

const useRealTimeData = () => {
    const context = useContext(RealTimeDataContext);
    if (!context) {
        throw new Error('useRealTimeData must be used within a RealTimeDataProvider');
    }
    return context;
};

export { RealTimeDataProvider, useRealTimeData };