import React from 'react';

function Mymessage_write({ writes, handle, func, user }) {

  return (
    <>
      <div className='w100 writebox'>
        <div>
          <div className='mb20'>
            받는이:
            {!user ?
              <input className="projectAdd_inputbox" required
                value={writes.user}
                onChange={(e) => { handle('user', e.target.value); }} />
              : <p>admin</p>
            }
          </div>
        </div>

        <div className='mb20'>
          제목:
          <input
            value={writes.title}
            className='projectAdd_inputbox' required onChange={(e) => { handle('title', e.target.value); }} />
        </div>
        <div className='mb20'>
          내용:
          <textarea
            value={writes.contents}
            className="projectAdd_inputbox heightover" required onChange={(e) => { handle('contents', e.target.value); }} />
        </div>
        <button className='navibtn_hover' onClick={() => func()}>전송</button>

      </div>
    </>
  );
}

export default Mymessage_write;