export const myTab = [
    {
        label: '나의 프로젝트',
        path: '/Mypage/Myprojects/myProject',
        value: 'myProject'
    },
    {
        label: '지원한 프로젝트',
        path: '/Mypage/Myprojects/applyProject',
        value: 'applyProject'
    },

    {
        label: '관심있는 프로젝트',
        path: '/Mypage/Myprojects/interestedProject',
        value: 'interestedProject'
    },

]
export const mypgTab = [
    {
        label: '클라이언트 정보',
        path: '/Mypage/Myinfo/clientinfo',
        value: 'clientinfo'
    },
    {
        label: '날인 방법 관리',
        path: '/Mypage/Myinfo/sealingmethod',
        value: 'sealingmethod'
    },

    {
        label: '계정관리',
        path: '/Mypage/Myinfo/Account ',
        value: 'Account'
    },

]

export const projectTabs = [
    {
        label: '전체',
        value: 0,
        biglabel: '전체',
        path: 'all',
        paths: '/Mypage/Myprojects/myProject/1',
        position: 'a'
    },
    {
        label: '임시저장',
        value: 1,
        biglabel: '프로젝트등록',
        path: 'a1',
        paths: '/Mypage/Myprojects/myProject/2',
        position: 'c'
    },
    {
        label: '프로젝트 등록',
        biglabel: '프로젝트등록',
        value: 2,
        position: 'c',
        paths: '/Mypage/Myprojects/myProject/2',
        path: 'a2',
    },
    {
        label: '견적서 작성',
        biglabel: '견적진행',
        value: 3,
        path: 'b1',
        paths: '/Mypage/Myprojects/myProject/3',
        position: 'p'
    },
    {
        label: '견적서 수신',
        biglabel: '견적진행',
        value: 4,
        path: 'b2',
        paths: '/Mypage/Myprojects/myProject/3',
        position: 'p'
    },
    {
        label: '견적 승인',
        biglabel: '견적진행',
        value: 5,
        path: 'b3',
        paths: '/Mypage/Myprojects/myProject/3',
        position: 'c'
    },

    {
        label: '계약서 작성',
        biglabel: '계약진행',
        value: 6,
        path: 'c1',
        paths: '/Mypage/Myprojects/myProject/4',
        position: 'p'
    },
    {
        label: '계약서 서명',
        biglabel: '계약진행',
        value: 7,
        path: 'c2',
        paths: '/Mypage/Myprojects/myProject/4',
        position: 'c'
    },
    {
        label: '선금 납부',
        biglabel: '프로젝트 진행',
        value: 8,
        path: 'd1',
        paths: '/Mypage/Myprojects/myProject/5',
        position: 'c'
    },
    {
        label: '1단계 개발',
        biglabel: '프로젝트 진행',
        value: 9,
        path: 'd2',
        paths: '/Mypage/Myprojects/myProject/5',
        position: 'p'

    },
    {
        label: '중도금 납부',
        biglabel: '프로젝트 진행',
        value: 10,
        paths: '/Mypage/Myprojects/myProject/5',
        path: 'd3',
        position: 'c'
    },
    {
        label: '2단계 개발',
        biglabel: '프로젝트 진행',
        value: 11,
        path: 'd4',
        paths: '/Mypage/Myprojects/myProject/5',
        position: 'p'
    },
    {
        label: '완료보고서',
        biglabel: '프로젝트 진행',
        value: 12,
        path: 'd5',
        paths: '/Mypage/Myprojects/myProject/5',
        position: 'p'
    },
    {
        label: '잔금 납부',
        biglabel: '프로젝트 진행',
        value: 13,
        path: 'd6',
        paths: '/Mypage/Myprojects/myProject/5',
        position: 'p'
    },

    {
        label: '프로젝트 완료',
        biglabel: '프로젝트 완료',
        value: 14,
        path: 'e1',
        paths: '/Mypage/Myprojects/myProject/6',
        position: 'p'
    },

]

export const applyTabs = [
    {
        label: '전체',
        value: 0,
        path: '/Mypage/Myprojects/applyProject/0',
    },
    {
        label: '신청완료',
        value: 1,
        path: '/Mypage/Myprojects/applyProject/1',
    },
    {
        label: '계약중',
        value: 2,
        path: '/Mypage/Myprojects/applyProject/2',
    },
    {
        label: '프로젝트진행중',
        value: 3,
        path: '/Mypage/Myprojects/applyProject/3',
    },
    {
        label: '프로젝트완료',
        value: 4,
        path: '/Mypage/Myprojects/applyProject/4',
    },
]

export const stampTap = [
    {
        label: '도장 등록',
        value: 0,

    },
    {
        label: '서명 등록',
        value: 1,
    },

]

export const modaltap = [
    {
        label: '등록내용',
        value: 0
    },
    {
        label: '견적서',
        value: 1
    },
    {
        label: '사양서',
        value: 2
    },
    {
        label: '계약서',
        value: 3
    },
    {
        label: '1차개발보고서',
        value: 4
    },
    {
        label: '2차개발보고서',
        value: 5
    },
    {
        label: '완료개발보고서',
        value: 6
    }

]
export const developtap = [
    {
        label: '견적내용',
        value: 1
    },
    {
        label: '계약내용',
        value: 2
    },
    {
        label: '개발보고서',
        value: 3
    }

]