
import React from 'react';
import {  comma, removeComma } from '../../service/project';
import { after_price, before_price, middle_price } from '../../service/dataarray';
import { VscSaveAs } from "react-icons/vsc";
function Project_contract({handleChanges , onClickss,onClick,contract,specificationImgRef,savePdfFile,userId}) {


  
  return (
    <div className="w100">
          <div className="input_wrap mb10">
            <h1 className="mb10 flex minititle a mt20">
              1.프로젝트 계약서 명칭
              <p className="subtext ml10">
                프로젝트 계약의 목적과 내용을 반영하는 명칭으로 지정할것
              </p>
            </h1>

            <input
              defaultValue={contract && contract.title}
              onChange={(e) => handleChanges("title", e.target.value)}
              className="projectAdd_inputbox"
            />
          </div>
          <div className="flex j a w100 mb10">
            <div className="input_wrap w33">
              <h1 className="mb10 flex minititle a">
                2.계약 시작일
                <p className="subtext ml10">실제 계약일자</p>
              </h1>
              <input
                type="date"
                defaultValue={contract && contract.startdate}
                onChange={(e) =>
                  handleChanges("startdate", e.target.value)
                }
                className="projectAdd_inputbox"
              />
            </div>
            <div className="input_wrap w33">
              <h1 className="mb10 flex minititle a">
                3.계약 마감일
                <p className="subtext ml10">프로젝트 완료예정 일자</p>
              </h1>
              <input
                type="date"
                defaultValue={contract && contract.lastdate}
                onChange={(e) =>
                  handleChanges("lastdate", e.target.value)
                }
                className="projectAdd_inputbox"
              />
            </div>
            <div className="input_wrap w33">
              <h1 className="mb10 flex minititle a">
                4.납품 장소
                <p className="subtext ml10"></p>
              </h1>
              <input
                defaultValue={contract && contract.deliverylocation}
                onChange={(e) =>
                  handleChanges("deliverylocation", e.target.value)
                }
                className="projectAdd_inputbox"
              />
            </div>
          </div>
          <div className="input_wrap mb10">
            <h1 className="mb10 flex minititle a">
              5.계약내용
              <p className="subtext ml10">
                시제품 설계 및 제작, 펌웨어, 개발자료, 제공방법 등
                간략하게 기재
              </p>
            </h1>
            <textarea
                defaultValue={contract && contract.detail}
              rows="3"
              value={contract && contract.detail}
              onChange={(e) => handleChanges("detail", e.target.value)}
              className="projectAdd_inputbox"
              placeholder=" ex) - 과금형 완속 충전기의 충전기 제어 보드 설계 및 제작
              - 과금형 완속 충전기의 충전기 임베디드 프로그램 구현
              - 개발 자료는 양산 제품의 5만대 납품(니즈로봇)후 제공함"
            />
          </div>
          <div className="flex j a w100 mb10">
            <div className="input_wrap w50">
              <h1 className="mb10 flex minititle a">
                6.계약 금액
                <p className="subtext ml10"></p>
              </h1>
              <input
                onChange={(e) =>
                  handleChanges("allprice", e.target.value)
                }
                value={contract && contract.allprice}
                className="projectAdd_inputbox"
              />
            </div>
            <div className="input_wrap w50">
              <h1 className="mb10 flex minititle a">
                7.계약 보증금
                <p className="subtext ml10"></p>
              </h1>
              <input
                defaultValue={contract && contract.deposit}
                onChange={(e) =>
                  handleChanges("deposit", e.target.value)
                }
                className="projectAdd_inputbox"
              />
            </div>
          </div>
          <div className="flex j a w100 mb10">
            <div className="input_wrap w33">
              <h1 className="mb10 flex minititle a">
                8.선금
                <p className="subtext ml10">
                  계약금액의 %비율로 입력(0 ~ 100%){" "}
                </p>
              </h1>
              <input
                defaultValue={contract && contract.beforeprice}
                onChange={(e) =>
                  handleChanges("beforeprice", e.target.value)
                }
                type="number"
                className="projectAdd_inputbox"
              />
              <p className='custom-price'>
                {contract &&
                  contract.allprice &&
                  comma(
                    (Number(removeComma(contract && contract.allprice)) *
                      Number(contract && contract.beforeprice)) /
                      100
                  )}{" "}
                원
              </p>
              <div className="mt10">
                <select
                  defaultValue={contract && contract.beforepricecon}
                  className="selectboxs_style"
                  onChange={(e) =>
                    handleChanges("beforepricecon", e.target.value)
                  }
                >
                  {before_price.map((value) => (
                    <option key={value.label}>{value.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input_wrap w33">
              <h1 className="mb10 flex minititle a">
                9.중도금
                <p className="subtext ml10">
                  계약금액의 %비율로 입력(0 ~ 100%)
                </p>
              </h1>

              <input
                defaultValue={contract && contract.middleprice}
                onChange={(e) =>
                  handleChanges("middleprice", e.target.value)
                }
                type="number"
                className="projectAdd_inputbox"
              />
              <p className='custom-price'>
                {contract &&
                  contract.allprice &&
                  comma(
                    (Number(removeComma(contract && contract.allprice)) *
                      Number(contract && contract.middleprice)) /
                      100
                  )}{" "}
                원
              </p>
              <div className="mt10">
                <select
                  defaultValue={contract && contract.middlepricecon}
                  className="selectboxs_style"
                  onChange={(e) =>
                    handleChanges("middlepricecon", e.target.value)
                  }
                >
                  {middle_price.map((value) => (
                    <option key={value.label}>{value.label}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="input_wrap w33">
              <h1 className="mb10 flex minititle a">
                10.잔금
                <p className="subtext ml10">
                  계약금액의 %비율로 입력(0 ~ 100%)
                </p>
              </h1>
              <input
                defaultValue={contract && contract.afterprice}
                onChange={(e) =>
                  handleChanges("afterprice", e.target.value)
                }
                type="number"
                className="projectAdd_inputbox"
              />
              <p className='custom-price'>
                {contract &&
                  contract.allprice &&
                  comma(
                    (Number(removeComma(contract.allprice)) * Number(contract.afterprice)) /
                      100
                  )}{" "}
                원
              </p>
              <div className="mt10">
                <select
                  defaultValue={contract && contract.afterpricecon}
                  className="selectboxs_style"
                  onChange={(e) =>
                    handleChanges("afterpricecon", e.target.value)
                  }
                >
                  {after_price.map((value) => (
                    <option key={value.label}>{value.label}</option>
                  ))}
                </select>
              </div>
         
            </div>
           
          </div>
          <div className="flex a">                    
        
<label htmlFor='specificationFileInput' className="gbox_hover mr10">
사양서 첨부
<input ref={specificationImgRef} className="none" id='specificationFileInput' type="file" accept="*" 
  
  onChange={(e) =>{
    handleChanges('newspecification',URL.createObjectURL(e.target.files[0]))
    handleChanges('newspecificationname',e.target.files[0].name)
    savePdfFile(specificationImgRef.current)
                }
  } />
</label>
{contract && contract? 
<div className='mr10 file-container '>
    {
     contract.newspecificationname? 
     contract.newspecificationname
     :contract.newspecification? 
     contract.newspecification.split('/')[contract.newspecification.split('/').length - 1]
    :contract.specificationname? 
    contract.specificationname
    :contract.specification?
    contract.specification.split('/')[contract.specification.split('/').length - 1]
  :'파일을 첨부해 주세요'}
  </div>
  :''
    }
  </div>

          <div className="flex relative mt20">
          <button
            onClick={() => onClickss()}
            className="navibtn_hover mr20"
          >
            {contract&&contract.c2f?'수정':'최종저장'}
          </button>
          {contract&&!contract.c2f? 
          <button
            onClick={() => onClick()}
            className="navibtn_hover"
          >
            임시저장
          </button>
          :null}{
            contract&&contract.companydate?
          <div className="f12 minitext">
            ({contract&&contract.companydate})
          </div>
          :''
          }

          
          </div>
          </div>

  );
}

export default Project_contract;