import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import FileDownloadAndPrint from "./FileDownloadAndPrint";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function Pdfcontain({
  file,
  mini,
  num,
  list,
  single,
  type,
  agree,
  onClick,
  nums,
  data,
  top
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoaded = ({ numPages }) => setNumPages(numPages);
  useEffect(() => {
    setPageNumber(1);
  }, [file, num]);
  const pagecouter = (types) => {
    if (pageNumber === 1 && numPages === pageNumber) {
      if (types) {
        Swal.fire("마지막페이지 입니다", "확인 버튼을 눌러 주세요", "error");
      } else {
        Swal.fire("처음페이지 입니다", "확인 버튼을 눌러 주세요", "error");
      }
    } else if (pageNumber === 1) {
      if (types) {
        setPageNumber(pageNumber + 1);
      } else {
        Swal.fire("처음페이지 입니다", "확인 버튼을 눌러 주세요", "error");
      }
    } else if (numPages === pageNumber) {
      if (!types) {
        setPageNumber(pageNumber - 1);
      } else {
        Swal.fire("마지막페이지 입니다", "확인 버튼을 눌러 주세요", "error");
      }
    } else if (types) {
      setPageNumber(pageNumber + 1);
    } else {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <div >
      <div className="pdfTop flex j">
        <div className="flex l">
          <div className="est-dev-contract mr10">
            {type === "estimate" ? "견적서" : "개발 사양서"}
          </div>
        </div>
        <div className="flex r mr10">
          <button
            className="next_btn_hover p10 mt10 ml10 "
            onClick={() => pagecouter(false)}
          >
            이전
          </button>
          <span className="mr10 flex a">
            <span className="pdf_border-box p10 mt10 ml10">
              {pageNumber} / {numPages}
            </span>
          </span>
          <button
            className="next_btn_hover p10 mt10 mr10"
            onClick={() => pagecouter(true)}
          >
            다음
          </button>
          <FileDownloadAndPrint list={list} single={single} type={type} />
          {(agree && nums === 1 && data && !data.b3f) ||
            (nums === 2 && data && data.c1 && !data.c1f) ||
            (nums === 4 && data && data.d2 && !data.d2f) ||
            (nums === 5 && data && data.d4 && !data.d4f) ||
            (nums === 6 && data && data.d5 && !data.d5f) ? (
            <button
              onClick={() => onClick(nums)}
              className="next_btn_hover p10 mt10 ml20 "
            >
              승인
            </button>
          ) : (
            ""
          )}
        </div>
        <div className={top ? "horizontal-eastimate-bar t140 " : "horizontal-eastimate-bar t80 "}></div>
      </div>

      <div className="flex center">
        <div className="quote-container mt20">
          <Document
            className={mini ? "pdfcontain mini" : "pdfcontain"}
            file={file}
            onLoadSuccess={onDocumentLoaded}
            renderMode="svg"
          >
            <Page
              scale={3}
              annotationLayer={false}
              renderTextLayer={false}
              pageNumber={pageNumber}
            />
          </Document>
        </div>
      </div>

    </div>
  );
}

export default Pdfcontain;
