import React from 'react';
import { BiMessageError } from "react-icons/bi";
function Mymessage_messageBox({ value, handle, type, path }) {

  const inputString = path !== 'writeMessage' && value && value.detail.title;
  const regex = /계약서가 작성이 완료되었습니다|사양서가 제출되었습니다|견적이 제출되었습니다|1차개발보고서가 제출되었습니다|2차개발보고서가 제출되었습니다|최종개발보고서가 제출되었습니다|견적이 수정되었습니다|계약서가 작성이 완료되었습니다|견적이 승인되었습니다/g;
  const matches = inputString && inputString.match(regex);



  return (
    <>
      {value && type === 'user' ?
        <div className='messageBox user' key={value.userId}>
          <div className='messageBox_h' onClick={() => handle('user', value.userId)}>{value.userId}</div>
        </div>
        : value && type === 'other' ?
          <div className={value.detail.type === 2 ? 'messageBox noticebox' : 'messageBox othersbox'} key={value.detail.id}>
            <div className='messageBox_h' onClick={() => handle('other', value.detail.id)}>
              <h1>{value.detail.type === 1 ? `${value.projectDetail.title} 프로젝트의 ${matches && matches[0]}` : value.detail.title}</h1>
              <p>{value.detail.contents}</p>
              {!value.detail.openmessage ?
                <BiMessageError className='messageIcon' />
                : ''
              }
            </div>
          </div>
          : ''
      }
    </>
  );
}

export default Mymessage_messageBox;