
import React from 'react';
import { useEffect } from 'react';
import Subhead from '../Subhead';
import { myproject } from '../../service/project';
import { useState } from 'react';
import MyProject from './MyProject';
import { useLocation, useNavigate } from 'react-router-dom';
import Applyproject from './Applyproject';
import Interestedproject from './Interestedproject';
import MetaTag from '../../MetaTag';
import { metaMypage } from '../../service/mataTags';
import { imageArray } from '../../service/dataarray';

function Myprojects({ userInfo, userId }) {
  const location = useLocation()
  const [project, setProject] = useState([]);
  const [updateData, setUpdateData] = useState([])
  const parts = location.pathname.split('/');
  const lastPart = parts[parts.length - 1];
  const lastsPart = parts[parts.length - 2];

  useEffect(() => {
    let isMounted = true;
    myproject(userInfo && userInfo.userId)
      .then(function (response) {
        if (isMounted) { // 컴포넌트가 마운트된 상태에서만 실행
          const saveListWithConvertedMinicate = response.data.map(item => {
            item.minicate = JSON.parse(item.minicate);
            item.conditions = JSON.parse(item.conditions);
            return item;
          });

          setProject(saveListWithConvertedMinicate);
          setUpdateData(saveListWithConvertedMinicate);
        }
      })
      .catch(function (error) {
        console.log(error);
      });


    return () => {
      isMounted = false; // 컴포넌트가 언마운트될 때 상태 변경
    };
  }, []);
  return (
    <div className='heightAuto'>
      <MetaTag {...metaMypage} />
      <div className='backback greyb'></div>
      {userId ?
        <div className="project">
          <Subhead marginBottom={'15px'} value={'MY PROJECTS'} subvalue={'내가 올린 나의 프로젝트들 입니다'} img={imageArray.subtitle_portfolio} />

          <div className='w1480 h100 mypro_contain'>

            {parts[3] === 'myProject' ?
              <MyProject userId={userInfo && userInfo.userId} updateData={updateData} setProject={setProject} project={project} />
              : lastPart === 'applyProject' || lastsPart === 'applyProject' ?
                <Applyproject userId={userInfo && userInfo.userId} />
                : lastPart === 'interestedProject' || lastsPart === 'interestedProject' ?
                  <Interestedproject userId={userInfo && userInfo.userId} />
                  : ''
            }


          </div>

        </div>
        : ''

      }
    </div>
  );
}

export default Myprojects;