
import React from 'react';
import { cate, moneystatus, sminicate } from '../../service/dataarray';
import { projectTabs } from '../../service/tabarray';

function Admin_left({ handleChange }) {
  return (
    <div className='project_left'>
      <div className='pro_left_box'>
        <h1>카테고리</h1>
        <ul className=''>
          {cate.map((value, index) =>
            <li key={value.label} className='radiolabel'>
              <label>
                <input
                  onChange={() => { handleChange('cate', value.label) }}
                  type='checkbox' />
                {value.label}
              </label>
            </li>
          )}
        </ul>
      </div>
      <div className='pro_left_box mt30'>
        <h1>세부 카테고리</h1>
        <ul className=''>
          {sminicate.map((value, index) =>
            <li key={value.label} className='radiolabel'>
              <label>
                <input
                  onChange={() => { handleChange('minicate', value.label) }}
                  type='checkbox' />
                {value.label}
              </label>
            </li>
          )}
        </ul>
      </div>
      <div className='pro_left_box mt30'>
        <h1>진행단계</h1>
        <ul className=''>
          {projectTabs.filter(values => values.label !== '전체').map((value, index) =>
            <li key={value.label} className='radiolabel'>
              <label>
                <input type='checkbox'
                  onChange={() => { handleChange('adminstatus', value.label) }} />
                {value.label}
              </label>
            </li>
          )}
        </ul>
      </div>
      <div className='pro_left_box mt30'>
        <h1>계약금 납부상태</h1>
        <ul className=''>
          {moneystatus.map((value, index) =>
            <li key={value.label} className='radiolabel'>
              <label>
                <input type='checkbox'
                  onChange={() => { handleChange('moneystatus', value.value) }} />
                {value.label}
              </label>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default Admin_left