
import React from 'react';
import { Link } from 'react-router-dom';
function MyProjects_l({ admin, project, num }) {
  return (

    <ul className='myproject_left'>
      <li className='flex j tableT'>
        <span style={admin ? { width: 'calc(100% - 330px)' } : { width: 'calc(100% - 130px)' }}>프로젝트 제목</span>

        <div className='flex'>
          {admin ?
            <span className='w140'>등록상태</span>
            : ""
          }
          <span className='w140'>등록일자</span>
          <span className='w140'>수정일자</span>
        </div>

      </li>
      {project && num[0] !== '전체' ? project.filter(value => value.adminstatus === num[0])
        .sort((a, b) => {
          if (a.adminstatus < b.adminstatus) return -1;
          if (a.adminstatus > b.adminstatus) return 1;
          return 0;
        }).map((value, index) =>
          <Link className='flex myprojectli j' to={`/Mypage/Myprojects/myProject?uid=${value.uid}`} key={index}>
            <span>{value.title}</span>

            <span className='w140'>
              {value.updatetime}
            </span>
          </Link>
        ) : project && admin ? project.sort((a, b) => {
          if (a.adminstatus < b.adminstatus) return -1;
          if (a.adminstatus > b.adminstatus) return 1;
          return 0;
        }).map((value, index) =>
          <Link className='flex myprojectli j' to={`?uid=${value.uid}`} key={index}>
            <span>{value.title}</span>
            <div className='flex'>
              <span className='w140'>
                {value.adminstatus}
              </span>
              <span className='flex w140 j'>
                {value.createdtime}
              </span>
              <span className='w140'>
                {value.updatetime}
              </span>
            </div>
          </Link>
        ) : project && project.map((value, index) =>
          <Link className='flex myprojectli j' to={`/Mypage/Myprojects/myProject?uid=${value.uid}`} key={index}>
            <span>{value.title}</span>
            <div className='flex'>
              <span className='flex w140 j'>
                {value.createdtime}
              </span>
              <span className='flex w140 j'>
                {value.updatetime}
              </span>
            </div>
          </Link>
        )


      }
    </ul>
  );
}

export default MyProjects_l