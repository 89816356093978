import Approuter from "./Approuter";
import './JDH_style.css';
import './style.css';
import './reset.css';
import React, { useEffect, useState } from "react";
import { RealTimeDataProvider } from './RealTimeDataContext';
import { useMediaQuery } from "react-responsive";

function App() {



  return (
    <RealTimeDataProvider>
      <Approuter />
    </RealTimeDataProvider>
  );
}

export default App;
