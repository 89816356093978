import React from 'react';
import './checkcss.css'
function Checkbox({ category, value, className, onClick, onChange, radio, check, other, placeholder, labelName, pg }) {
  return (

    <li className={className} >
      <label className='lilabel'>

        <input type={radio ? 'radio' : 'checkbox'}

          onChange={onClick}
          checked={check && category == 'cate' ? check == value.label :
            check && category == 'minicate' ? check.includes(value.value) :
              check && category == 'conditions' ? check.includes(value.label) :
                check == [] || check == undefined ? '' : ''}
          className="overflow-control-input" />
        <span className="overflow-control-description"> {value.label}</span>
        {labelName === '기타' ?
          <input className='otherinput' defaultValue={other ? other : ''} onChange={(pg.cate === '기타' || pg.minicate === '기타') && onChange} placeholder={placeholder} />
          : ''
        }
      </label>

    </li>
  );
}

export default Checkbox;