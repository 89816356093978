import React, { useRef, forwardRef } from 'react';
import ReactToPrint from 'react-to-print';
import { BiPrinter } from 'react-icons/bi';

import Out_Contract_signature from '../../components/Outsourcings/Out_Contract_signature';
import Out_Basic_contracts from '../../components/Outsourcings/Out_Basic_contracts';
import Out_Main_contract from '../../components/Outsourcings/Out_Main_contract';
import { exuserinfos } from '../dataarray';


const Mycontract = forwardRef(({ contract, userinfo }, ref) => (
  <div ref={ref}>
    {userinfo && contract ?
      <>
        <Out_Main_contract contract={contract} />
        <Out_Basic_contracts mini={true} userinfo={userinfo} />
        <Out_Contract_signature notmain={true} userinfo={userinfo} contract={contract} />
      </>
      : null
    }

  </div>
));

export const PDFDownloader = ({ contract, userinfo }) => {
  const divref = useRef(); // useRef 초기화

  return (
    <div>
      <ReactToPrint
        trigger={() => <button className="pdfDownloadBTN mt10"><BiPrinter className="f20" /></button>}
        content={() => divref.current} // divref를 content로 전달
      />
      <div style={{ display: 'none' }}>
        <Mycontract contract={contract} userinfo={contract && contract.uid === 'exampledata' ? exuserinfos : userinfo} ref={divref} />
      </div>
    </div>
  );
};