
import React from 'react';
import { useState, useEffect } from 'react';
import { addCommasToNumber, allProjects, contracts, getContract, getDate, myinfos, offerUid, uidproject, upgradeCheck, uploaddocufile } from '../../service/project';
import Projectedit from './Projedtedit';
import Projectpgs from './Projectpgs';
import Swal from 'sweetalert2';
import { ModalUser } from '../../service/components/Modals';
import Projectuserbox from './Projectuserbox';
import { projectTabs } from '../../service/tabarray';
import Projectdevelop from './Projectdevelop';



function Projectpgbox({ handleChange, edit, pg, allpg, uppg, path, updates, setEdit, userId, setPgs, setupPgs, setallPg, admin, setupPg, setSelectedFiles }) {

  const [isOpens, setIsOpens] = useState(false);
  const openModalHandlers = () => {
    setIsOpens(!isOpens);
  };


  const [isHeaderFixeds, setIsHeaderFixeds] = useState(false);
  const [userinfo, setuserinfo] = useState('')
  const [dedit, setDedit] = useState(false)
  const [contract, setContract] = useState({
    title: '',
    deliverylocation: '',//납품장소
    allprice: '',//계약금액
    deposit: '',//보증금
    detail: '',//상세내용
    startdate: '', //계약시작일자
    lastdate: '',//계약마감일자
    beforeprice: '', //선금
    beforepricecon: '',
    middleprice: '', //중도금
    middlepricecon: '',
    afterprice: '', //잔금
    afterpricecon: '',
    companydate: '',
    estimate: '',//견적서파일명
    estimatename: '',//견적서파일명
    newestimate: '',//견적서파일명
    newestimatename: '',//견적서파일명
    specification: '',//사양서파일명
    specificationname: '',//사양서파일이름
    newspecification: '',//새사양서파일명
    newspecificationname: '',//새사양서파일이름
    develop1: '',//1차개발파일명
    develop1name: '',//1차개빌파일이름
    newdevelop1: '',//새1차개발파일명
    newdevelop1name: '',//새1차개발파일이름
    develop2: '',//2차개발파일명
    develop3name: '',//2차개빌파일이름
    newdevelop2: '',//새2차개발파일명
    newdevelop2name: '',//새2차개발파일이름
    develop3: '',//3차개발파일명
    develop3name: '',//3차개빌파일이름
    newdevelop3: '',//새3차개발파일명
    newdevelop3name: '',//새3차개발파일이름

  })
  const [offer, setOffer] = useState({
    adminprice: '',
    adminterm: '',
    admincontent: ''
  })
  const [offerlist, setOfferlist] = useState('')


  function saveContract(date, code) {
    contracts(
      pg && pg.uid,
      contract.title,
      contract.deliverylocation,
      contract.allprice,
      contract.deposit,
      contract.detail,
      contract.startdate,
      contract.lastdate,
      contract.beforeprice,
      contract.beforepricecon,
      contract.middleprice,
      contract.middlepricecon,
      contract.afterprice,
      contract.afterpricecon,
      date,
      code,
      contract.newspecificationname,
      contract.specification,
      pg.userId

    ).then(function (response) {
      if (response.data) {
        if (contract.newspecification) {
          uploaddocufile(contract.newspecification, pg.uid, contract.newspecificationname, 'specification', pg.userId);
        } else if (contract.newdevelop1) {
          uploaddocufile(contract.newdevelop1, pg.uid, contract.newdevelop1name, 'develop1', pg.userId);
        } else if (contract.newdevelop2) {
          uploaddocufile(contract.newdevelop2, pg.uid, contract.newdevelop2name, 'develop2', pg.userId);
        } else if (contract.newdevelop3) {
          uploaddocufile(contract.newdevelop3, pg.uid, contract.newdevelop3name, 'develop3', pg.userId);
        }
        Swal.fire(
          "저장 되었습니다",
          'success'
        );
      }
    });
  }

  const onClick = () => {
    saveContract(0, 0);
  }

  const onClickss = () => {
    saveContract(getDate(), contract.c2f ? 2 : 1);
  }
  const handleChanges = (category, itemName) => {
    if (category === 'allprice' || category === 'beforeprice' || category === 'middleprice' || category === 'afterprice') {
      setContract(prevState => ({
        ...prevState,
        [category]: itemName.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }));

    } else if (category === 'price') {
      const numericValue = parseFloat(itemName.replace(/,/g, ""));

      // 입력값이 숫자일 경우에만 설정
      if (!isNaN(numericValue)) {
        setContract(prevState => ({
          ...prevState,
          [category]: addCommasToNumber(numericValue)
        }));
      }
      else {
        setContract(prevState => ({
          ...prevState,
          [category]: ''
        }));
      }
    } else if (category === 'adminprice' || category === 'adminterm' || category === 'admincontent') {
      const numericValue = parseFloat(itemName.replace(/,/g, ""));

      if (category === 'adminprice') {
        if (!isNaN(numericValue)) {
          setOffer(prevState => ({
            ...prevState,
            [category]: addCommasToNumber(numericValue)
          }));
        }
        else {
          setOffer(prevState => ({
            ...prevState,
            [category]: ''
          }));
        }
      } else {
        setOffer(prevState => ({
          ...prevState,
          [category]: itemName
        }));
      }
    }
    else {
      setContract(prevState => ({
        ...prevState,
        [category]: itemName
      }));
    }
  };
  useEffect(() => {
    const handleScrollAndResize = () => {
      const newIsHeaderFixed = window.scrollY >= 330;
      setIsHeaderFixeds(newIsHeaderFixed);

      const parentElement = document.querySelector('.addproject');
      const fixedElement = document.querySelector('.add_left.fixed');
      const fixedElements = document.querySelector('.add_left');

      if (parentElement && fixedElement) {
        const parentRect = parentElement.getBoundingClientRect();
        fixedElement.style.left = parentRect.left + 'px';
      } else if (fixedElements) {
        fixedElements.style.left = '0px';
      } else {
        console.error('Cannot find fixedElements element.');
      }

    };
    if (!admin) {
      allProjects().then(function (response) {

        setallPg(response.data);

      }).catch(function (error) {
        // Handle error here
        console.error("Error fetching data:", error);
      });

    }
    if (admin) {
      uidproject(path).then(function (response) {
        const saveListWithConvertedMinicate = response.data.map(item => {
          if (item.minicate) {
            item.minicate = JSON.parse(item.minicate);
            item.conditions = JSON.parse(item.conditions);
          }
          return item;
        });

        setPgs(saveListWithConvertedMinicate[0]);
        setupPgs(saveListWithConvertedMinicate[0]);
        myinfos(saveListWithConvertedMinicate[0].userId)
          .then(function (response) {
            if (response.data) {
              setuserinfo(response.data[0]);
            }
          });
      }).catch(function (error) {
        // Handle error here
        console.error("Error fetching data:", error);
      });
      getContract(path).then(function (response) {
        if (response.data) {
          setContract(response.data[0])
        }

      })
      offerUid(path).then(function (response) {
        if (response.data) {
          setOfferlist(response.data)
        }

      })

    }
    window.addEventListener('scroll', handleScrollAndResize);
    window.addEventListener('resize', handleScrollAndResize);

    return () => {
      window.removeEventListener('scroll', handleScrollAndResize);
      window.removeEventListener('resize', handleScrollAndResize);
    };
  }, []);


  const upgrade = () => {
    const selectedTab = projectTabs.find(tab => tab.label === pg.adminstatus);
    if (selectedTab) {
      const nextTab = projectTabs.find(tab => tab.value === selectedTab.value + 1);
      if (nextTab) {
        Swal.fire({
          title: `${nextTab.label} 단계로 변경하시겠습니까?`,
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: `아니요`,
          confirmButtonText: '네'
        }).then((result) => {
          if (result.isConfirmed) {
            upgradeCheck(pg.uid, nextTab.label, nextTab.path).then(function (response) {
              if (response.data) {
                setPgs(prevPg => ({
                  ...prevPg,
                  adminstatus: nextTab.label
                }));
                Swal.fire(
                  "진행단계가 변경 되었습니다",
                  '확인 버튼을 눌러 주세요',
                  'success'
                );
              }
            });
          }
        })
      } else {
        Swal.fire(
          '단계변동이 불가능합니다',
          '확인 버튼을 눌러 주세요',
          'error'
        );
      }
    }
  };
  const downgrade = () => {
    const selectedTab = projectTabs.find(tab => tab.label === pg.adminstatus);

    if (selectedTab) {
      const prevTab = projectTabs.find(tab => tab.value === selectedTab.value - 1);
      if (prevTab) {
        Swal.fire({
          title: `${prevTab.label} 단계로 변경하시겠습니까?`,
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: `아니요`,
          confirmButtonText: '네'
        }).then((result) => {
          if (result.isConfirmed) {
            upgradeCheck(pg.uid, prevTab.label, prevTab.path).then(function (response) {
              if (response.data) {
                setPgs(prevPg => ({
                  ...prevPg,
                  adminstatus: prevTab.label
                }));
                Swal.fire(
                  "진행단계가 변경 되었습니다",
                  '확인 버튼을 눌러 주세요',
                  'success'
                );
              }
            });
          }
        })
      } else {
        Swal.fire(
          '단계변동이 불가능합니다',
          '확인 버튼을 눌러 주세요',
          'error'
        );
      }

    }
  };
  return (
    <div className={isHeaderFixeds ? 'w1480 h100 flex addproject r' : 'w1480 h100 flex addproject j'}>
      {pg && allpg ?
        <div className={isHeaderFixeds ? 'add_left wide fixed' : 'add_left wide'}>
          <Projectuserbox upgrade={upgrade} downgrade={downgrade} edit={edit} pg={pg} allpg={allpg} updates={updates} setEdit={setEdit} userId={userId} admin={admin} openModalHandlers={openModalHandlers} dedit={dedit} setDedit={setDedit} />
        </div>
        : ""
      }
      {pg && allpg && !edit && !dedit ?
        <Projectpgs offerlist={offerlist} setContract={setContract} userinfo={userinfo} contract={contract} admin={admin} path={path} setPgs={setPgs} edit={edit} setEdit={setEdit} updates={updates} setupPgs={setupPgs} right={true} pg={pg} />
        : edit ?
          <Projectedit setSelectedFiles={setSelectedFiles} setupPg={setupPg} admin={admin} handleChange={handleChange} isHeaderFixeds={isHeaderFixeds} updates={updates} userId={userId} pg={uppg} edit={edit} />
          : dedit ?
            <Projectdevelop onClick={onClick} onClickss={onClickss} offer={offer} handleChanges={handleChanges} offerlist={offerlist} contract={contract} project={pg} userId={pg.userId} sadmin={admin} />
            :
            ''
      }

      {isOpens ?
        <ModalUser data={userinfo} openModalHandlers={openModalHandlers} isOpens={isOpens} admin={true} />
        : ''
      }

    </div>
  );
}

export default Projectpgbox;