
import React from 'react';
import { comma, offerUid, removeComma } from '../../service/project';


function Estimatecell({ array, detailProject }) {
  const flist = array && array.filter(values => values.num === 1)[0]
  const second = array && array.filter(values => values.num === 2)[0]
  const third = array && array.filter(values => values.num === 3)[0] || {
    price: '',
    term: '',
    createdtime: ''
  }

  const pricereturn2 = (value) => {
    if (value === '0') {
      return <span>{`0 (0) 원`}</span>;
    } else {
      const floatValue = parseFloat(removeComma(value));
      const plusValue = floatValue + floatValue * 0.1;
      return <span>{`${comma(plusValue)} 원`}</span>;
    }
  }

  return (
    <>
      <div className='estimatebox'>
        <div className='flex j a '>
          <div className='w50_ pr'>
            <div className='estiHead'>클라이언트</div>
            <div className='w100 table_container'>
              <div className='esti_head'>1차 예상금액</div>
              <ul className='w100'>
                <li>
                  <span>예상금액 </span>
                  :<span className='term'></span> {pricereturn2(detailProject.price)}
                </li>
                <li >
                  <span>예상기간 </span>
                  <span>: <span className='term'></span>{detailProject.term} 일</span>
                </li>
                <li >
                  <span>작성일 </span>
                  <span>: <span className='term'></span>{detailProject.createdtime}</span>
                </li>
              </ul>
            </div>
            {second ?
              <div className='w100 table_container'>
                <div className='esti_head'>수정 요청금액</div>
                <ul className='w100'>
                  <li>
                    <span>예상금액</span>
                    :<span className='term'></span> {pricereturn2(second.price)}
                  </li>
                  <li >
                    <span>예상기간</span>
                    <span>: <span className='term'></span>{second.term} 일</span>
                  </li>
                  <li >
                    <span>작성일 </span>
                    <span>: <span className='term'></span>{second.createdtime}</span>
                  </li>
                </ul>
              </div>
              : null}
          </div>

          <div className='w50'>
            <div className='estiHead'>파트너</div>
            {flist ?
              <div className='w100 table_container'>
                <div className='esti_head'>1차 견적금액</div>
                <ul className='w100'>
                  <li>
                    <span>견적금액</span>
                    :<span className='term'></span> {pricereturn2(flist.price)}
                  </li>
                  <li >
                    <span>견적기간</span>
                    <span>:<span className='term'></span> {flist.term} 일</span>
                  </li>
                  <li >
                    <span>작성일 </span>
                    <span>:<span className='term'></span> {flist.createdtime}</span>
                  </li>
                </ul>
              </div>
              : <div className='w100 table_container'>
                <div className='esti_head'>1차 견적금액</div>
                <ul className='w100'>
                  <li>
                    <span>견적금액</span>
                    :
                  </li>
                  <li >
                    <span>견적기간</span>
                    <span>: </span>
                  </li>
                  <li >
                    <span>작성일 </span>
                    <span>: </span>
                  </li>
                </ul>
              </div>}
            {second ?
              <div className='w100 table_container'>
                <div className='esti_head'>2차 견적금액</div>
                <ul className='w100'>
                  <li>
                    <span>견적금액</span>
                    : <span className='term'></span>{pricereturn2(third.price)}
                  </li>
                  <li >
                    <span>견적기간</span>
                    <span>:<span className='term'></span> {third.term} 일</span>
                  </li>
                  <li >
                    <span>작성일 </span>
                    <span>: <span className='term'></span>{third.createdtime}</span>
                  </li>
                </ul>
              </div>
              : null}
          </div>

        </div>


      </div>
    </>
  );
}

export default Estimatecell;