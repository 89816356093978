
import React from 'react';
import { BiUpload } from 'react-icons/bi';

function Project_file({ handleChanges, onClickss, onClick, contract, develop1Ref, develop2Ref, develop3Ref, savePdfFile, userId }) {



  return (
    <div>
      <div className="flex a mt20 j">
        <label htmlFor='develop1' className="gbox_hover">
          1차 개발 보고서 <BiUpload className='f14 ml10' />
          <input ref={develop1Ref} className="none" id='develop1' type="file" accept="*"
            onChange={(e) => {
              handleChanges('newdevelop1', URL.createObjectURL(e.target.files[0]))
              handleChanges('newdevelop1name', e.target.files[0].name)
              savePdfFile(develop1Ref.current)
            }} />
        </label>
        {contract && contract ?
          <div className='mr10 file-container '>
            {
              contract.newdevelop1name ?
                contract.newdevelop1name
                : contract.newdevelop1 ?
                  contract.newdevelop1.split('/')[contract.newdevelop1.split('/').length - 1]
                  : contract.develop1name ?
                    contract.develop1name
                    : contract.develop1 ?
                      contract.develop1.split('/')[contract.develop1.split('/').length - 1]
                      : '파일을 첨부해 주세요'}
          </div>
          : ''
        }
      </div>
      <div className="flex a mt20 j">
        <label htmlFor='develop2' className="gbox_hover">
          2차 개발 보고서 <BiUpload className='f14 ml10' />
          <input ref={develop2Ref} className="none" id='develop2' type="file" accept="*"

            onChange={(e) => {
              handleChanges('newdevelop2', URL.createObjectURL(e.target.files[0]))
              handleChanges('newdevelop2name', e.target.files[0].name)
              savePdfFile(develop2Ref.current)
            }
            } />
        </label>
        {contract && contract ?
          <div className='mr10 file-container '>
            {
              contract.newdevelop2name ?
                contract.newdevelop2name
                : contract.newdevelop2 ?
                  contract.newdevelop2.split('/')[contract.newdevelop2.split('/').length - 1]
                  : contract.develop2name ?
                    contract.develop2name
                    : contract.develop2 ?
                      contract.develop2.split('/')[contract.develop2.split('/').length - 1]
                      : '파일을 첨부해 주세요'}

          </div>
          : ''
        }

      </div>
      <div className="flex a j mt20">
        <label htmlFor='specificationFileInput' className="gbox_hover  ">3차 개발 보고서 <BiUpload className='f14 ml10' />
          <input ref={develop3Ref} className="none" id='specificationFileInput' type="file" accept="*"

            onChange={(e) => {
              handleChanges('newdevelop3', URL.createObjectURL(e.target.files[0]))
              handleChanges('newdevelop3name', e.target.files[0].name)
              savePdfFile(develop3Ref.current)
            }
            } />
        </label>
        {contract && contract ?
          <div className='mr10 file-container '>
            {
              contract.newdevelop3name ?
                contract.newdevelop3name
                : contract.newdevelop3 ?
                  contract.newdevelop3.split('/')[contract.newdevelop3.split('/').length - 1]
                  : contract.develop3name ?
                    contract.develop3name
                    : contract.develop3 ?
                      contract.develop3.split('/')[contract.develop3.split('/').length - 1]
                      : '파일을 첨부해 주세요'}
          </div>
          : ''
        }
      </div>
      <div className='flex'>
        <button
          onClick={() => onClickss()}
          className="navibtn_hover mr20"
        >
          저장
        </button>


      </div>
    </div>

  );
}

export default Project_file;