

import React from 'react';
import { needsstamp, getDate, clientContract, alertreturn } from '../../service/project';
import { companyinfo } from '../../service/dataarray';
import Mainstamp from '../../service/components/Mainstamp';
import Swal from 'sweetalert2';

function Out_Contract_signature({ userinfo, contract, fresh, setStampid, notmain, admin, setContract, stampid }) {

  const clientContracts = () => {
    Swal.fire({
      title: "전자서명 하시겠습니까?",
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '네',
      cancelButtonText: `아니요`,
    }).then((result) => {
      if (result.isConfirmed) {

        clientContract(stampid, contract.uid).then(function (response) {
          if (response.data) {
            alertreturn('contractdone')
            const updatedContract = { ...contract, clientstamp: stampid };
            setContract(updatedContract)

          }
        })
      } else {
        alertreturn('xsign')
      }
    })

  }
  return (
    <>
      {userinfo ?

        <div className={admin ? 'mt40 signaturebox' : 'signaturebox'}>
          <h3> 위의 계약을 체결함에 있어 '을'는 계약일반조건, 개발내역서 등의 모든 업무 및 기술조건을 이행할 것을 확약하고 기명날인한다.  </h3>
          <div className='flex j a bottombox'>
            <div className='clientbox w50'>
              <p className='p5'>
                (주소) <span className='ml10'>{userinfo.companyaddressf ? userinfo.companyaddressf : '마이페이지에 주소를'} {userinfo.companyaddressm ? userinfo.companyaddressm : '입력해주세요'}</span>
              </p>
              <p className='p5'>
                (회사명) <span className='comPany ml10'>{userinfo.company ? userinfo.company : '회사명을 입력해주세요'}({userinfo.companyregiNum ? userinfo.companyregiNum : '123-45-67890'})</span>
              </p>

              <p className='p5'>
                (서명일자) <span className='ml10'>{contract.c2f ? contract.c2f : getDate()}</span>
              </p>
              <div className='p5'>

                (대표자)  <span className='ml10'>{userinfo.representative ? userinfo.representative : '대표자를 입력해주세요'}</span> (인)
                <span className='stampwrap'><Mainstamp stampnum={userinfo.confidentstamp} notmain={notmain} stampid={stampid} setStampid={setStampid} fresh={fresh} userId={userinfo.userId} />
                </span>
              </div>
            </div>
            <div className='rightclientbox w50'>
              <p className='p5'>
                (주소)<span className='ml10'>{companyinfo.address}</span>
              </p>
              <p className='p5'>
                (회사명) <span className='myCompany ml10'>{companyinfo.mycompany}({companyinfo.comnum})</span>
              </p>
              <p className='p5'>
                (서명일자) <span className='ml10'>{contract.d1}</span>
              </p>
              <div className='p5'>

                (대표자) <span className='ml10'>{companyinfo.representative}</span> (인)
                <span className='stampwrap'>
                  {contract.d1 ?
                    <div className='stampbox'>
                      {needsstamp}
                    </div>
                    :
                    ''
                  }</span>
              </div>
            </div>
          </div>
          <div className='flex c a w100'>
            <div className='flex center'>
              {!contract.c2f && !contract.clientstamp && stampid ?
                <button className='navibtn_hover' onClick={() => { clientContracts() }}>전자서명</button>
                : null
              }
              {!contract.c2f && !contract.clientstamp ?
                <div className='f14 mt10'>
                  <p>날인변경을 원하는 경우 마이페이지에 날인관리에서 변경 가능합니다</p>
                </div>
                : null
              }
            </div>
          </div>
        </div>
        : null}
    </>
  );
}

export default Out_Contract_signature;

