
import React from 'react';
import { motion } from "framer-motion"
import { techBoxesData } from "../dataarray"
const TechBox = ({ data }) => (
    <motion.div
        className='maintechbox'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        transition={{ ease: 'easeOut', duration: 1, delay: data.delay }}
    >
        <div className='maintechinbox'>
            <img src={data.imageSrc} alt={data.altText} />
            <div className='w100 flex center'>
                <h1 className='techboxT'>{data.title}</h1>
                <h2 className='techboxT'>{data.subTitle}</h2>
                {data.description.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
        </div>
    </motion.div>
);

export const TechBoxes = () => (
    <div className='maintech'>
        {techBoxesData.map((boxData, index) => (
            <TechBox key={index} data={boxData} />
        ))}
    </div>
);